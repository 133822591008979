import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Container } from '@material-ui/core';

import JiscButton from '../../components/JiscButton/JiscButton';

import urls from '../../config/urls';
import styles from './NotFound.styles';

const NotFound = () => {
    const classes = styles();

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.notFound.title}</title>
                <link rel='canonical' href={urls.base.url} />
            </Helmet>
            <main className={classes.root}>
                <Container>
                    <section id='fes-404' data-testid='not-found' className={classes.content}>
                        <Typography variant='h1' className={classes.title}>
                            {urls.notFound.text}
                        </Typography>
                        <Typography variant='body1'>
                            The page you are looking for could not be found or has moved.
                        </Typography>

                        <JiscButton isLink href='/' className={classes.button}>
                            Go Back
                        </JiscButton>
                    </section>
                </Container>
            </main>
        </>
    );
};

export default NotFound;
