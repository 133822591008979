import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            padding: '0 10%'
        }
    },
    themeSelectionTitle: {
        color: theme.palette.fes.headline,
        marginBottom: theme.spacing(3)
    },
    themeSelectionGrid: {
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: 'auto',
        justifyContent: 'center',
        margin: theme.spacing(5, 0),
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    },
    themeSelectionStart: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(4),
        background: 'transparent',
        borderWidth: '0.188rem',
        borderStyle: 'solid',
        borderColor: theme.palette.fes.navy,
        borderRadius: '0.625rem',
        textAlign: 'left',
        transition: 'all 150ms ease-in-out',
        outline: 'none',
        '&[disabled]': {
            borderColor: theme.palette.jisc.greySilver,
            filter: 'brightness(1) grayscale(1)',
            '&:hover': {
                background: 'transparent',
                cursor: 'no-drop',
                '& > span': {
                    color: theme.palette.fes.navy
                },
                '& > svg': {
                    filter: 'initial'
                }
            }
        },
        '&:hover, &:focus': {
            cursor: 'pointer',
            backgroundColor: theme.palette.fes.blue,
            '& > span': {
                color: theme.palette.white
            },
            '& > svg': {
                filter: 'brightness(0) invert(1)'
            }
        },
        '& > span': {
            ...theme.typography.h4,
            color: theme.palette.fes.navy,
            width: '70%'
        },
        '& > svg': {
            width: '30%'
        }
    },
    error: {
        marginTop: theme.spacing(4)
    }
}));

export default styles;
