import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    heading: {
        fontWeight: 600
    },
    hero: {
        position: 'relative',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(28),
        margin: theme.spacing(0, 0, 5, 0),
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(32),
            margin: theme.spacing(5, 0, 10, 0)
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: theme.spacing(32)
        },
        '& > svg': {
            position: 'absolute',
            zIndex: -1,
            bottom: 0,
            right: 0,
            width: '100%',
            height: 'auto',
            [theme.breakpoints.up('lg')]: {
                height: 'fit-content'
            }
        }
    },
    heroTitle: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.75rem'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '5rem',
            paddingBottom: theme.spacing(10)
        }
    },
    heroSubTitle: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(2)
    },
    heroContent: {
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '30%'
        }
    },
    accordions: {
        margin: theme.spacing(5, 0, 10)
    }
}));

export default styles;
