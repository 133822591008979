import React from 'react';

function SpeechBubbles() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='254' height='183' viewBox='0 0 254 183'>
            <g data-name='Tell us' transform='translate(-163 -774)'>
                <text
                    data-name='1.'
                    transform='translate(208.25 808)'
                    fill='#333333'
                    fontSize='30'
                    fontFamily='ComicSansMS, Comic Sans MS'
                >
                    <tspan x='-10.488' y='0'>
                        1.
                    </tspan>
                </text>
                <g data-name='Layer 2' transform='translate(228.511 807.494)'>
                    <g data-name='Layer 1'>
                        <path
                            data-name='Path 4054'
                            d='M90.41,21.42a50.17,50.17,0,0,0-14.51,2.1A40.17,40.17,0,0,0,67.85,11.8,39.61,39.61,0,0,0,0,39.26,38.77,38.77,0,0,0,11.37,67.41,39.28,39.28,0,0,0,39.52,79.3h.35a52.25,52.25,0,0,0,4.55,15.39l.53.53v.69a4.89,4.89,0,0,1-.35,3.85c-2.63,4.37-11.72,7.17-14.87,7.7a1.46,1.46,0,0,0-1.22,1.22,2.14,2.14,0,0,0,.35,1.75c4.89,5.42,11.19,7,17,7a39.06,39.06,0,0,0,15.39-3.49,50.87,50.87,0,1,0,29.2-92.51ZM14,64.79A35.05,35.05,0,0,1,3.68,39.08,34.75,34.75,0,0,1,14.52,13.73a36,36,0,0,1,51.06.52,35.11,35.11,0,0,1,7.17,10.49,50.94,50.94,0,0,0-33,47.57,21.731,21.731,0,0,0,.17,3.32A37.32,37.32,0,0,1,14,64.79Zm51.76-.35-.69.7h0a7.64,7.64,0,0,0-1.58,5.42c.7,4.2,4.55,8,7.52,10.32-9.62,2.27-17.14-6.65-17.49-7a1.81,1.81,0,0,0-2.27-.35H50.9a28.67,28.67,0,0,1-7.69,1.58c0-1-.18-2.1-.18-3.15A47.68,47.68,0,0,1,74,27.54a36.21,36.21,0,0,1-8.25,36.9Zm24.66,55.08a47.39,47.39,0,0,1-28-9.09.17.17,0,0,1-.17-.18c-.18,0-.18-.17-.35-.17h-.7c-.18,0-.18,0-.35.17h-.18c-.17,0-16.08,7.87-26.93-.35,4.2-1.22,11.37-4,14-8.56a7.44,7.44,0,0,0,.7-6.65c-.17-.35-.35-.52-.52-.87a1.3,1.3,0,0,0-.18-.88,46,46,0,0,1-4.19-14,31.48,31.48,0,0,0,7.87-1.57c2.09,2.27,8.39,7.69,16.26,7.69a17.36,17.36,0,0,0,7.52-1.75,2.77,2.77,0,0,0,1-1.39,1.73,1.73,0,0,0-.7-1.58c-2.28-1.4-8-6.12-8.57-10a2.79,2.79,0,0,1,.7-2.62l.7-.7A39,39,0,0,0,77.3,27a46.35,46.35,0,0,1,13.11-1.93,47.218,47.218,0,1,1,0,94.435Z'
                            fill='#333333'
                        />
                    </g>
                </g>
                <rect data-name='Rectangle 2278' width='254' height='183' transform='translate(163 774)' fill='none' />
            </g>
        </svg>
    );
}

export default SpeechBubbles;
