import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';

import { post, get } from '../api';

export const SurveyContext = createContext();

export const useSurveyContext = () => useContext(SurveyContext);

export function SurveyContextProvider({ children }) {
    const [selectedThemes, setSelectedThemes] = useState([]);
    const [selectedSubThemes, setSelectedSubThemes] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState();
    const [themeStepper, setThemeStepper] = useState(0);
    const [responseOptions, setResponseOptions] = useState([]);
    const [currentQuestionsSelectedOption, setCurrentQuestionsSelectedOption] = useState();
    const [currentTheme, setCurrentTheme] = useState();
    const [currentSubTheme, setCurrentSubTheme] = useState();
    const [trackerHeight, setTrackerHeight] = useState(0);
    const [totalAnswered, setTotalAnswered] = useState({});

    const findTrackerHeight = useCallback((subTheme) => {
        const activeThemeCard = document.getElementById('themeCardActive');
        const activeSubThemeCard = document.getElementById(`subThemeCard-${subTheme}`);

        let elTop = 70;

        if (activeThemeCard && activeSubThemeCard) {
            elTop = activeSubThemeCard.getBoundingClientRect().top - activeThemeCard.getBoundingClientRect().bottom + 8;
        }

        return elTop;
    }, []);

    /**
     *  As there are a set of possible response options, when the survey is loaded,
     *  go & get all those options. We do this because response options could be different
     *  in the future & shouldn't be tied to the UI.
     */
    const getResponseOptions = useCallback(async () => {
        try {
            const { data } = await get('/responses-options');
            setResponseOptions(data);
        } catch (err) {
            throw Error('There was a problem getting the possible question answers.');
        }
    }, []);

    const sendNewResponse = useCallback(async (questionId, responseId, subThemeIds) => {
        try {
            const { data } = await post(`/responses?subThemes=${subThemeIds}`, {
                competencyOptionId: questionId,
                value: responseId
            });
            setTotalAnswered(data.totalAnswered);
            return data;
        } catch (err) {
            throw Error('There was a problem sending your new response.');
        }
    }, []);

    const getQuestion = useCallback(async (competencyOptionId) => {
        try {
            const { data } = await get(`/competency-options/${competencyOptionId}?verbose=true`);
            return data;
        } catch (err) {
            throw Error('Can not get question.');
        }
    }, []);

    const resetSurvey = useCallback(() => {
        setSelectedThemes([]);
        setSelectedSubThemes([]);
        setThemeStepper(0);
        setCurrentSubTheme(undefined);
        setTrackerHeight(0);
    }, []);

    const values = useMemo(
        () => ({
            selectedThemes,
            setSelectedThemes,
            currentTheme,
            setCurrentTheme,
            selectedSubThemes,
            setSelectedSubThemes,
            currentSubTheme,
            setCurrentSubTheme,
            currentQuestion,
            setCurrentQuestion,
            themeStepper,
            setThemeStepper,
            responseOptions,
            setResponseOptions,
            currentQuestionsSelectedOption,
            setCurrentQuestionsSelectedOption,
            trackerHeight,
            setTrackerHeight,
            sendNewResponse,
            getResponseOptions,
            resetSurvey,
            getQuestion,
            findTrackerHeight,
            totalAnswered
        }),
        [
            selectedThemes,
            currentTheme,
            selectedSubThemes,
            currentSubTheme,
            currentQuestion,
            themeStepper,
            responseOptions,
            currentQuestionsSelectedOption,
            trackerHeight,
            sendNewResponse,
            getResponseOptions,
            resetSurvey,
            getQuestion,
            findTrackerHeight,
            totalAnswered
        ]
    );

    return <SurveyContext.Provider value={values}>{children}</SurveyContext.Provider>;
}

SurveyContextProvider.propTypes = {
    children: PropTypes.element.isRequired
};
