import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    hero: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(5, 0)
        }
    },
    heroTitle: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.75rem',
            padding: `0 10% ${theme.spacing(5)}`
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '5rem'
        }
    },
    heroSubTitle: {
        [theme.breakpoints.up('sm')]: {
            padding: `0 10% ${theme.spacing(2.5)}`
        }
    },
    section: {
        [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(10)
        }
    }
}));

export default styles;
