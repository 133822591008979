import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(6, 0, 20, 0)
    },
    profileHeader: {
        margin: theme.spacing(10, 0, 5, 0)
    },
    profileTitle: {
        fontSize: '3.5rem',
        color: theme.palette.fes.headline,
        fontWeight: '300',
        [theme.breakpoints.up('md')]: {
            margin: '0 25%'
        }
    },
    profileSubTitle: {
        fontSize: '2.5rem',
        color: theme.palette.fes.headline,
        fontWeight: '300',
        padding: theme.spacing(0, 0, 2, 0),
        [theme.breakpoints.up('md')]: {
            margin: '0 25%'
        }
    },
    profileContent: {},
    profileForm: {
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.jisc.greySilver}`,
        borderRadius: '8px',
        marginBottom: theme.spacing(10),
        [theme.breakpoints.up('md')]: {
            margin: '0 25% 1.5rem'
        }
    },
    profileFormGrid: {
        display: 'grid',
        gridTemplateColumns: '25% auto',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.jisc.greySilver}`,
            padding: theme.spacing(2, 0),
            '&:nth-child(-n+2)': {
                paddingTop: 0
            },
            '&:nth-last-child(1), &:nth-last-child(2)': {
                borderBottom: 'none',
                paddingBottom: 0
            }
        },
        '& label': {
            display: 'none'
        }
    },
    skeleton: {
        width: '100%'
    },
    alert: {
        [theme.breakpoints.up('md')]: {
            margin: '0 25%'
        }
    },
    remindersContent: {
        margin: theme.spacing(6, 0, 5, 0)
    },
    reminderList: {
        display: 'flex',
        width: '50%',
        gap: theme.spacing(1),
        alignItems: 'start',
        padding: theme.spacing(0.5, 0),
        [theme.breakpoints.up('md')]: {
            margin: '0 25%'
        },
        '& > p': {
            width: '50%',
            '& > p': {
                margin: 0
            }
        }
    }
}));

export default styles;
