import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    titleHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    search: {
        margin: theme.spacing(4, 0)
    },
    resourceTitle: {
        marginBottom: theme.spacing(2.5),
        color: theme.palette.fes.headline
    },
    resourceBody: {
        marginBottom: theme.spacing(2),
        lineHeight: 1.8,
        '& p': { margin: 0 }
    },
    parentDivBoth: {
        margin: theme.spacing(5, 0, 3),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.jisc.greySilver}`,
        '& svg': { marginBottom: '-0.5rem' }
    },
    letterGrid: {
        display: 'grid',
        gap: theme.spacing(1),
        gridTemplateColumns: 'repeat(6, 1fr)',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(13, 1fr)'
        }
    },
    activeLetter: {
        backgroundColor: theme.palette.jisc.blueDark,
        borderColor: theme.palette.jisc.blueDark,
        color: theme.palette.jisc.white
    },
    noResults: {
        display: 'block',
        width: 'fit-content',
        margin: theme.spacing(3, 'auto', 6)
    },
    loading: {
        display: 'block',
        width: 'fit-content',
        margin: theme.spacing(3, 'auto', 6)
    },
    error: {
        display: 'block',
        width: 'fit-content',
        margin: theme.spacing(3, 'auto', 6)
    }
}));

export default styles;
