import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import styles from './Modal.styles';

function Modal({ title, open, maxWidth, setModalOpen, children }) {
    const classes = styles();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setModalOpen(false);
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
            onClose={() => setModalOpen(false)}
            maxWidth={maxWidth}
            className={classes.dialog}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id='User Responses' className={classes.diaTitle} disableTypography>
                <Typography variant='h5' component='h2' className={classes.title}>
                    {title}
                </Typography>
                <a
                    role='button'
                    tabIndex={0}
                    onKeyDown={(e) => handleKeyPress(e)}
                    onClick={() => setModalOpen(false)}
                    aria-label='Close'
                    className={classes.closeButton}
                    title='Close'
                >
                    <Close />
                </a>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>{children}</DialogContent>
        </Dialog>
    );
}

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setModalOpen: PropTypes.func.isRequired,
    maxWidth: PropTypes.string
};

Modal.defaultProps = {
    maxWidth: 'sm'
};

export default Modal;
