import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(6, 0, 20, 0),
        '& section': {
            [theme.breakpoints.up('lg')]: {
                maxWidth: '70%',
                margin: `0 auto ${theme.spacing(10)}`
            }
        }
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none !important',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.up('lg')]: {
            maxWidth: '70%',
            margin: `auto auto ${theme.spacing(6)}`
        },
        '& svg': {
            display: 'block',
            width: '1.25rem',
            height: '1.25rem',
            marginRight: theme.spacing(2)
        }
    },
    hero: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gap: theme.spacing(3),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '9.375rem auto',
            columnGap: theme.spacing(5),
            rowGap: theme.spacing(3),
            '& svg': {
                gridRow: '1/3'
            }
        }
    },
    heroTitle: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        [theme.breakpoints.up('lg')]: {
            fontSize: '3.5rem'
        }
    },
    subTitle: {
        fontSize: '2.125rem',
        fontWeight: 400,
        lineHeight: 1.235,
        color: theme.palette.fes.headline,
        maxWidth: '70%',
        margin: `0 auto ${theme.spacing(4)}`
    },
    goalAccordionSection: {
        padding: theme.spacing(2, 0)
    },
    accordians: {
        position: 'relative'
    },
    goalsStickyMenu: {
        display: 'none',
        position: 'absolute',
        height: '100%',
        right: 0,
        width: theme.spacing(49.2),
        top: theme.spacing(9),
        zIndex: 1,
        '& > div': {
            position: 'sticky',
            overflowY: 'auto',
            maxHeight: '90vh',
            top: theme.spacing(5),
            padding: theme.spacing(2, 3),
            backgroundColor: theme.palette.jisc.white,
            boxShadow: `0px 1px 3px 0px ${theme.palette.jisc.silver}`,
            borderRadius: '0.313rem'
        },
        '& a': {
            display: 'block',
            textDecoration: 'none !important',
            '& p': {
                fontWeight: 500,
                color: theme.palette.info
            }
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            right: `calc(-${theme.spacing(49.2)} + -${theme.spacing(3)})`
        }
    },
    goalsStickyMenuAccordion: {
        border: 'none',
        margin: '0 !important',
        padding: 0
    },
    goalsStickyMenuAccordionSummary: {
        padding: 0
    },
    goalsStickyMenuAccordionDetails: {
        padding: theme.spacing(0, 0, 0, 2),
        '& a': {
            marginBottom: theme.spacing(1)
        }
    }
}));

export default styles;
