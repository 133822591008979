import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import JiscLink from '../../components/JiscLink/JiscLink';

import * as enums from '../../lib/enums';
import * as api from '../../lib/api';
import urls from '../../config/urls';
import styles from './Profile.styles';

function formatDate(date) {
    const dt = DateTime.fromISO(date);
    return dt.toLocaleString(DateTime.DATETIME_FULL);
}

function Profile() {
    const classes = styles();
    const { auth } = useAuthContext();

    const [reminders, setReminders] = useState([]);

    useEffect(() => {
        const getReminders = async () => {
            try {
                const res = await api.get('/reminders');
                setReminders(res.data);
            } catch (error) {
                // do nothing
            }
        };

        getReminders();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.profile.title}</title>
                <link rel='canonical' href={urls.base.url + urls.profile.url} />
            </Helmet>
            {auth.user && (
                <main data-testid='profile-page' className={classes.root}>
                    <section className={classes.profileHeader}>
                        <Container maxWidth='lg'>
                            <Typography className={classes.profileTitle} variant='h1'>
                                {urls.profile.text}
                            </Typography>
                        </Container>
                    </section>
                    <section className={classes.profileContent}>
                        <Container maxWidth='lg'>
                            <form className={classes.profileForm} noValidate autoComplete='off'>
                                <div className={classes.profileFormGrid}>
                                    <div className={classes.profileFormGridLabel}>
                                        <Typography variant='body1'>Organisation</Typography>
                                    </div>
                                    <div className={classes.profileFormGridContent}>
                                        <Typography data-testid='profile-form-institution' variant='body1'>
                                            {auth.user.institution_name}
                                        </Typography>
                                    </div>
                                    <div className={classes.profileFormGridLabel}>
                                        <Typography variant='body1'>Email</Typography>
                                    </div>
                                    <div className={classes.profileFormGridContent}>
                                        <Typography data-testid='profile-form-email' variant='body1'>
                                            {auth.user.email}
                                        </Typography>
                                    </div>
                                    <div className={classes.profileFormGridLabel}>
                                        <Typography variant='body1'>Role</Typography>
                                    </div>
                                    <div className={classes.profileFormGridContent}>
                                        <Typography data-testid='profile-form-role' variant='body1'>
                                            {Object.keys(enums.user.role)[auth.user.role - 1]}
                                        </Typography>
                                    </div>
                                </div>
                            </form>

                            <Alert className={classes.alert} severity='info'>
                                <AlertTitle>Help us keep our records up to date</AlertTitle>
                                If something is not right,{' '}
                                <JiscLink
                                    type='external'
                                    href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    submit a request to update this information.
                                </JiscLink>
                            </Alert>
                        </Container>
                    </section>

                    {reminders?.length > 0 && (
                        <section className={classes.remindersContent}>
                            <Container maxWidth='lg'>
                                <Typography className={classes.profileSubTitle} variant='h2'>
                                    Upcoming reminders
                                </Typography>

                                <div>
                                    <div className={classes.reminderList}>
                                        <Typography variant='body1'>Section</Typography>
                                        <Typography variant='body1'>Created at</Typography>
                                        <Typography variant='body1'>To be sent at</Typography>
                                    </div>

                                    <div>
                                        {reminders.map((reminder) => (
                                            <div key={reminder.reminder_id} className={classes.reminderList}>
                                                <Typography variant='body2'>
                                                    <strong>{reminder.sub_theme}</strong>: {parse(reminder.statement)}
                                                </Typography>

                                                <Typography variant='body2'>
                                                    {formatDate(reminder.reminder_created_at)}
                                                </Typography>

                                                <Typography variant='body2'>
                                                    {formatDate(reminder.reminder_date)}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Container>
                        </section>
                    )}
                </main>
            )}
        </>
    );
}

export default Profile;
