import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContentManagementContext } from '../../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../../components/JiscButton/JiscButton';
import Loader from '../../../../components/Loader/Loader';
import HtmlEditor from '../../../../components/HtmlEditor/HtmlEditor';
import CustomModal from '../../../../components/CustomModal/CustomModal';

import * as api from '../../../../lib/api';
import urls from '../../../../config/urls';
import styles from './EditValidationStatement.styles';

let mounted;

const EditValidationStatement = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const history = useHistory();
    const { id } = useParams();

    // Data
    const [validationStatement, setValidationStatement] = useState();
    const [text, setText] = useState();
    const [textError, setTextError] = useState();

    const [competencyStatementId, setCompetencyStatementId] = useState();
    const [competencyStatementIdError, setCompetencyStatementIdError] = useState();

    const [availableCompetencyStatements, setAvailableCompetencStatments] = useState([]);

    // Error
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState();

    // Delete
    const [deleteError, setDeleteError] = useState();
    const [comfirmModal, setComfirmModal] = useState(false);

    const deleteValidationStatement = async () => {
        try {
            const { status } = await api.del(`/competency-options/${id}`);

            if (status !== 200) throw Error('There was a problem deleting this Validation Statement.');
            setComfirmModal(false);

            history.push(urls.contentManagement.validationStatements.url);
        } catch (err) {
            setDeleteError(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!text || !competencyStatementId) {
            if (!text) {
                setTextError('You must supply validation statement text.');
                return;
            }
            if (!competencyStatementId) {
                setCompetencyStatementIdError('You must supply a parent competency statement.');
                setTextError(undefined);
                return;
            }
        }

        setLoading(true);
        setTextError(undefined);
        setCompetencyStatementIdError(undefined);

        try {
            const { status } = await api.put(`/competency-options/${id}`, {
                text,
                competencyStatementId
            });

            if (status !== 200) throw Error('There was a problem updating this Validation Statement.');

            setErrorMessage(undefined);
            setError(undefined);
            setLoading(false);
            history.push(urls.contentManagement.validationStatements.url);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    const getData = async () => {
        try {
            if (mounted) {
                const { data: allCompetencyStatements } = await api.get(
                    '/competency-statements?limit=999999&orderBy=competency_statement_id'
                );
                setAvailableCompetencStatments(allCompetencyStatements.data);

                const { data } = await api.get(`/competency-options/${id}`);
                setValidationStatement(data);
                setText(data.text);
                setCompetencyStatementId(data.competency_statement_id);

                setLoading(false);
            }
        } catch (err) {
            setErrorMessage(`There was a problem getting validation statement with id ${id}.`);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        mounted = true;
        setActivePage(urls.contentManagement.validationStatements.edit);
        setLoading(true);
        getData();

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <ContentManagementLayout>
            {!loading && error && errorMessage && (
                <Alert severity='error' className={classes.alert}>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            {!loading && validationStatement && (
                <>
                    <header className={classes.titleHeader}>
                        <Typography variant='h5' component='h1'>
                            {urls.contentManagement.validationStatements.edit.text}
                        </Typography>
                        <div>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='check' role='button' />}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Update
                            </JiscButton>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='trash-alt' role='button' />}
                                onClick={() => setComfirmModal(true)}
                                className={classes.trash}
                            >
                                Delete
                            </JiscButton>
                            <JiscButton
                                isLink
                                linkType='internal'
                                href={urls.contentManagement.validationStatements.url}
                                variant='ghost'
                                startIcon={<FontAwesomeIcon icon='arrow-alt-circle-left' role='button' />}
                            >
                                Back to all
                            </JiscButton>
                        </div>
                    </header>

                    <form id='new-validation-statement' className={classes.form}>
                        <FormControl fullWidth>
                            <InputLabel id='parent-competency-statement'>
                                Parent Competency Statement (Required)
                            </InputLabel>
                            <Select
                                labelId='parent-competency-statement'
                                value={competencyStatementId}
                                label='Parent Comptency Statement'
                                error={competencyStatementIdError}
                                onChange={(e) => {
                                    setCompetencyStatementId(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Parent Competency Statement'
                                }}
                            >
                                {availableCompetencyStatements.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.jiscId}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                Currently: Competency Statement ID: {validationStatement.competency_statement_id}
                            </FormHelperText>

                            {competencyStatementIdError && (
                                <FormHelperText>{competencyStatementIdError}</FormHelperText>
                            )}
                        </FormControl>

                        <HtmlEditor
                            title='Question text'
                            placeholder='Start typing here...'
                            error={textError}
                            content={text}
                            setContent={setText}
                        />
                    </form>

                    <CustomModal
                        dialogTitle='delete-validation-statement'
                        title='Are you sure you want to delete this validation statement?'
                        open={comfirmModal}
                        onClose={() => {
                            setComfirmModal(false);
                        }}
                        buttonText='Confirm delete'
                        successCallback={deleteValidationStatement}
                        error={deleteError}
                        errorMessage={setDeleteError}
                    />
                </>
            )}
            {loading && <Loader />}
        </ContentManagementLayout>
    );
};

export default EditValidationStatement;
