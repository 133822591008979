import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import urls from '../../config/urls';

export const ContentManagementContext = createContext();

export const useContentManagementContext = () => useContext(ContentManagementContext);

export const ContentManagementProvider = ({ children }) => {
    const sidebarItems = Object.values(urls.contentManagement);

    const [activePage, setActivePage] = useState();

    return (
        <ContentManagementContext.Provider value={{ sidebarItems, activePage, setActivePage }}>
            {children}
        </ContentManagementContext.Provider>
    );
};

ContentManagementProvider.propTypes = {
    children: PropTypes.element.isRequired
};
