import React from 'react';
import { Helmet } from 'react-helmet-async';

import AuthForm from '../../components/AuthForm/AuthForm';

import urls from '../../config/urls';
import styles from './LogIn.styles';

const LogIn = () => {
    const classes = styles();

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.login.title}</title>
                <link rel='canonical' href={urls.base.url + urls.login.url} />
            </Helmet>

            <section className={classes.root}>
                <AuthForm
                    title='Log in'
                    description='Please provide your email address to log in to your account'
                    submitLabel='Log in'
                />
            </section>
        </>
    );
};

export default LogIn;
