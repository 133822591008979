import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import styles from './JiscLink.styles';

const JiscLink = ({ background, children, className, type, underline, visitedStyle, startIcon, endIcon, ...rest }) => {
    const classes = styles({ background, underline, visitedStyle });

    return (
        <>
            {type === 'internal' ? (
                <Link className={clsx(classes.root, className)} {...rest}>
                    {startIcon && <span className={classes.startIcon}>{startIcon}</span>}
                    {children}
                    {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
                </Link>
            ) : (
                <a className={clsx(classes.root, className)} {...rest}>
                    {startIcon && <span className={classes.startIcon}>{startIcon}</span>}
                    {children}
                    {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
                </a>
            )}
        </>
    );
};

JiscLink.propTypes = {
    background: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    type: PropTypes.string,
    underline: PropTypes.bool,
    visitedStyle: PropTypes.bool,
    startIcon: PropTypes.string,
    endIcon: PropTypes.string
};

JiscLink.defaultProps = {
    background: 'light',
    children: '',
    className: '',
    type: 'internal',
    underline: true,
    visitedStyle: false,
    startIcon: '',
    endIcon: ''
};

export default JiscLink;
