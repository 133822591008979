import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    title: {
        fontSize: '3.75rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline
    },
    banner: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gap: theme.spacing(5),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.fes.aquaSqueeze,
        padding: theme.spacing(10, 0),
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'auto auto',
            gap: theme.spacing(10)
        },
        '& p': {
            margin: theme.spacing(2.5, 0)
        },
        '& svg': {
            [theme.breakpoints.down('md')]: {
                margin: 'auto'
            }
        }
    }
}));

export default styles;
