import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: theme.palette.jisc.orange,
        zIndex: 10,
        padding: theme.spacing(4, 2)
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'auto 30%',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        },
        '& p ': {
            color: theme.palette.white
        }
    },
    buttonsDiv: {
        width: '100%',
        display: 'flex',
        '& :first-child': {
            marginRight: theme.spacing(2)
        }
    },
    buttons: {
        [theme.breakpoints.down('md')]: {
            width: '50%',
            marginTop: theme.spacing(2)
        },
        color: theme.palette.white,
        borderColor: theme.palette.white
    }
}));

export default styles;
