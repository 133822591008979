import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        display: 'block',
        animation: 'spin 1800ms linear infinite',
        margin: 'auto',
        minHeight: '50vh',
        width: theme.spacing(12),
        height: theme.spacing(12)
    },
    circle: {
        stroke: theme.palette.jisc.orange
    }
}));

export default styles;
