import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {},
    formControl: {
        display: 'block',
        '& > div': {
            width: '100%',
            border: '2px solid black'
        }
    },
    errorMessage: {
        gridColumn: '1/3'
    },
    statusSelect: {
        padding: theme.spacing(1, 1.5),
        border: '1px solid #BDBDBD',
        borderRadius: '0.25rem !important'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderTop: `0.5px solid  #BDBDBD`
    },
    noteButton: {
        padding: theme.spacing(0.75, 2),
        fontSize: '14px',
        background: '#FFF',
        border: '1px solid rgba(0, 0, 0, 0.5)',
        borderRadius: '0.25rem',
        cursor: 'pointer',
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    inputLabel: {
        backgroundColor: '#FAFAFA', // Make sure the label background matches the form background
        padding: '0 4px', // Add some padding to make space for the label
        transform: 'translate(14px, -6px) scale(1)', // Adjust the position and size of the label
        zIndex: 1, // Ensure the label appears above the border
        fontSize: '12px',
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.60)'
    },
    select: {
        padding: theme.spacing(0, 0),
        '& .MuiSelect-select': {
            padding: theme.spacing(1, 1.5)
        }
    },
    validationStatement: {
        border: `1px solid #BDBDBD`,
        borderRadius: '0.25rem !important',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        background: '#FAFAFA'
    },
    competencyOption: {
        padding: theme.spacing(2, 2),
        '& p': {
            margin: 0
        }
    }
}));

export default styles;
