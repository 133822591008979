import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
        background: 'transparent',
        textAlign: 'center',
        borderWidth: '0.188rem',
        borderStyle: 'solid',
        borderColor: theme.palette.jisc.greySilver,
        borderRadius: '0.625rem',
        outline: 'none',
        '&:hover': {
            cursor: 'pointer'
        },
        '& > svg': {
            marginBottom: theme.spacing(5)
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'start',
            textAlign: 'left'
        }
    },
    rootSelected: {
        borderColor: ({ color }) => color
    },
    themePickerCardTitle: {
        fontWeight: '500',
        color: theme.palette.fes.headline,
        [theme.breakpoints.up('md')]: {
            height: '5rem'
        }
    },
    selector: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '-0.188rem',
        right: '-0.188rem',
        width: '2.875rem',
        height: '2.875rem',
        fontSize: '1rem',
        color: theme.palette.white,
        borderRadius: '0.625rem',
        backgroundColor: ({ color }) => color
    },
    hovered: {
        position: 'absolute',
        zIndex: 0,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '0.425rem',
        opacity: 0.1,
        transition: 'all 150ms ease-in-out',
        backgroundColor: ({ color }) => color
    },
    completedIndicator: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    completedIndicatorBar: {
        backgroundColor: theme.palette.jisc.tab,
        height: '5px',
        borderRadius: '1rem'
    },
    completedIndicatorBarGrowth: {
        display: 'block',
        height: '5px',
        borderRadius: '1rem',
        backgroundColor: ({ color }) => color,
        transition: 'width 300ms ease-in-out',
        width: '0%'
    },
    completedIndicatorLabel: {
        marginTop: theme.spacing(2),
        '& span': {
            color: theme.palette.jisc.black
        }
    }
}));

export default styles;
