import React from 'react';
import { Typography } from '@material-ui/core';

import JiscButton from '../../JiscButton/JiscButton';
import styles from './InstitutionLoginsReport.styles';
import { get } from '../../../lib/api';

function InstitutionLoginsReport() {
    const classes = styles();

    const onExport = async () => {
        const result = await get('/report/institutions?type=loginsThisMonth');

        const { key } = result.data;
        if (key) {
            const { data } = await get(`/report/${key}`);
            const link = document.createElement('a');
            link.href = data;
            link.setAttribute('download', `${key}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    return (
        <>
            <Typography variant='body1' className={classes.p}>
                Institution logins this month
            </Typography>
            <JiscButton variant='primary' onClick={onExport}>
                Export results
            </JiscButton>
        </>
    );
}

export default InstitutionLoginsReport;
