import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';

import JiscLink from '../../components/JiscLink/JiscLink';

import urls from '../../config/urls';
import styles from './Cookies.styles';

const Cookies = () => {
    const classes = styles();

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.cookies.title}</title>
                <link rel='canonical' href={urls.base.url + urls.cookies.url} />
            </Helmet>

            <main className={classes.root}>
                <Container>
                    <section id='fes-cookies' className={classes.content}>
                        <Typography variant='h1' className={classes.title}>
                            {urls.cookies.text}
                        </Typography>
                        <Typography variant='body1'>
                            To help improve this site we place small files, known as cookies, onto your computer. Some
                            are essential for the site to work properly, others are to improve the experience you have.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant='h4'>Understanding our users&apos; needs</Typography>
                        <br />
                        <br />
                        <Typography variant='body1'>
                            We use Hotjar to better understand our users’ needs and optimise the experience of this
                            website. It helps us understand users’ experiences (eg how much time they spend on which
                            pages, which links they choose to click, what users do and don’t like, etc), which enables
                            us to build and maintain our service with user feedback.
                            <br />
                            Hotjar uses cookies and other technologies to collect data on our users’ behaviour and their
                            devices. This includes:
                            <ul>
                                <li>A device&apos;s IP address (processed during your session and stored in a form)</li>
                                <li>Device screen size </li>
                                <li>Device type (unique device identifiers)</li>
                                <li>Browser information </li>
                                <li>Geographic location (country only)</li>
                                <li>Preferred language used to display our website</li>
                            </ul>
                            <JiscLink
                                href='https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar'
                                type='external'
                                background='light'
                            >
                                Hotjar
                            </JiscLink>
                            {'  '}stores this information on our behalf in a pseudonymised user profile and is sell any
                            collected on our behalf.
                            <br />
                            <br />
                            The following cookies are set by Hotjar:
                            <br />
                            <br />
                        </Typography>
                        <TableContainer>
                            <Table data-testid='user-responses-table'>
                                <colgroup>
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '70%' }} />
                                    <col style={{ width: '15%' }} />
                                </colgroup>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Purpose</TableCell>
                                        <TableCell>Expires</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_hjSessionUser</TableCell>
                                        <TableCell>
                                            Hotjar cookie that is set when a user first lands on a page with the Hotjar
                                            script. It is used to persist the Hotjar User ID, unique to that site on the
                                            browser. This ensures that behavior in subsequent visits to the same site
                                            will be attributed to the same user ID.
                                        </TableCell>
                                        <TableCell align='left'>1 year</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_hjFirstSeen</TableCell>
                                        <TableCell>
                                            Hotjar sets this cookie to identify a new user’s first session. It stores a
                                            true/false value, indicating whether it was the first time Hotjar saw this
                                            user.
                                        </TableCell>
                                        <TableCell align='left'>30 minutes</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_hjSession</TableCell>
                                        <TableCell>
                                            A cookie that holds the current session data. This ensues that subsequent
                                            requests within the session window will be attributed to the same Hotjar
                                            session.{' '}
                                        </TableCell>
                                        <TableCell align='left'>30 minutes</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_hjIncludedInSessionSample</TableCell>
                                        <TableCell>
                                            Hotjar sets this cookie to know whether a user is included in the data
                                            sampling defined by the site&apos;s daily session limit.{' '}
                                        </TableCell>
                                        <TableCell align='left'>2 minutes</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_hjAbsoluteSessionInProgress</TableCell>
                                        <TableCell>
                                            Hotjar sets this cookie to detect the first pageview session of a user. This
                                            is a True/False flag set by the cookie.{' '}
                                        </TableCell>
                                        <TableCell align='left'>30 minutes</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_hjIncludedInPageviewSample</TableCell>
                                        <TableCell>
                                            Hotjar sets this cookie to know whether a user is included in the data
                                            sampling defined by the site&apos;s pageview limit.{' '}
                                        </TableCell>
                                        <TableCell align='left'>2 minutes</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <br />
                        <Typography variant='h4'>Measuring website usage (Matomo)</Typography>
                        <br />
                        <br />
                        <Typography variant='body1'>
                            We use Matomo to collect information about how people use our site, and rough demographics
                            as to what groups of people are using our site. We do this to make sure it’s meeting your
                            needs and to understand how we could improve it.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant='body1'>
                            Matomo stores information about:
                            <ul>
                                <li>The pages you visit</li>
                                <li>How long you spend on each page</li>
                                <li>How you got to the site</li>
                                <li>What you click on while you&apos;re here</li>
                            </ul>
                            The following cookies are set by Matomo:
                            <br />
                            <br />
                        </Typography>
                        <TableContainer>
                            <Table data-testid='user-responses-table'>
                                <colgroup>
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '70%' }} />
                                    <col style={{ width: '15%' }} />
                                </colgroup>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Purpose</TableCell>
                                        <TableCell>Expires</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>AWSALBCORS</TableCell>
                                        <TableCell>
                                            This cookie is managed by Amazon Web Services and is used for load
                                            balancing.
                                        </TableCell>
                                        <TableCell align='left'>7 days</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_pk_id</TableCell>
                                        <TableCell>
                                            Matomo sets this cookie to store a few details about the user such as the
                                            unique visitor ID.
                                        </TableCell>
                                        <TableCell align='left'>1 year 27 days</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>_pk_ses</TableCell>
                                        <TableCell>
                                            Matomo sets this cookie is used to temporarily store data for the visit.
                                        </TableCell>
                                        <TableCell align='left'>30 minutes</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='left'>AWSALB</TableCell>
                                        <TableCell>
                                            AWSALB is an application load balancer cookie set by Amazon Web Services to
                                            map the session to the target.
                                        </TableCell>
                                        <TableCell align='left'>7 days</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </section>
                </Container>
            </main>
        </>
    );
};

export default Cookies;
