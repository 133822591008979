const siteTitle = 'Digital Elevation Tool';

let url;

switch (process.env.REACT_APP_ENV) {
    case 'test':
    case 'local':
        url = 'http://localhost:3000';
        break;
    case 'int':
        url = 'https://int.elevate.jisc.ac.uk';
        break;
    case 'staging':
        url = 'https://staging.elevate.jisc.ac.uk';
        break;
    case 'prod':
        url = 'https://elevate.jisc.ac.uk';
        break;
    default:
        url = 'http://localhost:3000';
        break;
}

export default {
    base: {
        url
    },
    profile: {
        url: '/profile',
        text: 'Your profile',
        title: `Profile | ${siteTitle}`
    },
    organisationSettings: {
        url: '/organisation',
        text: 'User management',
        title: `Organisation Settings | ${siteTitle}`
    },
    organisationsOverview: {
        url: '/organisations-overview',
        text: 'Organisations',
        title: `Organisations | ${siteTitle}`
    },
    login: {
        url: '/login',
        text: 'Log In',
        title: `Log In | ${siteTitle}`
    },
    help: {
        url: '/help',
        text: 'Help & Support',
        title: `Help & Support | ${siteTitle}`
    },
    about: {
        url: '/about',
        text: 'About',
        title: `About | ${siteTitle}`
    },
    register: {
        url: '/register',
        text: 'Register',
        title: `Register | ${siteTitle}`
    },
    home: {
        url: '/',
        text: '',
        title: siteTitle
    },
    verify: {
        url: '/verify',
        text: 'Verifying...',
        title: `Verifying... | ${siteTitle}`
    },
    invitation: {
        url: '/invitation',
        text: 'Accepting invitation...',
        title: `Accepting invitation... | ${siteTitle}`
    },
    survey: {
        url: '/survey',
        text: 'Survey',
        title: `Survey | ${siteTitle}`
    },
    results: {
        url: '/results',
        text: 'Results',
        title: `Results | ${siteTitle}`
    },
    exportNotes: {
        url: '/export-notes',
        text: 'Export notes',
        title: `Export notes | ${siteTitle}`
    },
    themeGoals: {
        text: 'Survey Theme Goals',
        title: `Your Goals | ${siteTitle}`
    },
    glossary: {
        url: '/glossary',
        text: 'Resource Glossary',
        title: `Resources | ${siteTitle}`
    },
    selfAssessment: {
        url: '/self-assessment',
        text: 'Self-assessment',
        title: `Self-assessment | ${siteTitle}`
    },
    accessibility: {
        url: '/accessibility',
        text: 'Accessibility Statement for the Jisc digital elevation tool',
        title: `Accessibility Statement | ${siteTitle}`
    },
    cookies: {
        url: '/cookies',
        text: 'Cookies',
        title: `Cookies | ${siteTitle}`
    },
    privacy: {
        url: '/privacy',
        text: 'Privacy',
        title: `Privacy | ${siteTitle}`
    },
    notFound: {
        text: 'Error 404, Page Not Found',
        title: `Not Found | ${siteTitle}`
    },
    contentManagement: {
        themes: {
            url: '/admin/themes',
            text: 'Themes',
            title: `Themes | ${siteTitle}`,
            icon: 'layer-group',
            status: 'active',
            add: {
                url: '/admin/themes/create',
                text: 'New theme',
                title: `New theme | ${siteTitle}`
            },
            edit: {
                url: '/admin/themes',
                text: 'Update theme',
                title: `Update theme | ${siteTitle}`
            }
        },
        subThemes: {
            url: '/admin/sub-themes',
            text: 'Sub themes',
            title: `Sub themes | ${siteTitle}`,
            icon: 'object-ungroup',
            status: 'active',
            add: {
                url: '/admin/sub-themes/create',
                text: 'New sub theme',
                title: `New sub theme | ${siteTitle}`
            },
            edit: {
                url: '/admin/sub-themes',
                text: 'Update sub theme',
                title: `Update sub theme | ${siteTitle}`
            }
        },
        competencies: {
            url: '/admin/competencies',
            text: 'Competencies',
            title: `Competencies | ${siteTitle}`,
            icon: 'object-group',
            status: 'active',
            add: {
                url: '/admin/competencies/create',
                text: 'New competency',
                title: `New competency | ${siteTitle}`
            },
            edit: {
                url: '/admin/competencies',
                text: 'Update competency',
                title: `Update competency | ${siteTitle}`
            }
        },
        competencyStatements: {
            url: '/admin/competency-statements',
            text: 'Competency statements',
            title: `Competency statements | ${siteTitle}`,
            icon: 'bullseye',
            status: 'active',
            add: {
                url: '/admin/competency-statements/create',
                text: 'New competency statement',
                title: `New competency statement | ${siteTitle}`
            },
            edit: {
                url: '/admin/competency-statements',
                text: 'Update competency statement',
                title: `Update competency statement | ${siteTitle}`
            }
        },
        validationStatements: {
            url: '/admin/validation-statements',
            text: 'Validation statements',
            title: `Validation statements | ${siteTitle}`,
            icon: 'question-circle',
            status: 'active',
            add: {
                url: '/admin/validation-statements/create',
                text: 'New validation statement',
                title: `New validation statement | ${siteTitle}`
            },
            edit: {
                url: '/admin/validation-statements',
                text: 'Update validation statement',
                title: `Update validation statement | ${siteTitle}`
            }
        },
        resources: {
            url: '/admin/resources',
            text: 'Resources',
            title: `Resources | ${siteTitle}`,
            icon: 'file-signature',
            status: 'active',
            add: {
                url: '/admin/resources/create',
                text: 'New Resource',
                title: `New Resource | ${siteTitle}`
            },
            edit: {
                url: '/admin/resources',
                text: 'Update Resources',
                title: `Update Resources | ${siteTitle}`
            }
        }
    },
    reports: {
        frontend: {
            url: '/reports',
            text: 'Reports',
            title: `Reports`
        },
        api: {
            url: '/report'
        }
    }
};
