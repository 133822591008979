import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

/**
 * @description Accepts a FULL hex value & returns the rgb value, with or without the alpha
 * @param {string} hex
 * @param {number} alpha
 * @example hexToRGB('#FF0000', 0.5);
 * @return {string}
 */
export const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    let rgb;

    if (alpha) {
        rgb = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
        rgb = `rgb(${r}, ${g}, ${b})`;
    }

    return rgb;
};

export const findPercentage = (progression) => {
    if (progression.total === 0) return 0;
    return Math.ceil((progression.answers[0] * 100) / progression.total);
};

export const formatSentence = (value) => {
    const label = value.toLowerCase();
    return label[0].toUpperCase() + label.substring(1);
};

/**
 * Truncates a string to a specified length and appends an optional ending.
 *
 * @param {string} str - The string to be truncated.
 * @param {number} maxLength - The maximum length of the string after truncation.
 * @param {string} [ending='...'] - The string to append to the end of the truncated string.
 * @returns {string} The truncated string.
 */
export function truncate(str, maxLength, ending = '...') {
    if (typeof str !== 'string') {
        throw new TypeError('First argument must be a string');
    }

    if (maxLength < 0) {
        throw new Error('maxLength must be a non-negative number');
    }

    if (str.length > maxLength) {
        return str.substring(0, maxLength - ending.length) + ending;
    }

    return str;
}

export const parseHtml = (string) => parse(string, {});

export const stripHtml = (string) =>
    sanitizeHtml(string, {
        allowedTags: [],
        allowedAttributes: {}
    });
