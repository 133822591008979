import React, { useContext, useState } from 'react';
import { Typography, Paper, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import styles from './organisationReportSelector.styles';
import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { ResultsContext } from '../../pages/Results/ResultsContext';

function OrganisationReportSelector() {
    const { auth } = useAuthContext();
    const classes = styles();
    const resultsContext = useContext(ResultsContext);
    const reportTypeChanged = (e) => {
        const newReportTypestate = { ...resultsContext.selectedReports };
        switch (e.target.id) {
            case 'version_history_answers':
                newReportTypestate.versionHistoryAllAnswers = e.target.checked;
                break;
            case 'current_answers':
                newReportTypestate.yourCurrentAnswers = e.target.checked;
                break;
            case 'simple_overview':
                newReportTypestate.simpleOverview = e.target.checked;
                break;
            default:
                break;
        }
        resultsContext.setSelectedReports(newReportTypestate);
    };
    const reportFormatChanged = (e) => {
        const newReportFormatState = { ...resultsContext.selectedReportFormats };
        switch (e.target.id) {
            case 'xls':
                newReportFormatState.xls = e.target.checked;
                break;
            case 'csv':
                newReportFormatState.csv = e.target.checked;
                break;
            default:
                break;
        }
        resultsContext.setSelectedReportFormats(newReportFormatState);
    };
    return (
        <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={8}>
                <Paper variant='outlined' className={classes.greyPanel}>
                    <Typography variant='h6'>Report type</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                className={classes.checkboxItem}
                                control={
                                    <Checkbox
                                        id='current_answers'
                                        className={classes.checkbox}
                                        color='default'
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        defaultChecked={resultsContext.selectedReports.yourCurrentAnswers}
                                        onChange={reportTypeChanged}
                                    />
                                }
                                label='Your current answers'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                className={classes.checkboxItem}
                                control={
                                    <Checkbox
                                        id='version_history_answers'
                                        className={classes.checkbox}
                                        color='default'
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        defaultChecked={resultsContext.selectedReports.versionHistoryAllAnswers}
                                        onChange={reportTypeChanged}
                                    />
                                }
                                label='Version history of all answers'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                className={classes.checkboxItem}
                                control={
                                    <Checkbox
                                        id='simple_overview'
                                        className={classes.checkbox}
                                        color='default'
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        defaultChecked={resultsContext.selectedReports.simpleOverview}
                                        onChange={reportTypeChanged}
                                    />
                                }
                                label='Simple overview'
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper variant='outlined' className={classes.greyPanel}>
                    <Typography variant='h6'>Format</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                className={classes.checkboxItem}
                                control={
                                    <Checkbox
                                        id='xls'
                                        className={classes.checkbox}
                                        color='default'
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        defaultChecked={resultsContext.selectedReportFormats.xls}
                                        onChange={reportFormatChanged}
                                    />
                                }
                                label='XLSX'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                className={classes.checkboxItem}
                                control={
                                    <Checkbox
                                        id='csv'
                                        className={classes.checkbox}
                                        color='default'
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        defaultChecked={resultsContext.selectedReportFormats.csv}
                                        onChange={reportFormatChanged}
                                    />
                                }
                                label='CSV'
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default OrganisationReportSelector;
