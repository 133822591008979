import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContentManagementContext } from '../../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../../components/JiscButton/JiscButton';
import Loader from '../../../../components/Loader/Loader';
import HtmlEditor from '../../../../components/HtmlEditor/HtmlEditor';

import * as api from '../../../../lib/api';
import urls from '../../../../config/urls';
import styles from './NewCompetencyStatement.styles';

let mounted;

const NewCompetencyStatement = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const history = useHistory();

    // Data
    const [jiscId, setJiscId] = useState();
    const [jiscIdError, setJiscIdError] = useState();

    const [statement, setStatement] = useState('');
    const [statementError, setStatementError] = useState();

    const [helpText, setHelpText] = useState('');
    const [helpTextError] = useState();

    const [competencyId, setCompetencyId] = useState();
    const [competencyIdError, setCompetencyIdError] = useState();

    const [competencyLevelId, setCompetencyLevelId] = useState();
    const [competencyLevelIdError, setCompetencyLevelIdError] = useState();

    const [availableCompetencies, setAvailableCompetencies] = useState([]);
    const [availableCompetencyLevels] = useState([
        {
            id: 1,
            name: 'Foundation',
            color: '#0083BF',
            description: 'The must haves'
        },
        {
            id: 2,
            name: 'Transform',
            color: '#008E83',
            description: 'The should haves'
        },
        {
            id: 3,
            name: 'Elevate',
            color: '#AA1D5D',
            description: 'The can haves'
        }
    ]);

    // Error
    const [loading, setLoading] = useState();
    const [error, setError] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!jiscId || !statement || !competencyId || competencyLevelId) {
            if (!jiscId) {
                setJiscIdError('You must supply a Jisc ID.');
                return;
            }
            if (!statement) {
                setStatementError('You must supply a statement.');
                setJiscIdError(undefined);
                return;
            }
            if (!competencyId) {
                setCompetencyIdError('You must supply a competency.');
                setJiscIdError(undefined);
                setStatementError(undefined);
                return;
            }
            if (!competencyLevelId) {
                setCompetencyLevelIdError('You must supply a competency level.');
                setCompetencyIdError(undefined);
                setJiscIdError(undefined);
                setStatementError(undefined);
                return;
            }
        }

        setLoading(true);
        setJiscIdError(undefined);
        setStatementError(undefined);
        setCompetencyIdError(undefined);
        setCompetencyLevelIdError(undefined);

        try {
            const { status } = await api.post(`/competency-statements`, {
                jiscId,
                statement,
                helpText,
                competencyId,
                competencyLevelId
            });

            if (status !== 201) throw Error('There was a problem creating this Competency Statement.');

            setErrorMessage(undefined);
            setError(undefined);
            setLoading(false);
            history.push(urls.contentManagement.competencyStatements.url);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    const getAvailableCompetencies = async () => {
        try {
            if (mounted) {
                const { data } = await api.get('/competencies?limit=999999');
                setErrorMessage(undefined);
                setError(undefined);
                setAvailableCompetencies(data.data);
            }
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        mounted = true;
        setActivePage(urls.contentManagement.competencyStatements.add);
        getAvailableCompetencies();

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <ContentManagementLayout>
            {error && errorMessage && (
                <Alert severity='error' className={classes.alert}>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            {!loading && (
                <>
                    <header className={classes.titleHeader}>
                        <Typography variant='h5' component='h1'>
                            {urls.contentManagement.competencyStatements.add.text}
                        </Typography>
                        <div>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='check' role='button' />}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Save
                            </JiscButton>
                            <JiscButton
                                isLink
                                linkType='internal'
                                href={urls.contentManagement.competencyStatements.url}
                                variant='ghost'
                                startIcon={<FontAwesomeIcon icon='arrow-alt-circle-left' role='button' />}
                            >
                                Back to all
                            </JiscButton>
                        </div>
                    </header>
                    <br />
                    <Alert severity='warning'>
                        This Competency Statement (Goal) will NOT be visible on the goal pages until at least one
                        validation statement (question) is assigned.
                    </Alert>

                    <form id='new-comptency-statement' className={classes.form}>
                        <FormControl fullWidth>
                            <TextField
                                label='Jisc ID (Required)'
                                variant='outlined'
                                error={jiscIdError}
                                value={jiscId}
                                onChange={(e) => setJiscId(e.target.value)}
                            />
                            {jiscIdError && <FormHelperText>{jiscIdError}</FormHelperText>}
                        </FormControl>

                        <HtmlEditor
                            title='Statement (Question)'
                            placeholder='Start typing here...'
                            error={statementError}
                            content={statement}
                            setContent={setStatement}
                        />

                        <FormControl fullWidth>
                            <TextField
                                label='Support text (Not in use in alpha, not required.)'
                                variant='outlined'
                                multiline
                                rows={2}
                                defaultValue={helpText}
                                onChange={(e) => setHelpText(e.target.value)}
                                error={helpTextError}
                                value={helpText}
                            />
                            {helpTextError && <FormHelperText>{helpTextError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id='parent-competency'>Parent Competency (Required)</InputLabel>
                            <Select
                                labelId='parent-competency'
                                value={competencyId}
                                label='Parent Competency'
                                error={competencyIdError}
                                onChange={(e) => {
                                    setCompetencyId(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Parent Competency'
                                }}
                            >
                                {availableCompetencies.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            {competencyIdError && <FormHelperText>{competencyIdError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id='competency-level'>Competency Level (Required)</InputLabel>
                            <Select
                                labelId='competency-level'
                                value={competencyLevelId}
                                label='Competency Level'
                                error={competencyLevelIdError}
                                onChange={(e) => {
                                    setCompetencyLevelId(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Competency Level'
                                }}
                            >
                                {availableCompetencyLevels.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {competencyLevelIdError && <FormHelperText>{competencyLevelIdError}</FormHelperText>}
                        </FormControl>
                    </form>
                </>
            )}
            {loading && <Loader />}
        </ContentManagementLayout>
    );
};

export default NewCompetencyStatement;
