import React from 'react';
import { Helmet } from 'react-helmet-async';

import AuthForm from '../../components/AuthForm/AuthForm';

import urls from '../../config/urls';
import styles from './Register.styles';

const Register = () => {
    const classes = styles();

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.register.title}</title>
                <link rel='canonical' href={urls.base.url + urls.register.url} />
            </Helmet>

            <section className={classes.root}>
                <AuthForm
                    title='Register'
                    description='Please provide your email address to register your new account'
                    submitLabel='Register'
                />
            </section>
        </>
    );
};

export default Register;
