import React from 'react';

import styles from './Loader.styles';

const Loader = () => {
    const classes = styles();

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 100 100'
            preserveAspectRatio='xMidYMid'
            className={classes.root}
        >
            <circle
                cx='50'
                cy='50'
                fill='none'
                strokeWidth='10'
                r='35'
                strokeDasharray='164.934 56.978'
                className={classes.circle}
            />
        </svg>
    );
};

export default Loader;
