import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContentManagementContext } from '../../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../../components/JiscButton/JiscButton';
import Loader from '../../../../components/Loader/Loader';
import HtmlEditor from '../../../../components/HtmlEditor/HtmlEditor';
import CustomModal from '../../../../components/CustomModal/CustomModal';

import * as api from '../../../../lib/api';
import urls from '../../../../config/urls';
import styles from './EditCompetencyStatement.styles';
import JiscLink from '../../../../components/JiscLink/JiscLink';

let mounted;

const EditCompetencyStatement = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const history = useHistory();
    const { id } = useParams();

    // Data
    const [competencyStatement, setCompetencyStatement] = useState();
    const [jiscId, setJiscId] = useState();
    const [jiscIdError, setJiscIdError] = useState();

    const [statement, setStatement] = useState();
    const [statementError, setStatementError] = useState();

    const [helpText, setHelpText] = useState('');
    const [helpTextError] = useState();

    const [competencyId, setCompetencyId] = useState();
    const [competencyIdError, setCompetencyIdError] = useState();

    const [competencyLevelId, setCompetencyLevelId] = useState();
    const [competencyLevelIdError, setCompetencyLevelIdError] = useState();

    const [availableCompetencies, setAvailableCompetencies] = useState([]);
    const [availableCompetencyLevels] = useState([
        {
            id: 1,
            name: 'Foundation',
            color: '#0083BF',
            description: 'The must haves'
        },
        {
            id: 2,
            name: 'Transform',
            color: '#008E83',
            description: 'The should haves'
        },
        {
            id: 3,
            name: 'Elevate',
            color: '#AA1D5D',
            description: 'The can haves'
        }
    ]);

    const [availableResources, setAvailableResources] = useState([]);
    const [relatedResources, setRelatedResources] = useState();
    const [newResourceId, setNewResourceId] = useState();
    const [resourceModal, setResourceModal] = useState(false);
    const [resourceError, setResourceError] = useState();

    // Error
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState();

    // Delete
    const [deleteError, setDeleteError] = useState();
    const [comfirmModal, setComfirmModal] = useState(false);

    const deleteCompetencyStatement = async () => {
        try {
            const { status } = await api.del(`/competency-statements/${id}`);

            if (status !== 200) throw Error('There was a problem deleting this Competency statement.');
            setComfirmModal(false);

            history.push(urls.contentManagement.competencyStatements.url);
        } catch (err) {
            setDeleteError(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!jiscId || !statement || !competencyId || competencyLevelId) {
            if (!jiscId) {
                setJiscIdError('You must supply a Jisc ID.');
                return;
            }
            if (!statement) {
                setStatementError('You must supply a statement.');
                setJiscIdError(undefined);
                return;
            }
            if (!competencyId) {
                setCompetencyIdError('You must supply a competency.');
                setJiscIdError(undefined);
                setStatementError(undefined);
                return;
            }
            if (!competencyLevelId) {
                setCompetencyLevelIdError('You must supply a competency level.');
                setCompetencyIdError(undefined);
                setJiscIdError(undefined);
                setStatementError(undefined);
                return;
            }
        }

        setLoading(true);
        setJiscIdError(undefined);
        setStatementError(undefined);
        setCompetencyIdError(undefined);
        setCompetencyLevelIdError(undefined);

        try {
            const { status } = await api.put(`/competency-statements/${id}`, {
                jiscId,
                statement,
                helpText,
                competencyId,
                competencyLevelId
            });

            if (status !== 200) throw Error('There was a problem updating this Competency Statement.');

            setErrorMessage(undefined);
            setError(undefined);
            setLoading(false);
            history.push(urls.contentManagement.competencyStatements.url);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    const getData = async () => {
        try {
            if (mounted) {
                const { data: allCompetencies } = await api.get('/competencies?limit=999999');
                setAvailableCompetencies(allCompetencies.data);

                const { data: allResources } = await api.get('/resources?limit=999999');
                setAvailableResources(allResources.data);

                const { data: currentResources } = await api.get(`/resources?competencyStatementId=${id}&limit=999999`);
                setRelatedResources(currentResources.data);

                const { data } = await api.get(`/competency-statements/${id}`);
                setCompetencyStatement(data);
                setJiscId(data.jisc_id);
                setStatement(data.statement);
                setHelpText(data.help_text);
                setCompetencyId(data.competency_id);
                setCompetencyLevelId(data.competency_level_id);

                setLoading(false);
            }
        } catch (err) {
            setErrorMessage(`There was a problem getting competency statement with id ${id}.`);
            setError(true);
            setLoading(false);
        }
    };

    const linkNewResource = async () => {
        try {
            const { status } = await api.post('/competency-statements/resources', {
                competencyStatementId: id,
                resourceId: newResourceId
            });

            if (status !== 201) throw Error('There was a problem linking this resource.');

            setLoading(true);
            setResourceError(undefined);
            setResourceModal(false);
            setNewResourceId(undefined);

            getData();
        } catch (err) {
            setResourceError(err.message);
        }
    };

    const removeResourceRelation = async (resourceId) => {
        try {
            const { status } = await api.del(
                `/competency-statements/resources?competencyStatementId=${id}&resourceId=${resourceId}`
            );

            if (status !== 200) throw Error('There was a problem removing the resource link.');

            setLoading(true);
            setError(undefined);
            setErrorMessage(undefined);

            getData();
        } catch (err) {
            setError(true);
            setErrorMessage(err.message);
        }
    };

    useEffect(() => {
        mounted = true;
        setActivePage(urls.contentManagement.competencyStatements.edit);
        setLoading(true);
        getData();

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <ContentManagementLayout>
            {!loading && error && errorMessage && (
                <Alert severity='error' className={classes.alert}>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            {!loading && competencyStatement && (
                <>
                    <header className={classes.titleHeader}>
                        <Typography variant='h5' component='h1'>
                            {urls.contentManagement.competencyStatements.edit.text}
                        </Typography>
                        <div>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='check' role='button' />}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Update
                            </JiscButton>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='trash-alt' role='button' />}
                                onClick={() => setComfirmModal(true)}
                                className={classes.trash}
                            >
                                Delete
                            </JiscButton>
                            <JiscButton
                                isLink
                                linkType='internal'
                                href={urls.contentManagement.competencyStatements.url}
                                variant='ghost'
                                startIcon={<FontAwesomeIcon icon='arrow-alt-circle-left' role='button' />}
                            >
                                Back to all
                            </JiscButton>
                        </div>
                    </header>

                    <form id='new-competency-statement' className={classes.form}>
                        <FormControl fullWidth>
                            <TextField
                                label='Jisc ID (Required)'
                                variant='outlined'
                                error={jiscIdError}
                                value={jiscId}
                                onChange={(e) => setJiscId(e.target.value)}
                            />
                            {jiscIdError && <FormHelperText>{jiscIdError}</FormHelperText>}
                        </FormControl>

                        <HtmlEditor
                            title='Statement (Question)'
                            placeholder='Start typing here...'
                            error={statementError}
                            content={statement}
                            setContent={setStatement}
                        />

                        <FormControl fullWidth>
                            <TextField
                                label='Support text'
                                variant='outlined'
                                multiline
                                rows={2}
                                defaultValue={helpText}
                                onChange={(e) => setHelpText(e.target.value)}
                                error={helpTextError}
                                value={helpText}
                            />
                            <FormHelperText>Not in use in alpha, not required.</FormHelperText>
                            {helpTextError && <FormHelperText>{helpTextError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id='parent-competency'>Parent Competency (Required)</InputLabel>
                            <Select
                                labelId='parent-competency'
                                value={competencyId}
                                label='Parent Competency'
                                error={competencyIdError}
                                onChange={(e) => {
                                    setCompetencyId(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Parent Competency'
                                }}
                            >
                                {availableCompetencies.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Currently: {competencyStatement.competency.title}</FormHelperText>
                            {competencyIdError && <FormHelperText>{competencyIdError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id='competency-level'>Competency Level (Required)</InputLabel>
                            <Select
                                labelId='competency-level'
                                value={competencyLevelId}
                                label='Competency Level'
                                error={competencyLevelIdError}
                                onChange={(e) => {
                                    setCompetencyLevelId(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Competency Level'
                                }}
                            >
                                {availableCompetencyLevels.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                Currently: {availableCompetencyLevels[competencyLevelId - 1]?.name}
                            </FormHelperText>
                            {competencyLevelIdError && <FormHelperText>{competencyLevelIdError}</FormHelperText>}
                        </FormControl>
                    </form>

                    <section className={classes.resourceRelations}>
                        <header>
                            <Typography variant='h5' component='h2'>
                                Related resources
                            </Typography>
                            <JiscButton
                                type='button'
                                startIcon={<FontAwesomeIcon icon='link' role='button' />}
                                onClick={() => setResourceModal(true)}
                            >
                                Link resource
                            </JiscButton>
                        </header>

                        {relatedResources.length ? (
                            <TableContainer>
                                <Table>
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant='body1'>Title</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant='body1'>Excerpt</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant='body1'>Remove relation</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {relatedResources.map((resource) => (
                                            <TableRow key={resource.id}>
                                                <TableCell>
                                                    <JiscLink
                                                        type='internal'
                                                        to={`${urls.contentManagement.resources.edit.url}/${id}`}
                                                    >
                                                        {resource.title}
                                                    </JiscLink>
                                                </TableCell>
                                                <TableCell>{resource.excerpt}</TableCell>
                                                <TableCell>
                                                    <JiscButton
                                                        type='submit'
                                                        startIcon={<FontAwesomeIcon icon='unlink' role='button' />}
                                                        onClick={() => {
                                                            removeResourceRelation(resource.id);
                                                        }}
                                                        className={classes.trash}
                                                    >
                                                        Unlink
                                                    </JiscButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography variant='body1'>
                                This competency statement does not currently have any related resources. Use the above
                                button to create a link.
                            </Typography>
                        )}
                    </section>

                    <CustomModal
                        dialogTitle='new-resource'
                        title='Please select the resource you wish to link to this competency statement.'
                        open={resourceModal}
                        onClose={() => {
                            setResourceModal(false);
                        }}
                        buttonText='Link'
                        successCallback={linkNewResource}
                        error={resourceError}
                        errorMessage={resourceError}
                    >
                        <Select
                            label='Available resources'
                            error={resourceError}
                            onChange={(e) => {
                                setNewResourceId(e.target.value);
                            }}
                            className={classes.resourcesSelect}
                            inputProps={{
                                ariaLabel: 'Available resources'
                            }}
                        >
                            {availableResources.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </Select>

                        <Typography variant='body2' className={classes.resourcesSupport}>
                            Or create a new{' '}
                            <JiscLink type='internal' to={urls.contentManagement.resources.add.url}>
                                here
                            </JiscLink>
                            .
                        </Typography>
                    </CustomModal>

                    <CustomModal
                        dialogTitle='delete-competency-statement'
                        title='Are you sure you want to delete this competency statement?'
                        open={comfirmModal}
                        onClose={() => {
                            setComfirmModal(false);
                        }}
                        buttonText='Confirm delete'
                        successCallback={deleteCompetencyStatement}
                        error={deleteError}
                        errorMessage={setDeleteError}
                    />
                </>
            )}
            {loading && <Loader />}
        </ContentManagementLayout>
    );
};

export default EditCompetencyStatement;
