import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Select from '@mui/material/Select';
import { Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Typography, InputLabel, MenuItem, CircularProgress } from '@material-ui/core';

import { useSurveyContext } from '../../lib/hooks/useSurveyContext';
import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { NoteHistory, NewNote } from '../NoteModalContent/NoteModalContent';
import * as helpers from '../../lib/helpers';
import * as api from '../../lib/api';
import Modal from '../Modal/Modal';

import styles from './GoalCompetencyCard.styles';

function GoalCompetencyCard({ competencyOption, refreshSurvey, status = 'All', notes }) {
    const classes = styles({});

    const ANSWERED_DONE = 1;
    const [currentResponse, setCurrentResponse] = useState({
        value: competencyOption.responses[0] ? competencyOption.responses[0] : 0
    });

    return (
        <div key={competencyOption.competency_option_id} className={classes.validationStatement}>
            <div className={classes.competencyOption}>
                <Typography
                    variant='body1'
                    style={{
                        color: currentResponse.value === ANSWERED_DONE ? 'rgba(0, 0, 0, 0.60)' : 'rgba(0, 0, 0, 0.87)'
                    }}
                >
                    {parse(competencyOption.competency_option_text)}
                </Typography>
            </div>
            <GoalCompetencyOptionActions
                key={competencyOption.competency_option_id}
                competencyOption={competencyOption}
                refreshSurvey={refreshSurvey}
                status={status}
                currentResponse={currentResponse}
                setCurrentResponse={setCurrentResponse}
                initialNotes={notes}
            />
        </div>
    );
}

GoalCompetencyCard.propTypes = {
    competencyOption: PropTypes.shape({
        competency_option_id: PropTypes.number.isRequired,
        competency_option_text: PropTypes.string.isRequired,
        responses: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                value: PropTypes.number
            })
        ).isRequired
    }).isRequired,
    refreshSurvey: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(
        PropTypes.shape({
            competency_option_id: PropTypes.number,
            competency_title: PropTypes.string,
            content: PropTypes.string,
            created_at: PropTypes.string,
            created_by: PropTypes.string,
            statement: PropTypes.string,
            status: PropTypes.string,
            sub_theme_title: PropTypes.string,
            text: PropTypes.string,
            theme_title: PropTypes.string
        })
    ).isRequired
};

export default GoalCompetencyCard;

function GoalCompetencyOptionActions({
    competencyOption,
    refreshSurvey,
    status = 'All',
    currentResponse,
    setCurrentResponse,
    initialNotes
}) {
    const classes = styles({});
    const ctx = useSurveyContext();

    const { auth } = useAuthContext();

    const NOT_ANSWERED = 0;

    const [errorGetNotes, setErrorGetNotes] = useState(false);
    const [errorMessageGetNotes, setErrorMessageGetNotes] = useState(null);

    const [errorSubmitNotes, setErrorSubmitNotes] = useState(false);
    const [errorMessageSubmitNotes, setErrorMessageSubmitNotes] = useState(null);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [notesLoading, setNotesLoading] = useState(false);
    const [noteHistoryModal, setNoteHistoryModal] = useState(false);
    const [notes, setNotes] = useState(initialNotes);

    const findResponseOptionByValue = (value) => {
        return ctx.responseOptions.find((ro) => ro.id === Number(value));
    };

    const updateResponse = async (value) => {
        const foundResponse = findResponseOptionByValue(value);

        if (foundResponse !== currentResponse) {
            setCurrentResponse(foundResponse);

            try {
                await api.post('/responses', {
                    competencyOptionId: competencyOption.competency_option_id,
                    value: foundResponse.value
                });
                setError(false);
                refreshSurvey();
            } catch (err) {
                setError(true);
                setErrorMessage('There was a problem updating this questions answer, please try again later.');
            }
        }
    };

    const getNotes = async () => {
        setNotes([]);
        setErrorGetNotes(false);
        setNotesLoading(true);

        const institutionId = auth.user.institution_id;

        try {
            const res = await api.get(
                `/institutions/${institutionId}/competency-options/${competencyOption.competency_option_id}/notes`
            );

            setNotes(res.data.notes);
        } catch (e) {
            setErrorGetNotes(true);
            setErrorMessageGetNotes('There was a problem retrieving the notes for this question');
        } finally {
            setNotesLoading(false);
        }
    };

    const handleSubmitNote = async (noteContent) => {
        setErrorSubmitNotes(false);
        setNotesLoading(true);

        const institutionId = auth.user.institution_id;

        try {
            if (noteContent !== null) {
                await api.post(
                    `/institutions/${institutionId}/competency-options/${competencyOption.competency_option_id}/notes`,
                    {
                        content: noteContent
                    }
                );

                setErrorSubmitNotes(false);
                await getNotes();
            }
        } catch (e) {
            setErrorSubmitNotes(true);
            setErrorMessageSubmitNotes('There was a problem submitting the note');
        } finally {
            setNotesLoading(false);
        }
    };

    useEffect(() => {
        if (competencyOption.responses.length) {
            setCurrentResponse({ value: competencyOption.responses[0].value });
        }
    }, [competencyOption.responses]);

    useEffect(() => {
        setNotes(initialNotes);
    }, [initialNotes]);

    return currentResponse && competencyOption.competency_option_id ? (
        ((currentResponse.primary_label && currentResponse.primary_label === status) || status === 'All') &&
            competencyOption.competency_option_id && (
                <div className={classes.actions}>
                    {!errorGetNotes ? (
                        <button
                            type='button'
                            className={classes.noteButton}
                            onClick={() => {
                                setNoteHistoryModal(true);
                            }}
                            aria-label='Notes'
                        >
                            {notesLoading ? (
                                <CircularProgress size='16px' aria-label='Loading notes' />
                            ) : (
                                <> Notes ({notes.length}) </>
                            )}
                        </button>
                    ) : (
                        <Typography variant='body1' className={classes.errorMessage}>
                            {errorMessageGetNotes}
                        </Typography>
                    )}

                    <FormControl style={{ position: 'relative' }}>
                        <InputLabel
                            id={`label-${competencyOption.competency_option_id}`}
                            shrink
                            className={classes.inputLabel}
                        >
                            Status
                        </InputLabel>

                        <Select
                            labelId={`label-${competencyOption.competency_option_id}`}
                            id={competencyOption.competency_option_id}
                            value={currentResponse.value}
                            renderValue={(selected) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {selected === 1 && (
                                        <FontAwesomeIcon
                                            style={{ marginRight: '8px', color: '#2E7D32' }}
                                            icon={['fas', 'check-circle']}
                                        />
                                    )}
                                    {selected !== 0 &&
                                    ctx.responseOptions.find((responseOption) => responseOption.value === selected)
                                        ? helpers.formatSentence(
                                              ctx.responseOptions.find(
                                                  (responseOption) => responseOption.value === selected
                                              )?.secondary_label
                                          )
                                        : 'Not answered'}
                                </div>
                            )}
                            aria-label={competencyOption.competency_option_text}
                            onChange={(e) => updateResponse(e.target.value)}
                            className={classes.select}
                        >
                            <MenuItem disabled value={NOT_ANSWERED}>
                                Not answered
                            </MenuItem>
                            {ctx.responseOptions.map((responseOption) => (
                                <MenuItem key={responseOption.id} value={responseOption.value}>
                                    {responseOption.value === 0
                                        ? 'Not answered'
                                        : helpers.formatSentence(responseOption.secondary_label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {error && (
                        <Typography variant='body1' className={classes.errorMessage}>
                            {errorMessage}
                        </Typography>
                    )}
                    <Modal
                        title='Notes'
                        open={noteHistoryModal}
                        maxWidth='md'
                        setModalOpen={() => setNoteHistoryModal(false)}
                        useDefaultDialog={false}
                    >
                        <NoteHistory notes={notes} notesLoading={notesLoading} />
                        <NewNote handleSubmitNote={handleSubmitNote} setNoteHistoryModal={setNoteHistoryModal} />
                        {errorSubmitNotes && (
                            <Typography variant='body1' className={classes.errorMessage}>
                                {errorMessageSubmitNotes}
                            </Typography>
                        )}
                    </Modal>
                </div>
            )
    ) : (
        <Skeleton data-testid='skeleton' variant='rect' height={100} />
    );
}

GoalCompetencyOptionActions.propTypes = {
    competencyOption: PropTypes.shape({
        competency_option_id: PropTypes.number.isRequired,
        competency_option_text: PropTypes.string.isRequired,
        responses: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                value: PropTypes.number
            })
        ).isRequired
    }).isRequired,
    refreshSurvey: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    currentResponse: PropTypes.shape({
        value: PropTypes.number.isRequired
    }).isRequired,
    setCurrentResponse: PropTypes.func.isRequired,
    initialNotes: PropTypes.arrayOf(
        PropTypes.shape({
            competency_option_id: PropTypes.number,
            competency_title: PropTypes.string,
            content: PropTypes.string,
            created_at: PropTypes.string,
            created_by: PropTypes.string,
            statement: PropTypes.string,
            status: PropTypes.string,
            sub_theme_title: PropTypes.string,
            text: PropTypes.string,
            theme_title: PropTypes.string
        })
    ).isRequired
};
