import React, { useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { getAvailableThemes, titleToSlug } from '../../lib/api';
import ThemePickerCard from './ThemePickerCard/ThemePickerCard';
import styles from './ThemePicker.styles';
import urls from '../../config/urls';

function ThemePicker({ title }) {
    const history = useHistory();
    const classes = styles();

    const { auth } = useAuthContext();

    const [availableThemes, setAvailableThemes] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetchThemes = useCallback(async () => {
        setLoading(true);
        setError(false);
        const themes = await getAvailableThemes(auth.user.institution_id);

        if (!themes.length) {
            setError(true);
            setLoading(false);
            return;
        }

        setAvailableThemes(themes);
        setLoading(false);
    }, [auth.user.institution_id]);

    useEffect(() => {
        fetchThemes();
    }, [fetchThemes]);

    return (
        <div className={classes.root}>
            <Typography data-testid='theme-picker-title' variant='h3' className={classes.themeSelectionTitle}>
                {title}
            </Typography>
            <Typography data-testid='theme-picker-subtitle' variant='body1'>
                The self-assessment is split in to 5 key themes. Select the themes you want to answer on behalf of your
                organisation.
            </Typography>
            {error ? (
                <Alert data-testid='theme-picker-error-message' className={classes.error} severity='error'>
                    <AlertTitle>There was an error fetching themes for this survey.</AlertTitle>
                    <Typography varient='body2'>
                        Please try again later or if this issues persist, please contact us at{' '}
                        <a
                            href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                            target='_blank'
                            rel='noreferrer'
                        >
                            help@jisc.ac.uk
                        </a>
                    </Typography>
                </Alert>
            ) : (
                <div data-testid='theme-picker-options' className={classes.themeSelectionGrid}>
                    {!loading && availableThemes.length ? (
                        <>
                            {availableThemes.map((theme) => (
                                <ThemePickerCard
                                    key={theme.theme_id}
                                    theme={theme}
                                    handleClick={() =>
                                        history.push(`${urls.survey.url}/${titleToSlug(theme.theme_title)}`)
                                    }
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            <Skeleton data-testid='skeleton' variant='rect' height={350} />
                            <Skeleton data-testid='skeleton' variant='rect' height={350} />
                            <Skeleton data-testid='skeleton' variant='rect' height={350} />
                            <Skeleton data-testid='skeleton' variant='rect' height={350} />
                            <Skeleton data-testid='skeleton' variant='rect' height={350} />
                            <Skeleton data-testid='skeleton' variant='rect' height={350} />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

ThemePicker.propTypes = {
    title: PropTypes.string.isRequired
};

export default ThemePicker;
