import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Container,
    Typography,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    Grid,
    TableBody,
    TextField,
    TablePagination,
    Button,
    Grow,
    MenuItem,
    Menu,
    Radio,
    ListItemIcon,
    RadioGroup,
    FormControlLabel,
    Paper,
    Popper,
    ClickAwayListener,
    FormControl,
    Select,
    InputLabel
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { debounce } from 'lodash';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import NotFound from '../NotFound/NotFound';
import JiscButton from '../../components/JiscButton/JiscButton';
import JiscLink from '../../components/JiscLink/JiscLink';
import CustomModal from '../../components/CustomModal/CustomModal';

import * as enums from '../../lib/enums';
import * as api from '../../lib/api';
import urls from '../../config/urls';
import styles from './OrganisationSettings.styles';

let mounted;

const OrganisationSettings = () => {
    const classes = styles();
    const { id } = useParams();

    const institutionId = parseInt(id, 10);
    const [institution, setInstitution] = useState();

    const { auth } = useAuthContext();

    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState({
        users: [],
        count: 0
    });
    const [selectedUser, setSelectedUser] = useState({});
    const [error, setError] = useState(false);

    const [inviteUserModalState, setInviteUserModalState] = useState(false);
    const [editUserModalState, setEditUserModalState] = useState(false);
    const [deleteUserModalState, setDeleteUserModalState] = useState(false);
    const [revokeInvitationModalState, setRevokeInvitationModalState] = useState(false);

    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserRoleId, setNewUserRoleId] = useState(3);

    const [updatedUserRoleId, setUpdatedUserRoleId] = useState(3);

    const [inviteUserError, setInviteUserError] = useState(false);
    const [inviteUserErrorMessage, setInviteUserErrorMessage] = useState('');

    const [editUserError, setEditUserError] = useState(false);
    const [deleteUserError, setDeleteUserError] = useState(false);
    const [revokeInvitationError, setRevokeInvitationError] = useState(false);

    const [status, setStatus] = useState('1');
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuAnchorRef = useRef(null);

    const tableSettings = {
        0: {
            email: 'Email',
            role_id: 'Role',
            date_invited: 'Date invited',
            invited_by: 'Invited By'
        },
        1: {
            email: 'Email',
            role_id: 'Role',
            user_created: 'Date joined',
            invited_by: 'Invited By'
        }
    };

    const slowSearch = useCallback(
        debounce((_searchVal) => {
            setDebouncedSearch(_searchVal);
        }, 300),
        []
    );

    const updateSearch = (e) => {
        setSearch(e.target.value);
        slowSearch(e.target.value);
    };

    const handleMenuOpen = (event, user) => {
        setSelectedUser(user);
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleMenuClose = async () => {
        setMenuOpen(false);
    };

    const handleMenuListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    };

    const getInstitution = async () => {
        try {
            if (mounted) {
                const { data } = await api.get(`/institutions/${institutionId}`);
                setInstitution(data);
            }
        } catch (err) {
            setError(true);
        }
    };

    const getUsers = async () => {
        try {
            if (mounted) {
                const { data: userData } = await api.get(
                    `/institutions/${institutionId}/users?limit=${limit}&offset=${offset}&search=${search}&accepted=${status}`
                );
                setUsers(userData);
                setError(false);
            }
        } catch (err) {
            setError(true);
        }
    };

    const inviteUser = async () => {
        try {
            const { data } = await api.post(`/invitations/${institutionId}`, {
                invitations: [{ email: newUserEmail.toLowerCase(), role: newUserRoleId }]
            });

            if (data.usersAlreadyBelongToInstitution.length) {
                setInviteUserErrorMessage(`${data.usersAlreadyBelongToInstitution[0]} has already been invited.`);
                setInviteUserError(true);
                setInviteUserModalState(true);
            } else {
                setInviteUserError(false);
                setInviteUserModalState(false);
                setStatus('0');
                getUsers();
            }
        } catch (err) {
            setInviteUserErrorMessage('Failed to invite user, please ensure you have entered the correct details.');
            setInviteUserError(true);
            setInviteUserModalState(true);
        }
    };

    const modifyUser = async () => {
        try {
            await api.put(`/user/${selectedUser.user_id}`, {
                role: updatedUserRoleId
            });

            getUsers();
            setEditUserError(false);
            setEditUserModalState(false);
        } catch (err) {
            setEditUserError(true);
        }
    };

    const deleteUser = async () => {
        try {
            await api.del(`/user/${selectedUser.user_id}`);

            getUsers();
            setDeleteUserError(false);
            setDeleteUserModalState(false);
        } catch (err) {
            setEditUserError(true);
        }
    };

    const revokeUserInvitation = async () => {
        try {
            await api.put(`/invitations`, {
                email: selectedUser.email.toLowerCase(),
                institution_ids: [selectedUser.institution_id],
                all: false
            });

            getUsers();
            setRevokeInvitationError(false);
            setRevokeInvitationModalState(false);
        } catch (err) {
            setRevokeInvitationError(true);
        }
    };

    useEffect(() => {
        setNewUserRoleId(3);
    }, [inviteUserModalState]);

    useEffect(() => {
        if (selectedUser) {
            setNewUserEmail(selectedUser.email);
            setNewUserRoleId(selectedUser.roleId);
        }
    }, [editUserModalState]);

    useEffect(() => {
        mounted = true;
        getInstitution();
        getUsers();

        setLoading(false);

        return () => {
            mounted = false;
        };
    }, [limit, offset, debouncedSearch, status]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.organisationSettings.title}</title>
                <link rel='canonical' href={urls.base.url + urls.organisationSettings.url} />
            </Helmet>

            {auth.user.role === enums.user.role.JISC_ADMIN ||
            (auth.user.role === enums.user.role.ADMIN && institutionId === auth.user.institution_id) ? (
                <>
                    <main className={classes.root}>
                        <Container maxWidth='lg'>
                            {!loading && institution && (
                                <div className={classes.content}>
                                    <aside>
                                        <Typography variant='body1'>Status</Typography>
                                        {!error && (
                                            <RadioGroup
                                                name='status'
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <FormControlLabel
                                                    data-testid='organisation-settings-status-active'
                                                    label='Active'
                                                    value='1'
                                                    control={<Radio classes={{ root: classes.radioAll }} />}
                                                />
                                                <FormControlLabel
                                                    data-testid='organisation-settings-status-pending'
                                                    label='Pending'
                                                    value='0'
                                                    control={<Radio classes={{ root: classes.radioAll }} />}
                                                />
                                            </RadioGroup>
                                        )}
                                    </aside>
                                    <section className={classes.contentSection}>
                                        <header className={classes.contentSectionHeader}>
                                            <div className={classes.contentSectionHeaderTitles}>
                                                <Typography
                                                    data-testid='organisation-settings-main-title'
                                                    className={classes.primaryTitle}
                                                    variant='h1'
                                                >
                                                    {urls.organisationSettings.text} for {institution.name}
                                                </Typography>
                                                <Typography variant='body2'>
                                                    If you would like to learn about this feature please{' '}
                                                    <JiscLink type='internal' to={urls.help.url}>
                                                        view our help and support page
                                                    </JiscLink>
                                                    .
                                                </Typography>
                                            </div>
                                            <div
                                                data-testid='organisation-settings-invite-user'
                                                className={classes.contentSectionHeaderControls}
                                            >
                                                <JiscButton
                                                    variant='ghost'
                                                    startIcon={<FontAwesomeIcon icon='users' role='button' />}
                                                    onClick={() => setInviteUserModalState(true)}
                                                >
                                                    Invite user
                                                </JiscButton>

                                                <InputLabel htmlFor='search-input'>
                                                    <TextField
                                                        id='search-input'
                                                        variant='outlined'
                                                        placeholder='Search for a user by email'
                                                        fullWidth
                                                        value={search}
                                                        onChange={updateSearch}
                                                        className={classes.textField}
                                                    />
                                                </InputLabel>
                                            </div>
                                        </header>
                                        <TableContainer>
                                            <Table data-testid='organisation-settings-user-table'>
                                                <TableHead className={classes.tableHead}>
                                                    <TableRow>
                                                        {Object.values(tableSettings[status]).map((header) => (
                                                            <TableCell key={header}>{header}</TableCell>
                                                        ))}
                                                        <TableCell className={classes.userTableButtonTitle}>
                                                            Options
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {loading &&
                                                        new Array(limit).fill(0).map((item, index) => (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <TableRow key={item + index}>
                                                                <TableCell>
                                                                    <Skeleton
                                                                        data-testid='skeleton'
                                                                        variant='rect'
                                                                        height={20}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Skeleton
                                                                        data-testid='skeleton'
                                                                        variant='rect'
                                                                        height={20}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Skeleton
                                                                        data-testid='skeleton'
                                                                        variant='rect'
                                                                        height={20}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Skeleton
                                                                        data-testid='skeleton'
                                                                        variant='rect'
                                                                        height={20}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Skeleton
                                                                        data-testid='skeleton'
                                                                        variant='rect'
                                                                        height={20}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    {!loading &&
                                                        !error &&
                                                        users.users &&
                                                        users.users.map((user, index) => (
                                                            <TableRow
                                                                key={user.email}
                                                                className={
                                                                    index % 2 !== 0 ? classes.tableRowOdd : undefined
                                                                }
                                                            >
                                                                <TableCell>{user.email}</TableCell>
                                                                <TableCell>
                                                                    {user.accepted
                                                                        ? Object.keys(enums.user.role)[
                                                                              user.user_role_id - 1
                                                                          ]
                                                                        : Object.keys(enums.user.role)[
                                                                              user.invitation_role_id - 1
                                                                          ]}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.accepted
                                                                        ? DateTime.fromISO(user.user_created).toFormat(
                                                                              'DD'
                                                                          )
                                                                        : DateTime.fromISO(user.date_invited).toFormat(
                                                                              'DD'
                                                                          )}
                                                                </TableCell>
                                                                <TableCell>{user.invited_by}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        data-testid={`organisation-settings-user-table-options-${index}`}
                                                                        ref={menuAnchorRef}
                                                                        title={`organisation-settings-user-table-options-${user.email}`}
                                                                        aria-controls={
                                                                            menuOpen ? 'user-table-button' : undefined
                                                                        }
                                                                        aria-haspopup='true'
                                                                        onClick={(e) => handleMenuOpen(e, user)}
                                                                        variant='outlined'
                                                                        className={classes.userTableButton}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon='ellipsis-h'
                                                                            role='button'
                                                                        />
                                                                    </Button>

                                                                    <Popper
                                                                        open={menuOpen}
                                                                        anchorEl={menuAnchorRef.current}
                                                                        role={undefined}
                                                                        transition
                                                                        disablePortal
                                                                        className={classes.dropDownMenu}
                                                                    >
                                                                        {({ TransitionProps }) => (
                                                                            <Grow {...TransitionProps}>
                                                                                <Paper>
                                                                                    <ClickAwayListener
                                                                                        onClickAway={handleMenuClose}
                                                                                    >
                                                                                        <Menu
                                                                                            data-testid={`organisation-settings-user-table-option-menu-${index}`}
                                                                                            autofocusitem={menuOpen.toString()}
                                                                                            id={`menu-${user.email}`}
                                                                                            onKeyDown={
                                                                                                handleMenuListKeyDown
                                                                                            }
                                                                                            anchorEl={anchorEl}
                                                                                            keepMounted
                                                                                            open={selectedUser === user}
                                                                                        >
                                                                                            {user.accepted ? (
                                                                                                <div>
                                                                                                    <MenuItem
                                                                                                        key='user-table-edit'
                                                                                                        data-testid={`user-table-edit-${index}`}
                                                                                                        onClick={() =>
                                                                                                            setEditUserModalState(
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <ListItemIcon>
                                                                                                            <FontAwesomeIcon icon='user-cog' />
                                                                                                        </ListItemIcon>
                                                                                                        Edit user
                                                                                                    </MenuItem>
                                                                                                    <MenuItem
                                                                                                        key='user-table-remove'
                                                                                                        data-testid={`user-table-remove-${index}`}
                                                                                                        className={
                                                                                                            classes.buttonDelete
                                                                                                        }
                                                                                                        onClick={() =>
                                                                                                            setDeleteUserModalState(
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <ListItemIcon>
                                                                                                            <FontAwesomeIcon icon='user-slash' />
                                                                                                        </ListItemIcon>
                                                                                                        Remove user
                                                                                                    </MenuItem>{' '}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    <MenuItem
                                                                                                        key='user-table-revoke'
                                                                                                        data-testid={`user-table-revoke-${index}`}
                                                                                                        onClick={() =>
                                                                                                            setRevokeInvitationModalState(
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <ListItemIcon>
                                                                                                            <FontAwesomeIcon icon='ban' />
                                                                                                        </ListItemIcon>
                                                                                                        Revoke
                                                                                                        invitation
                                                                                                    </MenuItem>
                                                                                                </div>
                                                                                            )}
                                                                                        </Menu>
                                                                                    </ClickAwayListener>
                                                                                </Paper>
                                                                            </Grow>
                                                                        )}
                                                                    </Popper>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Grid container>
                                            <Grid item xs={12} md={6} />
                                            <Grid item md={6}>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                                                    component='div'
                                                    count={parseInt(users.count, 10)}
                                                    rowsPerPage={limit}
                                                    page={Math.floor(offset / limit)}
                                                    onChangePage={(_, page) => {
                                                        setOffset(page * limit);
                                                    }}
                                                    onChangeRowsPerPage={(event) => {
                                                        setLimit(event.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </section>
                                </div>
                            )}
                        </Container>
                    </main>

                    {/** @description Invite user modal */}
                    <CustomModal
                        testId='organisation-settings-invite-user-modal'
                        dialogTitle='invite-user'
                        title={`Invite a new user to ${institution?.name}`}
                        open={inviteUserModalState}
                        onClose={() => {
                            setNewUserEmail('');
                            setInviteUserModalState(false);
                            setSelectedUser({});
                        }}
                        buttonText='Send invite'
                        successCallback={() => {
                            inviteUser();
                            setNewUserEmail('');
                        }}
                        error={inviteUserError}
                        errorMessage={inviteUserErrorMessage}
                    >
                        <TextField
                            variant='outlined'
                            label='Users email'
                            fullWidth
                            value={newUserEmail || ''}
                            onChange={(e) => setNewUserEmail(e.target.value)}
                            className={classes.textField}
                            type='email'
                            inputProps={{ 'data-testid': 'organisation-settings-invite-user-modal-email-input' }}
                        />
                        <FormControl variant='outlined' fullWidth className={classes.textField}>
                            <Select
                                native
                                value={newUserRoleId}
                                onChange={(e) => setNewUserRoleId(e.target.value)}
                                inputProps={{
                                    ariaLabel: 'Role',
                                    name: 'Role',
                                    id: 'new-user-role-id',
                                    'data-testid': 'organisation-settings-invite-user-modal-role-select'
                                }}
                            >
                                <option disabled>Role</option>
                                <option value={3}>User</option>
                                {auth.user.role === enums.user.role.ADMIN && (
                                    <>
                                        <option value={2}>Admin</option>
                                    </>
                                )}
                                {auth.user.role === enums.user.role.JISC_ADMIN && (
                                    <>
                                        <option value={2}>Admin</option>
                                        <option value={4}>Jisc Reporter</option>
                                        <option value={5}>Jisc Content Manager</option>
                                    </>
                                )}
                            </Select>
                        </FormControl>
                    </CustomModal>

                    {/** @description Edit user modal */}
                    <CustomModal
                        testId='organisation-settings-edit-user-modal'
                        dialogTitle='edit-user'
                        title={`Edit User (${selectedUser.email})`}
                        open={editUserModalState}
                        onClose={() => {
                            setEditUserModalState(false);
                            setSelectedUser({});
                        }}
                        buttonText='Save changes'
                        successCallback={() => modifyUser()}
                        error={editUserError}
                        errorMessage='Failed to edit user, please ensure you have entered the correct details.'
                    >
                        <FormControl variant='outlined' fullWidth className={classes.textField}>
                            <Select
                                native
                                value={updatedUserRoleId}
                                onChange={(e) => setUpdatedUserRoleId(e.target.value)}
                                inputProps={{
                                    name: 'Role',
                                    id: 'new-user-role-id',
                                    ariaLabel: 'Role'
                                }}
                            >
                                <option disabled>Role</option>
                                <option value={3}>User</option>
                                {auth.user.role === enums.user.role.JISC_ADMIN ||
                                auth.user.role === enums.user.role.ADMIN ? (
                                    <option value={2}>Admin</option>
                                ) : null}
                                {auth.user.role === enums.user.role.JISC_ADMIN ? (
                                    <option value={1}>Jisc Admin</option>
                                ) : (
                                    ''
                                )}
                                {auth.user.role === enums.user.role.JISC_ADMIN && (
                                    <>
                                        <option value={4}>Jisc Reporter</option>
                                        <option value={5}>Jisc Content Manager</option>
                                    </>
                                )}
                            </Select>
                        </FormControl>
                    </CustomModal>

                    {/** @description Delete user modal */}
                    <CustomModal
                        testId='organisation-settings-delete-user-modal'
                        dialogTitle='delete-user'
                        title={`Are you sure you want to delete ${selectedUser.email}?`}
                        open={deleteUserModalState}
                        onClose={() => {
                            setDeleteUserModalState(false);
                            setSelectedUser({});
                        }}
                        buttonText='Delete user'
                        successCallback={() => deleteUser()}
                        error={deleteUserError}
                        errorMessage={`Failed to delete ${selectedUser.name}`}
                    />

                    {/** @description Revoke a pending invite */}
                    <CustomModal
                        testId='organisation-settings-revoke-invitation-modal'
                        dialogTitle='revoke-invitation'
                        title={`Are you sure you want to revoke this invitation to ${selectedUser.email}`}
                        open={revokeInvitationModalState}
                        onClose={() => {
                            setRevokeInvitationModalState(false);
                            setSelectedUser({});
                        }}
                        buttonText='Revoke invitation'
                        successCallback={revokeUserInvitation}
                        error={revokeInvitationError}
                        errorMessage={`Failed to revoke invitation for ${selectedUser.email}`}
                    />
                </>
            ) : (
                <NotFound />
            )}
        </>
    );
};

export default OrganisationSettings;
