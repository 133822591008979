import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { getThemeIcon } from '../../lib/enums';
import styles from './UnseenThemes.styles';

const UnseenThemes = ({ notStartedThemes }) => {
    const fullWidth = notStartedThemes.length % 2 === 0 || notStartedThemes.length === 1;
    const classes = styles({ fullWidth });

    return notStartedThemes.length ? (
        <div data-testid='unseen-themes' className={classes.root}>
            <Typography variant='h5' className={classes.unseenThemesTitle}>
                Themes you haven&apos;t looked at yet
            </Typography>
            <div className={classes.unseenThemeGrid}>
                {notStartedThemes.map((theme) => (
                    <div className={classes.themeCard} key={theme.theme_id}>
                        {getThemeIcon(theme.theme_icon)}
                        <Typography>{theme.theme_title}</Typography>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <></>
    );
};

UnseenThemes.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    notStartedThemes: PropTypes.array.isRequired
};

export default UnseenThemes;
