import { Typography } from '@material-ui/core';
import React from 'react';

import QuestionPlant from '../../lib/assets/QuestionPlant';
import JiscLink from '../JiscLink/JiscLink';

import styles from './HelpBanner.styles';

const HelpBanner = () => {
    const classes = styles();

    return (
        <section id='fes-help-banner' className={classes.banner}>
            <div>
                <Typography variant='h2' className={classes.title}>
                    We’re here to help
                </Typography>
                <Typography variant='body1'>Any further question please contact us.</Typography>
                <Typography variant='body1'>
                    Contact:{' '}
                    <JiscLink
                        type='external'
                        href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                        rel='noreferrer noopener'
                        target='_blank'
                    >
                        help@jisc.ac.uk
                    </JiscLink>
                </Typography>
            </div>
            <QuestionPlant />
        </section>
    );
};

export default HelpBanner;
