import React from 'react';

function Transform() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'>
            <g data-name='Group 2435' transform='translate(6658 -13973)'>
                <g data-name='Group 2434'>
                    <rect
                        data-name='Rectangle 2392'
                        width='80'
                        height='80'
                        transform='translate(-6658 13973)'
                        fill='none'
                    />
                </g>
                <g data-name='elevate icon 3' transform='translate(-6630.668 13983)'>
                    <g data-name='Layer 1' transform='translate(0 0)'>
                        <g data-name='Group 2415'>
                            <path
                                data-name='Path 4128'
                                d='M11.4,38.169A10.038,10.038,0,0,1,9.748,38C4.284,37.081-1.089,32.3.2,21.7,1.309,12.421,13.4.89,13.928.4a1.52,1.52,0,0,1,2.385.432c.314.655,7.7,15.894,7.05,24.018a15.4,15.4,0,0,1-5.53,11.02A10.483,10.483,0,0,1,11.4,38.169ZM14.5,4.1C11.294,7.428,3.93,15.644,3.183,22.065c-.891,7.325,1.69,12.055,7.076,13a7.338,7.338,0,0,0,5.726-1.572,12.343,12.343,0,0,0,4.363-8.884c.472-5.726-3.9-16.2-5.844-20.507Z'
                                transform='translate(-0.003 -0.004)'
                                fill='#0083bf'
                            />
                            <path
                                data-name='Path 4129'
                                d='M20.082,46.874a1.507,1.507,0,0,1-.865-.275,15.226,15.226,0,0,0-7.862-2.346A14.912,14.912,0,0,0,3.494,46.6a1.509,1.509,0,1,1-1.743-2.463,18.043,18.043,0,0,1,9.592-2.9,17.938,17.938,0,0,1,9.592,2.9,1.507,1.507,0,0,1-.865,2.739Z'
                                transform='translate(0.341 12.793)'
                                fill='#0083bf'
                            />
                            <path
                                data-name='Path 4130'
                                d='M9.445,53.263a1.507,1.507,0,0,1-1.507-1.31,184.523,184.523,0,0,1,.446-38.445,1.508,1.508,0,1,1,2.988.419,184.117,184.117,0,0,0-.485,37.685,1.52,1.52,0,0,1-1.31,1.651Z'
                                transform='translate(2.213 3.784)'
                                fill='#0083bf'
                            />
                        </g>
                    </g>
                    <g data-name='Layer 3' transform='translate(2.249 4.83)'>
                        <path
                            data-name='Path 4131'
                            d='M13.028,3.69C9.765,7.1,2.65,15.155,1.89,21.445,1.012,28.691,3.554,33.382,8.861,34.3a7.194,7.194,0,0,0,5.621-1.546,12.252,12.252,0,0,0,4.311-8.792C19.252,18.34,15.007,8.093,13.028,3.69Z'
                            transform='translate(-1.72 -3.69)'
                            fill='#0083bf'
                            opacity='0.5'
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Transform;
