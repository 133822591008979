import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        color: theme.palette.white
    },
    content: {
        backgroundColor: theme.palette.fes.smallText,
        padding: '3.125rem 0 4.063rem'
    },
    contentGrid: {
        display: 'grid',
        columnGap: '2rem',
        rowGap: '2rem',
        gridTemplateColumns: '100%',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '15% 30% auto auto'
        },
        '& p': {
            margin: '0 0 1rem 0'
        }
    },
    footerLink: {
        color: theme.palette.white
    },
    footerColumnTitle: {
        fontSize: '1.125rem',
        fontWeight: 300,
        lineHeight: '1.313rem',
        margin: 0
    },
    socket: {
        backgroundColor: theme.palette.fes.tundoraDark,
        padding: '1.563rem 0'
    },
    socketGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    socketLinkGrid: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        '& li': {
            listStyleType: 'none',
            padding: '0 1.25rem',
            '&:first-child': {
                paddingLeft: 0
            },
            '&:last-child': {
                paddingRight: 0
            }
        }
    },
    socketLink: {
        color: theme.palette.white
    }
}));

export default styles;
