import * as React from 'react';
import { Typography } from '@material-ui/core';
import * as ReactChartJS from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import styles from './DoughnutChart.styles';

function DoughnutChart({ datasets }) {
    const classes = styles();

    return (
        <div className={classes.chartGrid}>
            <ReactChartJS.Doughnut
                data={{
                    labels: ['Answered', 'Remaining'],
                    datasets
                }}
                plugins={[ChartDataLabels]}
                options={{
                    cutout: '70%',
                    plugins: {
                        tooltip: {
                            enabled: true,
                            mode: 'index',
                            callbacks: {
                                label: (tooltipItem) => {
                                    return ` ${tooltipItem.formattedValue}%`;
                                }
                            }
                        },
                        datalabels: {
                            display: false
                        }
                    }
                }}
            />
        </div>
    );
}

export default DoughnutChart;
