import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SliderPicker } from 'react-color';

import { useContentManagementContext } from '../../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../../components/JiscButton/JiscButton';
import IconLegend from '../../../../components/IconLegend/IconLegend';
import Loader from '../../../../components/Loader/Loader';
import CustomModal from '../../../../components/CustomModal/CustomModal';

import * as api from '../../../../lib/api';
import urls from '../../../../config/urls';
import styles from './EditTheme.styles';

let mounted;

const EditTheme = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const history = useHistory();
    const { id } = useParams();

    // Data
    const [theme, setTheme] = useState();
    const [title, setTitle] = useState();
    const [titleError, setTitleError] = useState();

    const [description, setDescription] = useState();
    const [descriptionError, setDescriptionError] = useState();

    const [color, setColor] = useState('#e85e13');
    const [icon, setIcon] = useState(1);
    const [surveyId, setSurveyId] = useState(1);
    const [surveyOptions] = useState([{ id: 1, title: 'FE Elevator' }]);

    // Error
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState();

    // Delete
    const [deleteError, setDeleteError] = useState();
    const [comfirmModal, setComfirmModal] = useState(false);

    const deleteTheme = async () => {
        try {
            const { status } = await api.del(`/themes/${id}`);

            if (status !== 200) throw Error('There was a problem deleting this Theme.');
            setComfirmModal(false);

            history.push(urls.contentManagement.themes.url);
        } catch (err) {
            setDeleteError(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !description) {
            if (!title) {
                setTitleError('You must supply a theme title.');
                return;
            }
            if (!description) {
                setDescriptionError('You must supply a theme description.');
                setTitleError(undefined);
                return;
            }
        }

        setLoading(true);
        setTitleError(undefined);
        setDescriptionError(undefined);

        try {
            const { status } = await api.put(`/themes/${id}`, {
                title,
                description,
                color,
                icon,
                surveyId
            });

            if (status !== 200) throw Error('There was a problem updating this theme.');

            setErrorMessage(undefined);
            setError(undefined);
            setLoading(false);
            history.push(urls.contentManagement.themes.url);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    const getData = async () => {
        try {
            if (mounted) {
                const { data } = await api.get(`/themes/${id}`);
                setTheme(data);
                setTitle(data.title);
                setDescription(data.description);
                setColor(data.color);
                setIcon(data.icon);

                setLoading(false);
            }
        } catch (err) {
            setErrorMessage(`No theme found for ID ${id}`);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        mounted = true;
        setActivePage(`${urls.contentManagement.themes.edit}/${id}`);
        setLoading(true);
        getData();

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <ContentManagementLayout>
            {!loading && error && errorMessage && (
                <Alert severity='error' className={classes.alert}>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            {!loading && !error && theme && (
                <>
                    <header className={classes.titleHeader}>
                        <Typography variant='h5' component='h1'>
                            {urls.contentManagement.themes.edit.text}
                        </Typography>
                        <div>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='check' role='button' />}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Update
                            </JiscButton>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='trash-alt' role='button' />}
                                onClick={() => setComfirmModal(true)}
                                className={classes.trash}
                            >
                                Delete
                            </JiscButton>
                            <JiscButton
                                isLink
                                linkType='internal'
                                href={urls.contentManagement.themes.url}
                                variant='ghost'
                                startIcon={<FontAwesomeIcon icon='arrow-alt-circle-left' role='button' />}
                            >
                                Back to all
                            </JiscButton>
                        </div>
                    </header>

                    <form id='new-theme' className={classes.form}>
                        <FormControl fullWidth>
                            <TextField
                                label='Title (Required)'
                                variant='outlined'
                                error={titleError}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            {titleError && <FormHelperText>{titleError}</FormHelperText>}
                        </FormControl>

                        <div>
                            <Typography variant='body2' className={classes.customLabel}>
                                Colour (Required)
                            </Typography>
                            <SliderPicker color={color} onChange={(c) => setColor(c.hex)} />
                        </div>

                        <FormControl fullWidth>
                            <InputLabel id='icon-id'>Icon ID (Required)</InputLabel>
                            <Select
                                labelId='icon-id'
                                value={icon}
                                label='Icon ID'
                                onChange={(e) => {
                                    setIcon(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Icon ID'
                                }}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id='parent-survey'>Parent Survey (Required)</InputLabel>
                            <Select
                                labelId='parent-survey'
                                value={surveyId}
                                label='Parent Survey'
                                onChange={(e) => {
                                    setSurveyId(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Parent Survey'
                                }}
                            >
                                {surveyOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                label='Description (Required)'
                                variant='outlined'
                                multiline
                                rows={4}
                                defaultValue={description}
                                onChange={(e) => setDescription(e.target.value)}
                                error={descriptionError}
                                value={description}
                            />
                            {descriptionError && <FormHelperText>{descriptionError}</FormHelperText>}
                        </FormControl>
                    </form>

                    <IconLegend />

                    <CustomModal
                        dialogTitle='delete-theme'
                        title='Are you sure you want to delete this theme?'
                        open={comfirmModal}
                        onClose={() => {
                            setComfirmModal(false);
                        }}
                        buttonText='Confirm delete'
                        successCallback={deleteTheme}
                        error={deleteError}
                        errorMessage={setDeleteError}
                    />
                </>
            )}
            {loading && <Loader />}
        </ContentManagementLayout>
    );
};

export default EditTheme;
