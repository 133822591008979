import { makeStyles } from '@material-ui/core';
import { hexToRGB } from '../../lib/helpers';

const styles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(5, 8),
        backgroundColor: ({ color }) => hexToRGB(color, 0.1),
        borderRadius: '0.625rem',
        textAlign: 'center',
        outline: 'none',
        [theme.breakpoints.up('md')]: {
            height: 'fit-content',
            alignItems: 'start',
            textAlign: 'left'
        }
    },
    icon: {
        backgroundColor: theme.palette.white,
        borderRadius: '100%',
        padding: theme.spacing(4),
        margin: '0 auto 2rem'
    },
    title: {
        fontSize: '1.75rem',
        fontWeight: '500',
        color: theme.palette.fes.headline,
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            height: theme.spacing(8)
        }
    },
    level: {
        display: 'grid',
        gridTemplateColumns: 'auto 20%',
        marginBottom: theme.spacing(4),
        '& > svg': {
            width: '100%'
        }
    },
    levelText: {
        fontSize: '1.5rem',
        fontWeight: '500',
        color: theme.palette.fes.headline
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none !important'
    }
}));

export default styles;
