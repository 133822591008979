import React from 'react';

const JiscLogo = () => {
    return (
        <svg id='jisc-logo' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
            <path id='Path_719' data-name='Path 719' d='M0,0H40V40H0Z' fill='#e85e13' />
            <path
                id='Path_720'
                data-name='Path 720'
                d='M93.156,319.016c0,3.212-1.871,4.8-4.534,4.8a5.083,5.083,0,0,1-4.322-2.186l1.563-1.6a3.353,3.353,0,0,0,2.719,1.588c1.271,0,2.143-.85,2.143-2.651v-8.886h2.431Zm4.3,4.6H95.123V313.4h2.335Zm-1.18-11.373a1.524,1.524,0,1,1,1.515-1.528v0A1.515,1.515,0,0,1,96.279,312.247Zm10.532,8.353c0,2.145-1.659,3.212-3.782,3.212a7.145,7.145,0,0,1-4.322-1.528L99.75,320.7a5.72,5.72,0,0,0,3.339,1.259c1,0,1.579-.425,1.579-1.1v-.04c0-.794-1.08-1.063-2.275-1.432-1.5-.425-3.183-1.043-3.183-3v-.04c0-1.933,1.6-3.116,3.626-3.116a7.035,7.035,0,0,1,3.742,1.163l-.928,1.664a5.925,5.925,0,0,0-2.875-.966c-.908,0-1.427.425-1.427,1.027v.04c0,.734,1.1,1.043,2.3,1.432,1.483.465,3.163,1.143,3.163,2.98Zm6.309,3.252a5.21,5.21,0,0,1-5.266-5.3,5.264,5.264,0,0,1,5.286-5.358,5.082,5.082,0,0,1,4.07,1.76l-1.447,1.568a3.423,3.423,0,0,0-2.643-1.275,3.066,3.066,0,0,0-2.931,3.268,3.111,3.111,0,0,0,3.047,3.308,3.579,3.579,0,0,0,2.643-1.275l1.407,1.4A5.164,5.164,0,0,1,113.12,323.853Z'
                transform='translate(-80.919 -296.848)'
                fill='#fff'
            />
        </svg>
    );
};

export default JiscLogo;
