import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    // Hero
    heroContainer: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(34)
        },
        '& > svg': {
            display: 'none',
            position: 'absolute',
            zIndex: -1,
            bottom: 0,
            left: '68%',
            transform: 'translateX(-50%)',
            width: '70%',
            height: 'auto',
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            }
        }
    },
    heroTitle: {
        fontSize: '4rem',
        fontWeight: 500,
        wordBreak: 'break-word',
        lineHeight: 1,
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('lg')]: {
            fontSize: '6rem',
            '& span': {
                display: 'block'
            }
        }
    },
    heroSubTitle: {
        fontSize: '2.5rem',
        fontWeight: 400,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(4)
    },
    heroContent: {
        maxWidth: '100%',
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            maxWidth: '35%'
        }
    },
    heroCTA: {
        display: 'inline-flex',
        marginRight: theme.spacing(2)
    },
    // Steps
    steps: {
        overflowX: 'hidden',
        padding: theme.spacing(8, 0),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(8, 0, 16)
        }
    },
    stepsTitle: {
        fontSize: '2.5rem',
        fontWeight: 400,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(4)
    },
    stepsGridItem: {
        textAlign: 'center',
        '& p': {
            [theme.breakpoints.up('lg')]: {
                minHeight: theme.spacing(14)
            }
        }
    },
    stepsGridItemSubTitle: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(5)
    },
    stepsGridItemButton: {
        margin: `${theme.spacing(2)} auto 0`
    },
    // Message section
    message: {
        position: 'relative',
        overflowX: 'hidden',
        padding: theme.spacing(8, 0),
        backgroundColor: theme.palette.fes.blue,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(15, 0)
        },
        '& > svg': {
            position: 'absolute',
            zIndex: 0,
            bottom: 0,
            right: '-6%',
            width: '75%',
            height: 'auto',
            [theme.breakpoints.up('md')]: {
                width: '60%',
                height: 'auto'
            },
            [theme.breakpoints.up('xl')]: {
                width: 'auto',
                height: 'fit-content'
            }
        }
    },
    messageTitle: {
        fontSize: '2.5rem',
        fontWeight: 400,
        wordBreak: 'break-word',
        lineHeight: '125%',
        color: theme.palette.white,
        padding: '0 15%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.75rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '5rem'
        }
    },
    messageButton: {
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        margin: 'auto',
        backgroundColor: theme.palette.white,
        marginTop: theme.spacing(8)
    },
    // Information
    information: {
        padding: theme.spacing(12, 4, 8, 0)
    },
    informationTitle: {
        fontSize: '2.5rem',
        fontWeight: 400,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(2)
    },
    informationGridItem: {
        margin: theme.spacing(6, 0, 0, 0),
        '& svg': {
            display: 'block',
            margin: `0 auto ${theme.spacing(2)}`
        },
        '& p': {
            textAlign: 'justify'
        }
    },
    informationGridItemSubTitle: {
        textAlign: 'center',
        margin: `0 auto ${theme.spacing(2)}`
    },
    themes: {
        padding: theme.spacing(0, 4, 8, 0)
    },
    themesTitle: {
        fontSize: '2.5rem',
        fontWeight: 400,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(2)
    },
    themesEntry: {
        display: 'grid',
        gridTemplateColumns: '100%',
        margin: theme.spacing(8, 0),
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '15% auto'
        }
    },
    themesEntryTitle: {
        fontSize: '2rem',
        color: theme.palette.fes.headline,
        margin: theme.spacing(4, 0, 2, 0),
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, 0, 2, 0)
        }
    },
    card: {
        marginTop: '48px',
        alignItems: 'baseline',
        width: '70%',
        boxShadow: '0px 7px 15px 0px rgba(0, 0, 0, 0.10), 0px 3px 6px 0px rgba(0, 0, 0, 0.10)',
        padding: '24px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    assessment: {
        marginTop: '8px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    assessmentLink: {
        marginTop: '8px',
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
    },

    progressCirclesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    progressCircles: {
        display: 'inline-flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    progressCircle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontSize: '16px',
        lineHeight: '150%' /* 24px */,
        letterSpacing: '0.15px',

        '& > p': {
            margin: '0px'
        }
    },
    questionCircle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
        fontSize: '16px',
        lineHeight: '150%' /* 24px */,
        letterSpacing: '0.15px',
        width: '50%',

        '& > span': {
            textAlign: 'center'
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    questionCircleRoot: {
        position: 'relative'
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    top: {
        color: '#1a90ff',
        position: 'absolute',
        left: 0
    },
    cardTitle: {
        fontSize: '20px'
    }
}));

export default styles;
