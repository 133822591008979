import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
    autoComplete: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        backgroundColor: 'blanchedalmond',
        '& ul': {
            listStyleType: 'none',
            paddingLeft: 0,
            '& li': {
                padding: '5px',
                paddingLeft: '15px'
            },
            '& li:hover': {
                backgroundColor: 'moccasin'
            },
            '& li.highlighted': {
                backgroundColor: 'moccasin'
            }
        }
    },
    wrapper: {
        position: 'relative'
    },
    selectedItemsArea: {
        padding: '5px'
    }
});

export default styles;
