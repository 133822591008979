import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0, 8),
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(8, 0, 16)
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(8, 0, 48)
        }
    }
}));

export default styles;
