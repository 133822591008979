import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';

import ThemeSidebarCard from '../../components/ThemeSidebarCard/ThemeSidebarCard';
import QuestionStepper from '../../components/QuestionStepper/QuestionStepper';
import SubThemePicker from '../../components/SubThemePicker/SubThemePicker';
import { useSurveyContext } from '../../lib/hooks/useSurveyContext';
import SurveyLayout from '../../layout/SurveyLayout/SurveyLayout';
import { useAuthContext } from '../../lib/hooks/useAuthContext';
import JiscButton from '../../components/JiscButton/JiscButton';
import { getAvailableThemes, titleToSlug } from '../../lib/api';
import * as enums from '../../lib/enums';
import urls from '../../config/urls';
import styles from './Survey.styles';
import ThemeAssignmentPicker from '../../components/ThemeAssignmentPicker/ThemeAssignmentPicker';

function Survey() {
    const { getResponseOptions, setTrackerHeight, resetSurvey, selectedSubThemes, setSelectedThemes } =
        useSurveyContext();
    const { auth } = useAuthContext();
    const { themeSlug } = useParams();
    const history = useHistory();
    const classes = styles();

    const [isAnsweringQuestions, setIsAnsweringQuestions] = useState(false);
    const [hasFinished, setHasFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [theme, setTheme] = useState(null);

    const fetchThemes = useCallback(async () => {
        setLoading(true);
        setError(false);
        const themes = await getAvailableThemes(auth.user.institution_id);
        if (!themes.length) setError('There was an error selected theme.');
        const found = themes.find((t) => titleToSlug(t.theme_title) === themeSlug);
        setTheme(found);
        setSelectedThemes([found]);
        setLoading(false);
    }, [auth.user.institution_id, setSelectedThemes, themeSlug]);

    useEffect(() => {
        fetchThemes();
        getResponseOptions();
    }, [fetchThemes, getResponseOptions]);

    useEffect(() => {
        if (hasFinished) setTrackerHeight(0);
    }, [hasFinished, setTrackerHeight]);

    useEffect(() => {
        return () => resetSurvey();
    }, [resetSurvey]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                {!!theme && (
                    <>
                        <title>{theme.theme_title}</title>
                        <link rel='canonical' href={urls.base.url + urls.survey.url + themeSlug} />
                    </>
                )}
            </Helmet>
            {!error ? (
                loading || !theme ? (
                    <div />
                ) : (
                    <SurveyLayout
                        theme={theme}
                        main={
                            theme &&
                            (!hasFinished ? (
                                isAnsweringQuestions ? (
                                    <QuestionStepper
                                        setIsAnsweringQuestions={setIsAnsweringQuestions}
                                        setHasFinished={setHasFinished}
                                        institutionId={auth.user.institution_id}
                                        theme={theme}
                                    />
                                ) : (
                                    <div>
                                        <SubThemePicker
                                            setIsAnsweringQuestions={setIsAnsweringQuestions}
                                            theme={theme}
                                        />
                                        {(auth.user.role === enums.user.role.JISC_ADMIN ||
                                            auth.user.role === enums.user.role.ADMIN) && (
                                            <ThemeAssignmentPicker theme={theme} authUser={auth.user} />
                                        )}
                                    </div>
                                )
                            ) : (
                                <>
                                    <Typography
                                        data-testid='finished'
                                        className={classes.finishedSurveyHeader}
                                        variant='h4'
                                    >
                                        Thanks! You have answered all the questions for your chosen priority areas.
                                    </Typography>

                                    <JiscButton variant='ghost' onClick={() => history.push(urls.results.url)}>
                                        Let&apos;s see my results
                                    </JiscButton>
                                </>
                            ))
                        }
                        sidebar={
                            <div className={classes.sidebar}>
                                <ThemeSidebarCard
                                    key={theme.theme_id}
                                    theme={theme}
                                    index={0}
                                    showDetail
                                    active
                                    selectedSubThemes={selectedSubThemes}
                                />
                            </div>
                        }
                    />
                )
            ) : (
                <Typography variant='h1'>{error}</Typography>
            )}
        </>
    );
}

export default Survey;
