import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    titleHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableHead: {
        backgroundColor: theme.palette.jisc.selectedGrey
    },
    textRow: {
        display: 'block',
        maxWidth: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    colorBlock: {
        display: 'block',
        padding: '0.25rem',
        textAlign: 'center',
        color: theme.palette.white,
        borderRadius: '5px'
    },
    search: {
        margin: theme.spacing(4, 0)
    },
    manageButton: {
        padding: theme.spacing(0.5)
    }
}));

export default styles;
