// 10.625
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
        textAlign: 'left',
        color: theme.palette.jisc.blackText,
        padding: theme.spacing(2),
        background: 'transparent',
        borderWidth: '0.188rem',
        borderStyle: 'solid',
        borderColor: theme.palette.jisc.greySilver,
        borderRadius: '0.625rem',
        outline: 'none',
        [theme.breakpoints.up('sm')]: {
            height: '55%',
            padding: theme.spacing(5)
        },
        [theme.breakpoints.up('lg')]: {
            aspectRatio: 1
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    rootSelected: {
        borderColor: ({ color }) => color
    },
    label: {
        [theme.breakpoints.down('md')]: {
            lineHeight: 1
        }
    },
    primary: {
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
        }
    },
    secondary: {
        [theme.breakpoints.up('md')]: {
            display: 'block',
            fontSize: '1.25rem'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
        }
    },
    selector: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '-0.188rem',
        right: '-0.188rem',
        width: '2.875rem',
        height: '2.875rem',
        fontSize: '1rem',
        color: theme.palette.white,
        borderRadius: '0.625rem',
        backgroundColor: ({ color }) => color
    },
    hovered: {
        position: 'absolute',
        zIndex: 0,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '0.425rem',
        opacity: 0.1,
        transition: 'all 150ms ease-in-out',
        backgroundColor: ({ color }) => color
    }
}));

export default styles;
