import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    grid: {
        paddingLeft: '40px',
        paddingRight: '40px'
    },
    heroContainer: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(34)
        },
        '& > svg': {
            display: 'none',
            position: 'absolute',
            zIndex: -1,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'calc(100% - 3rem)',
            height: 'auto',
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            }
        }
    },
    greyPanel: {
        border: 'none',
        backgroundColor: '#F8F5F5',
        padding: '10px',
        height: '100%'
    },
    checkboxItem: { display: 'flex', alignItems: 'center', minWidth: '50%', margin: 0 },
    checkbox: {
        color: '#006699'
    }
}));

export default styles;
