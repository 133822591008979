import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.white,
        borderRadius: '0.313rem',
        '& > svg': {
            width: '7rem',
            height: '7rem',
            marginRight: theme.spacing(2)
        }
    },
    title: {
        maxWidth: '12.5rem',
        wordBreak: 'break-word'
    },
    counter: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        width: '2.063rem',
        height: '2.063rem',
        border: `0.125rem solid`,
        borderRadius: '50%',
        backgroundColor: theme.palette.white,
        transform: 'translateY(-50%)',
        fontSize: '.875rem',
        fontWeight: 'bold'
    },
    inactive: {
        borderColor: theme.palette.jisc.greySilver,
        color: theme.palette.jisc.greyHeader
    },
    active: {
        borderColor: theme.palette.fes.navy,
        color: theme.palette.fes.navy
    },
    road: {
        position: 'absolute',
        zIndex: 1,
        display: 'block',
        width: '0.188rem',
        height: '0',
        left: '19.4%',
        backgroundColor: theme.palette.fes.navy,
        transition: 'all 800ms ease-in-out'
    },
    breakdown: {
        position: 'relative',
        zIndex: 0,
        display: 'block',
        marginTop: '0.75rem',
        width: '80%',
        float: 'right',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.white,
        borderRadius: '0.313rem'
    }
}));

export default styles;
