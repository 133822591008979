import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useAuthContext } from '../../lib/hooks/useAuthContext';

import JiscLink from '../JiscLink/JiscLink';

import * as enums from '../../lib/enums';

import urls from '../../config/urls';
import styles from './JiscAdminNotification.styles';

const JiscAdminNotification = () => {
    const classes = styles();
    const { auth } = useAuthContext();

    return (
        auth?.user?.role === enums.user.role.JISC_ADMIN && (
            <Alert data-testid='jisc-admin-notification' className={classes.root} severity='info'>
                <Typography varient='body2'>
                    You are managing this page as an <i>Admin</i> for another organisation.{' '}
                    <JiscLink type='internal' to={urls.organisationsOverview.url}>
                        Return to Jisc admin to stop managing as this organisation.
                    </JiscLink>
                </Typography>
            </Alert>
        )
    );
};

export default JiscAdminNotification;
