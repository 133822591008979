import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        borderRadius: '0.2rem',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        ...theme.typography.body2,
        borderWidth: '0.1rem',
        padding: theme.spacing(1, 2),
        textDecoration: 'none',
        '&:active': {
            backgroundColor: theme.palette.jisc.black,
            borderColor: theme.palette.jisc.black,
            color: theme.palette.jisc.white,
            outline: 'none'
        },
        '&:focus:not(:active):not(:hover)': {
            backgroundColor: theme.palette.jisc.blueDark,
            borderColor: theme.palette.jisc.blueDark,
            borderRadius: '0',
            color: theme.palette.jisc.white,
            outline: [['.25rem', 'solid', theme.palette.jisc.yellowLight]]
        },
        '&:hover': {
            backgroundColor: theme.palette.jisc.blueDark,
            borderColor: theme.palette.jisc.blueDark,
            color: theme.palette.jisc.white,
            cursor: 'pointer'
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1.5, 3)
        }
    },
    primary: {
        backgroundColor: theme.palette.jisc.blue,
        borderColor: theme.palette.jisc.blue,
        color: theme.palette.jisc.white
    },
    secondary: {
        backgroundColor: theme.palette.jisc.greyLight,
        borderColor: theme.palette.jisc.greyLight,
        color: theme.palette.jisc.blue
    },
    ghost: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.jisc.blue,
        color: theme.palette.jisc.blue
    },
    ghostDarkBg: {
        color: theme.palette.jisc.white,
        backgroundColor: 'transparent',
        borderColor: theme.palette.jisc.white
    },
    destructive: {
        color: theme.palette.jisc.white,
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '&:active': {
            color: theme.palette.jisc.white,
            backgroundColor: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            outline: 'none'
        },
        '&:focus:not(:active):not(:hover)': {
            color: theme.palette.jisc.white,
            backgroundColor: theme.palette.error.dark,
            borderColor: theme.palette.error.dark,
            borderRadius: '0',
            outline: [['.25rem', 'solid', theme.palette.jisc.yellowLight]]
        },
        '&:hover': {
            color: theme.palette.jisc.white,
            backgroundColor: theme.palette.error.dark,
            borderColor: theme.palette.error.dark
        }
    },
    link: {
        display: 'flex',
        flexDirection: 'row',
        textDecoration: 'underline',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        color: theme.palette.jisc.blue,
        ...theme.typography.body2,
        border: '0',
        '&:focus': {
            color: theme.palette.jisc.black,
            textDecoration: 'none',
            backgroundColor: theme.palette.jisc.yellowLight,
            outline: '.25rem solid transparent',
            boxShadow: `0 .25rem ${theme.palette.jisc.yellowLight}, 0 -.25rem ${theme.palette.jisc.yellowLight}`
        },
        '&:hover': {
            color: theme.palette.jisc.blueDark,
            textDecoration: 'underline',
            transition: 'color .2s ease',
            cursor: 'pointer'
        },
        '&:focus:hover': {
            color: theme.palette.jisc.black,
            textDecoration: 'none',
            backgroundColor: theme.palette.jisc.yellowLight,
            outline: '.25rem solid transparent',
            boxShadow: `0 .25rem ${theme.palette.jisc.yellowLight}, 0 -.25rem ${theme.palette.jisc.yellowLight}`
        },
        '&:active': {
            color: theme.palette.jisc.black
        },
        '&:focus:active': {
            color: theme.palette.jisc.white,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            outline: 'none',
            boxShadow: 'none'
        },
        '&$disabled': {
            backgroundColor: 'transparent',
            cursor: 'not-allowed',
            color: theme.palette.jisc.greyDisabled
        }
    },
    startIcon: {
        display: 'flex',
        marginRight: 4,
        marginLeft: -3.5,
        color: ({ disabled }) => (disabled ? theme.palette.jisc.silver : 'inherit')
    },
    endIcon: {
        display: 'flex',
        marginRight: -3.5,
        marginLeft: 4,
        color: ({ disabled }) => (disabled ? theme.palette.jisc.silver : 'inherit')
    },
    disabled: {
        cursor: 'not-allowed',
        ...theme.typography.body2,
        backgroundColor: theme.palette.jisc.grey,
        color: theme.palette.jisc.silver,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.palette.jisc.grey,
        borderWidth: ({ variant }) => (variant !== 'link' ? '0.07rem' : 0),
        borderStyle: ({ variant }) => (variant !== 'link' ? 'solid' : 'none'),
        borderRadius: ({ variant }) => (variant !== 'link' ? '0.2rem' : 0),
        padding: ({ variant }) => variant !== 'link' && [['0.75rem', '1.5rem']]
    },
    fullWidth: {
        minWidth: '100%'
    },
    uppercase: {
        textTransform: 'uppercase',
        fontWeight: '500'
    },
    inline: {
        display: 'inline-block'
    }
}));

export default styles;
