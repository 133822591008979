import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { useContentManagementContext } from '../../lib/hooks/useContentManagementContext';

import NotFound from '../../pages/NotFound/NotFound';

import SiteHeader from '../../components/SiteHeader/SiteHeader';
import SiteFooter from '../../components/SiteFooter/SiteFooter';
import JiscButton from '../../components/JiscButton/JiscButton';

import * as enums from '../../lib/enums';
import urls from '../../config/urls';
import styles from './ContentManagementLayout.styles';

const ContentManagementLayout = ({ children }) => {
    const classes = styles();

    const { auth } = useAuthContext();
    const { sidebarItems, activePage } = useContentManagementContext();

    const status = (item) => {
        let menuItemStatus;

        switch (item.status) {
            case 'work-in-progress':
            case 'deprecated':
                menuItemStatus = <FontAwesomeIcon icon='exclamation-circle' />;
                break;
            default:
                menuItemStatus = null;
        }

        return menuItemStatus;
    };

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{activePage?.title}</title>
                <link rel='canonical' href={urls?.base.url + activePage?.url} />
            </Helmet>
            <SiteHeader />
            {auth.user.role === enums.user.role.JISC_ADMIN || auth.user.role === enums.user.role.JISC_CONTENT ? (
                <div className={classes.root}>
                    <aside className={classes.sidebar}>
                        {sidebarItems && (
                            <>
                                <Typography className={classes.heading} variant='h2'>
                                    Content Management
                                </Typography>
                                <ul className={classes.menu}>
                                    {sidebarItems.map((item) => {
                                        return (
                                            <li
                                                key={item.text}
                                                className={`${classes.menuItem} ${
                                                    activePage?.text === item.text ? classes.active : ''
                                                }`}
                                            >
                                                <JiscButton
                                                    isLink
                                                    linkType='internal'
                                                    href={item.url}
                                                    startIcon={<FontAwesomeIcon icon={item.icon} role='button' />}
                                                    className={classes.menuItemButton}
                                                >
                                                    {item.text}
                                                    <span className={classes.menuItemTag}>{status(item)}</span>
                                                </JiscButton>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        )}
                    </aside>
                    <main className={classes.content}>{children}</main>
                </div>
            ) : (
                <NotFound />
            )}
            <SiteFooter />
        </>
    );
};

ContentManagementLayout.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.array.isRequired
};

export default ContentManagementLayout;
