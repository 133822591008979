import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@material-ui/core';

import JiscLink from '../../components/JiscLink/JiscLink';

import urls from '../../config/urls';
import styles from './Accessibility.styles';

function Accessibility() {
    const classes = styles();

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.accessibility.title}</title>
                <link rel='canonical' href={urls.base.url + urls.accessibility.url} />
            </Helmet>

            <main className={classes.root}>
                <Container>
                    <section id='fes-accessibility' className={classes.content}>
                        <Typography variant='h1' className={classes.title}>
                            {urls.accessibility.text}
                        </Typography>
                        <Typography variant='body1'>
                            This statement applies to content published on{' '}
                            <JiscLink
                                type='external'
                                href='https://elevate.jisc.ac.uk/'
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                https://elevate.jisc.ac.uk/
                            </JiscLink>
                            , otherwise known as the digital elevation tool. It does not apply to content on other
                            jisc.ac.uk subdomains.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            This website is run by Jisc. It is designed to be used by subscribing organisations and
                            their assigned users, and we want as many people as possible to be able to use it. The text
                            should be clear and simple to understand. For example, that means you should be able to:
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant='body1'>
                                    zoom in up to 400% without the text spilling off the screen{' '}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='body1'>
                                    navigate most of the website using just a keyboard
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='body1'>
                                    navigate most of the website using speech recognition software
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='body1'>
                                    listen to most of the website using a screen reader (including the most recent
                                    versions of JAWS, NVDA and VoiceOver)
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant='body1'>
                            We have also made the website text as simple as possible to understand.
                        </Typography>
                        <Typography variant='body1'>
                            <JiscLink
                                type='external'
                                href='https://mcmw.abilitynet.org.uk/'
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                AbilityNet
                            </JiscLink>
                            &nbsp;has advice on making your device easier to use if you have a disability.
                        </Typography>
                        <br />
                        <Typography variant='h3'>How accessible is this website</Typography>
                        <Typography variant='body1'>
                            We know some parts of this website are not fully accessible:
                            <ul>
                                <li>
                                    <Typography variant='body1'>
                                        you cannot change colours, contrast levels and fonts
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='body1'>
                                        you cannot modify the line height or spacing of text
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='body1'>
                                        some form options are not labelled correctly
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='body1'>
                                        some images do not have appropriate alternative text
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant='body1'>
                            There are currently no accessible alternative formats available, however please email{' '}
                            <JiscLink
                                type='external'
                                href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                help@jisc.ac.uk
                            </JiscLink>
                            &nbsp;to discuss any requirements, you may have.
                        </Typography>
                        <br />
                        <Typography variant='body1'>Reporting accessibility problems with this website</Typography>
                        <Typography variant='body1'>
                            We are always looking to improve the accessibility of this website. If you find any problems
                            not listed on this page or think we are not meeting accessibility requirements please,
                            contact{' '}
                            <JiscLink
                                type='external'
                                href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                help@jisc.ac.uk
                            </JiscLink>
                            .
                        </Typography>
                        <br />
                        <Typography variant='body1'>Enforcement procedure</Typography>
                        <Typography variant='body1'>
                            The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public
                            Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the
                            &lsquo;accessibility regulations&rsquo;). If you&apos;re not happy with how we respond to
                            your complaint, contact the Equality Advisory and Support Service (EASS).
                        </Typography>
                        <br />
                        <Typography variant='h3'>
                            Technical information about this website&apos;s accessibility
                        </Typography>
                        <Typography variant='body1'>
                            Jisc is committed to making this website accessible, in accordance with the Public Sector
                            Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.
                        </Typography>
                        <br />
                        <Typography variant='body1'>Compliance status</Typography>
                        <Typography variant='body1'>
                            This website is partially compliant with the{' '}
                            <JiscLink
                                type='external'
                                href='https://www.w3.org/TR/WCAG21'
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                Web Content Accessibility Guidelines version 2.1 AA standard
                            </JiscLink>
                            , due to the non-compliances listed below.
                        </Typography>
                        <br />
                        <Typography variant='h3'>Disproportionate burden</Typography>
                        <Typography variant='body'>
                            We are not claiming that it is a disproportionate burden to fix the issues on this site.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant='h3'>How we tested this website</Typography>
                        <Typography variant='body'>
                            The accessibility of this website was tested by Jisc in November 2023 using several
                            automated tools, including Site Improve, AQA, and Lighthouse, and where possible, issues
                            raised were addressed.
                        </Typography>
                        <br />
                        <br />
                        <br />
                        <Typography variant='h3'>What we&apos;re doing to improve accessibility</Typography>
                        <Typography variant='body1'>
                            Before new features and content are released, they go through our internal quality assurance
                            checks and must meet WCAG 2.1 AA.
                        </Typography>
                        <br />
                        <Typography variant='h3'>Preparation of this accessibility statement</Typography>
                        <Typography variant='body1'>This statement was updated in November 2023</Typography>
                        <Typography variant='body1'>
                            This website was tested in November 2023. The tests were carried out by Jisc. This website
                            will be next retested in Q1/Q2 2024 and the accessibility statement will be reviewed
                            accordingly.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            Content modified from the{' '}
                            <JiscLink
                                type='external'
                                href='https://www.gov.uk/help/accessibility-statement'
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                GOV.UK accessibility statement
                            </JiscLink>
                            - used through the{' '}
                            <JiscLink
                                type='external'
                                href='https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/'
                                rel='noreferrer noopener'
                                target='_blank'
                            >
                                Open Government Licence v3.0
                            </JiscLink>
                            .
                        </Typography>
                    </section>
                </Container>
            </main>
        </>
    );
}

export default Accessibility;
