import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.8, 1.5),
        background: 'transparent',
        borderWidth: '0.063rem',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '0.313rem',
        opacity: 0.8,
        transition: 'opacity 150ms ease-in-out',
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
            '& *': {
                color: theme.palette.white,
                borderColor: theme.palette.white,
                fill: theme.palette.white
            }
        },
        '&:disabled': {
            opacity: 0.3,
            '&:hover': {
                cursor: 'no-drop'
            }
        }
    },
    label: {
        display: 'inline'
    },
    icon: {
        display: 'inline-flex',
        alignItems: 'center',
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: 0,
        '& > svg': {
            display: 'block',
            width: '1.125rem',
            height: '1.125rem'
        }
    },
    iconRight: {
        marginRight: `calc(${theme.spacing(1)} + 0.063rem)`,
        paddingRight: theme.spacing(1),
        borderRightWidth: '0.063rem'
    },
    iconLeft: {
        marginLeft: `calc(${theme.spacing(1)} + 0.063rem)`,
        paddingLeft: theme.spacing(1),
        borderLeftWidth: '0.063rem'
    }
}));

export default styles;
