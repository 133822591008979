import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    accordion: {
        borderRadius: 'var(--borderRadius, 4px) var(--borderRadius, 4px) var(--none, 0px) var(--none, 0px)',
        border: 'var(--none, 1px) solid var(--elevation-outlined, #E0E0E0)',
        background: 'var(--background-paper-elevation-1, #FFF)',
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)'
    },
    chevron: {
        color: theme.palette.black,
        fontSize: '1rem',
        padding: theme.spacing(0.5)
    },
    expandIcon: {
        padding: 0
    },
    accordionSummary: {
        backgroundColor: ({ color }) => color,
        color: theme.palette.black,
        padding: theme.spacing(2, 4, 2, 4),
        '& *': {
            margin: 0
        }
    },
    heading: {
        lineHeight: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    chip: {
        backgroundColor: '#2E7D32',
        color: '#FFF',
        fontWeight: '700',
        marginLeft: theme.spacing(1)
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 2rem'
    },
    assignmentHeading: {
        fontSize: '26px',
        margin: theme.spacing(2, 0)
    },
    assignmentSupportingText: {
        color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
        fontSize: '14px'
    },
    fieldset: {
        border: '0',
        padding: '0',

        '& > legend': {
            padding: '0'
        }
    },
    radioInput: {
        margin: theme.spacing(1, 0),
        '& input': {
            margin: '9px',
            width: '20px',
            height: '20px'
        },
        '& label': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            lineHeight: 0,

            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    buttonArea: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        margin: theme.spacing(2, 0)
    },
    button: {
        color: '#ffffff',
        borderColor: 'var(--primary-main, #069)',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--primary-main, #069)',
        fontWeight: '500',
        fontSize: '15px',
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
    }
}));

export default styles;
