import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import slugify from 'slugify';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    FormControl,
    Select,
    Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import urls from '../../config/urls';
import * as api from '../../lib/api';
import * as helpers from '../../lib/helpers';
import * as enums from '../../lib/enums';
import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { useSurveyContext } from '../../lib/hooks/useSurveyContext';

import LevelProgress from '../../components/LevelProgress/LevelProgress';
import GoalAccordion from '../../components/GoalAccordion/GoalAccordion';
import JiscLink from '../../components/JiscLink/JiscLink';

import styles from './ActionPlan.styles';

function ActionPlan() {
    const slugOptions = {
        replacement: '-',
        lower: true,
        trim: true
    };

    const progresstionGoal = 70;

    const { surveyId, institutionId, themeId, levelId } = useParams();
    const { auth } = useAuthContext();
    const ctx = useSurveyContext();

    const [theme, setTheme] = useState();
    const [level, setLevel] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [expanded, setExpanded] = useState(false);
    const [status, setStatus] = useState('All');
    const [notes, setNotes] = useState([]);

    const classes = styles({ color: theme ? theme.theme_color : '' });

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getResponseOptions = async () => {
        let responseOptions = [];
        try {
            const { data } = await api.get('/responses-options');
            setError(false);
            responseOptions = data;
        } catch (err) {
            setError(true);
        }
        ctx.setResponseOptions(responseOptions);
    };

    const getThemeDetailsForInstitution = async () => {
        try {
            const { data } = await api.get(`/surveys/${surveyId}/institution/${institutionId}/progress`);

            const foundTheme = data.find((item) => item.theme_id === Number(themeId));
            if (!foundTheme) {
                setLoading(false);
                throw Error('There was a problem fetching this themes information.');
            }

            setTheme(foundTheme);
            setLevel(foundTheme.levels[levelId]);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const getAllNotes = async () => {
        try {
            const res = await api.get(`/institutions/${institutionId}/notes`);
            const notesByTheme = res.data.filter((note) => note.theme_title === theme.theme_title);

            setNotes(notesByTheme);
        } catch (e) {
            // Handle this...
        }
    };

    useEffect(() => {
        if (!ctx.responseOptions.length) getResponseOptions();
        getThemeDetailsForInstitution();
    }, []);

    useEffect(() => {
        if (theme) {
            getAllNotes();
        }
    }, [theme]);
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.themeGoals.title}</title>
                <link
                    rel='canonical'
                    href={`${urls.base.url}/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals/${levelId}`}
                />
            </Helmet>

            <main data-testid='theme-action-page' className={classes.root}>
                <Container>
                    {auth?.user?.institution_id === Number(institutionId) ||
                    auth?.user?.role === enums.user.role.JISC_ADMIN ? (
                        !loading ? (
                            <>
                                {theme && level && (
                                    <>
                                        <JiscLink
                                            type='external'
                                            href={`/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals`}
                                            className={classes.backButton}
                                        >
                                            <FontAwesomeIcon icon='arrow-circle-left' className={classes.arrowLeft} />
                                            <Typography variant='body1'>View Summary</Typography>
                                        </JiscLink>

                                        <section id='fes-action-plan-hero' className={classes.hero}>
                                            {enums.getThemeIcon(theme.theme_icon)}
                                            <Typography variant='h1' className={classes.heroTitle}>
                                                {theme.theme_title}
                                            </Typography>
                                            <Typography variant='body1'>{theme.theme_description}</Typography>
                                        </section>

                                        <section id='fes-goals-current-level'>
                                            <LevelProgress
                                                key={level.id}
                                                currentLevel={level}
                                                progression={level}
                                                progressionGoal={progresstionGoal}
                                                viewMore={false}
                                            />
                                            <Typography variant='body1'>{level.id}</Typography>
                                        </section>

                                        <div className={classes.asSection}>
                                            <Typography variant='h4' className={classes.subTitle}>
                                                Your action plan
                                            </Typography>
                                            <div className={classes.parentDivBoth}>
                                                <div className={`${classes.parentDivLeft} ${classes.left}`}>
                                                    <Typography variant='body1' className={classes.actionPlan}>
                                                        Complete more goals to elevate your college
                                                    </Typography>
                                                </div>
                                                <div className={`${classes.parentDivLeft} ${classes.form}`}>
                                                    <Typography variant='body1' className={classes.actionPlan}>
                                                        Show goals with
                                                    </Typography>
                                                    <FormControl>
                                                        <Select
                                                            className={classes.form}
                                                            native
                                                            value={status}
                                                            onChange={(e) => {
                                                                setStatus(e.target.value);
                                                            }}
                                                            inputProps={{
                                                                name: 'responseOption',
                                                                id: 'responseOption',
                                                                ariaLabel: 'Actions List'
                                                            }}
                                                            disableUnderline
                                                        >
                                                            <option>All</option>
                                                            {ctx.responseOptions.map((responseOption) => (
                                                                <option
                                                                    key={responseOption.id}
                                                                    value={responseOption.primary_label}
                                                                >
                                                                    {helpers.formatSentence(
                                                                        responseOption.secondary_label
                                                                    )}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <Typography variant='body1' className={classes.actionPlan}>
                                                        {'  '}actions
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>

                                        <section id='fes-goals-accordians' className={classes.accordians}>
                                            <aside className={classes.goalsStickyMenu}>
                                                <div>
                                                    <Typography variant='body1'>
                                                        Click the titles to navigate to the relevant area
                                                    </Typography>

                                                    {Object.values(theme.sub_themes).map((subTheme) => (
                                                        <Accordion
                                                            key={subTheme.sub_theme_id}
                                                            expanded={expanded === subTheme.sub_theme_id}
                                                            onChange={handleChange(subTheme.sub_theme_id)}
                                                            className={classes.goalsStickyMenuAccordion}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls='panel1bh-content'
                                                                id='panel1bh-header'
                                                                className={classes.goalsStickyMenuAccordionSummary}
                                                            >
                                                                <JiscLink
                                                                    key={`menu-${subTheme.sub_theme_id}`}
                                                                    type='external'
                                                                    href={`#${slugify(
                                                                        subTheme.sub_theme_title,
                                                                        slugOptions
                                                                    )}`}
                                                                >
                                                                    <Typography variant='body1'>
                                                                        {subTheme.sub_theme_title}
                                                                    </Typography>
                                                                </JiscLink>
                                                            </AccordionSummary>
                                                            <AccordionDetails
                                                                className={classes.goalsStickyMenuAccordionDetails}
                                                            >
                                                                <div>
                                                                    {Object.values(subTheme.competencies).map(
                                                                        (competency) => {
                                                                            return (
                                                                                <JiscLink
                                                                                    key={`menu-${competency.competency_id}`}
                                                                                    type='external'
                                                                                    href={`#${slugify(
                                                                                        competency.competency_title,
                                                                                        slugOptions
                                                                                    )}`}
                                                                                >
                                                                                    <Typography variant='body2'>
                                                                                        {competency.competency_title}
                                                                                    </Typography>
                                                                                </JiscLink>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            </aside>

                                            {Object.values(theme.sub_themes).map((subTheme) => (
                                                <div
                                                    id={slugify(subTheme.sub_theme_title, slugOptions)}
                                                    key={`goal-${subTheme.sub_theme_id}`}
                                                    className={classes.goalAccordionSection}
                                                >
                                                    <Typography variant='h5'>{subTheme.sub_theme_title}</Typography>
                                                    {Object.values(subTheme.competencies).map((competency) => (
                                                        <div
                                                            id={slugify(competency.competency_title, slugOptions)}
                                                            key={competency.competency_id}
                                                        >
                                                            {Object.values(competency.competency_statements).map(
                                                                (statement) => {
                                                                    if (
                                                                        (statement.level.id - 1).toString() === levelId
                                                                    ) {
                                                                        return (
                                                                            <GoalAccordion
                                                                                key={statement.competency_statement_id}
                                                                                competencyName={
                                                                                    competency.competency_title
                                                                                }
                                                                                statement={statement}
                                                                                refreshSurvey={
                                                                                    getThemeDetailsForInstitution
                                                                                }
                                                                                status={status}
                                                                                notes={notes}
                                                                            />
                                                                        );
                                                                    }
                                                                    return null;
                                                                }
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                            {level.level.id === 1 && (
                                                <div className={classes.parentDiv}>
                                                    {enums.getLevelIcon(level.level.id + 1)}
                                                    <JiscLink
                                                        type='external'
                                                        href={`/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals/${level.level.id}`}
                                                    >
                                                        <Typography variant='body1' className={classes.rightTyp}>
                                                            Look at the
                                                            <br />
                                                            Transform Level <span>&#8594;</span>
                                                        </Typography>
                                                    </JiscLink>
                                                </div>
                                            )}
                                            {level.level.id === 2 && (
                                                <div className={classes.parentDivBoth}>
                                                    <div className={`${classes.parentDivLeft} ${classes.left}`}>
                                                        <JiscLink
                                                            type='external'
                                                            href={`/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals/0`}
                                                        >
                                                            <Typography variant='body1' className={classes.leftTyp}>
                                                                <span>&#8592;</span> Look at the
                                                                <br />
                                                                Foundation Level
                                                            </Typography>
                                                        </JiscLink>
                                                        {enums.getLevelIcon(level.level.id - 1)}
                                                    </div>

                                                    <div className={`${classes.parentDivLeft}`}>
                                                        {enums.getLevelIcon(level.level.id + 1)}
                                                        <JiscLink
                                                            type='external'
                                                            href={`/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals/2`}
                                                        >
                                                            <Typography variant='body1' className={classes.rightTyp}>
                                                                Look at the
                                                                <br />
                                                                Elevate Level <span>&#8594;</span>
                                                            </Typography>
                                                        </JiscLink>
                                                    </div>
                                                </div>
                                            )}
                                            {level.level.id === 3 && (
                                                <div className={`${classes.parentDivLeft} ${classes.left}`}>
                                                    <JiscLink
                                                        type='external'
                                                        href={`/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals/1`}
                                                    >
                                                        <Typography variant='body1' className={classes.leftTyp}>
                                                            <span>&#8592;</span> Look at the
                                                            <br />
                                                            Transform Level
                                                        </Typography>
                                                    </JiscLink>
                                                    {enums.getLevelIcon(level.level.id - 1)}
                                                </div>
                                            )}
                                        </section>
                                    </>
                                )}
                                {error && (
                                    <section>
                                        <Typography variant='body1'>{error}</Typography>
                                    </section>
                                )}
                            </>
                        ) : (
                            <section>
                                <Skeleton data-testid='skeleton' variant='rect' height={200} />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={120} />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={120} />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={120} />
                                <br />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={400} />
                            </section>
                        )
                    ) : (
                        <section>
                            <Typography data-testid='theme-goals-error-title' variant='h1'>
                                You do not have permission to view this page.
                            </Typography>
                        </section>
                    )}
                </Container>
            </main>
        </>
    );
}

export default ActionPlan;
