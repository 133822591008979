import { makeStyles } from '@material-ui/core';
import { hexToRGB } from '../../lib/helpers';

const styles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        position: 'relative',
        gridTemplateColumns: 'auto',
        gap: theme.spacing(4),
        alignItems: 'center',
        margin: theme.spacing(4, 'auto', 8),
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, 'auto', 4),
            gridTemplateColumns: '50% 50%'
        }
    },
    visual: {
        display: 'grid',
        gridTemplateColumns: '80px auto',
        gap: theme.spacing(2),
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    name: {
        padding: 0,
        fontSize: '1.0rem',
        color: ({ color }) => color
    },
    actionPlanButton: {
        display: 'block',
        padding: theme.spacing(1.5, 2),
        fontSize: '1rem',
        lineHeight: 1,
        textAlign: 'center',
        minWidth: theme.spacing(26),
        backgroundColor: ({ color }) => color,
        color: theme.palette.white,
        margin: '0 auto',
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            right: '-160px'
        }
    },
    actionGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        paddingLeft: 0
    },
    description: {
        color: theme.palette.fes.smallText,
        fontStyle: 'italic',
        margin: theme.spacing(0, 0, 1, 0)
    },
    percentageBar: {
        position: 'relative',
        height: '0.5rem',
        borderRadius: '0.5rem',
        backgroundColor: ({ color }) => hexToRGB(color, 0.1)
    },
    percentageAmount: {
        display: 'block',
        height: '0.5rem',
        borderRadius: '0.5rem',
        backgroundColor: ({ color }) => color,
        width: 0,
        transition: 'width 800ms ease-in-out'
    },
    hidden: {
        opacity: 0,
        transform: 'translateX(calc(-50% + 4px))'
    },
    show: {
        opacity: 1,
        transform: 'translateX(calc(-50% + 4px))'
    },
    percentageGoal: {
        transition: 'all 300ms ease-in-out',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        border: ({ color, percentage, progressionGoal }) => {
            return percentage >= progressionGoal ? `2px solid ${color}` : `2px solid ${theme.palette.jisc.greySilver}`;
        },
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        left: ({ progressionGoal }) => `${progressionGoal}%`, // '70%'
        top: '-50px',
        transform: 'translateX(calc(-50% + 4px))',
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '2px',
            left: '50%',
            height: '22px',
            bottom: '-22px',
            zIndex: '-1',
            backgroundColor: ({ color, percentage, progressionGoal }) => {
                return percentage >= progressionGoal ? color : theme.palette.jisc.greySilver;
            },
            transform: 'translateX(-50%)'
        }
    },
    percentageGoalCurrent: {
        width: '40px',
        height: '40px',
        left: ({ progressionGoal }) => `calc(${progressionGoal}% - 5px)`,
        top: '-60px',
        transform: 'translateX(calc(-50% + 10px))'
    },
    info: {
        width: '100%',
        padding: theme.spacing(2),
        '& *': {
            display: 'block',
            fontSize: '1rem'
        }
    }
}));

export default styles;
