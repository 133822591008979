import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {},
    heading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > svg': {
            marginBottom: theme.spacing(5),
            width: '111px'
        },
        '& > h1': {
            fontSize: '34px !important',
            fontWeight: '700'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            '& > svg': {
                marginBottom: 0,
                marginRight: theme.spacing(4)
            }
        }
    },

    checkboxGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: theme.spacing(5, 0),
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
        '& > h2': {}
    },

    checkboxGridHeading: {
        '& > h2': {
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '133.4%'
        }
    },
    checkboxContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Creates 2 columns
        gap: '10px', // Adjust the gap between items as needed
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    checkboxItem: { display: 'flex', minWidth: '50%', margin: 0 },
    checkbox: {
        color: ({ color }) => color
    },
    button: {
        color: '#ffffff',
        borderColor: 'var(--primary-main, #069)',
        borderRadius: 'var(--borderRadius, 4px)',
        background: 'var(--primary-main, #069)',
        fontWeight: '500',
        fontSize: '15px',
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
    }
}));

export default styles;
