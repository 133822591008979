/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func } from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAuthContext } from '../lib/hooks/useAuthContext';

import FullPageLayout from '../layout/FullPageLayout/FullPageLayout';

const PrivateRoute = ({ component: Component, layout, ...rest }) => {
    const {
        auth: { token }
    } = useAuthContext();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: '/login' } };

    return (
        <Route
            {...rest}
            render={(props) =>
                !token ? (
                    <Redirect to={from.pathname} />
                ) : layout ? (
                    <FullPageLayout page={Component} {...props} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: func.isRequired,
    layout: bool
};

PrivateRoute.defaultProps = {
    layout: true
};

export default PrivateRoute;
