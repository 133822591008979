import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    alert: {
        marginBottom: theme.spacing(4)
    },
    titleHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                marginRight: theme.spacing(2)
            }
        }
    },
    form: {
        display: 'grid',
        gap: theme.spacing(4),
        gridTemplateColumns: '100%',
        margin: theme.spacing(4, 0),
        [theme.breakpoints.up('md')]: {
            gap: theme.spacing(8),
            gridTemplateColumns: 'repeat(2, 1fr)',
            '& > *': {
                '&:nth-child(1)': {
                    gridColumn: 1,
                    gridRow: 1
                },
                '&:nth-child(2)': {
                    gridColumn: 2,
                    gridRow: 1
                },
                '&:nth-child(3)': {
                    gridColumn: '1/3',
                    gridRow: 2
                }
            }
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    },
    customLabel: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary
    },
    trash: {
        backgroundColor: theme.palette.jisc.error,
        borderColor: theme.palette.jisc.error
    }
}));

export default styles;
