import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './QuestionOption.styles';

function QuestionOption({ option, selected, setSelected, theme }) {
    const classes = styles({ color: theme.theme_color });

    const [hovered, setHovered] = useState(false);

    return (
        <button
            data-testid={option.primary_label}
            className={`${classes.root} ${selected ? classes.rootSelected : ''}`}
            type='button'
            tabIndex='0'
            aria-pressed={selected}
            onClick={() => setSelected(option.value)}
            onFocus={() => setHovered(true)}
            onBlur={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {selected && (
                <span className={classes.selector}>
                    <FontAwesomeIcon icon='check' />
                </span>
            )}
            {hovered && <div className={classes.hovered} aria-hidden='true' />}
            <Typography className={classes.label} variant='h5'>
                {option.secondary_label && <span className={classes.primary}>{option.secondary_label}</span>}
            </Typography>
        </button>
    );
}

QuestionOption.propTypes = {
    option: PropTypes.shape({
        primary_label: PropTypes.string.isRequired,
        secondary_label: PropTypes.string,
        value: PropTypes.number.isRequired
    }).isRequired,
    selected: PropTypes.bool.isRequired,
    setSelected: PropTypes.func.isRequired,
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired
};

export default QuestionOption;
