import React from 'react';

function QuestionPlant() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='152.41' height='134.082' viewBox='0 0 152.41 134.082'>
            <g data-name='Group 2524' transform='translate(-870 -1521.918)'>
                <g data-name='Group 2498' transform='translate(-92 140.055)'>
                    <g data-name='Group 2184' transform='translate(962 1382.033)'>
                        <g data-name='Group 2183' transform='translate(0)'>
                            <g data-name='Group 2173' transform='translate(13.503 72.07)'>
                                <g data-name='Group 2171' transform='translate(0 17.619)'>
                                    <path
                                        data-name='Path 3738'
                                        d='M1086.523,435.4h32.14a7.17,7.17,0,0,0,7-5.62l8.3-36.871a1.43,1.43,0,0,0-1.08-1.72,1.121,1.121,0,0,0-.32,0h-60a1.44,1.44,0,0,0-1.44,1.44,1.17,1.17,0,0,0,0,.32l8.3,36.87a7.15,7.15,0,0,0,7.1,5.58Zm44.26-41.4-7.9,35.11a4.29,4.29,0,0,1-4.22,3.38h-32.14a4.31,4.31,0,0,1-4.22-3.38L1074.4,394Z'
                                        transform='translate(-1071.113 -391.179)'
                                        fill='#008e83'
                                    />
                                </g>
                                <g data-name='Group 2172' transform='translate(0.041)'>
                                    <path
                                        data-name='Path 3739'
                                        d='M1072.52,394h60a1.44,1.44,0,0,0,1.44-1.44h0V380.77a7.22,7.22,0,0,0-7.21-7.21h-48.46a7.21,7.21,0,0,0-7.21,7.21v11.82a1.44,1.44,0,0,0,1.44,1.44Zm58.52-2.85h-57.11V380.77a4.34,4.34,0,0,1,4.33-4.33h48.46a4.32,4.32,0,0,1,4.32,4.33Z'
                                        transform='translate(-1071.08 -373.56)'
                                        fill='#008e83'
                                    />
                                </g>
                            </g>
                            <g data-name='Group 2182'>
                                <g data-name='Group 2174' transform='translate(43.543 28.48)'>
                                    <path
                                        data-name='Path 3740'
                                        d='M1102.52,376.44a1.43,1.43,0,0,0,1.44-1.44h0V331.41a1.44,1.44,0,0,0-2.88,0V375a1.44,1.44,0,0,0,1.44,1.44Z'
                                        transform='translate(-1101.08 -329.97)'
                                        fill='#008e83'
                                    />
                                </g>
                                <g data-name='Group 2181'>
                                    <g data-name='Group 2177' transform='translate(0 0.01)'>
                                        <g data-name='Group 2175'>
                                            <path
                                                data-name='Path 3741'
                                                d='M1132.155,346.51a14.57,14.57,0,0,0,10.61-4.44l.74-.77h0a14.231,14.231,0,0,0,3.72-12.69c-2.59-12.58-14.6-26.29-40.5-27l-4.15-.11a1.46,1.46,0,0,0-1.08.44,1.44,1.44,0,0,0-.4,1.1l.3,4.13c1.55,21.19,11.83,35.77,27.49,39a16.277,16.277,0,0,0,3.27.34Zm9.26-7.2-.73.77a12,12,0,0,1-11.22,3.27c-14.32-3-23.74-16.56-25.19-36.39l-.18-2.54,2.55.07c24.22.67,35.39,13.2,37.76,24.7a11.37,11.37,0,0,1-2.99,10.12Z'
                                                transform='translate(-1101.092 -301.5)'
                                                fill='#008e83'
                                            />
                                        </g>
                                        <g data-name='Group 2176' transform='translate(21.994 21.061)'>
                                            <path
                                                data-name='Path 3742'
                                                d='M1124.069,341.75a1.45,1.45,0,0,0,1.44-1.45,1.491,1.491,0,0,0-.16-.65c-.27-.53-6.76-12.9-18.71-17a1.449,1.449,0,0,0-1,2.72h0c10.86,3.75,17,15.5,17.08,15.62A1.46,1.46,0,0,0,1124.069,341.75Z'
                                                transform='translate(-1104.69 -322.561)'
                                                fill='#008e83'
                                            />
                                        </g>
                                    </g>
                                    <g data-name='Group 2180' transform='translate(43.541)'>
                                        <g data-name='Group 2178'>
                                            <path
                                                data-name='Path 3743'
                                                d='M1072.908,346.51a16.178,16.178,0,0,0,3.26-.34c15.67-3.23,25.94-17.81,27.49-39l.3-4.13a1.4,1.4,0,0,0-.4-1.1,1.5,1.5,0,0,0-1.08-.45l-4.14.12c-25.91.71-37.91,14.42-40.51,27a14.26,14.26,0,0,0,3.68,12.69h0l.73.77a14.59,14.59,0,0,0,10.67,4.44Zm28.06-42.09-.18,2.54c-1.45,19.83-10.87,33.43-25.2,36.39a12,12,0,0,1-11.21-3.27l-.73-.77a11.37,11.37,0,0,1-3-10.12c2.37-11.5,13.54-24,37.77-24.7Z'
                                                transform='translate(-1057.545 -301.49)'
                                                fill='#008e83'
                                            />
                                        </g>
                                        <g data-name='Group 2179' transform='translate(3.636 21.046)'>
                                            <path
                                                data-name='Path 3744'
                                                d='M1080.968,341.75a1.44,1.44,0,0,0,1.28-.78c.06-.12,6.25-11.88,17.09-15.62a1.444,1.444,0,1,0-.91-2.74h0c-12,4.12-18.44,16.5-18.71,17a1.43,1.43,0,0,0,1.28,2.1Z'
                                                transform='translate(-1079.551 -322.536)'
                                                fill='#008e83'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <path
                        data-name='Path 3760'
                        d='M1099.427,303.68s-2.38,25.92-12.74,35.07-19.32,9.28-25.66,3.33-4.63-16.84,3.56-25.43S1099.427,303.68,1099.427,303.68Z'
                        transform='translate(-47.104 1080.543)'
                        fill='#008e83'
                        opacity='0.47'
                        style={{ isolation: 'isolate' }}
                    />
                    <path
                        data-name='Path 3761'
                        d='M1101.145,301.32s25.53,5.08,33.55,16.34,7.2,20.19.62,25.86-17.22,2.85-24.91-6.19S1101.145,301.32,1101.145,301.32Z'
                        transform='translate(-132.121 1080.543)'
                        fill='#008e83'
                        opacity='0.47'
                        style={{ isolation: 'isolate' }}
                    />
                </g>
                <ellipse
                    data-name='Ellipse 217'
                    cx='2'
                    cy='2'
                    rx='2'
                    ry='2'
                    transform='translate(899.82 1628.076)'
                    fill='#008e83'
                    stroke='#008d82'
                    strokeWidth='0.5'
                />
                <ellipse
                    data-name='Ellipse 218'
                    cx='2'
                    cy='2'
                    rx='2'
                    ry='2'
                    transform='translate(926.82 1628.076)'
                    fill='#008e83'
                    stroke='#008d82'
                    strokeWidth='0.5'
                />
                <path
                    data-name='Path 4151'
                    d='M22,0H0'
                    transform='translate(903.82 1640.576)'
                    fill='none'
                    stroke='#008e83'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2.5'
                />
                <text
                    data-name='?'
                    transform='matrix(0.809, 0.588, -0.588, 0.809, 998.14, 1521.918)'
                    fill='#008e83'
                    fontSize='65'
                    fontFamily='Roboto-Light, Roboto'
                    fontWeight='300'
                >
                    <tspan x='0.242' y='60'>
                        ?
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

export default QuestionPlant;
