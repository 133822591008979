import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import * as enums from '../../lib/enums';
import GreyTick from '../../lib/assets/GreyTick';
import { findPercentage } from '../../lib/helpers';
import JiscButton from '../JiscButton/JiscButton';
import styles from './LevelProgress.styles';

function LevelProgress({ currentLevel, progression, progressionGoal, viewMore }) {
    const { surveyId, institutionId, themeId } = useParams();
    const { color } = progression.level;

    const [iconVisible, setIconVisible] = useState(false);
    const [progressBarWidth, setProgressBarWidth] = useState(0);

    const percentage = useMemo(() => findPercentage(progression), [progression]);

    const classes = styles({ color, progressionGoal, percentage });
    useEffect(() => {
        setIconVisible(true);
        setProgressBarWidth(percentage);
    }, []);

    return (
        progression && (
            <article data-testid={progression.level.name} key={progression.level.id} className={classes.root}>
                <div className={classes.visual}>
                    {enums.getLevelIcon(progression.level.id)}
                    <div>
                        <Typography className={classes.name}>
                            <strong>{progression.level.name}</strong>
                        </Typography>
                        <Typography className={classes.description}>{progression.level.description}</Typography>
                        <div className={classes.percentageBar}>
                            <span
                                data-testid='progressBar'
                                style={{
                                    width: `${progressBarWidth}%`
                                }}
                                className={classes.percentageAmount}
                            />
                            <span
                                className={
                                    // eslint-disable-next-line no-nested-ternary
                                    iconVisible
                                        ? progression.level === currentLevel
                                            ? classes.percentageGoal
                                            : classes.percentageGoal
                                        : `${classes.hidden} ${classes.percentageGoal}`
                                }
                            >
                                {percentage >= progressionGoal ? <GreyTick color={color} /> : <GreyTick />}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.info}>
                    <Typography className={classes.name}>
                        <strong>You have completed {percentage}% of the goals</strong>
                        {percentage >= progressionGoal ? (
                            <span>Completed!</span>
                        ) : (
                            <span>Complete {progressionGoal}% to achieve this level</span>
                        )}
                    </Typography>
                </div>
                {viewMore && (
                    <JiscButton
                        dataTestId='Save and Exit'
                        variant='secondary'
                        className={classes.actionPlanButton}
                        isLink
                        linkType='internal'
                        href={`/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals/${
                            progression.level.id - 1
                        }`}
                    >
                        View {progression.level.name.toLowerCase()} goals
                    </JiscButton>
                )}
            </article>
        )
    );
}

LevelProgress.propTypes = {
    currentLevel: PropTypes.shape({}).isRequired,
    progression: PropTypes.shape({
        answers: PropTypes.arrayOf(PropTypes.number).isRequired,
        total: PropTypes.number.isRequired,
        level: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired
        })
    }).isRequired,
    progressionGoal: PropTypes.number.isRequired,
    viewMore: PropTypes.bool.isRequired
};

export default LevelProgress;
