import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Card } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import * as ChartJS from 'chart.js';
import PropTypes from 'prop-types';

import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import DoughnutChart from '../../components/DoughnutChart/DoughnutChart';
import JiscButton from '../../components/JiscButton/JiscButton';
import { useAuthContext } from '../../lib/hooks/useAuthContext';
import GrowthTree from '../../lib/assets/GrowthTree';
import urls from '../../config/urls';
import { get } from '../../lib/api';
import styles from './Home.styles';

ChartJS.Chart.register(ChartJS.ArcElement, ChartJS.Tooltip);

function InProgress({ currentLevel, nextLevel, remaining, color, previousLevel }) {
    return (
        <Typography variant='body1'>
            You&apos;ve achieved <span style={{ color }}>{currentLevel}</span> level across all 5 themes!{' '}
            {previousLevel
                ? `But there are still ${remaining} themes to get to ${previousLevel} level. `
                : `And you've got ${remaining} themes to the next level '${nextLevel}'.`}
        </Typography>
    );
}

InProgress.propTypes = {
    currentLevel: PropTypes.number.isRequired,
    nextLevel: PropTypes.string.isRequired,
    remaining: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    previousLevel: PropTypes.string.isRequired
};

function ProgressionMessage({ foundation, transform, elevate }) {
    const remainingFoundation = 5 - foundation;
    const remainingTransform = 5 - transform;
    const remainingElevate = 5 - elevate;

    if (elevate === 5) {
        if (foundation !== 5) {
            return (
                <InProgress
                    currentLevel='Elevate'
                    remaining={remainingFoundation}
                    color='#AA1D5D'
                    previousLevel='Foundation'
                />
            );
        }
        if (transform !== 5) {
            return (
                <InProgress
                    currentLevel='Elevate'
                    remaining={remainingTransform}
                    color='#AA1D5D'
                    previousLevel='Transform'
                />
            );
        }
        return (
            <Typography variant='body1'>
                You&apos;ve achieved Elevate level across all 5 themes! Congratulations from all at Jisc! 🎉🎉
            </Typography>
        );
    }

    if (transform === 5) {
        if (foundation !== 5) {
            return (
                <InProgress
                    currentLevel='Transform'
                    nextLevel='Elevate'
                    remaining={remainingFoundation}
                    color='#08857E'
                    previousLevel='Foundation'
                />
            );
        }
        return <InProgress currentLevel='Transform' nextLevel='Elevate' remaining={remainingElevate} color='#08857E' />;
    }

    if (foundation === 5) {
        return (
            <InProgress
                currentLevel='Foundation'
                nextLevel='Transform'
                remaining={remainingTransform}
                color='#057DBC'
            />
        );
    }

    if (foundation === 0) {
        return <Typography variant='body1'>You are currently working towards Foundation level</Typography>;
    }

    return (
        <Typography variant='body1'>
            You have reached <span style={{ color: '#057DBC' }}> Foundation </span> level in {foundation} theme
            {foundation > 1 ? 's' : ''}.
        </Typography>
    );
}

ProgressionMessage.propTypes = {
    foundation: PropTypes.number.isRequired,
    transform: PropTypes.number.isRequired,
    elevate: PropTypes.number.isRequired
};

function Home() {
    const classes = styles();
    const { auth } = useAuthContext();

    const [statistics, setStatistics] = useState({});
    const [progress, setProgress] = useState({});

    const [loading, setLoading] = useState(true);
    const [, setError] = useState();

    const getProgress = useCallback(async () => {
        try {
            const { data } = await get(`/surveys/1/institution/${auth.user.institution_id}/progress`);

            let totalQuestions = 0;
            let totalResponses = 0;

            // Calculate total questions and responses
            const calculateProgress = (theme) => {
                return new Promise((resolve) => {
                    theme.levels.forEach((level) => {
                        totalQuestions += level.total;
                        totalResponses += level.totalResponses;
                    });
                    resolve();
                });
            };

            const progressPromises = data.map((theme) => calculateProgress(theme));

            await Promise.all(progressPromises);

            // Calculate percentage
            const percentageOfQuestionsAnswered = Math.round((totalResponses / totalQuestions) * 100);

            setStatistics({
                isNewUser: totalResponses === 0,
                totalResponses,
                totalQuestions,
                percentageOfQuestionsAnswered
            });

            const foundation = [];
            const transform = [];
            const elevate = [];

            const processLevels = (theme) => {
                return new Promise((resolve) => {
                    theme.levels.forEach((level) => {
                        // calculate if totalResponses is 70% of total
                        const percentage = Math.ceil((level.answers[0] * 100) / level.total);

                        if (percentage >= 70) {
                            switch (level.level.name) {
                                case 'Foundation':
                                    foundation.push(level);
                                    break;
                                case 'Transform':
                                    transform.push(level);
                                    break;
                                case 'Elevate':
                                    elevate.push(level);
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                    resolve();
                });
            };

            const promises = data.map((theme) => processLevels(theme));

            await Promise.all(promises);

            setProgress({
                foundation: foundation.length,
                transform: transform.length,
                elevate: elevate.length
            });

            setLoading(false);
        } catch (err) {
            setError(err.message);
        }
    }, [auth.user.institution_id]);

    useEffect(() => {
        getProgress();
    }, [getProgress]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.home.title}</title>
                <link rel='canonical' href={urls.base.url + urls.home.url} />
            </Helmet>
            <main>
                {!loading && (
                    <section>
                        <Container className={classes.heroContainer}>
                            <Typography variant='h1' style={{ fontSize: '24px' }}>
                                Welcome Back
                            </Typography>

                            <Typography variant='h2' style={{ fontSize: '34px' }}>
                                Let&apos;s continue elevating {auth.user.institution_name}
                            </Typography>

                            {!statistics.isNewUser ? (
                                <>
                                    <Card className={classes.card}>
                                        <div className={classes.cardContent}>
                                            <div className={classes.questionCircle}>
                                                <Typography className={classes.cardTitle} variant='h3'>
                                                    Your assessment progress
                                                </Typography>
                                                <div className={classes.questionCircleRoot}>
                                                    <DoughnutChart
                                                        datasets={[
                                                            {
                                                                data: [
                                                                    statistics.percentageOfQuestionsAnswered,
                                                                    100 - statistics.percentageOfQuestionsAnswered
                                                                ],
                                                                backgroundColor: ['#006699', '#E0E0E0'],
                                                                borderWidth: 2,
                                                                borderRadius: 1
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                                <Typography variant='body1'>
                                                    {statistics.percentageOfQuestionsAnswered}% of questions answered
                                                </Typography>
                                            </div>
                                            <div className={classes.assessment}>
                                                <Typography variant='body1'>
                                                    The more questions you answer, the more insights into digital
                                                    maturity you will gain.
                                                </Typography>
                                                <JiscButton
                                                    isLink
                                                    linkType='internal'
                                                    href={urls.selfAssessment.url}
                                                    className={classes.assessmentLink}
                                                >
                                                    Continue building your assessment
                                                </JiscButton>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className={classes.card}>
                                        <div className={classes.cardContent}>
                                            <div className={classes.progressCirclesContainer}>
                                                <Typography className={classes.cardTitle} variant='h3'>
                                                    Your current assessment
                                                </Typography>

                                                <div className={classes.progressCircles}>
                                                    <div className={classes.progressCircle}>
                                                        <ProgressCircle
                                                            progressTotal={progress.foundation * 20}
                                                            level='foundation'
                                                        />
                                                        <p> Foundation </p>
                                                    </div>
                                                    <div className={classes.progressCircle}>
                                                        <ProgressCircle
                                                            progressTotal={progress.transform * 20}
                                                            level='transform'
                                                        />
                                                        <p> Transform </p>
                                                    </div>
                                                    <div className={classes.progressCircle}>
                                                        <ProgressCircle
                                                            progressTotal={progress.elevate * 20}
                                                            level='elevate'
                                                        />
                                                        <p> Elevate </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.assessment}>
                                                <ProgressionMessage
                                                    foundation={progress.foundation}
                                                    transform={progress.transform}
                                                    elevate={progress.elevate}
                                                />
                                                <JiscButton
                                                    isLink
                                                    linkType='internal'
                                                    href={urls.results.url}
                                                    className={classes.assessmentLink}
                                                >
                                                    See your assessment and goals
                                                </JiscButton>
                                            </div>
                                        </div>
                                    </Card>
                                </>
                            ) : (
                                <Card className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <div className={classes.progressCirclesContainer}>
                                            <div className={classes.progressCircles}>
                                                <div className={classes.progressCircle}>
                                                    <ProgressCircle progressTotal={0} level='foundation' />
                                                    <p> Foundation </p>
                                                </div>
                                                <div className={classes.progressCircle}>
                                                    <ProgressCircle progressTotal={0} level='transform' />
                                                    <p> Transform </p>
                                                </div>
                                                <div className={classes.progressCircle}>
                                                    <ProgressCircle progressTotal={0} level='elevate' />
                                                    <p> Elevate </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.assessment}>
                                            <Typography className={classes.cardTitle} variant='h3'>
                                                Get started
                                            </Typography>
                                            <Typography variant='body1'>
                                                The more questions you answer, the more insights into digital maturity
                                                you will gain
                                            </Typography>
                                            <JiscButton
                                                isLink
                                                linkType='internal'
                                                href={urls.selfAssessment.url}
                                                className={classes.assessmentLink}
                                            >
                                                Start your assessment today
                                            </JiscButton>
                                        </div>
                                    </div>
                                </Card>
                            )}

                            <GrowthTree />
                        </Container>
                    </section>
                )}
            </main>
        </>
    );
}

export default Home;
