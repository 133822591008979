import React from 'react';

const JiscLogoLarge = () => {
    return (
        <svg
            id='Jisc_logo'
            data-name='Jisc logo'
            xmlns='http://www.w3.org/2000/svg'
            width='144.301'
            height='144.706'
            viewBox='0 0 144.301 144.706'
        >
            <rect
                id='Rectangle_24'
                data-name='Rectangle 24'
                width='128.301'
                height='85.706'
                transform='translate(6.809 23.404)'
                fill='#fff'
            />
            <path
                id='j-square-trans'
                d='M.7,0V144.706H145V0ZM44.837,80.176c0,11.592-6.754,17.319-16.363,17.319a18.353,18.353,0,0,1-15.6-7.892l5.643-5.789c2.855,3.63,5.708,5.725,9.814,5.725,4.6,0,7.73-3.072,7.73-9.565V47.914h8.773ZM60.358,96.791H51.931V59.922h8.427ZM56.109,55.745a5.5,5.5,0,1,1,5.475-5.492A5.492,5.492,0,0,1,56.109,55.745ZM94.117,85.9c0,7.75-5.986,11.592-13.647,11.592a25.747,25.747,0,0,1-15.6-5.518l3.764-5.718a20.616,20.616,0,0,0,12.048,4.538c3.619,0,5.706-1.538,5.706-3.981v-.145c0-2.863-3.9-3.841-8.216-5.167-5.431-1.535-11.489-3.763-11.489-10.822v-.145c0-6.983,5.783-11.242,13.091-11.242a25.428,25.428,0,0,1,13.508,4.19l-3.341,6c-3.553-2.161-7.313-3.492-10.375-3.492-3.273,0-5.154,1.535-5.154,3.7v.145c0,2.654,3.97,3.77,8.288,5.168,5.36,1.674,11.417,4.119,11.417,10.753Zm22.769,11.731A18.812,18.812,0,0,1,97.877,78.5a19.006,19.006,0,0,1,19.076-19.341c6.965,0,11.281,2.583,14.691,6.353l-5.221,5.654c-2.576-2.721-5.292-4.606-9.538-4.606-6.129,0-10.586,5.305-10.586,11.8,0,6.634,4.529,11.941,11,11.941,3.966,0,6.893-1.881,9.538-4.609l5.086,5.029c-3.625,4.049-7.87,6.912-15.042,6.912Z'
                transform='translate(-0.7)'
                fill='#e85e13'
            />
        </svg>
    );
};

export default JiscLogoLarge;
