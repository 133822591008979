import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {},
    dialog: {
        borderRadius: '1rem'
    },
    diaTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(4, 4, 0, 4)
    },
    title: {
        fontWeight: 400
    },
    dialogContent: {
        overflowY: 'auto',
        padding: theme.spacing(3, 4)
    },
    dialogActions: {
        padding: theme.spacing(2, 4, 5, 4)
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer'
        },
        '&:focus:not(:active):not(:hover)': {
            borderColor: theme.palette.jisc.blueDark,
            borderRadius: '0',
            outline: [['.25rem', 'solid', theme.palette.jisc.yellowLight]]
        }
    },
    successCallback: {
        padding: '8px 22px',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '26px' /* 173.333% */,
        letterSpacing: '0.46px',
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
    },
    dialogPaper: {
        maxHeight: '90vh'
    }
}));

export default styles;
