import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import ResultsSummary from '../../components/ResultsSummary/ResultsSummary';
import HelpBanner from '../../components/HelpBanner/HelperBanner';
import urls from '../../config/urls';
import styles from './Results.styles';
import { ResultsContextProvider } from './ResultsContext';

function Results() {
    const classes = styles();
    const { auth } = useAuthContext();

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.results.title}</title>
                <link rel='canonical' href={urls.base.url + urls.results.url} />
            </Helmet>

            <main>
                <section>
                    <Container>
                        <Typography variant='h1' className={classes.heroTitle}>
                            {urls.results.text}
                        </Typography>
                    </Container>
                </section>
                <section className={classes.section}>
                    <ResultsContextProvider>
                        <Container>
                            {auth.user.institution_id ? (
                                <ResultsSummary />
                            ) : (
                                <Alert severity='info'>
                                    <AlertTitle>Your account does not have a related institution!</AlertTitle>
                                    If you believe this to be an error, please contact a member of Jisc on{' '}
                                    <a
                                        href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        help@jisc.ac.uk
                                    </a>
                                </Alert>
                            )}
                        </Container>
                    </ResultsContextProvider>
                </section>

                <HelpBanner />
            </main>
        </>
    );
}

export default Results;
