import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

import SiteHeader from '../../components/SiteHeader/SiteHeader';
import JiscAdminNotification from '../../components/JiscAdminNotification/JiscAdminNotification';
import SiteFooter from '../../components/SiteFooter/SiteFooter';
import CookieAcceptance from '../../components/CookieAcceptance/CookieAcceptance';

const FullPageLayout = ({ page: Component }) => {
    return (
        <>
            <CookieAcceptance />
            <SiteHeader />
            <Container>
                <JiscAdminNotification />
            </Container>
            <Component />
            <SiteFooter />
        </>
    );
};

FullPageLayout.propTypes = {
    page: PropTypes.func.isRequired
};

export default FullPageLayout;
