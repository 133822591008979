import { makeStyles } from '@material-ui/core';

const linkFocus = {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#fd6',
    outline: '.25rem solid transparent',
    boxShadow: '0 .25rem 0 0 #fd6, 0 -.25rem 0 0 #fd6'
};

const linkFocusActive = {
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    outline: 'none',
    boxShadow: 'none'
};

const setLinkStyles = ({ background, underline, visitedStyle, theme }) => {
    switch (background) {
        case 'light':
            return {
                '&:link': {
                    color: theme.palette.jisc.blue,
                    textDecoration: underline ? 'underline' : 'none'
                },
                '&:visited': {
                    color: visitedStyle ? theme.palette.jisc.purple : theme.palette.jisc.blue
                },
                '&:focus': {
                    ...linkFocus
                },
                '&:hover': {
                    color: theme.palette.jisc.blueDark,
                    textDecoration: 'underline',
                    transition: 'color .2s ease'
                },
                '&:focus:hover': {
                    ...linkFocus
                },
                '&:active': {
                    color: theme.palette.jisc.black
                },
                '&:focus:active': {
                    ...linkFocusActive
                }
            };
        case 'dark':
            return {
                '&:link': {
                    color: theme.palette.jisc.white,
                    textDecoration: underline ? 'underline' : 'none'
                },
                '&:visited': {
                    color: visitedStyle ? theme.palette.jisc.greySilver : theme.palette.jisc.white
                },
                '&:focus': {
                    ...linkFocus
                },
                '&:hover': {
                    color: theme.palette.jisc.greySilver,
                    textDecoration: 'underline',
                    transition: 'color .2s ease'
                },
                '&:focus:hover': {
                    ...linkFocus
                },
                '&:active': {
                    color: theme.palette.jisc.white
                },
                '&:focus:active': {
                    color: theme.palette.jisc.white,
                    ...linkFocusActive
                }
            };
        default:
            return '';
    }
};

const styles = makeStyles((theme) => ({
    root: (props) => ({
        ...setLinkStyles({
            background: props.background,
            underline: props.underline,
            visitedStyle: props.visitedStyle,
            theme
        })
    }),
    endIcon: {
        display: 'inline',
        verticalAlign: 'middle',
        lineHeight: 'inherit',
        marginLeft: theme.spacing(0.5),
        color: ({ disabled }) => (disabled ? theme.palette.jisc.silver : 'inherit')
    },
    startIcon: {
        display: 'inline',
        verticalAlign: 'middle',
        lineHeight: 'inherit',
        marginRight: theme.spacing(0.5),
        color: ({ disabled }) => (disabled ? theme.palette.jisc.silver : 'inherit')
    }
}));

export default styles;
