import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        gridColumn: ({ fullWidth }) => (fullWidth ? 'initial' : '1/3'),
        filter: 'grayscale(1) brightness(1)',
        pointerEvents: 'none',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
        background: 'transparent',
        textAlign: 'center',
        borderWidth: '0.188rem',
        borderStyle: 'solid',
        borderColor: theme.palette.jisc.greySilver,
        borderRadius: '0.625rem',
        outline: 'none',
        [theme.breakpoints.up('md')]: {
            alignItems: 'start',
            textAlign: 'left'
        }
    },
    unseenThemesTitle: {
        fontWeight: '600',
        color: theme.palette.fes.headline,
        margin: '1rem auto 2rem',
        textAlign: 'center'
    },
    unseenThemeGrid: {
        display: 'grid',
        gap: '3rem',
        gridTemplateColumns: 'auto',
        margin: theme.spacing(1, 0),
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: ({ fullWidth }) => (fullWidth ? 'repeat(2, 1fr)' : 'repeat(5, 1fr)')
        }
    },
    themeCard: {
        textAlign: 'center',
        '&>svg': {
            maxWidth: '8em'
        }
    }
}));

export default styles;
