import React from 'react';

const GreyTick = ({ color = '#D9D9D9' }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='17.685' height='12.674' viewBox='0 0 17.685 12.674'>
            <path
                id='Path_4166'
                data-name='Path 4166'
                d='M19.59,26.559l4.4,4.7L34.448,21'
                transform='translate(-18.177 -19.586)'
                fill='none'
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                strokeWidth='2'
            />
        </svg>
    );
};

export default GreyTick;
