import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: { padding: theme.spacing(5, 0) },
    title: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.75rem'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '5rem',
            paddingBottom: theme.spacing(3)
        }
    },
    subTitle: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        padding: theme.spacing(6, 0, 2)
    },
    sectionSubTitle: {
        fontSize: '1.5rem',
        fontWeight: 400,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        padding: theme.spacing(4, 0, 2)
    },
    content: {
        padding: '0 5%',
        [theme.breakpoints.up('md')]: {
            padding: '0 15% 3rem'
        }
    }
}));

export default styles;
