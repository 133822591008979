import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@material-ui/core';

import JiscLink from '../../components/JiscLink/JiscLink';

import urls from '../../config/urls';
import styles from './Privacy.styles';

const Privacy = () => {
    const classes = styles();

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.privacy.title}</title>
                <link rel='canonical' href={urls.base.url + urls.privacy.url} />
            </Helmet>

            <main className={classes.root}>
                <Container>
                    <section id='fes-privacy' className={classes.content}>
                        <Typography variant='h1' className={classes.title}>
                            {urls.privacy.text}
                        </Typography>
                        <Typography variant='body1'>
                            The following information for the nominated signatory and other designated leads is needed
                            for us to provide the Digital Elevation Tool service:
                            <ul>
                                <li>Name</li>
                                <li>Institution</li>
                                <li>Work email</li>
                            </ul>
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            In addition to the purposes provided in the main Jisc Privacy Notice (which can be found at{' '}
                            <JiscLink
                                href='https://www.jisc.ac.uk/website/privacy-notice'
                                type='external'
                                background='light'
                            >
                                https://www.jisc.ac.uk/website/privacy-notice
                            </JiscLink>
                            ) we will also process personal data for the following purposes:
                            <ul>
                                <li>
                                    Creating accounts - when you create accounts on this service for others you, or your
                                    employer, are responsible for ensuring that they are given the information and
                                    rights required by data protection law.
                                </li>
                                <li>
                                    Adding you to the Microsoft Teams Digital Elevation Tool Community Site unless you
                                    indicate otherwise on the Order Form.
                                </li>
                                <li>
                                    Collecting your responses, which will not be personally identifiable except by your
                                    own institution within the digital elevation tool and will only be used in aggregate
                                    to provide feedback to your institution, benchmarking, and to create public reports
                                    for the sector. These reports will be published by Jisc and will not identify
                                    individuals. If your institution&apos;s account becomes inactive, we will also
                                    anonymise your data within the digital elevation tool. We will retain the anonymised
                                    aggregate information indefinitely.
                                </li>
                            </ul>
                            As part of these services Jisc will need to disclose your personal data to the following
                            recipients:
                            <ul>
                                <li> Microsoft Teams </li>
                            </ul>
                        </Typography>
                    </section>
                </Container>
            </main>
        </>
    );
};

export default Privacy;
