import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    heroContainer: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(34)
        },
        '& > svg': {
            display: 'none',
            position: 'absolute',
            zIndex: -1,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'calc(100% - 3rem)',
            height: 'auto',
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            }
        }
    },
    title: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.75rem'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '5rem',
            paddingBottom: theme.spacing(3)
        }
    },
    subTitle: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        padding: theme.spacing(4, 0, 2)
    },
    latestReport: {
        backgroundColor: '#f0f0f0', // Slight grey background
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        borderRadius: theme.shape.borderRadius
    },
    latestReportTitle: {
        fontSize: '24px',
        marginTop: 0,
        marginBottom: theme.spacing(1),
        color: '#000000' // Black text color
    },
    description: {
        fontSize: '16px',
        margin: '0p 0px 20px 0px',
        color: '#000000' // Black text color
    }
}));

export default styles;
