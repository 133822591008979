import React from 'react';

function Documents() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='254' height='183' viewBox='0 0 254 183'>
            <g data-name='Get Plan' transform='translate(-614 -774)'>
                <text
                    data-name='2.'
                    transform='translate(631.25 808)'
                    fill='#333333'
                    fontSize='30'
                    fontFamily='ComicSansMS, Comic Sans MS'
                >
                    <tspan x='-12.891' y='0'>
                        2.
                    </tspan>
                </text>
                <g data-name='Layer 2' transform='translate(674.056 798.87)'>
                    <g data-name='Layer 1'>
                        <path
                            data-name='Path 4061'
                            d='M1.75,131.55H70.17v6.59a1.75,1.75,0,0,0,1.75,1.75h66.22a1.75,1.75,0,0,0,1.75-1.75v-97a1.75,1.75,0,0,0-1.75-1.75H98.56V1.75A1.74,1.74,0,0,0,96.82,0H1.75A1.75,1.75,0,0,0,0,1.75V129.8a1.75,1.75,0,0,0,1.75,1.75Zm134.64,4.84H73.67V42.93h62.72Zm-64.47-97a1.76,1.76,0,0,0-1.75,1.75V93.92H54.83a1.75,1.75,0,0,0,0,3.5H70.17v6.06H54.83a1.75,1.75,0,0,0,0,3.5H70.17v6.07H54.83a1.75,1.75,0,1,0,0,3.5H70.17v11.5H3.5V8.48l7,12.42a1.74,1.74,0,0,0,1.53.9H86.55a1.73,1.73,0,0,0,1.52-.9l7-12.5v31ZM4.72,3.5H93.8L85.52,18.3H13Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4062'
                            d='M43.36,93.92H14.57a1.75,1.75,0,0,0-1.75,1.75V114.8a1.76,1.76,0,0,0,1.75,1.75H43.36a1.75,1.75,0,0,0,1.75-1.75V95.67A1.75,1.75,0,0,0,43.36,93.92Zm-1.74,19.13H16.32V97.42h25.3Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4063'
                            d='M109.18,58.09h17.4a1.75,1.75,0,1,0,0-3.5h-17.4a1.75,1.75,0,1,0,0,3.5Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4064'
                            d='M109.18,71.8h17.4a1.75,1.75,0,1,0,0-3.49h-17.4a1.75,1.75,0,1,0,0,3.49Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4065'
                            d='M83.48,71.8H97.2A1.75,1.75,0,0,0,99,70.06V56.34a1.76,1.76,0,0,0-1.75-1.75H83.48a1.75,1.75,0,0,0-1.74,1.75V70.06a1.74,1.74,0,0,0,1.74,1.74Zm1.75-13.71H95.45V68.31H85.23Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4066'
                            d='M109.18,84.08h17.4a1.75,1.75,0,1,0,0-3.5h-17.4a1.75,1.75,0,0,0,0,3.5Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4067'
                            d='M109.18,97.79h17.4a1.75,1.75,0,1,0,0-3.5h-17.4a1.75,1.75,0,0,0,0,3.5Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4068'
                            d='M83.48,97.79H97.2A1.75,1.75,0,0,0,99,96V82.33a1.75,1.75,0,0,0-1.75-1.75H83.48a1.74,1.74,0,0,0-1.74,1.75V96a1.74,1.74,0,0,0,1.74,1.79Zm1.75-13.71H95.45V94.3H85.23Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4069'
                            d='M109.18,111h17.4a1.75,1.75,0,1,0,0-3.5h-17.4a1.75,1.75,0,1,0,0,3.5Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4070'
                            d='M109.18,124.73h17.4a1.75,1.75,0,1,0,0-3.5h-17.4a1.75,1.75,0,0,0,0,3.5Z'
                            fill='#333333'
                        />
                        <path
                            data-name='Path 4071'
                            d='M83.48,124.73H97.2A1.75,1.75,0,0,0,99,123V109.27a1.75,1.75,0,0,0-1.75-1.75H83.48a1.74,1.74,0,0,0-1.74,1.75V123a1.74,1.74,0,0,0,1.74,1.73ZM85.23,111H95.45v10.21H85.23Z'
                            fill='#333333'
                        />
                    </g>
                </g>
                <rect data-name='Rectangle 2276' width='254' height='183' transform='translate(614 774)' fill='none' />
            </g>
        </svg>
    );
}

export default Documents;
