import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

import SurveyTreePlants from '../../lib/assets/SurveyTreePlants';

import SiteHeader from '../../components/SiteHeader/SiteHeader';

import styles from './SurveyLayout.styles';

function SurveyLayout({ main, sidebar, theme }) {
    const classes = styles({ color: theme.theme_color });

    return (
        <div className={classes.root}>
            <main className={classes.main}>
                <SiteHeader />
                <Container>
                    <section className={classes.contentArea}>{main}</section>
                </Container>

                {/* <SurveyTreePlants /> */}
            </main>
            <aside className={classes.sidebar}>{sidebar}</aside>
        </div>
    );
}

SurveyLayout.propTypes = {
    main: PropTypes.element.isRequired,
    sidebar: PropTypes.element.isRequired,
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired
};

export default SurveyLayout;
