import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import useQueryParams from '../../lib/hooks/useQueryParams';

import urls from '../../config/urls';

const Invitation = () => {
    const query = useQueryParams();
    const history = useHistory();
    const queryToken = query.get('token');
    const queryEmail = query.get('email');

    const { acceptInvitation } = useAuthContext();

    useEffect(() => {
        if (queryToken && queryEmail) {
            acceptInvitation(queryToken, queryEmail);
        }
        history.push('/login');
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.invitation.title}</title>
                <link rel='canonical' href={urls.base.url + urls.invitation.url} />
            </Helmet>
            <div>Accepting invitation...</div>
        </>
    );
};

export default Invitation;
