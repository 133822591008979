import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import JiscButton from '../JiscButton/JiscButton';
import styles from './CustomModal.styles';

const CustomModal = ({
    testId,
    buttonText,
    onClose,
    open,
    successCallback,
    title,
    dialogTitle,
    children,
    error,
    errorMessage
}) => {
    const classes = styles();
    return (
        <Dialog
            data-testid={testId}
            open={open}
            fullWidth
            maxWidth='sm'
            onClose={onClose}
            className={classes.dialog}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }}
        >
            <DialogTitle id={dialogTitle} className={classes.diaTitle} disableTypography>
                <Typography variant='h1' align='center' className={classes.title}>
                    {title}
                </Typography>
            </DialogTitle>
            {children && <DialogContent className={classes.dialogContent}>{children}</DialogContent>}
            <DialogActions className={classes.dialogActions}>
                <JiscButton variant='link' onClick={onClose} className={classes.cancelButton}>
                    Cancel
                </JiscButton>
                <JiscButton variant='ghost' onClick={successCallback}>
                    {buttonText}
                </JiscButton>
            </DialogActions>
            {error && <Alert severity='error'>{errorMessage}</Alert>}
        </Dialog>
    );
};

CustomModal.defaultProps = {
    testId: undefined,
    children: undefined
};

CustomModal.propTypes = {
    testId: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    successCallback: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    children: PropTypes.node,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired
};

export default CustomModal;
