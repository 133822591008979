import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';

import * as api from '../api';
import keys from '../../config/keys';

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        token: localStorage.getItem(keys.storage.token) || false,
        user: JSON.parse(localStorage.getItem(keys.storage.user)) || {
            email: null,
            role: null,
            institution_id: null,
            institution_name: null
        },
        loading: false,
        error: false
    });

    const logout = () => {
        localStorage.removeItem(keys.storage.token);
        localStorage.removeItem(keys.storage.user);
        setAuth({ token: false, user: null, error: false, loading: false });
    };

    const login = async (email, tempToken) => {
        logout();
        setAuth({ ...auth, loading: true });
        try {
            const response = await api.post(`/login/validate?token=${tempToken}&email=${email}`, null, false);
            if (response.status === 200) {
                const {
                    data: { token }
                } = response;

                localStorage.setItem(keys.storage.token, token);
                const userInfo = jwtDecode(token);
                localStorage.setItem(keys.storage.user, JSON.stringify(userInfo));

                setAuth({ ...auth, token, user: userInfo, loading: false });
            }
        } catch (err) {
            setAuth({ ...auth, error: true, loading: false });
        }
    };

    const acceptInvitation = async (invitationToken, email) => {
        logout();
        await api.post(`/login/validate/invite?invitation=${invitationToken}&email=${email}`, null, false);
    };

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            if (error.response.status === 401) {
                logout();
            }
            throw error;
        }
    );

    return (
        !auth.loading && (
            <AuthContext.Provider
                value={{
                    auth,
                    login,
                    logout,
                    acceptInvitation
                }}
            >
                {children}
            </AuthContext.Provider>
        )
    );
};

AuthContextProvider.propTypes = {
    children: PropTypes.element.isRequired
};
