import React from 'react';

function GrowthTree() {
    return (
        <svg
            data-name='Elevate description'
            xmlns='http://www.w3.org/2000/svg'
            width='914'
            height='367.71'
            viewBox='0 0 914 367.71'
        >
            <g data-name='Layer 1'>
                <g data-name='Group 2184'>
                    <g data-name='Group 2183'>
                        <g data-name='Group 2173'>
                            <g data-name='Group 2171'>
                                <path
                                    data-name='Path 3738'
                                    d='M496.33,366.86H469.75a5.9,5.9,0,0,1-5.79-4.53l-6.86-30.48a1.23,1.23,0,0,1,.87-1.55h49.88a1.19,1.19,0,0,1,1.19,1.19,1.088,1.088,0,0,1,0,.26l-6.86,30.49a5.92,5.92,0,0,1-5.85,4.62Zm-36.6-34.21,6.53,29a3.56,3.56,0,0,0,3.49,2.8h26.58a3.56,3.56,0,0,0,3.49-2.8l6.54-29Z'
                                    fill='#008e83'
                                />
                            </g>
                            <g data-name='Group 2172'>
                                <path
                                    data-name='Path 3739'
                                    d='M507.85,332.65H458.24a1.19,1.19,0,0,1-1.19-1.19h0v-9.78a6,6,0,0,1,6-6h40.07a6,6,0,0,1,6,6v9.78a1.19,1.19,0,0,1-1.19,1.19Zm-48.42-2.38h47.23v-8.59a3.59,3.59,0,0,0-3.58-3.57H463a3.57,3.57,0,0,0-3.58,3.57Z'
                                    fill='#008e83'
                                />
                            </g>
                        </g>
                        <g data-name='Group 2182'>
                            <g data-name='Group 2174'>
                                <path
                                    data-name='Path 3740'
                                    d='M483,318.11a1.19,1.19,0,0,1-1.19-1.19h0V280.87a1.19,1.19,0,0,1,2.38,0v36.05A1.19,1.19,0,0,1,483,318.11Z'
                                    fill='#008e83'
                                />
                            </g>
                            <g data-name='Group 2181'>
                                <g data-name='Group 2177'>
                                    <g data-name='Group 2175'>
                                        <path
                                            data-name='Path 3741'
                                            d='M494.55,293.36a12.07,12.07,0,0,1-8.77-3.67l-.61-.64h0a11.77,11.77,0,0,1-3.08-10.5c2.15-10.4,12.07-21.73,33.5-22.32l3.42-.1a1.26,1.26,0,0,1,.9.37,1.21,1.21,0,0,1,.33.91l-.25,3.42c-1.28,17.52-9.78,29.57-22.73,32.25a14.491,14.491,0,0,1-2.71.28Zm-7.66-6,.61.64a9.92,9.92,0,0,0,9.27,2.7c11.85-2.45,19.64-13.69,20.83-30.09l.16-2.1-2.11.06c-20,.56-29.27,10.92-31.23,20.43a9.4,9.4,0,0,0,2.47,8.4Z'
                                            fill='#008e83'
                                        />
                                    </g>
                                    <g data-name='Group 2176'>
                                        <path
                                            data-name='Path 3742'
                                            d='M486,289.42a1.19,1.19,0,0,1-1.19-1.19,1.17,1.17,0,0,1,.13-.55c.22-.43,5.59-10.67,15.47-14.08a1.194,1.194,0,1,1,.8,2.25h0c-9,3.1-14.08,12.82-14.13,12.91A1.18,1.18,0,0,1,486,289.42Z'
                                            fill='#008e83'
                                        />
                                    </g>
                                </g>
                                <g data-name='Group 2180'>
                                    <g data-name='Group 2178'>
                                        <path
                                            data-name='Path 3743'
                                            d='M471.53,293.36a14.381,14.381,0,0,1-2.7-.28c-12.95-2.68-21.45-14.73-22.73-32.25l-.25-3.42a1.21,1.21,0,0,1,.33-.91,1.26,1.26,0,0,1,.9-.37l3.42.1c21.43.59,31.35,11.92,33.5,22.32a11.8,11.8,0,0,1-3.08,10.5h0l-.61.63a12.05,12.05,0,0,1-8.78,3.68Zm-23.2-34.81.15,2.1c1.2,16.4,9,27.64,20.83,30.09a9.94,9.94,0,0,0,9.27-2.7l.61-.64a9.37,9.37,0,0,0,2.47-8.36c-2-9.51-11.19-19.87-31.23-20.43Z'
                                            fill='#008e83'
                                        />
                                    </g>
                                    <g data-name='Group 2179'>
                                        <path
                                            data-name='Path 3744'
                                            d='M480.06,289.42a1.18,1.18,0,0,1-1.06-.65c-.05-.1-5.17-9.82-14.13-12.91a1.2,1.2,0,0,1,.75-2.27h0c9.87,3.41,15.24,13.65,15.47,14.08a1.19,1.19,0,0,1-.52,1.6,1.17,1.17,0,0,1-.54.14Z'
                                            fill='#008e83'
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g data-name='Group 2507'>
                    <path
                        data-name='Path 4152'
                        d='M824,146.52A81.81,81.81,0,0,0,776,72l-1-.47-.2-1.11c-3.15-17.78-12.84-35.4-26.58-48.34C737.45,12,719.37,0,693.89,0c-51.45,0-86,53.18-86,102.86,0,1.78.08,3.52.16,5l.07,1.35-1.17.65a55.9,55.9,0,0,0,27.33,104.69c10.5,0,44.61-3.2,61.66-13.52l3.27-2v166.5a1.8,1.8,0,0,0,3.6,0V200.37l3.87,5.15c9.82,13.08,16.6,18.48,35.12,22.78a54.6,54.6,0,0,0,12.2,1.4c13,0,25.82-4.73,37.08-13.67C811.09,200.13,824,172.84,824,146.52Zm-35.18,66.69c-7.41,5.89-19.43,12.92-34.61,12.92a52,52,0,0,1-11.62-1.35c-20.74-4.8-26-11.09-39.42-30.28l-.39-.55v-28.5l1.18-.6a1.92,1.92,0,0,0,.46-.33l34.9-34.9a1.8,1.8,0,1,0-2.55-2.55l-30.32,30.32-3.67,3.67V65.6a1.8,1.8,0,0,0-3.08-1.27,1.78,1.78,0,0,0-.52,1.27v37.89L695.56,100l-30-28.55a1.81,1.81,0,0,0-2.44,0h0l-.14.12a1.84,1.84,0,0,0-.44,1.23,1.779,1.779,0,0,0,.46,1.3l35.49,33.73.67.64v85.42l-.62.63c-6,6.12-19.64,10.07-30,12.3-11.56,2.5-25,4.11-34.29,4.11h0a52.29,52.29,0,0,1-23.45-99.07,1.8,1.8,0,0,0,1-1.73c-.16-2.41-.31-4.78-.31-7.28,0-47.93,33.11-99.25,82.4-99.25C736,3.6,766.45,38.9,771.51,73a1.83,1.83,0,0,0,1.08,1.4,77.91,77.91,0,0,1,47.82,72.09c0,25.3-12.41,51.51-31.57,66.72Z'
                        fill='#aa1d5d'
                    />
                    <path
                        data-name='Path 4153'
                        d='M776,72l-1-.46-.2-1.12c-3.15-17.78-12.84-35.4-26.58-48.34C737.45,12,719.37,0,693.89,0c-51.45,0-86,53.18-86,102.85,0,1.79.09,3.54.17,5l.07,1.35-1.17.65a55.9,55.9,0,0,0,27.33,104.69c10.49,0,44.6-3.2,61.66-13.52l3.27-2V365.53a1.8,1.8,0,0,0,3.6,0V200.37l3.87,5.15c9.82,13.08,16.6,18.49,35.12,22.78A54.112,54.112,0,0,0,754,229.7c13,0,25.81-4.73,37.07-13.67,20-15.9,32.93-43.18,32.93-69.51A81.8,81.8,0,0,0,776,72Zm12.9,141.24c-7.42,5.89-19.44,12.91-34.62,12.91a52,52,0,0,1-11.63-1.34c-20.73-4.8-26-11.09-39.42-30.28l-.38-.55v-28.5l1.18-.6a1.681,1.681,0,0,0,.46-.33l34.9-34.9a1.76,1.76,0,0,0,.53-1.27,1.81,1.81,0,0,0-3.08-1.28l-30.31,30.32-3.68,3.66V65.6a1.8,1.8,0,0,0-3.08-1.27,1.78,1.78,0,0,0-.52,1.27v37.89L695.56,100l-30-28.54a1.81,1.81,0,0,0-1.24-.5,1.78,1.78,0,0,0-1.19.45h0l-.14.12A1.81,1.81,0,0,0,663,74.1l35.49,33.73.67.64v85.42l-.62.63c-6,6.12-19.64,10.07-30,12.3-5.79,1.25-12,2.28-18,3a143.4,143.4,0,0,1-16.28,1.12,52.28,52.28,0,0,1-23.45-99.07,1.8,1.8,0,0,0,1-1.73c-.16-2.41-.31-4.78-.31-7.28,0-47.92,33.11-99.24,82.4-99.24C736,3.61,766.45,38.9,771.51,73a1.83,1.83,0,0,0,1.08,1.4,77.91,77.91,0,0,1,47.82,72.09c0,25.3-12.41,51.51-31.56,66.72Z'
                        fill='#aa1d5d'
                    />
                </g>
                <path
                    data-name='Path 3763'
                    d='M742.15,230.58a55.291,55.291,0,0,1-12.37-1.42c-20.86-4.84-27.12-11.13-39.8-29-15,11.75-52,15.7-64.37,15.7A56.49,56.49,0,0,1,598,110c-.09-1.64-.16-3.29-.16-5,0-49.35,34.48-102.19,85.8-102.19,43.17,0,74.54,35.33,80.73,70.22a81.58,81.58,0,0,1,48,74.45c0,26.24-12.89,53.43-32.83,69.29C768.14,225.87,755.2,230.58,742.15,230.58Z'
                    fill='#aa1d5d'
                    opacity='0.28'
                    style={{ isolation: 'isolate' }}
                />
                <path
                    data-name='Path 3759'
                    d='M325.31,326.2s-8.94,6.83-9.91,12.73,1,9.2,4.67,10.15,7.23-2.17,8.5-7.14S325.31,326.2,325.31,326.2Z'
                    fill='#0083bf'
                    opacity='0.52'
                    style={{ isolation: 'isolate' }}
                />
                <path
                    data-name='Path 4154'
                    d='M322.87,351.67a7.655,7.655,0,0,1-1.779-.21c-6.351-1.56-7.531-7.81-6.511-13.13,1.36-7.12,11.33-15.37,11.76-15.72l1.21-1,.6,1.46c.2.51,5.1,12.7,4.16,18.94a11.93,11.93,0,0,1-4.86,8.22,8.28,8.28,0,0,1-4.58,1.44Zm3.71-26.2c-2.65,2.39-8.78,8.37-9.73,13.3-1.07,5.62.67,9.43,4.79,10.44a5.65,5.65,0,0,0,4.52-.91,9.61,9.61,0,0,0,3.86-6.64C330.69,337.25,327.85,328.89,326.58,325.47Z'
                    fill='#0083bf'
                />
                <path
                    data-name='Path 4155'
                    d='M327.82,366.14a23.31,23.31,0,0,0-2.48,0q-.69-1.5-1.23-3c-5.07-14-1.73-28.25-1.57-28.9a1.16,1.16,0,0,1,2.25.54c0,.16-3.43,14.66,1.74,28.23.35.94.75,1.87,1.19,2.8a1.119,1.119,0,0,1,.1.33Z'
                    fill='#0083bf'
                />
                <path
                    data-name='Path 4156'
                    d='M334,366.21a1.2,1.2,0,0,1-1.6.54,19.339,19.339,0,0,0-4.57-.61,23.318,23.318,0,0,0-2.48,0,23.7,23.7,0,0,0-5.22.93,2.319,2.319,0,0,1-.64.19,1.22,1.22,0,0,1-1-.55,1.2,1.2,0,0,1,.37-1.65,13.52,13.52,0,0,1,7.69-2.07,17.451,17.451,0,0,1,6.92,1.59,1.19,1.19,0,0,1,.53,1.63Z'
                    fill='#0083bf'
                />
                <g style={{ isolation: 'isolate' }}>
                    <g data-name='Group 2508' style={{ isolation: 'isolate' }}>
                        <path
                            data-name='Path 4157'
                            d='M280.9,289.25a1.19,1.19,0,0,1-.44-.09,11.289,11.289,0,0,0-3.85-.54,11.039,11.039,0,0,0-1.81.11c-.69.11-1.46.19-2.32.35v5.06a14.661,14.661,0,0,1,3.4-.6,34.591,34.591,0,0,1,3.74.21,1.09,1.09,0,0,1,1.1,1.2,1.18,1.18,0,0,1-.34.87,1.13,1.13,0,0,1-.84.35,12.859,12.859,0,0,1-1.83-.11,12.51,12.51,0,0,0-1.83-.1,17.833,17.833,0,0,0-3.44.68l-.11,8.06a1.18,1.18,0,0,1-.33.85,1.09,1.09,0,0,1-.83.35,1.15,1.15,0,0,1-.84-.35,1.18,1.18,0,0,1-.33-.85q0-2.31.09-6.93c.06-3.08.08-5.39.08-6.93v-3.11a1.28,1.28,0,0,1,.32-.87,1.12,1.12,0,0,1,.84-.35,1.17,1.17,0,0,1,.69.21c1-.17,1.84-.3,2.61-.38q1-.109,2-.12a14.321,14.321,0,0,1,3.09.39c1.57.36,2.35.82,2.35,1.4a1.27,1.27,0,0,1-.3.83A1,1,0,0,1,280.9,289.25Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4158'
                            d='M288.53,305.34a4.58,4.58,0,0,1-3.39-1.45,6.28,6.28,0,0,1-1.66-4.35,7.54,7.54,0,0,1,1.34-4.66,5.22,5.22,0,0,1,4.57-2.38,3.86,3.86,0,0,1,3.55,2.15,8.14,8.14,0,0,1,.92,4.17,7.52,7.52,0,0,1-1.36,4.44A4.65,4.65,0,0,1,288.53,305.34Zm.7-10.46a3,3,0,0,0-2.68,1.64,5.92,5.92,0,0,0-.76,3,3.61,3.61,0,0,0,.93,2.65,2.43,2.43,0,0,0,1.81.79,2.87,2.87,0,0,0,2.1-.89,3.8,3.8,0,0,0,1.05-2.66C291.79,296.4,291,294.89,289.23,294.88Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4159'
                            d='M306.32,297.81v5.06a5.267,5.267,0,0,0,.05.79,3.945,3.945,0,0,1,0,.78,1.12,1.12,0,0,1-.34.83,1.2,1.2,0,0,1-.84.32q-.9,0-1.14-1.11a7.22,7.22,0,0,1-3.38.76,4.47,4.47,0,0,1-2.54-.68,3.05,3.05,0,0,1-1.33-2.19,39.1,39.1,0,0,1-.45-5.53,21.373,21.373,0,0,1,.35-3.66,1,1,0,0,1,1.11-1,1.21,1.21,0,0,1,.85.32,1.12,1.12,0,0,1,.34.83,14.3,14.3,0,0,1-.17,1.72,12.681,12.681,0,0,0-.17,1.81,27.966,27.966,0,0,0,.12,3.35,12.131,12.131,0,0,0,.39,2.24,4.412,4.412,0,0,0,.76.32,2.761,2.761,0,0,0,.74.11,12.22,12.22,0,0,0,3.32-.5v-4.66a39.6,39.6,0,0,1,.22-4.39,1.06,1.06,0,0,1,1.14-1.08,1.18,1.18,0,0,1,.85.33,1,1,0,0,1,.31.82Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4160'
                            d='M318.23,305.49q-1,0-1.14-1.11l-.33-2.56a21.6,21.6,0,0,1-.16-2.57v-2.23c0-1.68-.31-2.53-.94-2.53a3.29,3.29,0,0,0-2.51,1.56,10.68,10.68,0,0,0-1.68,3.59,10.094,10.094,0,0,1-.11,1.15,8.9,8.9,0,0,0-.07,1.13,10.537,10.537,0,0,0,.07,1.06c.05.47.07.82.07,1a1.147,1.147,0,1,1-2.29,0,5.1,5.1,0,0,0-.08-1,5.6,5.6,0,0,1-.08-1.06c0-.8.06-1.92.17-3.34s.17-2.54.17-3.35V293a1.09,1.09,0,0,1,.33-.82,1.14,1.14,0,0,1,.83-.32c.72,0,1.11.5,1.18,1.52v1.37q2-2.62,4-2.62a2.65,2.65,0,0,1,2.62,1.65,8.69,8.69,0,0,1,.55,3.26v2.27a16,16,0,0,0,.26,2.51,17.253,17.253,0,0,1,.25,2.5,1.08,1.08,0,0,1-.34.84,1.13,1.13,0,0,1-.77.33Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4161'
                            d='M332.59,294.31c-.09,1.14-.14,2.29-.14,3.44a34.581,34.581,0,0,0,.37,5.9v.26a1,1,0,0,1-.33.78,1.09,1.09,0,0,1-.76.3c-.48,0-.84-.35-1.07-1.05a6.92,6.92,0,0,1-1.75.94,5.68,5.68,0,0,1-1.89.31,5.4,5.4,0,0,1-4-1.62,5.77,5.77,0,0,1-1.64-4.24,7.28,7.28,0,0,1,1.66-5,5.45,5.45,0,0,1,4.3-1.89,4.29,4.29,0,0,1,2.1.42l1.1.81q.16-4.94.39-6.64c.1-.7.46-1,1.07-1a1.016,1.016,0,0,1,1.06,1.14C333.06,288.2,332.91,290.59,332.59,294.31Zm-5.2.3a3.63,3.63,0,0,0-2.95,1.17,5.29,5.29,0,0,0-1,3.51,3.58,3.58,0,0,0,1.08,2.6A3.43,3.43,0,0,0,327,303a3.37,3.37,0,0,0,1.66-.37,8.418,8.418,0,0,0,1.3-1,2.362,2.362,0,0,0,.34-.36v-4.74a3.32,3.32,0,0,0-1.18-1.43,3.24,3.24,0,0,0-1.73-.49Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4162'
                            d='M345.05,305.43c-.15,0-.42-.18-.81-.52a5.149,5.149,0,0,1-.78-.85,16.381,16.381,0,0,1-2,.92,4.57,4.57,0,0,1-1.43.31,4.86,4.86,0,0,1-4-1.55,7,7,0,0,1-1.18-4.45,6.76,6.76,0,0,1,1.95-4.86,6.26,6.26,0,0,1,4.66-2,5.83,5.83,0,0,1,2.28.53c1.06.43,1.6,1,1.6,1.58a.77.77,0,0,1-.24.56,8.23,8.23,0,0,0-.18,1.47c0,.68-.07,1.55-.08,2.61a10.08,10.08,0,0,0,.2,2.3,19.275,19.275,0,0,0,.74,2.06l.27.62.07.2a1,1,0,0,1-.33.77,1.1,1.1,0,0,1-.74.3Zm-2.44-8.15a6.024,6.024,0,0,1,.07-1.09c.07-.39.11-.82.19-1.26a6,6,0,0,0-.74-.33,1.75,1.75,0,0,0-.5-.11,4.08,4.08,0,0,0-3.14,1.49,5,5,0,0,0-1.31,3.41,4.94,4.94,0,0,0,.68,2.88,2.33,2.33,0,0,0,2,1,4.06,4.06,0,0,0,1.92-.42,6.472,6.472,0,0,0,1.18-.89,42.879,42.879,0,0,1-.35-4.68Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4163'
                            d='M356,294.67h-1.16q-.27,0-1.59.18l.22,6.92v1.12c0,1.71-.38,2.56-1.23,2.56a1.2,1.2,0,0,1-.81-.3,1,1,0,0,1-.35-.76v-2.68l-.23-6.84c-.57,0-1.44-.09-2.6-.2a1.06,1.06,0,0,1-1.05-1.16,1.2,1.2,0,0,1,.32-.85,1,1,0,0,1,.81-.34l2.46.2v-1.6c0-.66-.06-1.15-.06-1.45a1.12,1.12,0,0,1,.34-.82,1.2,1.2,0,0,1,.84-.32c.7,0,1.11.58,1.23,1.72v2.45c1-.12,1.52-.18,1.66-.18a7.3,7.3,0,0,1,1.59.11,1,1,0,0,1,.72,1.11,1.17,1.17,0,0,1-.32.84A1.09,1.09,0,0,1,356,294.67Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4164'
                            d='M362.25,299.33c0,.47,0,1.17.05,2.11s0,1.63,0,2.1a1.17,1.17,0,0,1-.32.84,1.1,1.1,0,0,1-.83.33,1.12,1.12,0,0,1-.83-.33,1.17,1.17,0,0,1-.32-.84v-4.21a26.289,26.289,0,0,1,.11-2.75,26.39,26.39,0,0,0,.12-2.74,1.152,1.152,0,1,1,2.3,0,23.859,23.859,0,0,1-.12,2.74A25.344,25.344,0,0,0,362.25,299.33Zm-.52-9.51a1.39,1.39,0,0,1-1-.4,1.32,1.32,0,0,1-.41-1,1.28,1.28,0,0,1,.41-1,1.35,1.35,0,0,1,1-.4,1.31,1.31,0,0,1,1,.4,1.28,1.28,0,0,1,.41,1,1.32,1.32,0,0,1-.41,1,1.35,1.35,0,0,1-1,.4Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4165'
                            d='M370.61,305.34a4.58,4.58,0,0,1-3.39-1.45,6.24,6.24,0,0,1-1.66-4.35,7.6,7.6,0,0,1,1.33-4.66,5.25,5.25,0,0,1,4.58-2.38,3.86,3.86,0,0,1,3.55,2.15,8.14,8.14,0,0,1,.92,4.17,7.52,7.52,0,0,1-1.36,4.44,4.65,4.65,0,0,1-3.97,2.08Zm.7-10.46a2.94,2.94,0,0,0-2.68,1.64,5.92,5.92,0,0,0-.76,3,3.65,3.65,0,0,0,.92,2.65,2.46,2.46,0,0,0,1.82.79,2.87,2.87,0,0,0,2.1-.89,3.8,3.8,0,0,0,1.05-2.66C373.87,296.4,373.06,294.89,371.31,294.88Z'
                            fill='#0083bf'
                        />
                        <path
                            data-name='Path 4166'
                            d='M387.82,305.49c-.67,0-1.05-.37-1.13-1.11l-.33-2.56a21.59,21.59,0,0,1-.16-2.57v-2.23c0-1.68-.31-2.53-.94-2.53a3.29,3.29,0,0,0-2.51,1.56,10.69,10.69,0,0,0-1.69,3.59c0,.3-.05.68-.1,1.15a8.9,8.9,0,0,0-.07,1.13A10.547,10.547,0,0,0,381,303c0,.47.07.82.07,1a1.2,1.2,0,0,1-.32.86,1.12,1.12,0,0,1-.83.33,1.1,1.1,0,0,1-.83-.33,1.2,1.2,0,0,1-.32-.86,5.807,5.807,0,0,0-.07-1,6.592,6.592,0,0,1-.08-1.06c0-.8.06-1.92.17-3.34s.17-2.54.17-3.35v-2.23a1.09,1.09,0,0,1,.33-.82,1.14,1.14,0,0,1,.83-.32c.72,0,1.11.5,1.18,1.52v1.37q2-2.62,4-2.62a2.63,2.63,0,0,1,2.61,1.65,8.491,8.491,0,0,1,.55,3.26v2.27a17.116,17.116,0,0,0,.25,2.51,16.139,16.139,0,0,1,.26,2.5,1.08,1.08,0,0,1-.34.84,1.14,1.14,0,0,1-.81.31Z'
                            fill='#0083bf'
                        />
                    </g>
                </g>
                <g style={{ isolation: 'isolate' }}>
                    <g data-name='Group 2509' style={{ isolation: 'isolate' }}>
                        <path
                            data-name='Path 4167'
                            d='M436.54,219.6a21.535,21.535,0,0,1-2.67-.14,18.914,18.914,0,0,0-2.68-.12h-.83c0,1.08.13,2.83.32,5.25.17,2.26.26,4,.26,5.26a11.777,11.777,0,0,0,.08,1.24c.05.56.08,1,.08,1.26a3.19,3.19,0,0,1-.26,1.37,1.07,1.07,0,0,1-1,.77,1.24,1.24,0,0,1-.84-.33,1.06,1.06,0,0,1-.38-.84,3.506,3.506,0,0,1,.06-.53,3.411,3.411,0,0,0,.06-.52c0-.39,0-1-.07-1.73s-.08-1.34-.08-1.73q0-1.68-.27-4.71c-.19-2.19-.29-3.76-.32-4.71h-1.06a16.919,16.919,0,0,1-4-.38,1.11,1.11,0,0,1-.95-1.16,1.27,1.27,0,0,1,.3-.84,1,1,0,0,1,.9-.37c.28,0,.9.08,1.88.21a17.446,17.446,0,0,0,1.9.14h4.26a22.973,22.973,0,0,1,2.68.13,22.973,22.973,0,0,0,2.68.13,1.12,1.12,0,0,1,.84.34,1.17,1.17,0,0,1,.34.86,1.15,1.15,0,0,1-.34.85,1.13,1.13,0,0,1-.89.3Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4168'
                            d='M447.64,225.74c-.06,1-.45,1.46-1.18,1.46s-1-.38-1-1.16v-1.82a6.25,6.25,0,0,0-3,1,5.7,5.7,0,0,0-1.73,2.3v6.42a1,1,0,0,1-1.16,1.18c-.69,0-1-.39-1-1.18V223.39c0-.79.34-1.18,1-1.18s1.15.65,1.16,1.95a7,7,0,0,1,5-2.17,1.59,1.59,0,0,1,1.42.71,4,4,0,0,1,.47,2.16C447.65,225.32,447.64,225.6,447.64,225.74Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4169'
                            d='M459.2,235.17q-.22,0-.81-.51a5.157,5.157,0,0,1-.78-.86,15,15,0,0,1-2,.92,4.3,4.3,0,0,1-1.43.31,4.86,4.86,0,0,1-4-1.54A7,7,0,0,1,449,229a6.72,6.72,0,0,1,2-4.85,6.27,6.27,0,0,1,4.66-2,6,6,0,0,1,2.28.53c1.07.44,1.6,1,1.6,1.58a.8.8,0,0,1-.24.57,8,8,0,0,0-.18,1.46c0,.68-.07,1.55-.07,2.61a9.561,9.561,0,0,0,.19,2.3,18.5,18.5,0,0,0,.74,2.06l.27.63.07.19a1,1,0,0,1-.33.78,1.07,1.07,0,0,1-.79.31ZM456.77,227a7.14,7.14,0,0,1,.06-1.1c.06-.4.11-.81.19-1.26a5.36,5.36,0,0,0-.74-.33,1.619,1.619,0,0,0-.5-.1,4,4,0,0,0-3.13,1.48,5,5,0,0,0-1.32,3.42A4.94,4.94,0,0,0,452,232a2.33,2.33,0,0,0,2,1,4.14,4.14,0,0,0,1.91-.41,7,7,0,0,0,1.18-.89,42.283,42.283,0,0,1-.32-4.7Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4170'
                            d='M471.32,235.23c-.67,0-1.05-.37-1.14-1.1l-.33-2.57a23.75,23.75,0,0,1-.16-2.56c0-.22,0-.59,0-1.12v-1.11c0-1.69-.31-2.53-.93-2.53a3.26,3.26,0,0,0-2.51,1.56,10.63,10.63,0,0,0-1.69,3.58,9.7,9.7,0,0,1-.11,1.15,11.24,11.24,0,0,0-.07,1.14,6.654,6.654,0,0,0,.07,1.05,10.546,10.546,0,0,1,.07,1.06,1.14,1.14,0,0,1-.32.85,1.09,1.09,0,0,1-.82.33,1.12,1.12,0,0,1-.84-.33,1.17,1.17,0,0,1-.31-.85,5.877,5.877,0,0,0-.08-1.06,5.619,5.619,0,0,1-.07-1.05,26.6,26.6,0,0,1,.17-3.35,26.535,26.535,0,0,0,.17-3.34,10.5,10.5,0,0,0-.05-1.12,7.992,7.992,0,0,1,0-1.11,1.09,1.09,0,0,1,.34-.83,1.16,1.16,0,0,1,.82-.32c.72,0,1.12.51,1.19,1.53v1.37q2-2.62,4-2.63a2.63,2.63,0,0,1,2.61,1.65,8.73,8.73,0,0,1,.55,3.26v2.28a16,16,0,0,0,.25,2.51,15.928,15.928,0,0,1,.25,2.5,1.14,1.14,0,0,1-.33.84,1.19,1.19,0,0,1-.73.32Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4171'
                            d='M482.8,225.38a1,1,0,0,1-.9-.44,3.771,3.771,0,0,1-.3-1.26c-.59.14-1.49.42-2.71.82s-1.89,1.11-1.9,1.85a5.141,5.141,0,0,0,.69.19,11.32,11.32,0,0,1,4,1.32,4,4,0,0,1,1.91,3.56,3.15,3.15,0,0,1-1.65,2.89,6.85,6.85,0,0,1-3.47.79,8.48,8.48,0,0,1-2.93-.51q-1.8-.66-1.8-1.83a1.11,1.11,0,0,1,.36-.82,1.17,1.17,0,0,1,.84-.35,1.55,1.55,0,0,1,.94.56,2.63,2.63,0,0,0,1.27.44,7.189,7.189,0,0,0,1.32.15,5.65,5.65,0,0,0,1.73-.24c.73-.23,1.09-.59,1.09-1.08,0-1.06-.74-1.78-2.22-2.15l-1.22-.27a6.74,6.74,0,0,1-2.22-.8,2.26,2.26,0,0,1-.95-2,3.58,3.58,0,0,1,2.31-3.49,17.638,17.638,0,0,1,2.25-.76,18,18,0,0,0,2.22-.74,2.58,2.58,0,0,1,1-.21,1.15,1.15,0,0,1,.83.32,1.2,1.2,0,0,1,.32.86,4.909,4.909,0,0,0,.15,1,5.444,5.444,0,0,1,.14,1,1.16,1.16,0,0,1-.32.86A1.1,1.1,0,0,1,482.8,225.38Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4172'
                            d='M494.45,217.92c-1.89,0-2.95,1.32-3.17,3.94v.73c1.33-.1,2.19-.14,2.58-.14q1.86,0,1.86,1.17a1,1,0,0,1-.91,1,5.321,5.321,0,0,1-.93.05,23.153,23.153,0,0,0-2.7.16l-.1,2.67v2.18c0,.97.05,1.69.05,2.17a18.2,18.2,0,0,1-.26,3.47,1,1,0,0,1-1.09.93A1.15,1.15,0,0,1,489,236a1,1,0,0,1-.34-.78v-.23a20.187,20.187,0,0,0,.25-3.32V225c-1,.07-1.59.11-1.85.11-.79,0-1.18-.38-1.18-1.13s.68-1.18,2.05-1.22h1.12c0-.44.09-1,.18-1.78a7.4,7.4,0,0,1,1.31-3.83,4.64,4.64,0,0,1,3.81-1.49q1.68,0,1.68,1.14C496,217.55,495.47,217.92,494.45,217.92Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4173'
                            d='M503.17,235.09a4.58,4.58,0,0,1-3.39-1.45,6.28,6.28,0,0,1-1.66-4.35,7.52,7.52,0,0,1,1.34-4.66,5.22,5.22,0,0,1,4.57-2.38,3.85,3.85,0,0,1,3.55,2.14,8.19,8.19,0,0,1,.92,4.17,7.48,7.48,0,0,1-1.36,4.44,4.64,4.64,0,0,1-3.97,2.09Zm.7-10.46a2.93,2.93,0,0,0-2.68,1.64,5.92,5.92,0,0,0-.76,3,3.66,3.66,0,0,0,.92,2.65,2.5,2.5,0,0,0,1.82.78,2.84,2.84,0,0,0,2.1-.89,3.77,3.77,0,0,0,1.05-2.66c.11-3-.7-4.52-2.45-4.52Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4174'
                            d='M520.52,225.74c-.06,1-.45,1.46-1.18,1.46s-1-.38-1-1.16v-1.82a6.25,6.25,0,0,0-3,1,5.61,5.61,0,0,0-1.73,2.3v6.42a1,1,0,0,1-1.16,1.18c-.69,0-1-.39-1-1.18V223.39c0-.79.34-1.18,1-1.18s1.15.65,1.16,1.95a7,7,0,0,1,5-2.17,1.59,1.59,0,0,1,1.42.71,4,4,0,0,1,.47,2.16C520.53,225.32,520.52,225.6,520.52,225.74Z'
                            fill='#008e83'
                        />
                        <path
                            data-name='Path 4175'
                            d='M537.81,235.37a1.05,1.05,0,0,1-1.12-1q-.21-1.29-.42-3.63l-.4-3.63a8.055,8.055,0,0,0-.4-1.59q-.4-1-.87-1.05a4.15,4.15,0,0,0-1.62.74,6.192,6.192,0,0,0-1.47,1.09c0,.73,0,1.47.06,2.2l.35,3.13a26,26,0,0,1,.22,3.06,1.2,1.2,0,0,1-.32.85,1.12,1.12,0,0,1-.83.33,1.09,1.09,0,0,1-.82-.33,1.16,1.16,0,0,1-.33-.85c0-.95-.09-2.34-.28-4.19a41.7,41.7,0,0,1-.29-4.19,4.379,4.379,0,0,0-.35-1.93,5.939,5.939,0,0,0-1.67,1.21l-1.38,1.55a5.394,5.394,0,0,1-.6.74,13.688,13.688,0,0,1-.13,2.07,12.942,12.942,0,0,0-.12,2,5.539,5.539,0,0,0,.11,1,6.434,6.434,0,0,1,.11.91,1.06,1.06,0,0,1-.36.81,1.2,1.2,0,0,1-.84.33q-1.32,0-1.32-3a14.179,14.179,0,0,1,.13-2.1,14.179,14.179,0,0,0,.13-2.1,11.943,11.943,0,0,0-.12-1.86,12.186,12.186,0,0,1-.11-1.88c0-1.77.4-2.66,1.2-2.66a1.15,1.15,0,0,1,.84.4,1.25,1.25,0,0,1,.38.87,5.977,5.977,0,0,1-.06.69,6.051,6.051,0,0,0-.06.71v1.23a7.079,7.079,0,0,1,1.8-2.26,3.4,3.4,0,0,1,2-1,2.53,2.53,0,0,1,2.53,1.83,4.09,4.09,0,0,1,1.54-1.28,4.569,4.569,0,0,1,2-.43,2.69,2.69,0,0,1,2.67,2.11,32.935,32.935,0,0,1,.48,3.29c.22,1.35.5,3.58.84,6.72a.92.92,0,0,1-.31.79A1.22,1.22,0,0,1,537.81,235.37Z'
                            fill='#008e83'
                        />
                    </g>
                </g>
                <g style={{ isolation: 'isolate' }}>
                    <g data-name='Group 2510' style={{ isolation: 'isolate' }}>
                        <path
                            data-name='Path 4176'
                            d='M503.18,97.28a1.23,1.23,0,0,1-.36-.06,13.8,13.8,0,0,0-3.82-.56,13.359,13.359,0,0,0-2.1.17,22.421,22.421,0,0,0-2.45.54,9.21,9.21,0,0,1,.1,1.22c0,1.09-.06,2.58-.2,4.48l5.14-.5c1.51-.15,2.43-.23,2.78-.23a1.2,1.2,0,0,1,.86.33,1.12,1.12,0,0,1,.35.88,1.08,1.08,0,0,1-1.07,1.19l-2.79.25-5.42.49c0,.55-.05,1.08-.07,1.58s0,1,0,1.38c0,1.73.12,2.75.37,3s.8.33,1.84.33h4.38a4.941,4.941,0,0,0,.7-.08,4.41,4.41,0,0,1,.68-.07,1.08,1.08,0,0,1,1.24,1.21,1.1,1.1,0,0,1-1,1.19,30.83,30.83,0,0,1-4.77.22c-2.42,0-4-.32-4.66-1s-1.13-2.3-1.13-4.81v-1.64a14.075,14.075,0,0,1,.09-1.92,1.4,1.4,0,0,1-.13-.57,1.21,1.21,0,0,1,.23-.72c.15-2.25.23-3.92.23-5a9.749,9.749,0,0,0-.12-1.27,11.388,11.388,0,0,1-.11-1.27c0-.93.39-1.4,1.17-1.4A1.18,1.18,0,0,1,494,95a23.1,23.1,0,0,1,2.66-.56,15.15,15.15,0,0,1,2.35-.19,12.55,12.55,0,0,1,4.54.68,1.13,1.13,0,0,1,.79,1.13,1.22,1.22,0,0,1-.31.83,1.06,1.06,0,0,1-.85.39Z'
                            fill='#aa1d5d'
                        />
                        <path
                            data-name='Path 4177'
                            d='M509.71,103.84l-.18,5.82c0,.62,0,1.53-.09,2.72-.08.78-.46,1.17-1.15,1.17a1.064,1.064,0,0,1-1.15-1.19c0-1,0-2.37.13-4.26s.13-3.32.13-4.26c0-1.23,0-2.65.06-4.24s.06-3,.06-4.23a1.15,1.15,0,0,1,2.3,0c0,1.23,0,2.64-.05,4.23S509.71,102.61,509.71,103.84Z'
                            fill='#aa1d5d'
                        />
                        <path
                            data-name='Path 4178'
                            d='M518.78,113.59a6.62,6.62,0,0,1-4.29-1.32,5,5,0,0,1-1.8-4.11,8.6,8.6,0,0,1,1.48-5.11,5,5,0,0,1,4.29-2.25,6.7,6.7,0,0,1,3.18.66,2.59,2.59,0,0,1,.18,4.62,18.668,18.668,0,0,1-2.48,1.31l-4.48,2.12A4.34,4.34,0,0,0,516.5,111a5.1,5.1,0,0,0,2.28.49,7,7,0,0,0,1.9-.31,3,3,0,0,0,1.78-1c.26-.42.55-.63.85-.63a1.1,1.1,0,0,1,1.06,1.03c0,.87-.7,1.62-2.1,2.24a8.61,8.61,0,0,1-3.49.77Zm-.32-10.68a3,3,0,0,0-2.38,1.11,7.59,7.59,0,0,0-1.42,3.34l3.6-1.72a14.732,14.732,0,0,0,3-1.79A4,4,0,0,0,518.46,102.91Z'
                            fill='#aa1d5d'
                        />
                        <path
                            data-name='Path 4179'
                            d='M531.85,112.44a.953.953,0,0,1-1.06,1.08,1.31,1.31,0,0,1-1.24-1l-1.8-4.76-2.08-5.38a1.47,1.47,0,0,1-.12-.53,1.08,1.08,0,0,1,.36-.82,1.21,1.21,0,0,1,.85-.34,1,1,0,0,1,1,.71l3,8.19q.88-1.87,2.22-5.44.31-.93,1-2.64a1.13,1.13,0,0,1,1-.75,1.19,1.19,0,0,1,.85.34,1.05,1.05,0,0,1,.36.8,36.518,36.518,0,0,1-1.93,5Z'
                            fill='#aa1d5d'
                        />
                        <path
                            data-name='Path 4180'
                            d='M547.28,113.82q-.22,0-.81-.51a5.289,5.289,0,0,1-.79-.86,13.78,13.78,0,0,1-2,.92,4.25,4.25,0,0,1-1.43.31,4.82,4.82,0,0,1-4-1.54,7,7,0,0,1-1.19-4.46,6.73,6.73,0,0,1,1.95-4.85,6.29,6.29,0,0,1,4.66-2,6.07,6.07,0,0,1,2.29.52q1.59.66,1.59,1.59a.82.82,0,0,1-.23.56,8,8,0,0,0-.18,1.46c0,.68-.07,1.55-.08,2.62a9.439,9.439,0,0,0,.2,2.29,17.108,17.108,0,0,0,.74,2.07c.05.14.14.34.27.62l.07.2a1,1,0,0,1-.33.77A1.09,1.09,0,0,1,547.28,113.82Zm-2.44-8.14a7.14,7.14,0,0,1,.06-1.1c.06-.4.11-.81.2-1.26a6.672,6.672,0,0,0-.75-.32,1.621,1.621,0,0,0-.5-.11,4,4,0,0,0-3.13,1.48,5.61,5.61,0,0,0-.64,6.3,2.33,2.33,0,0,0,2,1,4,4,0,0,0,1.91-.41,6.6,6.6,0,0,0,1.18-.89,42.3,42.3,0,0,1-.33-4.69Z'
                            fill='#aa1d5d'
                        />
                        <path
                            data-name='Path 4181'
                            d='M558.25,103.07h-1.16c-.18,0-.72.06-1.6.17l.22,6.93v1.11c0,1.71-.38,2.57-1.23,2.57a1.2,1.2,0,0,1-.82-.3,1,1,0,0,1-.34-.77c0-.29,0-.74,0-1.33v-1.34l-.24-6.84c-.57,0-1.43-.09-2.6-.2a1.06,1.06,0,0,1-1-1.16,1.23,1.23,0,0,1,.31-.86,1.11,1.11,0,0,1,.81-.34l2.46.2v-1.6c0-.67-.05-1.15-.05-1.44a1.08,1.08,0,0,1,.34-.82,1.18,1.18,0,0,1,.83-.32c.7,0,1.11.57,1.23,1.72v2.41c1-.12,1.52-.19,1.67-.19a6.51,6.51,0,0,1,1.59.12,1,1,0,0,1,.71,1.1,1.14,1.14,0,0,1-.32.85,1.1,1.1,0,0,1-.81.33Z'
                            fill='#aa1d5d'
                        />
                        <path
                            data-name='Path 4182'
                            d='M567.19,113.59a6.62,6.62,0,0,1-4.29-1.32,5,5,0,0,1-1.8-4.11,8.6,8.6,0,0,1,1.48-5.11,5,5,0,0,1,4.29-2.25,6.7,6.7,0,0,1,3.18.66,2.59,2.59,0,0,1,.18,4.62,18,18,0,0,1-2.48,1.31l-4.48,2.12a4.339,4.339,0,0,0,1.64,1.47,5.1,5.1,0,0,0,2.28.49,7,7,0,0,0,1.9-.31,3,3,0,0,0,1.78-1c.26-.42.55-.63.85-.63a1.1,1.1,0,0,1,1.06,1.03c0,.87-.7,1.62-2.1,2.24a8.61,8.61,0,0,1-3.49.79Zm-.32-10.68a3,3,0,0,0-2.38,1.11,7.59,7.59,0,0,0-1.42,3.34l3.6-1.72a14.732,14.732,0,0,0,3-1.79,4,4,0,0,0-2.8-.94Z'
                            fill='#aa1d5d'
                        />
                    </g>
                </g>
                <path
                    data-name='Path 4085'
                    d='M382,225.4c-28.94,0-58.44,1.52-58.44,38.28'
                    fill='none'
                    stroke='#96d0cc'
                    strokeLinecap='round'
                    strokeWidth='4'
                />
                <path
                    data-name='Path 4139'
                    d='M236,320.68c-28.94,0-58.44,1.51-58.44,38.27'
                    fill='none'
                    stroke='#96d0cc'
                    strokeLinecap='round'
                    strokeWidth='4'
                />
                <path
                    data-name='Path 4087'
                    d='M539.34,159.2c-28.94,0-58.44,1.51-58.44,38.27'
                    fill='none'
                    stroke='#96d0cc'
                    strokeLinecap='round'
                    strokeWidth='4'
                />
                <path
                    data-name='Path 4086'
                    d='M374.78,216.84l8.77,8.77-8.77,8.77'
                    fill='none'
                    stroke='#96d0cc'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='4'
                />
                <path
                    data-name='Path 4140'
                    d='M228.78,312.11l8.77,8.77-8.77,8.77'
                    fill='none'
                    stroke='#96d0cc'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='4'
                />
                <path
                    data-name='Path 4088'
                    d='M532.11,150.63l8.77,8.77-8.77,8.77'
                    fill='none'
                    stroke='#96d0cc'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='4'
                />
            </g>
            <g data-name='Layer 4'>
                <g data-name='Group 2511' opacity='0.5'>
                    <path
                        data-name='Path 4183'
                        d='M483.22,280.56a9.38,9.38,0,0,0,2.47,8.37l.61.63a9.9,9.9,0,0,0,9.27,2.71c11.85-2.45,19.63-13.7,20.83-30.09l.15-2.1-2.1.06C494.41,260.69,485.18,271.06,483.22,280.56Z'
                        fill='#008e83'
                    />
                    <path
                        data-name='Path 4184'
                        d='M449.23,260.14l-2.11-.06.16,2.1c1.19,16.39,9,27.64,20.83,30.09a9.92,9.92,0,0,0,9.27-2.71l.61-.63a9.41,9.41,0,0,0,2.46-8.37C478.5,271.06,469.26,260.69,449.23,260.14Z'
                        fill='#008e83'
                    />
                </g>
            </g>
        </svg>
    );
}

export default GrowthTree;
