import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash';

import { get, post } from '../../lib/api';
import { useSurveyContext } from '../../lib/hooks/useSurveyContext';
import { useQuestionContext } from '../../lib/hooks/useQuestionContext';

import keys from '../../config/keys';
import QuestionStep from './QuestionStep/QuestionStep';

function QuestionStepper({ setIsAnsweringQuestions, setHasFinished, institutionId, theme }) {
    const {
        currentQuestion,
        getQuestion,
        sendNewResponse,
        selectedSubThemes,
        currentQuestionsSelectedOption,
        setSelectedSubThemes,
        setThemeStepper,
        themeStepper,
        selectedThemes
    } = useSurveyContext();
    const { setQuestions, setQuestionIndex, questionIndex, questions, setLastCompetencyId } = useQuestionContext();

    const [shouldDisablePrevious, setShouldDisablePrevious] = useState(true);
    const [shouldDisableNext, setShouldDisableNext] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [notesLoading, setNotesLoading] = useState(false);
    const [notes, setNotes] = useState([]);

    const getNotes = useCallback(
        async (questionNumber) => {
            setNotes([]);
            setError(false);
            setNotesLoading(true);

            try {
                const res = await get(`/institutions/${institutionId}/competency-options/${questionNumber}/notes`);

                setNotes(res.data.notes);
            } catch (e) {
                setError(true);
                setErrorMessage('There was a problem retrieving the notes for this question');
            } finally {
                setNotesLoading(false);
            }
        },
        [institutionId]
    );

    const getSubThemes = useCallback(async () => {
        try {
            const subThemeIds = selectedSubThemes.map((subTheme) => subTheme.sub_theme_id);
            const { data: subThemes } = await get(`/sub-themes/${subThemeIds}?verbose=true`);

            const tempQuestionArr = [];
            Object.values(subThemes).forEach((subTheme) =>
                Object.values(subTheme.competencies).forEach((comp) =>
                    Object.values(comp.competency_statements).forEach((statement) =>
                        Object.values(statement.competency_options).forEach((option) => {
                            if (option.competency_option_id) {
                                tempQuestionArr.push(option);
                            }
                        })
                    )
                )
            );

            // once we have set the questions, check if the last comp option id
            // is in it to restart from where left off.
            const lastCompetencyId = localStorage.getItem(keys.storage.lastCompetencyId);

            if (lastCompetencyId) {
                const lastQuestionIndex = tempQuestionArr.findIndex(
                    (item) => item.competency_option_id === Number(lastCompetencyId)
                );

                if (lastQuestionIndex >= 0) {
                    setQuestionIndex(lastQuestionIndex);
                    await getNotes(lastCompetencyId);
                } else {
                    // if we can't find it, remove it from local storage as we are starting fresh
                    localStorage.removeItem(keys.storage.lastCompetencyId);
                }
            }

            setQuestions(tempQuestionArr);

            if (!lastCompetencyId) {
                await getNotes(tempQuestionArr[questionIndex].competency_option_id);
            }
            setError(false);
        } catch (err) {
            setError(true);
            setErrorMessage('There was a problem receiving the questions for this sub theme.');
        }
    }, [selectedSubThemes, setQuestions, setQuestionIndex, getNotes, questionIndex]);

    const handleSubmitNote = async (noteContent) => {
        setError(false);
        setNotesLoading(true);

        const questionNumber = currentQuestion.id;

        try {
            if (noteContent !== null) {
                await post(`/institutions/${institutionId}/competency-options/${questionNumber}/notes`, {
                    content: noteContent
                });

                setError(false);
                await getNotes(questionNumber);
            }
        } catch (e) {
            setError(true);
            setErrorMessage('There was a problem submitting the note');
        } finally {
            setNotesLoading(false);
        }
    };

    const handleSendingResponse = async () => {
        const theQuestion = questions[questionIndex];
        const apiQuestion = await getQuestion(theQuestion.competency_option_id);
        const subThemeIds = selectedSubThemes.map((subTheme) => subTheme.sub_theme_id);

        if (currentQuestionsSelectedOption.value !== undefined) {
            if (isEmpty(apiQuestion.latestResponses)) {
                // New Answer (question never answered before)
                await sendNewResponse(
                    theQuestion.competency_option_id,
                    currentQuestionsSelectedOption.value,
                    subThemeIds
                );
            } else if (apiQuestion.latestResponses[0].response_option_id !== currentQuestionsSelectedOption.value) {
                // Different answer (Question answered, but a new response)
                await sendNewResponse(
                    theQuestion.competency_option_id,
                    currentQuestionsSelectedOption.value,
                    subThemeIds
                );
            }
        }
    };

    const handlePrevious = async () => {
        setTimeout(async () => {
            const lastCompetencyId = questions[questionIndex - 1].competency_option_id;
            setLastCompetencyId(lastCompetencyId);
            setQuestionIndex(questionIndex - 1);
            await getNotes(lastCompetencyId);
        }, 300);
    };

    const handleForward = async () => {
        setShouldDisableNext(true);

        // handles going to next
        const newIndex = questionIndex + 1;

        if (newIndex >= questions.length) {
            const newThemeIndex = themeStepper + 1;

            if (newThemeIndex + 1 > selectedThemes.length) {
                setHasFinished(true);
            } else {
                setThemeStepper(themeStepper + 1);
            }
            setSelectedSubThemes([]);
            setQuestionIndex(0);
            setIsAnsweringQuestions(false);
        } else {
            setTimeout(async () => {
                setQuestionIndex(newIndex);
                setLastCompetencyId(questions[newIndex].competency_option_id);
                await getNotes(questions[newIndex].competency_option_id);
                setShouldDisableNext(false);
            }, 300);
        }
    };

    useEffect(() => {
        getSubThemes();
    }, [getSubThemes]);

    useEffect(() => {
        if (questionIndex <= 0) {
            setShouldDisablePrevious(true);
        } else {
            setShouldDisablePrevious(false);
        }
    }, [questionIndex]);

    return !error ? (
        questions.length ? (
            <QuestionStep
                key={questions[questionIndex]}
                question={questions[questionIndex]}
                handlePrevious={handlePrevious}
                shouldDisablePrevious={shouldDisablePrevious}
                handleForward={handleForward}
                handleSendingResponse={handleSendingResponse}
                shouldDisableNext={shouldDisableNext}
                handleSubmitNote={handleSubmitNote}
                notes={notes}
                notesLoading={notesLoading}
                institutionId={institutionId}
                theme={theme}
            />
        ) : (
            <>
                <Skeleton variant='rect' height={50} width={200} />
                <br />
                <Skeleton variant='rect' height={50} />
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Skeleton variant='rect' height={250} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Skeleton variant='rect' height={250} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Skeleton variant='rect' height={250} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Skeleton variant='rect' height={250} />
                    </Grid>
                </Grid>
            </>
        )
    ) : (
        <Typography variant='h1'>{errorMessage}</Typography>
    );
}

QuestionStepper.propTypes = {
    setIsAnsweringQuestions: PropTypes.func.isRequired,
    setHasFinished: PropTypes.func.isRequired,
    institutionId: PropTypes.number.isRequired,
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired
};

export default QuestionStepper;
