import React from 'react';

function CurriculumDevelopment() {
    return (
        <svg
            data-name='Layer 2'
            xmlns='http://www.w3.org/2000/svg'
            width='133.95'
            height='134.7'
            viewBox='0 0 133.95 134.7'
        >
            <path
                data-name='Path 4038'
                d='m 132.35,58.84 -12.11,-2.1 -0.59,-0.1 -0.19,-0.58 a 34.471,34.471 0 0 0 -2,-5 l -0.27,-0.54 0.34,-0.49 7.07,-10 a 1.84,1.84 0 0 0 -0.21,-2.37 l -9.47,-9.5 a 1.82,1.82 0 0 0 -1.3,-0.54 1.89,1.89 0 0 0 -1.08,0.34 l -9.92,7 -0.49,0.35 -0.54,-0.27 A 34.721,34.721 0 0 0 96.5,33 l -0.57,-0.19 -0.1,-0.59 -2.08,-12 A 1.84,1.84 0 0 0 91.93,18.69 H 87 V 1.85 A 1.85,1.85 0 0 0 85.14,0 H 49.49 a 1.85,1.85 0 0 0 -1.85,1.85 v 131 a 1.85,1.85 0 0 0 1.85,1.85 H 85.14 A 1.85,1.85 0 0 0 87,132.88 v -16.83 h 5 a 1.85,1.85 0 0 0 1.82,-1.54 l 2.13,-12.36 0.1,-0.59 0.57,-0.19 a 36.561,36.561 0 0 0 4.72,-1.94 l 0.53,-0.26 0.49,0.34 10.24,7.23 a 1.86,1.86 0 0 0 2.37,-0.21 l 9.47,-9.47 a 1.84,1.84 0 0 0 0.21,-2.37 l -7.17,-10.18 -0.35,-0.49 0.27,-0.53 a 36.38,36.38 0 0 0 2,-4.8 l 0.19,-0.57 0.58,-0.1 12.24,-2.11 a 1.84,1.84 0 0 0 1.54,-1.82 V 60.66 a 1.85,1.85 0 0 0 -1.6,-1.82 z M 83.29,92.77 V 131 H 51.34 V 91.77 h 32 z m 0,-17.4 v 12.7 H 51.34 v -13.7 h 32 z m 0,-70.67 v 66 H 51.34 v -67 h 32 z M 87,85.82 V 47.74 l 1.15,0.17 a 19.67,19.67 0 0 1 0,38.9 L 87,87 Z m 43.21,-22.76 v 9.47 l -0.83,0.15 -11.6,2 a 1.86,1.86 0 0 0 -1.48,1.32 33,33 0 0 1 -3,7.25 1.85,1.85 0 0 0 0.1,2 l 6.8,9.65 0.48,0.68 -0.59,0.6 -6.09,6.04 -0.59,0.6 -0.69,-0.49 -9.72,-6.85 a 1.83,1.83 0 0 0 -1.07,-0.33 1.91,1.91 0 0 0 -0.9,0.23 32.74,32.74 0 0 1 -7.16,2.94 1.87,1.87 0 0 0 -1.33,1.47 l -2,11.73 -0.15,0.83 H 87 V 90.66 l 0.89,-0.1 a 23.35,23.35 0 0 0 0,-46.4 L 87,44.06 V 22.38 h 3.39 l 0.15,0.83 2,11.35 a 1.87,1.87 0 0 0 1.28,1.44 31.44,31.44 0 0 1 7.48,3.12 1.87,1.87 0 0 0 0.93,0.24 1.82,1.82 0 0 0 1.06,-0.33 l 9.41,-6.66 0.69,-0.49 0.6,0.6 6.08,6.1 0.59,0.59 -0.48,0.69 -6.7,9.5 a 1.85,1.85 0 0 0 -0.09,2 31.65,31.65 0 0 1 3,7.37 1.84,1.84 0 0 0 1.47,1.35 l 11.49,2 0.83,0.15 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4039'
                d='M 37.49,0 H 1.85 A 1.85,1.85 0 0 0 0,1.85 v 131 a 1.85,1.85 0 0 0 1.85,1.85 h 35.64 a 1.85,1.85 0 0 0 1.85,-1.85 V 1.85 A 1.85,1.85 0 0 0 37.49,0 Z M 35.64,92.77 V 131 H 3.7 V 91.77 h 31.94 z m 0,-17.4 v 12.7 H 3.7 v -13.7 h 31.94 z m 0,-70.67 v 66 H 3.7 v -67 h 31.94 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4040'
                d='m 11.07,19.24 h 17.19 a 1.85,1.85 0 0 0 0,-3.7 H 11.07 a 1.85,1.85 0 1 0 0,3.7 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4041'
                d='m 11.07,31.34 h 17.19 a 1.85,1.85 0 0 0 0,-3.7 H 11.07 a 1.85,1.85 0 1 0 0,3.7 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4042'
                d='m 11.07,43.43 h 17.19 a 1.85,1.85 0 1 0 0,-3.69 H 11.07 a 1.85,1.85 0 1 0 0,3.69 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4043'
                d='m 9.86,112.16 a 9.81,9.81 0 1 0 9.81,-9.8 9.81,9.81 0 0 0 -9.81,9.8 z m 9.81,-6.11 a 6.11,6.11 0 1 1 -6.11,6.11 6.11,6.11 0 0 1 6.11,-6.11 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4044'
                d='m 56.87,17.39 a 1.85,1.85 0 0 0 1.85,1.85 h 17.19 a 1.85,1.85 0 0 0 0,-3.7 H 58.72 a 1.85,1.85 0 0 0 -1.85,1.85 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4045'
                d='m 56.87,29.49 a 1.85,1.85 0 0 0 1.85,1.85 h 17.19 a 1.85,1.85 0 0 0 0,-3.7 H 58.72 a 1.85,1.85 0 0 0 -1.85,1.85 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4046'
                d='m 56.87,41.58 a 1.85,1.85 0 0 0 1.85,1.85 h 17.19 a 1.85,1.85 0 1 0 0,-3.69 H 58.72 a 1.85,1.85 0 0 0 -1.85,1.84 z'
                fill='#6d2077'
            />
            <path
                data-name='Path 4047'
                d='m 67.31,122 c 8.742791,0.009 13.128086,-10.55833 6.949901,-16.74282 -1.53185,-1.53341 -3.333799,-2.41769 -5.177735,-2.74721 -5.592941,-0.99946 -11.572162,3.10496 -11.572166,9.68003 -3e-6,5.41401 4.385992,9.80448 9.8,9.81 z m -1.33304,-12.41347 c -43.98464,-73.057688 -21.99232,-36.528847 0,0 z'
                fill='#6d2077'
            />
            <ellipse
                fill='#ffffff'
                stroke='#ffffff'
                strokeWidth='0.437589'
                cx='67.382965'
                cy='112.17'
                rx='5.8893523'
                ry='5.6328259'
            />
        </svg>
    );
}

export default CurriculumDevelopment;
