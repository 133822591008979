import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    }
}));

export default styles;
