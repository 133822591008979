const prefix = `${process.env.REACT_APP_ENV}-fes`;

const keys = {
    storage: {
        token: `${prefix}-token`,
        cookie: `${prefix}-cookie`,
        user: `${prefix}-user`,
        lastCompetencyId: `${prefix}-last-competency-id`
    }
};

export default keys;
