import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faHome,
    faAngleDoubleRight,
    faBars,
    faBook,
    faQuestionCircle,
    faSignOutAlt,
    faTachometerAlt,
    faUser,
    faSignInAlt,
    faUserPlus,
    faCheck,
    faCheckCircle,
    faCogs,
    faListAlt,
    faEllipsisH,
    faUsers,
    faUsersSlash,
    faUserCog,
    faUserSlash,
    faStopCircle,
    faEye,
    faBan,
    faLongArrowAltRight,
    faAngleDown,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faPlus,
    faEdit,
    faArrowAltCircleLeft,
    faLink,
    faLayerGroup,
    faObjectUngroup,
    faObjectGroup,
    faBullseye,
    faTrashAlt,
    faFileSignature,
    faExclamationCircle,
    faUnlink,
    faChevronDown,
    faArrowCircleLeft,
    faFileCsv,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import { AuthContextProvider } from '../lib/hooks/useAuthContext';
import { ContentManagementProvider } from '../lib/hooks/useContentManagementContext';
import { SurveyContextProvider } from '../lib/hooks/useSurveyContext';
import { QuestionContextProvider } from '../lib/hooks/useQuestionContext';

import Home from '../pages/Home/Home';
import Help from '../pages/Help/Help';
import About from '../pages/About/About';
import Invitation from '../pages/Invitation/Invitation';
import LogIn from '../pages/LogIn/LogIn';
import PrivateRoute from './PrivateRoute';
import Profile from '../pages/Profile/Profile';
import PublicRoute from './PublicRoute';
import NotFound from '../pages/NotFound/NotFound';
import Register from '../pages/Register/Register';
import Results from '../pages/Results/Results';
import ExportNotes from '../pages/ExportNotes/ExportNotes';
import PrintLayout from '../layout/PrintLayout/PrintLayout';
import ThemeGoals from '../pages/ThemeGoals/ThemeGoals';
import ActionPlan from '../pages/ActionPlan/ActionPlan';
import Survey from '../pages/Survey/Survey';
import Verify from '../pages/Verify/Verify';
import Glossary from '../pages/Glossary/Glossary';
import SelfAssessment from '../pages/SelfAssessment/SelfAssessment';

import Accessability from '../pages/Accessibility/Accessibility';
import Cookies from '../pages/Cookies/Cookies';
import Privacy from '../pages/Privacy/Privacy';

import OrganisationsOverview from '../pages/OrganisationsOverview/OrganisationsOverview';
import OrganisationSettings from '../pages/OrganisationSettings/OrganisationSettings';

import Themes from '../pages/ContentManagement/Themes/Themes';
import NewTheme from '../pages/ContentManagement/Themes/NewTheme/NewTheme';
import EditTheme from '../pages/ContentManagement/Themes/EditTheme/EditTheme';

import SubThemes from '../pages/ContentManagement/SubThemes/SubThemes';
import NewSubTheme from '../pages/ContentManagement/SubThemes/NewSubTheme/NewSubTheme';
import EditSubTheme from '../pages/ContentManagement/SubThemes/EditSubTheme/EditSubTheme';

import Competencies from '../pages/ContentManagement/Competencies/Competencies';
import NewCompetency from '../pages/ContentManagement/Competencies/NewCompetency/NewCompetency';
import EditCompetency from '../pages/ContentManagement/Competencies/EditCompetency/EditCompetency';

import CompetencyStatements from '../pages/ContentManagement/CompetencyStatements/CompetencyStatements';
import NewCompetencyStatement from '../pages/ContentManagement/CompetencyStatements/NewCompetencyStatement/NewCompetencyStatement';
import EditCompetencyStatement from '../pages/ContentManagement/CompetencyStatements/EditCompetencyStatement/EditCompetencyStatement';

import ValidationStatements from '../pages/ContentManagement/ValidationStatements/ValidationStatements';
import NewValidationStatement from '../pages/ContentManagement/ValidationStatements/NewValidationStatement/NewValidationStatement';
import EditValidationStatement from '../pages/ContentManagement/ValidationStatements/EditValidationStatement/EditValidationStatement';

import Resources from '../pages/ContentManagement/Resources/Resources';
import NewResource from '../pages/ContentManagement/Resources/NewResource/NewResource';
import EditResource from '../pages/ContentManagement/Resources/EditResource/EditResource';

import Reports from '../pages/Reports/Reports';

import './index.css';
import theme from './theme';
import ScrollToTop from '../lib/ScrollToTop';
import urls from '../config/urls';

library.add(
    faHome,
    faAngleDoubleRight,
    faBars,
    faBook,
    faUser,
    faTachometerAlt,
    faSignOutAlt,
    faQuestionCircle,
    faSignInAlt,
    faUserPlus,
    faCheck,
    faCheckCircle,
    faCogs,
    faListAlt,
    faEllipsisH,
    faUsers,
    faUsersSlash,
    faUserCog,
    faUserSlash,
    faStopCircle,
    faEye,
    faBan,
    faLongArrowAltRight,
    faAngleDown,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faUserCog,
    faPlus,
    faEdit,
    faArrowAltCircleLeft,
    faLink,
    faLayerGroup,
    faObjectGroup,
    faObjectUngroup,
    faBullseye,
    faQuestionCircle,
    faTrashAlt,
    faFileSignature,
    faExclamationCircle,
    faUnlink,
    faChevronDown,
    faArrowCircleLeft,
    faFileCsv,
    faInfoCircle
);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <HelmetProvider>
                <AuthContextProvider>
                    <ContentManagementProvider>
                        <SurveyContextProvider>
                            <QuestionContextProvider>
                                <Router>
                                    <ScrollToTop />
                                    <Switch>
                                        <PublicRoute path={urls.login.url} component={LogIn} restricted exact />
                                        <PublicRoute path={urls.register.url} component={Register} restricted exact />
                                        <PublicRoute path={urls.verify.url} component={Verify} restricted exact />
                                        <PublicRoute
                                            path={urls.invitation.url}
                                            component={Invitation}
                                            restricted
                                            exact
                                        />
                                        <PublicRoute path={urls.help.url} component={Help} exact />
                                        <PublicRoute path={urls.about.url} component={About} exact />
                                        <PublicRoute path={urls.accessibility.url} component={Accessability} exact />
                                        <PublicRoute path={urls.cookies.url} component={Cookies} exact />
                                        <PublicRoute path={urls.privacy.url} component={Privacy} exact />

                                        <PrivateRoute path={urls.home.url} component={Home} exact />
                                        <PrivateRoute path={urls.profile.url} component={Profile} exact />
                                        <PrivateRoute
                                            path={`${urls.survey.url}/:themeSlug`}
                                            component={Survey}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute path={urls.results.url} component={Results} exact />
                                        <PrivateRoute
                                            path={urls.exportNotes.url}
                                            component={ExportNotes}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute path={urls.selfAssessment.url} component={SelfAssessment} exact />
                                        <PrivateRoute
                                            path='/surveys/:surveyId/institution/:institutionId/themes/:themeId/goals'
                                            component={ThemeGoals}
                                            exact
                                        />
                                        <PrivateRoute
                                            path='/surveys/:surveyId/institution/:institutionId/themes/:themeId/goals/:levelId'
                                            component={ActionPlan}
                                            exact
                                        />
                                        <PrivateRoute
                                            path={urls.organisationsOverview.url}
                                            component={OrganisationsOverview}
                                            exact
                                        />
                                        <PrivateRoute
                                            path={`${urls.organisationSettings.url}/:id`}
                                            component={OrganisationSettings}
                                            exact
                                        />

                                        <PrivateRoute
                                            path={urls.contentManagement.themes.url}
                                            component={Themes}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.themes.add.url}
                                            component={NewTheme}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={`${urls.contentManagement.themes.edit.url}/:id`}
                                            component={EditTheme}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.subThemes.url}
                                            component={SubThemes}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.subThemes.add.url}
                                            component={NewSubTheme}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={`${urls.contentManagement.subThemes.edit.url}/:id`}
                                            component={EditSubTheme}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.competencies.url}
                                            component={Competencies}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.competencies.add.url}
                                            component={NewCompetency}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={`${urls.contentManagement.competencies.edit.url}/:id`}
                                            component={EditCompetency}
                                            exact
                                            layout={false}
                                        />

                                        <PrivateRoute
                                            path={urls.contentManagement.competencyStatements.url}
                                            component={CompetencyStatements}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.competencyStatements.add.url}
                                            component={NewCompetencyStatement}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={`${urls.contentManagement.competencyStatements.edit.url}/:id`}
                                            component={EditCompetencyStatement}
                                            exact
                                            layout={false}
                                        />

                                        <PrivateRoute
                                            path={urls.contentManagement.validationStatements.url}
                                            component={ValidationStatements}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.validationStatements.add.url}
                                            component={NewValidationStatement}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={`${urls.contentManagement.validationStatements.edit.url}/:id`}
                                            component={EditValidationStatement}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.resources.url}
                                            component={Resources}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={urls.contentManagement.resources.add.url}
                                            component={NewResource}
                                            exact
                                            layout={false}
                                        />
                                        <PrivateRoute
                                            path={`${urls.contentManagement.resources.edit.url}/:id`}
                                            component={EditResource}
                                            exact
                                            layout={false}
                                        />

                                        <PrivateRoute path={urls.glossary.url} component={Glossary} exact />

                                        <PrivateRoute path={urls.reports.frontend.url} component={Reports} exact />
                                        <PublicRoute component={NotFound} />
                                    </Switch>
                                </Router>
                            </QuestionContextProvider>
                        </SurveyContextProvider>
                    </ContentManagementProvider>
                </AuthContextProvider>
            </HelmetProvider>
        </ThemeProvider>
    );
}

export default App;
