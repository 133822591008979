import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import styles from './SurveyNavigationButton.styles';
import JiscButton from '../JiscButton/JiscButton';

function SurveyNavigationButton({ disable, label, icon, iconPlacement, clickAction }) {
    const classes = styles();

    return (
        <JiscButton
            disabled={disable}
            className={classes.root}
            variant='ghost'
            onClick={() => {
                clickAction();
            }}
        >
            {iconPlacement === 'left' && <span className={`${classes.icon} ${classes.iconRight}`}>{icon}</span>}
            <Typography variant='body1' color='primary' className={classes.label}>
                {label}
            </Typography>
            {iconPlacement === 'right' && <span className={`${classes.icon} ${classes.iconLeft}`}>{icon}</span>}
        </JiscButton>
    );
}

SurveyNavigationButton.propTypes = {
    disable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    iconPlacement: PropTypes.string.isRequired,
    clickAction: PropTypes.func.isRequired
};

SurveyNavigationButton.defaultProps = {
    disable: false
};

export default SurveyNavigationButton;
