import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#f0f0f0', // Slight grey background
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,

        [`@media print`]: {
            display: 'none'
        }
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginTop: 0,
        marginBottom: theme.spacing(1),
        color: '#000000' // Black text color
    },
    description: {
        fontSize: '16px',
        margin: '0p 0px 20px 0px',
        color: '#000000' // Black text color
    },
    question: {
        margin: '40px 0px 15px 0px'
    },
    questionText: {
        fontWeight: 'bold',
        fontSize: '18px',
        marginBottom: '20px'
    },
    additionalInfo: {
        borderLeft: '3px solid #ccc',
        paddingLeft: '10px',
        marginBottom: '20px',
        '& > p': {
            margin: '5px'
        }
    },
    noteContainer: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '0px 10px',
        marginBottom: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' // Adding a subtle shadow
    },
    noteContent: {
        fontSize: '16px',
        margin: '10px 0px 5px 0px'
    },
    noteInfo: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    createdBy: {
        fontSize: '14px',
        color: '#666',
        margin: '0px 0px 10px 0px'
    },
    createdAt: {
        fontSize: '14px',
        color: '#666',
        margin: '0px 0px 10px 0px'
    },
    actions: {
        display: 'flex',
        marginTop: theme.spacing(2),
        [`& > * + *`]: {
            marginLeft: theme.spacing(2)
        }
    }
}));

export default styles;
