import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useSurveyContext } from '../../lib/hooks/useSurveyContext';

import SubThemeSidebarCard from '../SubThemeSidebarCard/SubThemeSidebarCard';
import BlueTick from '../../lib/assets/BlueTick';

import { getThemeIcon } from '../../lib/enums';
import styles from './ThemeSidebarCard.styles';

function ThemeSidebarCard({ theme, index, showDetail, active, selectedSubThemes }) {
    const { currentSubTheme, setTrackerHeight, findTrackerHeight, themeStepper, trackerHeight } = useSurveyContext();
    const { theme_title: title, theme_color: color, theme_icon: icon } = theme;
    const classes = styles({ color });

    useEffect(() => {
        if (currentSubTheme) {
            setTrackerHeight(findTrackerHeight(currentSubTheme));
        } else {
            setTrackerHeight(0);
        }
    }, [currentSubTheme, findTrackerHeight, setTrackerHeight]);

    return (
        <div className={classes.root}>
            <div id={`${active ? 'themeCardActive' : 'themeCard'}`} className={classes.card}>
                <span
                    className={`${classes.counter} ${
                        active || index < themeStepper ? classes.active : classes.inactive
                    }`}
                >
                    {index < themeStepper ? <BlueTick /> : index + 1}
                </span>
                {getThemeIcon(icon)}
                <Typography variant='h6' className={classes.title}>
                    {title}
                </Typography>
            </div>

            {showDetail && active && (
                <>
                    <span style={{ height: trackerHeight }} className={classes.road} />

                    {theme &&
                        selectedSubThemes &&
                        selectedSubThemes.map((subTheme) => {
                            return (
                                <div
                                    data-testid={subTheme.sub_theme_id}
                                    key={subTheme.sub_theme_id}
                                    className={classes.breakdown}
                                >
                                    <SubThemeSidebarCard theme={theme} subTheme={subTheme} />
                                </div>
                            );
                        })}
                </>
            )}
        </div>
    );
}

ThemeSidebarCard.propTypes = {
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired,
    index: PropTypes.number.isRequired,
    showDetail: PropTypes.bool.isRequired,
    active: PropTypes.bool,
    selectedSubThemes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

ThemeSidebarCard.defaultProps = {
    active: false
};

export default ThemeSidebarCard;
