import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import slugify from 'slugify';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import urls from '../../config/urls';
import * as api from '../../lib/api';
import * as enums from '../../lib/enums';
import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { useSurveyContext } from '../../lib/hooks/useSurveyContext';
import { findPercentage } from '../../lib/helpers';

import LevelProgress from '../../components/LevelProgress/LevelProgress';
import GoalAccordion from '../../components/GoalAccordion/GoalAccordion';
import JiscLink from '../../components/JiscLink/JiscLink';

import styles from './ThemeGoals.styles';

function ThemeGoals() {
    const slugOptions = {
        replacement: '-',
        lower: true,
        trim: true
    };

    const progresstionGoal = 70;

    const { surveyId, institutionId, themeId } = useParams();
    const { auth } = useAuthContext();
    const ctx = useSurveyContext();

    const [theme, setTheme] = useState();
    const [level, setLevel] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [expanded, setExpanded] = useState(false);
    const [notes, setNotes] = useState([]);

    const classes = styles({ color: theme ? theme.theme_color : '' });

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getResponseOptions = async () => {
        let responseOptions = [];
        try {
            const { data } = await api.get('/responses-options');
            setError(false);
            responseOptions = data;
        } catch (err) {
            setError(true);
        }
        ctx.setResponseOptions(responseOptions);
    };

    const handleProgressionSet = (foundTheme) => {
        const sorted = foundTheme.levels.sort(({ id }, prev) => id > prev.id);
        const fallback = sorted[0].level;

        sorted.forEach((progression) => {
            if (findPercentage(progression) >= progresstionGoal) {
                setLevel(progression.level);
            } else {
                setLevel(fallback);
            }
        });
    };

    const getThemeDetailsForInstitution = async () => {
        try {
            const { data } = await api.get(`/surveys/${surveyId}/institution/${institutionId}/progress`);
            const foundTheme = data.find((item) => item.theme_id === Number(themeId));

            if (!foundTheme) {
                setLoading(false);
                throw Error('There was a problem fetching this themes information.');
            }

            setTheme(foundTheme);
            handleProgressionSet(foundTheme);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const getAllNotes = async () => {
        try {
            const res = await api.get(`/institutions/${institutionId}/notes`);
            const notesByTheme = res.data.filter((note) => note.theme_title === theme.theme_title);

            setNotes(notesByTheme);
        } catch (e) {
            // Handle this...
        }
    };

    useEffect(() => {
        if (!ctx.responseOptions.length) getResponseOptions();
        getThemeDetailsForInstitution();
    }, []);

    useEffect(() => {
        if (theme) {
            getAllNotes();
        }
    }, [theme]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.themeGoals.title}</title>
                <link
                    rel='canonical'
                    href={`${urls.base.url}/surveys/${surveyId}/institution/${institutionId}/themes/${themeId}/goals`}
                />
            </Helmet>

            <main data-testid='theme-goals-page' className={classes.root}>
                <Container>
                    {auth?.user?.institution_id === parseInt(institutionId, 10) ||
                    auth?.user?.role === enums.user.role.JISC_ADMIN ? (
                        !loading ? (
                            <>
                                {theme && level && (
                                    <>
                                        <JiscLink
                                            type='external'
                                            href={urls.results.url}
                                            className={classes.backButton}
                                        >
                                            <FontAwesomeIcon icon='arrow-circle-left' className={classes.arrowLeft} />
                                            <Typography variant='body1'>Your results</Typography>
                                        </JiscLink>

                                        <section id='fes-goals-hero' className={classes.hero}>
                                            {enums.getThemeIcon(theme.theme_icon)}
                                            <Typography variant='h1' className={classes.heroTitle}>
                                                {theme.theme_title}
                                            </Typography>
                                            <Typography variant='body1'>{theme.theme_description}</Typography>
                                        </section>

                                        <section id='fes-goals-current-level'>
                                            {theme.levels
                                                .sort((a, b) => a.level.id - b.level.id)
                                                .map((progression) => (
                                                    <LevelProgress
                                                        key={progression.level.id}
                                                        currentLevel={level}
                                                        progression={progression}
                                                        progressionGoal={progresstionGoal}
                                                        viewMore
                                                    />
                                                ))}
                                        </section>

                                        <Typography variant='h2' className={classes.subTitle}>
                                            Complete more goals to elevate your college:
                                        </Typography>

                                        <section id='fes-goals-accordians' className={classes.accordians}>
                                            <aside className={classes.goalsStickyMenu}>
                                                <div>
                                                    <Typography variant='body1'>
                                                        Click the titles to navigate to the relevant area
                                                    </Typography>

                                                    {Object.values(theme.sub_themes).map((subTheme) => (
                                                        <Accordion
                                                            key={subTheme.sub_theme_id}
                                                            expanded={expanded === subTheme.sub_theme_id}
                                                            onChange={handleChange(subTheme.sub_theme_id)}
                                                            className={classes.goalsStickyMenuAccordion}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                className={classes.goalsStickyMenuAccordionSummary}
                                                            >
                                                                <JiscLink
                                                                    key={`menu-${subTheme.sub_theme_id}`}
                                                                    type='external'
                                                                    href={`#${slugify(
                                                                        subTheme.sub_theme_title,
                                                                        slugOptions
                                                                    )}`}
                                                                >
                                                                    <Typography variant='body1'>
                                                                        {subTheme.sub_theme_title}
                                                                    </Typography>
                                                                </JiscLink>
                                                            </AccordionSummary>
                                                            <AccordionDetails
                                                                className={classes.goalsStickyMenuAccordionDetails}
                                                            >
                                                                <div>
                                                                    {Object.values(subTheme.competencies).map(
                                                                        (competency) => (
                                                                            <JiscLink
                                                                                key={`menu-${competency.competency_id}`}
                                                                                type='external'
                                                                                href={`#${slugify(
                                                                                    competency.competency_title,
                                                                                    slugOptions
                                                                                )}`}
                                                                            >
                                                                                <Typography variant='body2'>
                                                                                    {competency.competency_title}
                                                                                </Typography>
                                                                            </JiscLink>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            </aside>

                                            {Object.values(theme.sub_themes).map((subTheme) => (
                                                <div
                                                    id={slugify(subTheme.sub_theme_title, slugOptions)}
                                                    key={`goal-${subTheme.sub_theme_id}`}
                                                    className={classes.goalAccordionSection}
                                                >
                                                    <Typography variant='h3'>{subTheme.sub_theme_title}</Typography>
                                                    {Object.values(subTheme.competencies).map((competency) => {
                                                        return (
                                                            <div
                                                                id={slugify(competency.competency_title, slugOptions)}
                                                                key={competency.competency_id}
                                                            >
                                                                {Object.values(competency.competency_statements).map(
                                                                    (statement) => {
                                                                        return (
                                                                            statement.competency_statement_id && (
                                                                                <GoalAccordion
                                                                                    key={
                                                                                        statement.competency_statement_id
                                                                                    }
                                                                                    competencyName={
                                                                                        competency.competency_title
                                                                                    }
                                                                                    statement={statement}
                                                                                    refreshSurvey={
                                                                                        getThemeDetailsForInstitution
                                                                                    }
                                                                                    status='All'
                                                                                    notes={notes.filter(
                                                                                        (note) =>
                                                                                            note.statement ===
                                                                                            statement.competency_statement_statement
                                                                                    )}
                                                                                />
                                                                            )
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </section>
                                    </>
                                )}
                                {error && (
                                    <section>
                                        <Typography variant='body1'>{error}</Typography>
                                    </section>
                                )}
                            </>
                        ) : (
                            <section>
                                <Skeleton data-testid='skeleton' variant='rect' height={200} />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={120} />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={120} />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={120} />
                                <br />
                                <br />
                                <Skeleton data-testid='skeleton' variant='rect' height={400} />
                            </section>
                        )
                    ) : (
                        <section>
                            <Typography data-testid='theme-goals-error-title' variant='h1'>
                                You do not have permission to view this page.
                            </Typography>
                        </section>
                    )}
                </Container>
            </main>
        </>
    );
}

export default ThemeGoals;
