import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Typography } from '@material-ui/core';

import { useSurveyContext } from '../../../../lib/hooks/useSurveyContext';

import QuestionOption from './QuestionOption/QuestionOption';

import styles from './Question.styles';

function Question({ question, theme }) {
    const classes = styles();
    const {
        getQuestion,
        setCurrentQuestion,
        setCurrentSubTheme,
        setCurrentQuestionsSelectedOption,
        responseOptions,
        currentQuestion
    } = useSurveyContext();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(); // 1, 2, 3, 4
    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const getApiQuestion = useCallback(async () => {
        setLoading(true);
        setSelected(undefined);

        try {
            const apiQuestion = await getQuestion(question.competency_option_id);
            setCurrentQuestion(apiQuestion);
            setCurrentSubTheme(apiQuestion.subThemeId);
            if (apiQuestion.latestResponses.length !== 0) {
                setSelected(apiQuestion.latestResponses[0].response_option_id);
            }
            setError(false);
        } catch (err) {
            setError(true);
            setErrorMessage('There was a problem.');
        }
        setLoading(false);
    }, [getQuestion, question.competency_option_id, setCurrentQuestion, setCurrentSubTheme]);

    useEffect(() => {
        getApiQuestion();
    }, [getApiQuestion]);

    useEffect(() => {
        setCurrentQuestionsSelectedOption({
            value: selected
        });
    }, [selected, setCurrentQuestionsSelectedOption]);

    return (
        <>
            {!error && !loading ? (
                <article className={classes.root}>
                    <q className={classes.statement} data-testid={question.competency_option_text}>
                        {parse(question.competency_option_text)}
                    </q>

                    <div className={classes.responseContainer}>
                        {responseOptions &&
                            responseOptions.map((option) => {
                                return (
                                    <QuestionOption
                                        key={option.id}
                                        option={option}
                                        selected={option.value === selected}
                                        setSelected={setSelected}
                                        theme={theme}
                                    />
                                );
                            })}
                    </div>
                    {currentQuestion?.latestResponses?.answered_by && (
                        <div>
                            <Typography data-testid='latestResponse' className={classes.statementResponse}>
                                Last Answered by: {currentQuestion.latestResponses[0].answered_by}
                            </Typography>
                        </div>
                    )}
                </article>
            ) : (
                <></>
            )}
            {error && <p>{errorMessage}</p>}
        </>
    );
}

Question.propTypes = {
    question: PropTypes.shape({
        competency_option_id: PropTypes.number.isRequired,
        competency_option_text: PropTypes.string.isRequired,
        latestResponse: PropTypes.shape({
            value: PropTypes.number
        }).isRequired
    }).isRequired,
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired
};

export default Question;
