import { makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const styles = makeStyles((theme) => ({
    heroContainer: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(34)
        },
        '& > svg': {
            display: 'none',
            position: 'absolute',
            zIndex: -1,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'calc(100% - 3rem)',
            height: 'auto',
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            }
        }
    },
    greyPanel: {
        backgroundColor: grey[300],
        padding: '10px'
    },
    checkField: {
        marginRight: '1em',
        marginLeft: '2em',
        accentColor: '#f24e00'
    }
}));

export default styles;
