import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    main: {
        padding: '4rem 10%',
        [theme.breakpoints.up('xl')]: {
            padding: '12rem 10%'
        }
    },
    sidebar: {
        display: 'grid',
        gap: theme.spacing(7),
        '& >div:first-child': {
            marginTop: theme.spacing(7)
        },
        '& >div:last-child': {
            marginBottom: theme.spacing(7)
        }
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(4, 0),
        '& > button:first-child': {
            marginRight: theme.spacing(2)
        }
    },
    navigationContainer: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            right: '5%',
            bottom: '5%'
        }
    },
    navigationParent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > button': {
            '&:first-child': {
                marginRight: '0.5rem'
            }
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        }
    },
    error: {
        margin: theme.spacing(0, 0, 3, 0)
    },
    finishedSurveyHeader: {
        color: theme.palette.fes.headline,
        lineHeight: 1.5,
        margin: theme.spacing(0, 0, 4, 0),
        paddingRight: '15%'
    }
}));

export default styles;
