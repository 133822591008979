import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { Container, Typography, Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { getThemeIcon } from '../../lib/enums';

import JiscButton from '../../components/JiscButton/JiscButton';
import HelpBanner from '../../components/HelpBanner/HelperBanner';

import SpeechBubbles from '../../lib/assets/SpeechBubbles';
import Documents from '../../lib/assets/Documents';
import Cerificate from '../../lib/assets/Certificate';
import MessageWhiteSvg from '../../lib/assets/MessageWhiteSvg';
import Foundation from '../../lib/assets/Foundation';
import Transform from '../../lib/assets/Transform';
import Elevate from '../../lib/assets/Elevate';

import urls from '../../config/urls';
import styles from './About.styles';
import { get } from '../../lib/api';

function About() {
    const classes = styles();

    const history = useHistory();

    const [themes, setThemes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const getThemes = async () => {
        try {
            const { data } = await get('/themes?surveyId=1');
            data.data.sort((a, b) => (a.id > b.id ? 1 : -1));
            setThemes(data.data);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        getThemes();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.about.title}</title>
                <link rel='canonical' href={urls.base.url + urls.about.url} />
            </Helmet>

            <main>
                <section className={classes.steps}>
                    <Container>
                        <Typography variant='h2' className={classes.stepsTitle}>
                            How does it work?
                        </Typography>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={4} className={classes.stepsGridItem}>
                                <SpeechBubbles />
                                <Typography variant='h3' className={classes.stepsGridItemSubTitle}>
                                    Complete the self-assessment
                                </Typography>
                                <Typography variant='body1'>
                                    Answer questions about your college and we will map your college digital elevation
                                    level to our model.
                                </Typography>
                                <JiscButton
                                    variant='ghost'
                                    onClick={() => history.push(urls.selfAssessment.url)}
                                    endIcon={<FontAwesomeIcon icon='angle-double-right' />}
                                    className={classes.stepsGridItemButton}
                                >
                                    Complete your self-assessment
                                </JiscButton>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.stepsGridItem}>
                                <Documents />
                                <Typography variant='h3' className={classes.stepsGridItemSubTitle}>
                                    Get an action plan
                                </Typography>
                                <Typography variant='body1'>
                                    Identify and prioritise the changes you want to make and you will receive your
                                    college’s individual plan of a digital journey relevant to your unique
                                    circumstances.
                                </Typography>
                                <JiscButton
                                    variant='ghost'
                                    onClick={() => history.push(urls.results.url)}
                                    endIcon={<FontAwesomeIcon icon='angle-double-right' />}
                                    className={classes.stepsGridItemButton}
                                >
                                    View results
                                </JiscButton>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.stepsGridItem}>
                                <Cerificate />
                                <Typography variant='h3' className={classes.stepsGridItemSubTitle}>
                                    Advance your college
                                </Typography>
                                <Typography variant='body1'>
                                    We will help you on the way by providing useful information and resources from Jisc
                                    and other organisations, stories from other colleges and a community of like minded
                                    senior leaders.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </section>
                <section className={classes.message}>
                    <Container>
                        <Typography variant='h2' className={classes.messageTitle}>
                            Helping you to get to the forefront of digital best practice
                        </Typography>
                        <JiscButton
                            variant='ghost'
                            onClick={() => history.push(urls.about.url)}
                            endIcon={<FontAwesomeIcon icon='angle-double-right' />}
                            className={classes.messageButton}
                        >
                            Learn more
                        </JiscButton>
                    </Container>
                    <MessageWhiteSvg />
                </section>
                <section>
                    <Container>
                        <br />
                        <Typography variant='h3'>Background</Typography>
                        <Typography variant='body1'>
                            Our Digital Elevation Model brings to life how digital, data and technology will help FES
                            providers elevate their digital ambitions.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            The model will allow providers to plan and invest in a digital journey relevant to their
                            unique circumstances, and, in the devolved nations, will assist sectoral strategy and
                            collaboration. We will also, over time, use the model to signpost providers to useful
                            information and resources from Jisc and other organisations.
                        </Typography>
                    </Container>
                </section>

                <section className={classes.information}>
                    <Container>
                        <Typography variant='h2' className={classes.informationTitle}>
                            Digital elevation levels
                        </Typography>
                        <Typography variant='body1'>
                            The digital elevation tool provides statements that allow anyone working within a Jisc FES
                            member organisation to review the status of their digital elevation in terms of the
                            foundations they have put in place, the elements to transform and where possible the elevate
                            components that ensure a provider is at the forefront of digital best practice.
                        </Typography>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={4} className={classes.informationGridItem}>
                                <Foundation />
                                <Typography variant='h3' className={classes.informationGridItemSubTitle}>
                                    Foundation
                                </Typography>
                                <Typography variant='body1'>
                                    The &apos;must-haves&apos;. The policies, processes and digital, data or technology
                                    elements that each provider must have in place as the foundations for futher
                                    progress.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.informationGridItem}>
                                <Transform />
                                <Typography variant='h3' className={classes.informationGridItemSubTitle}>
                                    Transform
                                </Typography>
                                <Typography variant='body1'>
                                    The &apos;should-haves&apos;. Building on the &apos;foundations&apos;, the very same
                                    elements that we expect, and hope providers have or are planning to put in place to
                                    transform the end user experience.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.informationGridItem}>
                                <Elevate />
                                <Typography variant='h3' className={classes.informationGridItemSubTitle}>
                                    Elevate
                                </Typography>
                                <Typography variant='body1'>
                                    The &apos;can-haves&apos;. Building on &apos;transform&apos;, some of these aspects
                                    might currently be in operation across a small number of UK FES providers. This is
                                    where early adopters will lead future innovation across the regions and truly
                                    elevate the experience of all FES stakeholders.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </section>
                <section className={classes.themes}>
                    <Container>
                        <Typography variant='h2' className={classes.themesTitle}>
                            Themes
                        </Typography>

                        {!loading && !error && themes.length && (
                            <>
                                <Typography variant='body1'>
                                    Our digital elevation model brings to life how digital, data and technology will
                                    help FES providers elevate their digital ambitions. The key themes include
                                    curriculum development, leadership governance & culture, learner experience, staff
                                    experience, underpinning technologies.
                                </Typography>

                                {themes.map((theme) => (
                                    <div key={theme.id} className={classes.themesEntry}>
                                        {getThemeIcon(theme.icon)}
                                        <div>
                                            <Typography variant='h3' className={classes.themesEntryTitle}>
                                                {theme.title}
                                            </Typography>
                                            <Typography variant='body1' className={classes.themeEntryDescription}>
                                                {theme.description}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}

                        {!loading && !!error && (
                            <Alert data-testid='theme-picker-error-message' severity='error'>
                                <AlertTitle>There was an error fetching themes for this survey.</AlertTitle>
                                <Typography varient='body2'>
                                    Please try again later or if this issues persist, please contact us at{' '}
                                    <a
                                        href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        help@jisc.ac.uk
                                    </a>
                                </Typography>
                            </Alert>
                        )}
                    </Container>
                </section>

                <HelpBanner />
            </main>
        </>
    );
}

export default About;
