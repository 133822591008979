import React, { useState } from 'react';

import { Typography, Container, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useSWR from 'swr';

import InstitutionThemesReport from '../../components/InstitutionReports/InstitutionThemesReport/InstitutionThemesReport';
import InstitutionLoginsReport from '../../components/InstitutionReports/InstitutionLoginsReport/InstitutionLoginsReport';
import InstitutionEngagementReport from '../../components/InstitutionReports/InstitutionEngagementReport/InstitutionEngagementReport';
import ReportSelector from '../../components/ReportSelector/reportSelector';
import Autocomplete from '../../components/Autocomplete/autocomplete';
import JiscButton from '../../components/JiscButton/JiscButton';
import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { ReportContextProvider } from './ReportContext';
import * as enums from '../../lib/enums';
import { get } from '../../lib/api';
import styles from './Reports.styles';
import urls from '../../config/urls';

function Reports() {
    const [processing, setProcessing] = useState(false);

    const handleSetProcessing = (value) => {
        setProcessing(value);
    };

    const fetcher = async () => {
        const { data } = await get(urls.reports.api.url);
        return data;
    };

    const { data, error } = useSWR('/report', fetcher, {
        refreshInterval: 10000, // Poll every 5 seconds
        onSuccess: (response) => {
            if (response.url) {
                handleSetProcessing(false);
            } else {
                handleSetProcessing(true);
            }
        }
    });

    const { auth } = useAuthContext();
    const classes = styles();
    if (
        ![enums.user.role.JISC_REPORTER, enums.user.role.JISC_ADMIN, enums.user.role.JISC_CONTENT].includes(
            auth?.user?.role
        )
    ) {
        window.location = '/';
    }
    return (
        <ReportContextProvider>
            <Container className={classes.heroContainer}>
                <Typography variant='h1' className={classes.title}>
                    Export your results
                </Typography>

                <Typography variant='h2' className={classes.subTitle}>
                    Select your institution
                </Typography>
                <Autocomplete />
                <ReportSelector processing={processing} handleSetProcessing={handleSetProcessing} />

                <div className={classes.latestReport}>
                    <Typography variant='h2' className={classes.latestReportTitle}>
                        Latest report
                    </Typography>

                    {error && !processing && (
                        <Alert severity='error'>
                            {error.message
                                ? error.response.data.message
                                : `There was a problem getting the report. Please try again later.`}
                        </Alert>
                    )}

                    {processing && (
                        <>
                            <p className={classes.description}>
                                Your report is being generated. This may take a few minutes.
                            </p>
                            <CircularProgress />
                        </>
                    )}
                    {data && data.url && !error && !processing && (
                        <>
                            <p className={classes.description}>
                                This is the latest report for all organisations and report types.
                            </p>
                            <div style={{ display: 'flex' }}>
                                <JiscButton isLink linkType='external' href={data.url}>
                                    Download latest report
                                </JiscButton>
                            </div>
                        </>
                    )}
                </div>
                <Typography variant='h2' className={classes.subTitle}>
                    All institution reports
                </Typography>
                <div>
                    <InstitutionLoginsReport />
                    <br />
                    <InstitutionEngagementReport />
                    <br />
                    <InstitutionThemesReport />
                </div>
            </Container>
        </ReportContextProvider>
    );
}

export default Reports;
