// @ts-check

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from '@material-ui/core';

import GoalCompetencyCard from '../GoalCompetencyCard/GoalCompetencyCard';
import { useSurveyContext } from '../../lib/hooks/useSurveyContext';

import styles from './GoalAccordion.styles';

function GoalAccordion({ competencyName, statement, refreshSurvey, status = 'All', notes }) {
    const { color } = statement.level;
    const ctx = useSurveyContext();
    const t = useRef(null);
    const classes = styles({ color });
    const [competencyOptions, setCompetencyOptions] = useState(0);
    const [competencyOptionsMax, setCompetencyOptionsMax] = useState(0);
    const [displayAccordion, setDisplayAccordion] = useState(false);

    const checkCompetency = async (competency) => {
        let value = 0;
        Object.values(competency.competency_options).forEach((option) => {
            if (option.responses.length) {
                const highestResponseId = Math.max(...option.responses.map((obj) => obj.responses_id), 0);
                const lastResponse = option.responses.find((element) => element.responses_id === highestResponseId);
                if (lastResponse.value === 1) {
                    value += 1;
                }
            }
        });
        setCompetencyOptions(value);
        setCompetencyOptionsMax(Object.keys(competency.competency_options).length);
    };

    const findResponseOptionByValue = (value) => {
        return ctx.responseOptions.find((ro) => ro.id === Number(value));
    };

    const determineRender = async () => {
        const competencyResponses = [];
        if (status === 'All') {
            setDisplayAccordion(true);
            return;
        }
        Object.values(statement.competency_options).forEach((option) => {
            if (status === 'Not Started' && !option.responses.length) {
                competencyResponses.push('Not started');
            }
            if (option.responses.length) {
                const highestResponseId = Math.max(...option.responses.map((obj) => obj.responses_id), 0);
                const lastResponse = option.responses.find((element) => element.responses_id === highestResponseId);
                const foundResponse = findResponseOptionByValue(lastResponse.value);
                if (foundResponse.primary_label === status) {
                    competencyResponses.push(foundResponse);
                }
            }
        });
        if (competencyResponses.length === 0) {
            setDisplayAccordion(false);
        } else {
            setDisplayAccordion(true);
        }
    };

    useEffect(() => {
        checkCompetency(statement);
        determineRender();
    }, [statement]);

    useEffect(() => {
        determineRender();
    }, [status]);

    return displayAccordion ? (
        <div ref={t} className={classes.root}>
            <header className={classes.header}>
                <Typography variant='h4' className={classes.accordionSummaryTitle}>
                    {competencyName} ({statement.level.name})
                </Typography>
                {competencyOptions < competencyOptionsMax ? (
                    <div className={classes.circleRoot}>
                        <CircularProgress
                            variant='determinate'
                            className={classes.bottom}
                            size={20}
                            thickness={4}
                            value={100}
                            aria-label='Progress bar base image'
                        />
                        <CircularProgress
                            variant='determinate'
                            className={classes.top}
                            classes={{
                                circle: classes.circle
                            }}
                            size={20}
                            thickness={4}
                            value={(competencyOptions / competencyOptionsMax) * 100}
                            aria-label={`${(competencyOptions / competencyOptionsMax) * 100}%`}
                        />
                        <Typography variant='body1' className={classes.heading}>
                            {competencyOptions}/{competencyOptionsMax}&nbsp;done
                        </Typography>
                    </div>
                ) : (
                    <div className={classes.circleRoot}>
                        <FontAwesomeIcon icon='check' className={classes.check} />
                        <Typography variant='body1' className={classes.heading}>
                            Completed
                        </Typography>
                    </div>
                )}
            </header>
            <div className={classes.accordionBody}>
                <div className={classes.goal}>
                    <Typography variant='body1'>Your goal:</Typography>
                    <q className={classes.statementTitle}>{parse(statement.competency_statement_statement)}</q>
                </div>
                <Typography variant='body1' className={classes.secondaryHeading}>
                    To achieve this goal, the following should be in place
                </Typography>

                {statement.competency_options &&
                    Object.values(statement.competency_options).map((option) => (
                        <GoalCompetencyCard
                            key={option.competency_option_id}
                            competencyOption={option}
                            refreshSurvey={refreshSurvey}
                            status={status}
                            notes={notes.filter((note) => note.competency_option_id === option.competency_option_id)}
                        />
                    ))}
            </div>
            <Accordion className={classes.accordionSummaryParent}>
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon className={classes.chevron} icon='chevron-down' />}
                    classes={{ expandIcon: classes.expandIcon }} // Override expandIcon styles
                    className={classes.accordionSummary}
                >
                    <Typography className={classes.accordionSummaryFooter}>Resources to help you with this</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div className={classes.resources}>
                        {statement.competency_statements_resources &&
                            statement.competency_statements_resources.length > 0 &&
                            statement.competency_statements_resources.map((resource) => {
                                return (
                                    <div key={resource.competency_statement_resource_id}>
                                        {parse(resource.resource_body)}
                                    </div>
                                );
                            })}

                        <Typography variant='body1'>Talk with Jisc to create further strategy</Typography>
                        <div className={classes.resourceLinkContact}>
                            <Typography variant='body1'>Contact</Typography>
                            <Typography variant='body1'>
                                <span>help@jisc.ac.uk</span>
                                <span>0300 300 2212</span>
                                <span>07:00 - 12 midnight (Mon - Fri)</span>
                            </Typography>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    ) : null;
}

GoalAccordion.propTypes = {
    competencyName: PropTypes.string.isRequired,
    statement: PropTypes.shape({
        competency_statement_id: PropTypes.number.isRequired,
        competency_statement_statement: PropTypes.string.isRequired,
        level: PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired
        }),
        useful_links: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                url: PropTypes.string
            })
        ),
        competency_statements_resources: PropTypes.arrayOf(
            PropTypes.shape({
                competency_statement_resource_id: PropTypes.number.isRequired,
                competency_statement_resource_competency_statement_id: PropTypes.number.isRequired,
                resource_body: PropTypes.string.isRequired
            })
        ),
        competency_options: PropTypes.shape({})
    }).isRequired,
    refreshSurvey: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(
        PropTypes.shape({
            competency_option_id: PropTypes.number,
            competency_title: PropTypes.string,
            content: PropTypes.string,
            created_at: PropTypes.string,
            created_by: PropTypes.string,
            statement: PropTypes.string,
            status: PropTypes.string,
            sub_theme_title: PropTypes.string,
            text: PropTypes.string,
            theme_title: PropTypes.string
        })
    ).isRequired
};
export default GoalAccordion;
