import { Typography } from '@material-ui/core';
import React from 'react';

import { getThemeIcon } from '../../lib/enums';

import styles from './IconLegend.styles';

const IconLegend = () => {
    const classes = styles();
    return (
        <section>
            <Typography variant='h5' component='h2'>
                Icon legend
            </Typography>
            <div className={classes.grid}>
                {new Array(5).fill(0).map((n, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i + 1}>
                        <Typography variant='body1'>ID: {i + 1}</Typography>
                        {getThemeIcon(i + 1)}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default IconLegend;
