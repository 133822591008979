import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '100%',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '25% auto'
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '16% auto'
        },
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: '13% auto'
        }
    },
    content: {
        padding: theme.spacing(4, 4, 8, 4),
        minHeight: '60vh',
        backgroundColor: theme.palette.jisc.greyLight
    },
    sidebar: {
        display: 'none',
        backgroundColor: theme.palette.jisc.grey,
        padding: theme.spacing(8.94, 4, 8, 4),
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    heading: {
        ...theme.typography.body1
    },
    menu: {
        listStyleType: 'none',
        padding: 0
    },
    menuItem: {
        borderRadius: '0.2rem',
        '& a': {
            backgroundColor: theme.palette.jisc.greyLight,
            color: theme.palette.blue,
            justifyContent: 'start',
            padding: theme.spacing(1, 2),
            margin: theme.spacing(2, 0),
            '& > span:first-child': {
                marginRight: theme.spacing(1.2)
            }
        },
        '&:hover': {
            backgroundColor: theme.palette.blue,
            '& a': {
                color: theme.palette.white
            }
        }
    },
    active: {
        '& a': {
            color: `${theme.palette.white} !important`,
            backgroundColor: `${theme.palette.blue} !important`
        }
    },
    menuItemButton: {
        position: 'relative',
        '& > span': {
            '&:empty': {
                display: 'none'
            }
        }
    },
    menuItemTag: {
        position: 'absolute',
        right: theme.spacing(1),
        fontSize: '0.75rem',
        color: theme.palette.jisc.error,
        textAlign: 'center',
        lineHeight: 1,
        borderRadius: '50%'
    }
}));

export default styles;
