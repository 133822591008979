import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@material-ui/core';

import keys from '../../config/keys';

import JiscButton from '../JiscButton/JiscButton';

import styles from './CookieAcceptance.styles';

const CookieAcceptance = () => {
    const classes = styles();
    const [cookie, setCookie] = useState();
    const [hotjar, setHotjar] = useState();
    const [matomo, setMatomo] = useState();

    const checkCookie = async () => {
        const cookieCheck = localStorage.getItem(keys.storage.cookie);
        if (cookieCheck) {
            setCookie(cookieCheck);
        }
        if (!cookieCheck) {
            setCookie(false);
        }
    };

    const storeCookie = async (cookieState) => {
        localStorage.setItem(keys.storage.cookie, cookieState);
        const cookieCheck = localStorage.getItem(keys.storage.cookie);
        setCookie(cookieCheck);
    };

    const checkEnvironment = async () => {
        let hotjarCode;
        let matomoCode;

        switch (process.env.REACT_APP_ENV) {
            case 'local':
                hotjarCode = '2635769';
                matomoCode = 27;
                break;
            case 'int':
                hotjarCode = '2635769';
                matomoCode = 27;
                break;
            case 'staging':
                hotjarCode = '2635769';
                matomoCode = 27;
                break;
            default:
                hotjarCode = '2635425';
                matomoCode = 27;
        }

        const hotjarScript = `(function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = { hjid: ${hotjarCode}, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`;

        const matomoScript = `var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
          var u="https://live.matomo.jisc.ac.uk/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '${matomoCode}']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();`;

        setHotjar(hotjarScript);
        setMatomo(matomoScript);
    };

    useEffect(() => {
        checkEnvironment();
        checkCookie();
    }, [cookie]);

    return (
        <>
            {cookie !== 'deny' && hotjar && matomo && (
                <Helmet>
                    <script>{hotjar}</script>
                    <script>{matomo}</script>
                </Helmet>
            )}

            {!cookie && (
                <div className={classes.root}>
                    <Container>
                        <div className={classes.grid}>
                            <Typography variant='body1'>
                                This site uses cookies. By continuing to browse the site, you are agreeing to our use of
                                cookies.
                            </Typography>
                            <div className={classes.buttonsDiv}>
                                <JiscButton
                                    className={classes.buttons}
                                    variant='ghost'
                                    onClick={() => storeCookie('accept')}
                                >
                                    OK
                                </JiscButton>
                                <JiscButton
                                    className={classes.buttons}
                                    variant='ghost'
                                    onClick={() => storeCookie('deny')}
                                >
                                    Deny
                                </JiscButton>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
        </>
    );
};

export default CookieAcceptance;
