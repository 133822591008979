import React from 'react';

import LeadershipGovernanceCulture from './assets/LeadershipGovernanceCulture';
import LearnerExperience from './assets/LearnerExperience';
import StaffExperience from './assets/StaffExperience';
import CurriculumDevelopment from './assets/CurriculumDevelopment';
import UnderpinningTechnologies from './assets/UnderpinningTechnologies';
import Foundation from './assets/Foundation';
import Transform from './assets/Transform';
import Elevate from './assets/Elevate';

export const user = {
    role: {
        JISC_ADMIN: 1,
        ADMIN: 2,
        USER: 3,
        JISC_REPORTER: 4,
        JISC_CONTENT: 5
    }
};

export const getLevelIcon = (levelId) => {
    switch (levelId) {
        case 1:
            return <Foundation />;
        case 2:
            return <Transform />;
        case 3:
            return <Elevate />;
        default:
            return 'Icon unavailable';
    }
};

export const getThemeIcon = (themeId) => {
    switch (themeId) {
        case 1:
            return <LeadershipGovernanceCulture />;
        case 2:
            return <LearnerExperience />;
        case 3:
            return <StaffExperience />;
        case 4:
            return <CurriculumDevelopment />;
        case 5:
            return <UnderpinningTechnologies />;
        default:
            return 'Icon unavailable';
    }
};
