/* eslint-disable no-nested-ternary */
import React from 'react';
import { bool, func } from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuthContext } from '../lib/hooks/useAuthContext';

import FullPageLayout from '../layout/FullPageLayout/FullPageLayout';

import urls from '../config/urls';

const PublicRoute = ({ component: Component, restricted, layout, ...rest }) => {
    const {
        auth: { token }
    } = useAuthContext();

    return (
        <Route
            {...rest}
            render={(props) =>
                token && restricted ? (
                    <Redirect to={urls.home.url} />
                ) : layout ? (
                    <FullPageLayout page={Component} {...props} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

PublicRoute.propTypes = {
    component: func.isRequired,
    restricted: bool,
    layout: bool
};

PublicRoute.defaultProps = {
    restricted: false,
    layout: true
};

export default PublicRoute;
