import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContentManagementContext } from '../../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../../components/JiscButton/JiscButton';
import Loader from '../../../../components/Loader/Loader';

import * as api from '../../../../lib/api';
import urls from '../../../../config/urls';
import styles from './NewSubTheme.styles';

let mounted;

const NewSubTheme = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const history = useHistory();

    // Data
    const [title, setTitle] = useState();
    const [titleError, setTitleError] = useState();

    const [description, setDescription] = useState();
    const [descriptionError, setDescriptionError] = useState();

    const [themeId, setThemeId] = useState();
    const [themeIdError, setThemeIdError] = useState();

    const [availableThemes, setAvailableThemes] = useState([]);

    // Error
    const [loading, setLoading] = useState();
    const [error, setError] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !description || !themeId) {
            if (!title) {
                setTitleError('You must supply a sub theme title.');
                return;
            }
            if (!themeId) {
                setThemeIdError('You must supply a parent theme');
                setTitleError(undefined);
                return;
            }
            if (!description) {
                setDescriptionError('You must supply a sub theme description.');
                setTitleError(undefined);
                setThemeIdError(undefined);
                return;
            }
        }

        setLoading(true);
        setTitleError(undefined);
        setThemeIdError(undefined);
        setDescriptionError(undefined);

        try {
            const { status } = await api.post(`/sub-themes`, {
                title,
                description,
                themeId
            });

            if (status !== 201) throw Error('There was a problem creating this sub theme.');

            setErrorMessage(undefined);
            setError(undefined);
            setLoading(false);
            history.push(urls.contentManagement.subThemes.url);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    const getAvailableSubThemes = async () => {
        try {
            if (mounted) {
                const { data } = await api.get('/themes?limit=999999');
                setErrorMessage(undefined);
                setError(undefined);
                setAvailableThemes(data.data);
            }
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        mounted = true;
        setActivePage(urls.contentManagement.subThemes.add);
        getAvailableSubThemes();

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <ContentManagementLayout>
            {error && errorMessage && (
                <Alert severity='error' className={classes.alert}>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            {!loading && (
                <>
                    <header className={classes.titleHeader}>
                        <Typography variant='h5' component='h1'>
                            {urls.contentManagement.subThemes.add.text}
                        </Typography>
                        <div>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='check' role='button' />}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Save
                            </JiscButton>
                            <JiscButton
                                isLink
                                linkType='internal'
                                href={urls.contentManagement.subThemes.url}
                                variant='ghost'
                                startIcon={<FontAwesomeIcon icon='arrow-alt-circle-left' role='button' />}
                            >
                                Back to all
                            </JiscButton>
                        </div>
                    </header>

                    <form id='new-theme' className={classes.form}>
                        <FormControl fullWidth>
                            <TextField
                                label='Title (Required)'
                                variant='outlined'
                                error={titleError}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            {titleError && <FormHelperText>{titleError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id='parent-theme'>Parent Theme (Required)</InputLabel>
                            <Select
                                labelId='parent-theme'
                                value={themeId}
                                label='Parent Theme'
                                error={themeIdError}
                                onChange={(e) => {
                                    setThemeId(e.target.value);
                                }}
                                inputProps={{
                                    ariaLabel: 'Parent Theme'
                                }}
                            >
                                {availableThemes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            {themeIdError && <FormHelperText>{themeIdError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                label='Description (Required)'
                                variant='outlined'
                                multiline
                                rows={4}
                                defaultValue={description}
                                onChange={(e) => setDescription(e.target.value)}
                                error={descriptionError}
                                value={description}
                            />
                            {descriptionError && <FormHelperText>{descriptionError}</FormHelperText>}
                        </FormControl>
                    </form>
                </>
            )}
            {loading && <Loader />}
        </ContentManagementLayout>
    );
};

export default NewSubTheme;
