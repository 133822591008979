import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(10, 0, 20, 0)
    },
    content: {
        display: 'grid'
    },
    contentSidebar: {},
    contentSection: {},
    contentSectionHeader: {
        display: 'grid',
        gridTemplateColumns: 'auto 30%',
        margin: theme.spacing(0, 0, 5, 0),
        '& button': {
            width: '100%'
        }
    },
    contentSectionHeaderTitles: {
        paddingRight: '20%',
        '& > *:first-child': {
            margin: theme.spacing(0, 0, 3.6, 0)
        }
    },
    primaryTitle: {
        fontSize: '3.5rem',
        color: theme.palette.fes.headline,
        fontWeight: '300'
    },
    contentSectionHeaderControls: {
        '& > *:first-child': {
            margin: theme.spacing(0, 0, 4, 0)
        }
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    organisationTableButton: {
        color: theme.palette.fes.navy,
        padding: 0,
        border: 0,
        float: 'right',
        minWidth: 'auto',
        marginRight: theme.spacing(2)
    },
    buttonDelete: {
        color: theme.palette.jisc.delete,
        '& svg': {
            color: theme.palette.jisc.delete
        }
    },
    tableHead: {
        backgroundColor: theme.palette.jisc.selectedGrey
    },
    tableRowOdd: {
        backgroundColor: theme.palette.jisc.greyLight
    }
}));

export default styles;
