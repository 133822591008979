import axios from 'axios';
import slugify from 'slugify';

import keys from '../config/keys';

let url;

switch (process.env.REACT_APP_ENV) {
    case 'local':
        url = 'http://localhost:4003/local';
        break;
    case 'test':
        url = 'http://test';
        break;
    case 'int':
        url = 'https://int.api.elevate.jisc.ac.uk';
        break;
    case 'staging':
        url = 'https://staging.api.elevate.jisc.ac.uk';
        break;
    case 'prod':
        url = 'https://api.elevate.jisc.ac.uk';
        break;
    default:
        url = 'http://localhost:4003/local';
        break;
}

/**
 * @description Create an axios get request
 * @param {String} path
 * @returns
 */
export const get = async (path) => {
    const res = await axios.get(`${url}${path}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.storage.token)}`
        }
    });
    return res;
};

/**
 * @description Create an axios delete request
 * @param {String} path
 * @returns
 */
export const del = async (path) => {
    const res = await axios.delete(`${url}${path}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.storage.token)}`
        }
    });
    return res;
};

/**
 * @description Create an axios post request
 * @param {String} path
 * @param {JSON} body
 * @param {Boolean} widthAuth
 * @returns
 */
export const post = async (path, body = null, withAuth = true) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.storage.token)}`
        }
    };

    const res = await axios.post(`${url}${path}`, body, withAuth && authHeader);
    return res;
};

/**
 * @description Create an axios post json request
 * @param {String} path
 * @param {JSON} body
 * @param {Boolean} widthAuth
 * @returns
 */
export const postJson = async (path, body = null, withAuth = true) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.storage.token)}`,
            'Content-Type': 'application/json'
        }
    };

    const res = await axios.post(`${url}${path}`, body, withAuth && authHeader);
    return res;
};

/**
 * @description Create an axios put request
 * @param {String} path
 * @param {JSON} body
 * @param {Boolean} widthAuth
 * @returns
 */
export const put = async (path, body = null, withAuth = true) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.storage.token)}`
        }
    };

    const res = await axios.put(`${url}${path}`, body, withAuth && authHeader);
    return res;
};

/**
 * @description Create an axios patch request
 * @param {String} path
 * @param {JSON} body
 * @param {Boolean} widthAuth
 * @returns
 */
export const patch = async (path, body = null, withAuth = true) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.storage.token)}`
        }
    };

    const res = await axios.patch(`${url}${path}`, body, withAuth && authHeader);
    return res;
};

/**
 * @description
 * @async
 * @param {string} path
 * @param {Object} body
 * @param {Object} queryParams
 * @returns
 */
export const login = async (path, body) => {
    const res = await axios.post(`${url}${path}`, body);
    return res;
};

export const getFile = async (path) => {
    const options = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(keys.storage.token)}`
        },
        responseType: 'blob',
        method: 'GET'
    };

    return axios.get(`${url}${path}`, options);
};

export const titleToSlug = (title) => slugify(title, { lower: true, trim: true, strict: true });

export const getAvailableThemes = async (institutionId) => {
    try {
        const { data } = await get(`/surveys/1/institution/${institutionId}/progress`);
        return data;
    } catch (err) {
        return [];
    }
};
