import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {},
    contentParent: {
        display: 'grid',
        columnGap: theme.spacing(2),
        gridTemplateColumns: 'repeat(2, 1fr)',

        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            '& > p': {
                display: 'flex',
                justifyContent: 'space-between',
                margin: 0,
                padding: theme.spacing(2, 0)
            }
        }
    },
    textarea: {
        border: `1px solid ${theme.palette.jisc.silver}`,
        borderRadius: '5px',
        padding: theme.spacing(2),
        width: '100%',
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.jisc.greySilver,
        lineHeight: 2,
        height: '100%'
    },
    preview: {
        border: `1px solid ${theme.palette.jisc.silver}`,
        borderRadius: '5px',
        padding: theme.spacing(0, 2),
        width: '100%',
        height: '100%'
    },
    previewMessage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    error: {
        borderColor: theme.palette.jisc.error
    }
}));

export default styles;
