import React from 'react';

import { useAuthContext } from '../../lib/hooks/useAuthContext';

import PrintLayout from '../../layout/PrintLayout/PrintLayout';

import NoteExport from '../../components/NoteExport/NoteExport';
import SiteHeader from '../../components/SiteHeader/SiteHeader';
import SiteFooter from '../../components/SiteFooter/SiteFooter';

import NotFound from '../NotFound/NotFound';

import * as enums from '../../lib/enums';

function ExportNotes() {
    const {
        auth: {
            user: { role }
        }
    } = useAuthContext();

    if (role === enums.user.role.JISC_ADMIN || role === enums.user.role.ADMIN) {
        return (
            <PrintLayout>
                <NoteExport />
            </PrintLayout>
        );
    }

    return (
        <>
            <SiteHeader />
            <NotFound />
            <SiteFooter />
        </>
    );
}

export default ExportNotes;
