// @ts-check

import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';

import JiscButton from '../../JiscButton/JiscButton';
import styles from './InstitutionEngagementReport.styles';
import { get } from '../../../lib/api';

/**
 * @type {function(): JSX.Element}
 * @returns {JSX.Element}
 */
function InstitutionEngagementReport() {
    const classes = styles();

    /** @type {[string|undefined, React.Dispatch<React.SetStateAction<string|undefined>>]} */
    const [start, setStart] = useState();
    /** @type {[string|undefined, React.Dispatch<React.SetStateAction<string|undefined>>]} */
    const [end, setEnd] = useState();
    /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
    const [loading, setLoading] = useState(false);
    /** @type {[string|undefined, React.Dispatch<React.SetStateAction<string|undefined>>]} */
    const [error, setError] = useState();

    /**
     * @type {function(): Promise<void>}
     * @returns {Promise<void>}
     */
    const onExport = async () => {
        setLoading(true);
        setError(undefined);
        try {
            let endpoint = '/report/institutions?type=engagement';

            if (!!start && !!end) {
                endpoint += `&start=${start}&end=${end}`;
            }

            const response = await get(endpoint);
            const { key } = response.data;
            if (key) {
                const { data } = await get(`/report/${key}`);
                const link = document.createElement('a');
                link.href = data;
                link.setAttribute('download', `${key}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (e) {
            setError('Something went wrong, please try again later');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Typography variant='body1' className={classes.p}>
                Institution engagement
            </Typography>
            <div className={classes.wrapper}>
                <JiscButton variant='primary' onClick={onExport} disabled={loading}>
                    Export results
                </JiscButton>
                <TextField
                    id='date'
                    label='Start date'
                    type='date'
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                <TextField
                    id='date'
                    label='End date'
                    type='date'
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </div>
            {!!error && (
                <Typography variant='body1' className={classes.error}>
                    {error}
                </Typography>
            )}
        </>
    );
}

export default InstitutionEngagementReport;
