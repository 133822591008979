import { makeStyles } from '@material-ui/core';
import { hexToRGB } from '../../lib/helpers';

const styles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        minHeight: '100vh',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'auto 30%'
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '98% 2%'
        }
    },
    main: {
        position: 'relative',
        '& > svg': {
            display: 'none',
            position: 'absolute',
            height: 'auto',
            width: '60%',
            bottom: '-0.188rem',
            left: '-10%',
            pointerEvents: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block'
            }
        }
    },
    contentArea: {
        position: 'relative',
        zIndex: 1,
        padding: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    sidebar: {
        position: 'relative',
        zIndex: 1,
        display: 'none',
        placeItems: 'center',
        overflow: 'auto',
        padding: theme.spacing(5),
        backgroundColor: ({ color }) => (color ? hexToRGB(color, 0.1) : 'rgba(0, 142, 130, 0.1)'),
        [theme.breakpoints.up('md')]: {
            display: 'grid'
        }
    }
}));

export default styles;
