import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Grid,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DateTime } from 'luxon';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContentManagementContext } from '../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../components/JiscButton/JiscButton';
import TableLoader from '../../../components/TableLoader/TableLoader';

import * as api from '../../../lib/api';
import urls from '../../../config/urls';
import styles from './SubThemes.styles';

let mounted;

const SubThemes = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const searchBox = useRef();

    // Loading & Error
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState();

    // data
    const [subThemes, setsubThemes] = useState([]);
    const [count, setCount] = useState(0);

    // filters
    const [surveyId] = useState();
    const [themeId] = useState();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState('');
    const [orderBy, setOrderBy] = useState('sub_theme_id');
    const [direction, setDirection] = useState('asc');
    const [debouncedSearch, setDebouncedSearch] = useState();

    const getAllsubThemes = async () => {
        setLoading(true);
        try {
            if (mounted) {
                const { data } = await api.get(
                    `/sub-themes?verbose=false&limit=${limit}&offset=${offset}&orderBy=${orderBy}&direction=${direction}&${
                        debouncedSearch ? `search=${debouncedSearch}` : `search=`
                    }&${surveyId ? `surveyId=${surveyId}` : ``}&${themeId ? `themeId=${themeId}` : ``}`
                );
                setsubThemes(data.data);
                setCount(data.count);

                setError(false);
                setErrorMessage(undefined);
                setLoading(false);
                searchBox.current.focus();
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.message);
            setLoading(false);
        }
    };

    const handleSort = (sortBy) => {
        setOrderBy(sortBy);
        const dir = direction === 'asc' ? 'desc' : 'asc';
        setDirection(dir);
        setOffset(0);
        getAllsubThemes();
    };

    const slowSearch = useCallback(
        debounce((_searchVal) => {
            setDebouncedSearch(_searchVal);
        }, 300),
        []
    );

    const updateSearch = (e) => {
        setSearch(e.target.value);
        slowSearch(e.target.value);
    };

    useEffect(() => {
        getAllsubThemes();
    }, [debouncedSearch, limit, offset]);

    useEffect(() => {
        mounted = true;
        setActivePage(urls.contentManagement.subThemes);
        getAllsubThemes();

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <ContentManagementLayout>
            <header className={classes.titleHeader}>
                <Typography variant='h5' component='h1'>
                    All {urls.contentManagement.subThemes.text}
                </Typography>

                <JiscButton
                    isLink
                    linkType='internal'
                    href='/admin/sub-themes/create'
                    variant='ghost'
                    startIcon={<FontAwesomeIcon icon='plus' role='button' />}
                >
                    Add sub theme
                </JiscButton>
            </header>

            {error && errorMessage && (
                <Alert severity='error'>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            <InputLabel htmlFor='search-input'>
                <TextField
                    id='search-input'
                    inputRef={searchBox}
                    variant='outlined'
                    placeholder='Search for sub theme'
                    fullWidth
                    value={search}
                    onChange={updateSearch}
                    className={classes.search}
                    disabled={loading}
                />
            </InputLabel>

            <TableContainer>
                <Table data-testid='organisation-settings-user-table'>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell className={classes.userTableButtonTitle}>
                                <TableSortLabel direction={direction} onClick={() => handleSort('sub_theme_id')}>
                                    ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.userTableButtonTitle}>
                                <TableSortLabel direction={direction} onClick={() => handleSort('sub_theme_title')}>
                                    Title
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.userTableButtonTitle}>
                                <TableSortLabel
                                    direction={direction}
                                    onClick={() => handleSort('sub_theme_description')}
                                >
                                    Description
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.userTableButtonTitle}>
                                <TableSortLabel direction={direction} onClick={() => handleSort('sub_theme_theme_id')}>
                                    Theme
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.userTableButtonTitle}>Survey</TableCell>
                            <TableCell className={classes.userTableButtonTitle}>
                                <TableSortLabel
                                    direction={direction}
                                    onClick={() => handleSort('sub_theme_created_at')}
                                >
                                    Created at
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.userTableButtonTitle}>
                                <TableSortLabel
                                    direction={direction}
                                    onClick={() => handleSort('sub_theme_updated_at')}
                                >
                                    Updated at
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.userTableButtonTitle}>Option</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && <TableLoader rows={10} columns={8} />}
                        {!loading &&
                            !error &&
                            subThemes &&
                            subThemes.map((subTheme, index) => (
                                <TableRow
                                    key={subTheme.id}
                                    className={index % 2 !== 0 ? classes.tableRowOdd : undefined}
                                >
                                    <TableCell>{subTheme.id}</TableCell>
                                    <TableCell>
                                        <span className={classes.textRow}>{subTheme.title}</span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={classes.textRow}>{subTheme.description}</span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={classes.textRow}>{subTheme.theme.title}</span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={classes.textRow}>{subTheme.survey.title}</span>
                                    </TableCell>
                                    <TableCell>{DateTime.fromISO(subTheme.created_at).toFormat('DD')}</TableCell>
                                    <TableCell>{DateTime.fromISO(subTheme.updated_at).toFormat('DD')}</TableCell>
                                    <TableCell>
                                        <JiscButton
                                            isLink
                                            linkType='internal'
                                            href={`/admin/sub-themes/${subTheme.id}`}
                                            variant='ghost'
                                            startIcon={<FontAwesomeIcon icon='edit' role='button' />}
                                            className={classes.manageButton}
                                        >
                                            Edit
                                        </JiscButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container>
                <Grid item xs={12} md={6} />
                <Grid item md={6}>
                    <TablePagination
                        rowsPerPageOptions={[1, 5, 10, 15, 20, 25, 50]}
                        component='div'
                        count={parseInt(count, 10)}
                        rowsPerPage={limit}
                        page={Math.floor(offset / limit)}
                        onChangePage={(_, page) => {
                            setOffset(page * limit);
                        }}
                        onChangeRowsPerPage={(event) => {
                            setLimit(event.target.value);
                        }}
                    />
                </Grid>
            </Grid>
        </ContentManagementLayout>
    );
};

export default SubThemes;
