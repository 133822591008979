import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    container: {
        maxHeight: '50vh',
        overflow: 'auto'
    },
    note: {
        background: '#FAFAFA',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
        padding: '16px',
        marginBottom: '2rem'
    },
    info: {
        display: 'flex',
        alignItems: 'baseline',
        fontSize: '14px',
        fontWeight: '400',
        '& > *': {
            marginRight: '21px'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& > *': {
                margin: '0 0 10px 0'
            }
        }
    },
    noNotesInfo: {
        textAlign: 'center',
        fontSize: '24px',
        padding: '72px'
    },
    date: {
        color: 'rgba(0, 0, 0, 0.6)'
    },
    content: {
        margin: '10px 0 5px 0',
        fontSize: '1rem',
        [theme.breakpoints.up('sm')]: {
            padding: '0 20px 0 0'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.90rem'
        }
    },
    spinner: {
        textAlign: 'center',
        padding: '72px'
    },
    textField: {
        marginTop: '2rem'
    },
    noteDialogActions: {
        display: 'flex',
        justifyContent: ({ showAddNote }) => (showAddNote ? 'space-between' : 'flex-end'),
        marginTop: theme.spacing(2)
    },
    closeButton: {
        padding: '8px 22px',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '26px' /* 173.333% */,
        letterSpacing: '0.46px',
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
    },
    addNoteButton: {
        padding: '8px 22px',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '26px' /* 173.333% */,
        letterSpacing: '0.46px',
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
}));

export default styles;
