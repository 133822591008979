// @ts-check

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputLabel, TextField, Typography, Container } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

import JiscButton from '../../components/JiscButton/JiscButton';

import * as api from '../../lib/api';
import urls from '../../config/urls';
import styles from './Glossary.styles';

/** @type {Record<string, any[]>} */
const alpha = {
    A: [],
    B: [],
    C: [],
    D: [],
    E: [],
    F: [],
    G: [],
    H: [],
    I: [],
    J: [],
    K: [],
    L: [],
    M: [],
    N: [],
    O: [],
    P: [],
    Q: [],
    R: [],
    S: [],
    T: [],
    U: [],
    V: [],
    W: [],
    X: [],
    Y: [],
    Z: [],
    misc: []
};

const clean = (data) => {
    const alphab = structuredClone(alpha);

    data.forEach((resource) => {
        /** @type {string} A | B | C */
        const char = resource.title.charAt(0).toUpperCase();

        // regex to check if char is not a letter
        if (!/[A-Z]/.test(char)) {
            alphab.misc.push(resource);
            return;
        }

        alphab[char].push(resource);
    });

    return alphab;
};

function Glossary() {
    const classes = styles();

    /** @type {React.MutableRefObject<Record<string, any[]>|undefined>} */
    const originalData = useRef(undefined);

    /** @type {[Record<string, any[]>, React.Dispatch<React.SetStateAction<Record<string, any[]>>>]} */
    const [filteredAlphabet, setFilteredAlphabet] = useState(alpha);
    /** @type {[string|undefined, React.Dispatch<React.SetStateAction<string|undefined>>]} */
    const [activeAlphabetLetter, setActiveAlphabetLetter] = useState();

    // Loading & Error
    /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
    const [loading, setLoading] = useState(false);
    /** @type {[string|undefined, React.Dispatch<React.SetStateAction<string|undefined>>]} */
    const [error, setError] = useState();

    /** @async */
    const getAllResources = useCallback(async () => {
        setError(undefined);
        setLoading(true);
        try {
            const { data } = await api.get('/resources?limit=999999');
            const structure = clean(data.data);

            originalData.current = structure;
            setFilteredAlphabet(structure);
        } catch (err) {
            setError(err.message);
        } finally {
            setTimeout(() => setLoading(false), 1000);
        }
    }, []);

    /** @param {string} query @param {string|undefined} activeLetter */
    const updateSearch = (query, activeLetter) => {
        setLoading(true);

        /** @type Record<string, any[]> */
        // @ts-expect-error
        let filtered = originalData.current;

        if (query === '' && !activeLetter) {
            setFilteredAlphabet(filtered);
            setTimeout(() => setLoading(false), 1000);
            return;
        }

        if (activeLetter !== undefined) {
            /** @type Record<string, any[]> */
            const temp = {};
            temp[activeLetter] = filtered[activeLetter];
            filtered = temp;
        }

        /** @type Record<string, any[]> */
        const createFiltered = {};

        Object.entries(filtered).forEach(([letter, resources]) => {
            const filterd = resources.filter((resource) => resource.title.toLowerCase().includes(query.toLowerCase()));
            if (filterd.length === 0) return;
            createFiltered[letter] = filterd;
        });

        setFilteredAlphabet(createFiltered);
        setTimeout(() => setLoading(false), 1000);
    };

    useEffect(() => {
        getAllResources();
    }, [getAllResources]);

    useEffect(() => {
        setTimeout(() => {
            document.querySelectorAll('#resources a').forEach((anchor) => {
                anchor.setAttribute('target', '_blank');
                anchor.setAttribute('rel', 'noopener noreferrer');
            });
        }, 500);
    }, []);

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.about.glossary}</title>
                <link rel='canonical' href={urls.base.url + urls.glossary.url} />
            </Helmet>

            <main>
                <section>
                    <Container>
                        <header className={classes.titleHeader}>
                            <Typography variant='h1' component='h1'>
                                Resources
                            </Typography>
                        </header>
                    </Container>
                </section>
                <section>
                    <Container>
                        <article className={classes.letterGrid}>
                            {Object.keys(alpha).map((letter) => (
                                <JiscButton
                                    key={letter}
                                    variant='ghost'
                                    onClick={(e) => {
                                        const matchedLetter = letter === activeAlphabetLetter ? undefined : letter;
                                        if (matchedLetter === undefined) {
                                            e.target.blur();
                                        }
                                        setActiveAlphabetLetter(matchedLetter);
                                        updateSearch('', matchedLetter);
                                    }}
                                    type='button'
                                    className={activeAlphabetLetter === letter ? classes.activeLetter : null}
                                >
                                    {letter.charAt(0).toUpperCase() + letter.slice(1)}
                                </JiscButton>
                            ))}
                        </article>
                    </Container>
                </section>
                <section>
                    <Container>
                        <InputLabel htmlFor='search-input'>
                            <TextField
                                id='search-input'
                                variant='outlined'
                                placeholder='Search for resources by title'
                                fullWidth
                                onChange={(e) => updateSearch(e.target.value, activeAlphabetLetter)}
                                className={classes.search}
                            />
                        </InputLabel>
                    </Container>
                </section>
                <section id='resources'>
                    <Container>
                        {loading && (
                            <div className={classes.loading}>
                                <Typography variant='h4' component='h2'>
                                    Loading...
                                </Typography>
                            </div>
                        )}

                        {!loading && error && (
                            <div className={classes.loading}>
                                <Typography variant='h4' component='h2'>
                                    {error}
                                </Typography>
                            </div>
                        )}

                        {!loading && !error && (
                            <div>
                                {Object.keys(filteredAlphabet).length > 0 ? (
                                    Object.entries(filteredAlphabet).map(([letter, resources]) => (
                                        <div key={letter}>
                                            <article>
                                                <h2>{letter.charAt(0).toUpperCase() + letter.slice(1)}</h2>
                                            </article>
                                            {resources.map((resource) => (
                                                <div className={classes.parentDivBoth} key={resource.id}>
                                                    <div className={classes.resourceTitle}>
                                                        <Typography variant='h5' component='h2'>
                                                            {resource.title}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.resourceBody}>{parse(resource.body)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                ) : (
                                    <div className={classes.noResults}>
                                        <Typography variant='h4' component='h2'>
                                            No results
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        )}
                    </Container>
                </section>
            </main>
        </>
    );
}

export default Glossary;
