import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    p: {
        padding: theme.spacing(0, 0, 2)
    },
    wrapper: {
        display: 'flex',
        gap: theme.spacing(3)
    },
    error: {
        marginTop: theme.spacing(2)
    }
}));

export default styles;
