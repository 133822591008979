import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';

import JiscLogoLarge from '../../lib/assets/JiscLogoLarge';

import urls from '../../config/urls';
import styles from './SiteFooter.styles';

function SiteFooter() {
    const classes = styles();
    return (
        <footer className={classes.root}>
            <section className={classes.content}>
                <Container maxWidth='lg'>
                    <div className={classes.contentGrid}>
                        <div>
                            <JiscLogoLarge />
                        </div>
                        <div>
                            <p className={classes.footerColumnTitle}>Digital Elevation Tool</p>
                            <Typography variant='body1'>
                                Jisc&apos;s vision: for the UK to be a world leader in technology for education and
                                research
                            </Typography>
                            <a
                                href='https://www.jisc.ac.uk'
                                target='_blank'
                                rel='noreferrer'
                                className={classes.footerLink}
                            >
                                <Typography variant='body1'>Jisc.ac.uk</Typography>
                            </a>
                        </div>
                        <div>
                            <p className={classes.footerColumnTitle}>Explore</p>
                            <Link to={urls.profile.url} className={classes.footerLink}>
                                <Typography variant='body1'>Profile</Typography>
                            </Link>
                            <Link to={urls.about.url} className={classes.footerLink}>
                                <Typography variant='body1'>About</Typography>
                            </Link>
                            <Link to={urls.help.url} className={classes.footerLink}>
                                <Typography variant='body1'>Help</Typography>
                            </Link>
                        </div>
                        <div>
                            <p className={classes.footerColumnTitle}>Contact</p>
                            <a
                                href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                                target='_blank'
                                rel='noreferrer'
                                className={classes.footerLink}
                            >
                                <Typography variant='body1'>help@jisc.ac.uk</Typography>
                            </a>
                            <Typography variant='body1'>9am - 5pm (Mon - Fri)</Typography>
                        </div>
                    </div>
                </Container>
            </section>

            <section className={classes.socket}>
                <Container maxWidth='lg'>
                    <div className={classes.socketGrid}>
                        <ul className={classes.socketLinkGrid}>
                            <li>
                                <Link to={urls.accessibility.url} className={classes.socketLink}>
                                    <Typography variant='body1'>Accessibility</Typography>
                                </Link>
                            </li>

                            <li>
                                <Link to={urls.cookies.url} className={classes.socketLink}>
                                    <Typography variant='body1'>Cookies</Typography>
                                </Link>
                            </li>
                            <li>
                                <Link to={urls.privacy.url} className={classes.socketLink}>
                                    <Typography variant='body1'>Privacy</Typography>
                                </Link>
                            </li>
                        </ul>
                        <Typography variant='body1'>v2.5.0</Typography>
                    </div>
                </Container>
            </section>
        </footer>
    );
}

export default SiteFooter;
