import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Container,
    Typography,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    Grid,
    TableBody,
    TextField,
    TablePagination,
    Button,
    Grow,
    Select,
    MenuItem,
    Menu,
    ListItemIcon,
    Paper,
    Popper,
    ClickAwayListener,
    InputLabel,
    FormControl
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { debounce } from 'lodash';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import NotFound from '../NotFound/NotFound';
import JiscButton from '../../components/JiscButton/JiscButton';
import CustomModal from '../../components/CustomModal/CustomModal';

import * as enums from '../../lib/enums';
import * as api from '../../lib/api';
import urls from '../../config/urls';
import styles from './OrganisationsOverview.styles';

let mounted;

function OrganisationsOverview() {
    const classes = styles();
    const history = useHistory();
    const { auth } = useAuthContext();

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [institutions, setInstitutions] = useState({
        count: 0,
        institutions: []
    });
    const [selectedInstitution, setSelectedInstitution] = useState({});
    const [error, setError] = useState(false); // error is api fails for loading orgs, error on page?

    const [createOrganisationModalState, setCreateOrganisationModalState] = useState(false);
    const [editOrganisationModalState, setEditOrganisationModalState] = useState(false);
    const [deleteInstitutionModalState, setDeleteInstitutionModalState] = useState(false);

    const [organisationName, setOrganisationName] = useState('');
    const [organisationFLA, setOrganisationFLA] = useState('');
    const [organisationSLA, setOrganisationSLA] = useState('');
    const [organisationPC, setOrganisationPC] = useState('');
    const [organisationAdmins, setOrganisationAdmins] = useState('');
    const [UKPRN, setUKPRN] = useState('');
    const [JOID, setJOID] = useState('');
    const [nation, setNation] = useState('');

    const [newOrganisationName, setNewOrganisationName] = useState('');
    const [newOrganisationFLA, setNewOrganisationFLA] = useState('');
    const [newOrganisationSLA, setNewOrganisationSLA] = useState('');
    const [newOrganisationPC, setNewOrganisationPC] = useState('');
    const [newUKPRN, setNewUKPRN] = useState('');
    const [newJOID, setNewJOID] = useState('');
    const [newNation, setNewNation] = useState('');

    const [createOrganisationError, setCreateOrganisationError] = useState(false);
    const [createOrganisationErrorMessage, setCreateOrganisationErrorMessage] = useState(false);
    const [editOrganisationError, setEditOrganisationError] = useState(false);
    const [editOrganisationErrorMessage, setEditOrganisationErrorMessage] = useState(false);
    const [deleteInstitutionError, setDeleteInstitutionError] = useState(false);
    const [deleteInstitutionErrorMessage, setDeleteInstitutionErrorMessage] = useState(false);

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuAnchorRef = useRef(null);

    const slowSearch = useCallback(
        debounce((_searchVal) => {
            setDebouncedSearch(_searchVal);
        }, 300),
        []
    );

    const updateSearch = (e) => {
        setSearch(e.target.value);
        slowSearch(e.target.value);
    };

    const handleMenuOpen = (event, institution) => {
        setSelectedInstitution(institution);
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleMenuClose = async () => {
        setMenuOpen(false);
    };

    const handleMenuListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    };

    const getInsitutions = async () => {
        setLoading(true);
        try {
            if (mounted) {
                const { data: institutionData } = await api.get(
                    `/institutions?limit=${limit}&offset=${offset}&search=${search}`
                );
                setInstitutions(institutionData);

                setError(false);
                setLoading(false);
            }
        } catch (err) {
            setError(true);
            setLoading(false);
        }
    };

    const createOrganisation = async () => {
        // remove empty string admins caused by user error
        const sanitisedAdmins = organisationAdmins.split(',').filter((e) => String(e).trim());

        try {
            await api.post('/institutions', {
                name: organisationName,
                addressLineOne: organisationFLA,
                addressLineTwo: organisationSLA,
                postalCode: organisationPC,
                joid: JOID,
                ukprn: UKPRN,
                admins: sanitisedAdmins.map((email) => email.trim().toLowerCase()),
                nation
            });

            setCreateOrganisationError(false);
            setCreateOrganisationModalState(false);
            await getInsitutions();
        } catch (err) {
            setCreateOrganisationErrorMessage(err.message);
            setCreateOrganisationError(true);
        }
    };

    const modifyOrganisation = async () => {
        try {
            await api.put(`/institutions/${selectedInstitution.id}`, {
                name: newOrganisationName,
                addressLineOne: newOrganisationFLA,
                addressLineTwo: newOrganisationSLA,
                postalCode: newOrganisationPC,
                joid: newJOID,
                ukprn: newUKPRN,
                nation: newNation
            });

            setEditOrganisationError(false);
            setEditOrganisationModalState(false);
            await getInsitutions();
        } catch (err) {
            setEditOrganisationErrorMessage(err.message);
            setEditOrganisationError(true);
        }
    };

    const deleteInsitution = async () => {
        try {
            await api.del(`/institutions/${selectedInstitution.id}`);

            setDeleteInstitutionError(false);
            setDeleteInstitutionModalState(false);
            await getInsitutions();
        } catch (err) {
            setDeleteInstitutionErrorMessage(err.message);
            setDeleteInstitutionError(true);
        }
    };

    useEffect(() => {
        if (selectedInstitution) {
            setNewOrganisationName(selectedInstitution.name);
            setNewOrganisationFLA(selectedInstitution.address_line_one);
            setNewOrganisationSLA(selectedInstitution.address_line_two);
            setNewOrganisationPC(selectedInstitution.postal_code);
            setNewUKPRN(selectedInstitution.ukprn);
            setNewJOID(selectedInstitution.joid);
            setNewNation(selectedInstitution.nation);
        }
    }, [editOrganisationModalState]);

    useEffect(() => {
        mounted = true;
        getInsitutions();

        return () => {
            mounted = false;
        };
    }, [limit, offset, debouncedSearch]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.organisationsOverview.title}</title>
                <link rel='canonical' href={urls.base.url + urls.organisationsOverview.url} />
            </Helmet>
            {auth.user.role === enums.user.role.JISC_ADMIN ? (
                <>
                    <main data-testid='organisations-overview-page' className={classes.root}>
                        <Container maxWidth='lg'>
                            <div className={classes.content}>
                                <section className={classes.contentSection}>
                                    <header className={classes.contentSectionHeader}>
                                        <div className={classes.contentSectionHeaderTitles}>
                                            <Typography className={classes.primaryTitle} variant='h1'>
                                                {urls.organisationsOverview.text}
                                            </Typography>
                                            <Typography variant='body2'>
                                                You are able to manage organisations that will be able to use the
                                                Digital Elevation Tool.
                                            </Typography>
                                        </div>
                                        <div className={classes.contentSectionHeaderControls}>
                                            <JiscButton
                                                variant='ghost'
                                                startIcon={<FontAwesomeIcon icon='users' role='button' />}
                                                onClick={() => setCreateOrganisationModalState(true)}
                                            >
                                                Create organisation
                                            </JiscButton>

                                            <InputLabel htmlFor='search-input'>
                                                <TextField
                                                    id='search-input'
                                                    variant='outlined'
                                                    placeholder='Search for a organisation'
                                                    fullWidth
                                                    value={search}
                                                    onChange={updateSearch}
                                                    className={classes.textField}
                                                />
                                            </InputLabel>
                                        </div>
                                    </header>
                                    <TableContainer>
                                        <Table>
                                            <TableHead className={classes.tableHead}>
                                                <TableRow>
                                                    <TableCell>UID</TableCell>
                                                    <TableCell>JOID</TableCell>
                                                    <TableCell>UKPRN</TableCell>
                                                    <TableCell>Organisation Name</TableCell>
                                                    <TableCell>Date Created</TableCell>
                                                    <TableCell>Date Updated</TableCell>
                                                    <TableCell aria-label='Options' />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading &&
                                                    new Array(limit).fill(0).map((item, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <TableRow key={item + index}>
                                                            <TableCell>
                                                                <Skeleton
                                                                    data-testid={`skeleton-uid-${index}`}
                                                                    variant='rect'
                                                                    height={20}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton
                                                                    data-testid={`skeleton-joid-${index}`}
                                                                    variant='rect'
                                                                    height={20}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton
                                                                    data-testid={`skeleton-ukprn-${index}`}
                                                                    variant='rect'
                                                                    height={20}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton
                                                                    data-testid={`skeleton-name-${index}`}
                                                                    variant='rect'
                                                                    height={20}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton
                                                                    data-testid={`skeleton-updated-${index}`}
                                                                    variant='rect'
                                                                    height={20}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton
                                                                    data-testid={`skeleton-created-${index}`}
                                                                    variant='rect'
                                                                    height={20}
                                                                />
                                                            </TableCell>
                                                            <TableCell width='10%'>
                                                                <Skeleton
                                                                    data-testid={`skeleton-options-${index}`}
                                                                    variant='rect'
                                                                    height={20}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                {!loading &&
                                                    !error &&
                                                    institutions &&
                                                    institutions.institutions.map((institution, index) => (
                                                        <TableRow
                                                            key={institution.id}
                                                            className={
                                                                index % 2 !== 0 ? classes.tableRowOdd : undefined
                                                            }
                                                        >
                                                            <TableCell data-testid={`uid-${index}`}>
                                                                {institution.id}
                                                            </TableCell>
                                                            <TableCell data-testid={`joid-${index}`}>
                                                                {institution.joid}
                                                            </TableCell>
                                                            <TableCell data-testid={`ukprn-${index}`}>
                                                                {institution.ukprn}
                                                            </TableCell>
                                                            <TableCell data-testid={`name-${index}`}>
                                                                {institution.name}
                                                            </TableCell>
                                                            <TableCell data-testid={`created-${index}`}>
                                                                {DateTime.fromISO(institution.created_at).toFormat(
                                                                    'DD'
                                                                )}
                                                            </TableCell>
                                                            <TableCell data-testid={`updated-${index}`}>
                                                                {DateTime.fromISO(institution.updated_at).toFormat(
                                                                    'DD'
                                                                )}
                                                            </TableCell>
                                                            <TableCell data-testid={`options-${index}`}>
                                                                <Button
                                                                    data-testid={`organisation-table-button-${index}`}
                                                                    ref={menuAnchorRef}
                                                                    title={`organisation-table-button-${index}`}
                                                                    aria-controls={
                                                                        menuOpen
                                                                            ? 'organisation-table-button'
                                                                            : undefined
                                                                    }
                                                                    aria-haspopup='true'
                                                                    onClick={(e) => handleMenuOpen(e, institution)}
                                                                    variant='outlined'
                                                                    className={classes.organisationTableButton}
                                                                >
                                                                    <FontAwesomeIcon icon='ellipsis-h' role='button' />
                                                                </Button>

                                                                <Popper
                                                                    open={menuOpen}
                                                                    anchorEl={menuAnchorRef.current}
                                                                    role={undefined}
                                                                    transition
                                                                    disablePortal
                                                                    className={classes.dropDownMenu}
                                                                >
                                                                    {({ TransitionProps }) => (
                                                                        <Grow {...TransitionProps}>
                                                                            <Paper>
                                                                                <ClickAwayListener
                                                                                    onClickAway={handleMenuClose}
                                                                                >
                                                                                    <Menu
                                                                                        data-testid={`organisation-table-menu-${index}`}
                                                                                        autofocusitem={menuOpen.toString()}
                                                                                        id={`menu-${institution.id}`}
                                                                                        onKeyDown={
                                                                                            handleMenuListKeyDown
                                                                                        }
                                                                                        anchorEl={anchorEl}
                                                                                        keepMounted
                                                                                        open={
                                                                                            selectedInstitution ===
                                                                                            institution
                                                                                        }
                                                                                    >
                                                                                        <MenuItem
                                                                                            key='organisation-table-manage'
                                                                                            data-testid={`organisation-table-manage-${index}`}
                                                                                            role='link'
                                                                                            onClick={() =>
                                                                                                history.push(
                                                                                                    `/organisation/${institution.id}`
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <ListItemIcon>
                                                                                                <FontAwesomeIcon icon='eye' />
                                                                                            </ListItemIcon>
                                                                                            Manage organisation
                                                                                        </MenuItem>
                                                                                        <MenuItem
                                                                                            key='organisation-table-edit'
                                                                                            data-testid={`organisation-table-edit-${index}`}
                                                                                            onClick={() =>
                                                                                                setEditOrganisationModalState(
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <ListItemIcon>
                                                                                                <FontAwesomeIcon icon='users' />
                                                                                            </ListItemIcon>
                                                                                            Edit details
                                                                                        </MenuItem>
                                                                                        <MenuItem
                                                                                            key='organisation-table-remove'
                                                                                            data-testid={`organisation-table-remove-${index}`}
                                                                                            className={
                                                                                                classes.buttonDelete
                                                                                            }
                                                                                            onClick={() =>
                                                                                                setDeleteInstitutionModalState(
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <ListItemIcon>
                                                                                                <FontAwesomeIcon icon='users-slash' />
                                                                                            </ListItemIcon>
                                                                                            Remove organisation
                                                                                        </MenuItem>
                                                                                    </Menu>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid container>
                                        <Grid item xs={12} md={6} />
                                        <Grid item md={6}>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                                                component='div'
                                                count={parseInt(institutions.count, 10)}
                                                rowsPerPage={limit}
                                                page={Math.floor(offset / limit)}
                                                onChangePage={(_, page) => {
                                                    setOffset(page * limit);
                                                }}
                                                onChangeRowsPerPage={(event) => {
                                                    setLimit(event.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </section>
                            </div>
                        </Container>
                    </main>

                    {/* create organisation modal */}
                    <CustomModal
                        testId='create-organisation-modal'
                        dialogTitle='create-organisation'
                        title='Create a new organisation'
                        open={createOrganisationModalState}
                        onClose={() => {
                            setCreateOrganisationModalState(false);
                            setOrganisationName('');
                            setUKPRN('');
                            setJOID('');
                            setOrganisationFLA('');
                            setOrganisationSLA('');
                            setOrganisationPC('');
                            setOrganisationAdmins('');
                            setNation('');
                        }}
                        buttonText='Create organisation'
                        successCallback={() => {
                            createOrganisation();
                            setOrganisationName('');
                            setUKPRN('');
                            setJOID('');
                            setOrganisationFLA('');
                            setOrganisationSLA('');
                            setOrganisationPC('');
                            setOrganisationAdmins('');
                            setNation('');
                        }}
                        error={createOrganisationError}
                        errorMessage={createOrganisationErrorMessage}
                    >
                        <TextField
                            variant='outlined'
                            label='Organisation name'
                            fullWidth
                            value={organisationName}
                            onChange={(e) => setOrganisationName(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='UKPRN'
                            fullWidth
                            value={UKPRN}
                            onChange={(e) => setUKPRN(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='JOID'
                            fullWidth
                            value={JOID}
                            onChange={(e) => setJOID(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='Address line 1'
                            fullWidth
                            value={organisationFLA}
                            onChange={(e) => setOrganisationFLA(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='Address line 2'
                            fullWidth
                            value={organisationSLA}
                            onChange={(e) => setOrganisationSLA(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='Postcode'
                            fullWidth
                            value={organisationPC}
                            onChange={(e) => setOrganisationPC(e.target.value)}
                            className={classes.textField}
                        />

                        <FormControl fullWidth variant='outlined' className={classes.formControl}>
                            <InputLabel id='nation'>Nation</InputLabel>
                            <Select
                                labelId='nation'
                                label='Nation'
                                variant='outlined'
                                onChange={(e) => setNation(e.target.value)}
                            >
                                <MenuItem value='England'>England</MenuItem>
                                <MenuItem value='Northern Ireland'>Northern Ireland</MenuItem>
                                <MenuItem value='Scotland'>Scotland</MenuItem>
                                <MenuItem value='Wales'>Wales</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            variant='outlined'
                            label='Inital Admins (comma seperated list)'
                            fullWidth
                            value={organisationAdmins}
                            onChange={(e) => setOrganisationAdmins(e.target.value)}
                            className={classes.textField}
                            multiline
                            rows={5}
                        />
                    </CustomModal>
                    {/* edit organisation modal */}
                    <CustomModal
                        testId='edit-organisation-modal'
                        dialogTitle='edit-organisation'
                        title={`Edit Organisation (${selectedInstitution.name})`}
                        open={editOrganisationModalState}
                        onClose={() => {
                            setEditOrganisationModalState(false);
                        }}
                        buttonText='Save changes'
                        successCallback={() => modifyOrganisation()}
                        error={editOrganisationError}
                        errorMessage={editOrganisationErrorMessage}
                    >
                        <TextField
                            variant='outlined'
                            label='Organisation name'
                            fullWidth
                            value={newOrganisationName}
                            onChange={(e) => setNewOrganisationName(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='UKPRN'
                            fullWidth
                            value={newUKPRN}
                            onChange={(e) => setNewUKPRN(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='JOID'
                            fullWidth
                            value={newJOID}
                            onChange={(e) => setNewJOID(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='Add address line 1'
                            fullWidth
                            value={newOrganisationFLA}
                            onChange={(e) => setNewOrganisationFLA(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='Second line of address'
                            fullWidth
                            value={newOrganisationSLA}
                            onChange={(e) => setNewOrganisationSLA(e.target.value)}
                            className={classes.textField}
                        />
                        <TextField
                            variant='outlined'
                            label='Post code'
                            fullWidth
                            value={newOrganisationPC}
                            onChange={(e) => setNewOrganisationPC(e.target.value)}
                            className={classes.textField}
                        />
                        <FormControl fullWidth variant='outlined' className={classes.formControl}>
                            <InputLabel id='nation'>Nation</InputLabel>
                            <Select
                                labelId='nation'
                                label='Nation'
                                variant='outlined'
                                defaultValue={newNation}
                                onChange={(e) => setNewNation(e.target.value)}
                            >
                                <MenuItem value='England'>England</MenuItem>
                                <MenuItem value='Northern Ireland'>Northern Ireland</MenuItem>
                                <MenuItem value='Scotland'>Scotland</MenuItem>
                                <MenuItem value='Wales'>Wales</MenuItem>
                            </Select>
                        </FormControl>
                    </CustomModal>
                    {/* delete organisation modal */}
                    <CustomModal
                        testId='remove-organisation-modal'
                        dialogTitle='delete-institution'
                        title={`Are you sure you want to delete ${selectedInstitution.name}`}
                        open={deleteInstitutionModalState}
                        onClose={() => {
                            setDeleteInstitutionModalState(false);
                            setOrganisationName('');
                            setUKPRN('');
                            setJOID('');
                            setOrganisationFLA('');
                            setOrganisationPC('');
                            setNation('');
                        }}
                        buttonText='Delete organisation'
                        successCallback={() => deleteInsitution()}
                        error={deleteInstitutionError}
                        errorMessage={deleteInstitutionErrorMessage}
                    />
                </>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default OrganisationsOverview;
