import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {},
    statement: {
        ...theme.typography.h5,
        display: 'block',
        margin: 'auto',
        color: theme.palette.fes.headline,
        lineHeight: 1.8,
        textAlign: 'center',
        '& p': {
            margin: 0,
            display: 'inline'
        }
    },
    statementResponse: {
        ...theme.typography.h5,
        display: 'block',
        margin: 'auto',
        color: theme.palette.fes.headline,
        lineHeight: 1.8,
        textAlign: 'right',
        '& p': {
            margin: 0,
            display: 'inline'
        }
    },
    responseContainer: {
        display: 'grid',
        gap: theme.spacing(2),
        margin: theme.spacing(10, 0),
        gridTemplateColumns: 'auto',
        [theme.breakpoints.up('lg')]: {
            gap: theme.spacing(5),
            margin: theme.spacing(5, 0),
            gridTemplateColumns: 'repeat( auto-fit, minmax(9.375rem, 1fr) );'
        }
    },
    dialog: {
        borderRadius: '1rem'
    },
    diaTitle: {
        padding: theme.spacing(4, 0, 0, 4)
    },
    title: {
        fontWeight: 400
    },
    dialogContent: {
        overflowY: 'hidden',
        padding: theme.spacing(3, 4)
    },
    dialogActions: {
        padding: theme.spacing(2, 4, 5, 0)
    }
}));

export default styles;
