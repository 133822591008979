import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionSummary, Typography, Chip, AccordionDetails, acc } from '@material-ui/core';
import { Alert } from '@mui/material';

import { get, post } from '../../lib/api';

import JiscButton from '../JiscButton/JiscButton';

import styles from './ThemeAssignmentPicker.styles';

const ThemeAssignmentPicker = ({ theme, authUser }) => {
    const classes = styles();

    const [filteredUsers, setFilteredUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState();
    const [userAssigned, setUserAssigned] = useState(false);
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [assignmentError, setAssignmentError] = useState(false);
    const [userError, setUserError] = useState(false);

    const getUsers = useCallback(async () => {
        try {
            setUserError(false);

            const acceptedUsers = (await get(`/institutions/${authUser.institution_id}/users`)).data.users;
            const invitedUsers = (await get(`/institutions/${authUser.institution_id}/users?accepted=0`)).data.users;
            const users = [...acceptedUsers, ...invitedUsers];

            setFilteredUsers(users.filter((user) => user.id !== authUser.id));
        } catch (err) {
            setUserError(true);
        }
    }, [authUser.institution_id, authUser.id]);

    const assignUserToTheme = async () => {
        try {
            const response = await post(`/themes/${theme.theme_id}/assign`, {
                email: selectedUser,
                theme_id: theme.theme_id
            });

            if (response.status === 200) {
                setUserAssigned(true);
                setAssignmentError(false);
            }
        } catch (err) {
            setAssignmentError(true);
        }
    };

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    return (
        <Accordion className={classes.accordion} expanded={accordionExpanded}>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon className={classes.chevron} icon='chevron-down' />}
                IconButtonProps={{
                    onClick: () => {
                        setAccordionExpanded(!accordionExpanded);
                        setSelectedUser(null);
                        setUserAssigned(false);
                    }
                }}
                classes={{ expandIcon: classes.expandIcon }} // Override expandIcon styles
                className={classes.accordionSummary}
            >
                <Typography className={classes.heading}>Or, ask a colleague to answer these questions</Typography>
                <Chip label='New!' className={classes.chip} />
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {!userError ? (
                    filteredUsers ? (
                        userAssigned ? (
                            <div>
                                <Alert severity='success'>
                                    An email has been sent to {selectedUser} asking them to review the question set
                                    about {theme.theme_title}
                                </Alert>
                                <div className={classes.buttonArea}>
                                    <JiscButton
                                        onClick={() => {
                                            setAccordionExpanded(false);
                                            setAssignmentError(false);
                                        }}
                                        className={classes.button}
                                    >
                                        Done
                                    </JiscButton>
                                    <JiscButton
                                        onClick={() => {
                                            setSelectedUser(null);
                                            setUserAssigned(false);
                                            setAssignmentError(false);
                                        }}
                                        variant='ghost'
                                    >
                                        Also send it to someone else
                                    </JiscButton>
                                </div>
                            </div>
                        ) : (
                            <>
                                <Alert severity='info'>
                                    As an admin, you can send this theme ({theme.theme_title}) to another user of your
                                    choosing. They will be notified by email that you have invited them to answer the
                                    questions in this theme.
                                </Alert>
                                <div className={classes.assignmentArea}>
                                    <fieldset className={classes.fieldset}>
                                        <legend>
                                            <Typography variant='h2' className={classes.assignmentHeading}>
                                                Who would you like to answer these questions?
                                            </Typography>
                                        </legend>
                                        <Typography className={classes.assignmentSupportingText}>
                                            We’d suggest someone with knowledge of the topics listed above.
                                        </Typography>

                                        {filteredUsers &&
                                            filteredUsers.map((user) => {
                                                return (
                                                    <div key={user.email} className={classes.radioInput}>
                                                        <label
                                                            htmlFor={user.id}
                                                            style={{ opacity: user.accepted === false ? '0.4' : '1' }}
                                                        >
                                                            <input
                                                                type='radio'
                                                                id={user.id}
                                                                name='user'
                                                                readOnly
                                                                disabled={user.accepted === false}
                                                                value={user.id}
                                                                checked={selectedUser === user.email}
                                                                onClick={() => setSelectedUser(user.email)}
                                                            />
                                                            {user.email}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                    </fieldset>
                                    {assignmentError && (
                                        <Alert severity='error'>
                                            There was an issue with assignment, please try again.
                                        </Alert>
                                    )}
                                </div>
                                <div className={classes.buttonArea}>
                                    <JiscButton
                                        onClick={assignUserToTheme}
                                        disabled={!selectedUser}
                                        className={classes.button}
                                    >
                                        Send to the selected colleague
                                    </JiscButton>
                                </div>
                            </>
                        )
                    ) : (
                        <Alert severity='info'>
                            As an admin, you can send this theme ({theme.theme_title}) to another user of your choosing.
                            Currently there are no other registered users for {authUser.institution_name}.
                        </Alert>
                    )
                ) : (
                    <Alert severity='error'>
                        There was an issue getting users. Please refresh the page and try again.
                    </Alert>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

ThemeAssignmentPicker.propTypes = {
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired,

    authUser: PropTypes.shape({
        id: PropTypes.number,
        institution_id: PropTypes.number,
        institution_name: PropTypes.string
    }).isRequired
};

export default ThemeAssignmentPicker;
