import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    navigationButtonParent: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            justifyContent: 'space-between',
            gridTemplateColumns: '1fr 1fr',
            rowGap: '10px'
        },
        marginTop: theme.spacing(4),
        '& > *': {
            marginRight: theme.spacing(4)
        },
        '& > *:last-child': {
            marginRight: theme.spacing(0)
        },
        '& svg': {
            color: theme.palette.jisc.blue
        }
    },
    questionWrap: {
        [theme.breakpoints.up('lg')]: {
            marginTop: '5%',
            minHeight: theme.spacing(50)
        }
    },
    addNoteError: {
        margin: theme.spacing(2, 0)
    },
    reminderModalContent: {
        display: 'flex',
        marginTop: theme.spacing(2),
        '& > *': {
            marginRight: theme.spacing(2)
        }
    },
    reminderModalForm: {
        display: 'block',
        '& > *': {
            marginTop: theme.spacing(2)
        }
    }
}));

export default styles;
