import React from 'react';

function StaffExperience() {
    return (
        <svg
            title='staff-experience-svg'
            xmlns='http://www.w3.org/2000/svg'
            width='148'
            height='141'
            viewBox='0 0 148 141'
        >
            <defs>
                <clipPath>
                    <rect
                        data-name='Rectangle 2270'
                        width='138.47'
                        height='138.47'
                        transform='translate(0 0.1)'
                        fill='none'
                    />
                </clipPath>
            </defs>
            <g data-name='Layer 2' transform='translate(3,0.43)'>
                <g data-name='Layer 1'>
                    <g data-name='Group 2092'>
                        <g data-name='Group 2259' clipPath='url(#clip-path)'>
                            <g data-name='Group 2091'>
                                <g data-name='Group 2073'>
                                    <path
                                        data-name='Path 1805'
                                        d='M 80.26,11 A 11,11 0 1 0 69.19,22 v 0 a 11,11 0 0 0 11,-11 m -18.51,0 a 7.56,7.56 0 1 1 7.6,7.5 v 0 A 7.53,7.53 0 0 1 61.68,11'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2074'>
                                    <path
                                        data-name='Path 1806'
                                        d='m 121.43,28.06 a 11.05,11.05 0 0 0 -11,-11 v 0 A 10.93,10.93 0 0 0 99.44,28 a 0.13,0.13 0 0 0 0,0.06 11.06,11.06 0 0 0 11,11 v 0 a 10.93,10.93 0 0 0 10.94,-10.91 v -0.06 m -5.65,5.32 a 7.5596786,7.5596786 0 1 1 -10.691,-10.691 7.48,7.48 0 0 1 5.3,-2.189 7.57,7.57 0 0 1 5.4,12.88'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2075'>
                                    <path
                                        data-name='Path 1807'
                                        d='m 127.5,58.22 a 11,11 0 1 0 11,11.07 v -0.05 a 11,11 0 0 0 -11,-11 m 0,18.57 a 7.56,7.56 0 1 1 7.5,-7.63 v 0.06 a 7.53,7.53 0 0 1 -7.51,7.55'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2076' />
                                <g data-name='Group 2077'>
                                    <path
                                        data-name='Path 1809'
                                        d='m 58.22,127.5 a 11,11 0 1 0 11.07,-11 h -0.05 a 11,11 0 0 0 -11,11 m 18.57,0 A 7.56,7.56 0 1 1 69.2,120 h 0.06 a 7.54,7.54 0 0 1 7.55,7.5'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 1773'>
                                    <path
                                        data-name='Path 1809'
                                        d='m 58.22,127.5 a 11,11 0 1 0 11.07,-11 h -0.05 a 11,11 0 0 0 -11,11 m 18.57,0 A 7.56,7.56 0 1 1 69.2,120 h 0.06 a 7.54,7.54 0 0 1 7.55,7.5'
                                        transform='translate(40.941291,-18.094059)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2078'>
                                    <path
                                        data-name='Path 1810'
                                        d='m 17,110.42 a 11.05,11.05 0 0 0 11,11 v 0 A 10.94,10.94 0 0 0 39,110.5 v 0 a 11.07,11.07 0 0 0 -11,-11 v 0 a 10.93,10.93 0 0 0 -11,10.85 v 0.07 m 5.65,-5.33 a 7.49,7.49 0 1 1 -2.21,5.29 7.49,7.49 0 0 1 2.21,-5.29'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2079'>
                                    <path
                                        data-name='Path 1811'
                                        d='M 11,80.25 A 11,11 0 1 0 0,69.19 v 0 a 11,11 0 0 0 11,11 m 0,-18.57 a 7.56,7.56 0 1 1 -7.51,7.6 v 0 A 7.53,7.53 0 0 1 11,61.68'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2080'>
                                    <path
                                        data-name='Path 1812'
                                        d='m 28,39 v 0 A 11.06,11.06 0 0 0 39,28 10.94,10.94 0 0 0 28.12,17 h -0.06 a 11.05,11.05 0 0 0 -11,11 10.94,10.94 0 0 0 10.87,11 H 28 M 22.73,22.72 a 7.58,7.58 0 0 1 5.36,-2.22 7.45,7.45 0 0 1 5.29,2.19 7.56,7.56 0 1 1 -10.65,0'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2081'>
                                    <path
                                        data-name='Path 1813'
                                        d='M 40.89,20 A 1.62,1.62 0 0 0 41.73,19.78 56.87,56.87 0 0 1 53.08,15 1.73,1.73 0 0 0 54.25,12.85 v 0 A 1.73,1.73 0 0 0 52.1,11.69 v 0 a 60.05,60.05 0 0 0 -12,5.08 1.73,1.73 0 0 0 0.84,3.25'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2082'>
                                    <path
                                        data-name='Path 1814'
                                        d='m 96,19.43 a 1.734,1.734 0 1 0 1.65,-3.05 59.74,59.74 0 0 0 -12.11,-4.92 1.73,1.73 0 0 0 -2.14,1.18 v 0 a 1.73,1.73 0 0 0 1.19,2.13 56.55,56.55 0 0 1 11.41,4.66'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2083'>
                                    <path
                                        data-name='Path 1815'
                                        d='m 123.22,52.86 a 1.71,1.71 0 0 0 1.65,1.24 1.68,1.68 0 0 0 0.5,-0.07 1.72,1.72 0 0 0 1.16,-2.15 59.319,59.319 0 0 0 -5.08,-12.05 1.73,1.73 0 1 0 -3,1.69 v 0 a 56.821,56.821 0 0 1 4.79,11.34'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2084'>
                                    <path
                                        data-name='Path 1816'
                                        d='m 125.6,83.16 a 1.73,1.73 0 0 0 -2.13,1.19 v 0 a 56.789,56.789 0 0 1 -4.64,11.41 1.737,1.737 0 1 0 3,1.75 l 0.06,-0.11 a 60.35,60.35 0 0 0 4.91,-12.1 1.75,1.75 0 0 0 -1.2,-2.14'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2085'>
                                    <path
                                        data-name='Path 1817'
                                        d='m 96.74,118.21 a 56.57,56.57 0 0 1 -11.35,4.79 1.73,1.73 0 0 0 0.49,3.39 2.08,2.08 0 0 0 0.5,-0.07 59.571,59.571 0 0 0 12,-5.08 1.73,1.73 0 0 0 -1.68,-3'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2086'>
                                    <path
                                        data-name='Path 1818'
                                        d='m 42.49,118.61 a 1.73,1.73 0 1 0 -1.65,3 60.349,60.349 0 0 0 12.16,4.97 2,2 0 0 0 0.48,0.07 1.75,1.75 0 0 0 1.73,-1.74 1.73,1.73 0 0 0 -1.26,-1.66 56.55,56.55 0 0 1 -11.41,-4.64'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2087'>
                                    <path
                                        data-name='Path 1819'
                                        d='m 15.26,85.17 a 1.734,1.734 0 1 0 -3.32,1 59.57,59.57 0 0 0 5.06,12.03 1.72,1.72 0 0 0 1.52,0.89 1.67,1.67 0 0 0 0.84,-0.22 1.73,1.73 0 0 0 0.67,-2.35 v 0 A 56.329,56.329 0 0 1 15.24,85.17'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2088'>
                                    <path
                                        data-name='Path 1820'
                                        d='m 16.6,40.62 a 60.35,60.35 0 0 0 -4.93,12.11 1.74,1.74 0 0 0 1.2,2.14 1.49,1.49 0 0 0 0.47,0.07 1.74,1.74 0 0 0 1.66,-1.26 55.41,55.41 0 0 1 4.64,-11.41 1.73,1.73 0 1 0 -3,-1.65'
                                        transform='translate(0,0.1)'
                                        fill='#14416d'
                                    />
                                </g>
                                <g data-name='Group 2089'>
                                    <path
                                        data-name='Path 1821'
                                        d='M 69.87,30.77 A 17.2,17.2 0 1 1 52.67,48 v 0 a 17.2,17.2 0 0 1 17.2,-17.2'
                                        transform='translate(0,0.1)'
                                        fill='none'
                                        stroke='#14416d'
                                        strokeWidth='3.3'
                                    />
                                </g>
                                <g data-name='Group 2090'>
                                    <path
                                        data-name='Path 1822'
                                        d='m 95.67,95.26 a 25.8,25.8 0 1 0 -51.6,0 v 8.6 h 51.59 v -8.6 z'
                                        transform='translate(0,0.1)'
                                        fill='none'
                                        stroke='#14416d'
                                        strokeLinejoin='round'
                                        strokeWidth='3.3'
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <rect data-name='Rectangle 2291' width='148' height='141' fill='none' x='0' y='0' />
        </svg>
    );
}

export default StaffExperience;
