import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            padding: '0 10%'
        }
    },
    goalsTitle: {
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: '2rem',
        letterSpacing: 'unset',
        color: theme.palette.fes.headline,
        marginBottom: theme.spacing(3)
    },
    grid: {
        display: 'grid',
        gap: theme.spacing(4),
        gridTemplateColumns: 'auto',
        margin: theme.spacing(5, 0),
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(auto-fit, 29.75rem)'
        }
    },
    goalsStart: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(4),
        background: 'transparent',
        borderWidth: '0.188rem',
        borderStyle: 'solid',
        borderColor: theme.palette.fes.navy,
        borderRadius: '0.625rem',
        textAlign: 'left',
        transition: 'all 150ms ease-in-out',
        outline: 'none',
        '&[disabled]': {
            borderColor: theme.palette.jisc.greySilver,
            filter: 'brightness(1) grayscale(1)',
            '&:hover': {
                background: 'transparent',
                cursor: 'no-drop',
                '& > span': {
                    color: theme.palette.fes.navy
                },
                '& > svg': {
                    filter: 'initial'
                }
            }
        },
        '&:hover, &:focus': {
            cursor: 'pointer',
            backgroundColor: theme.palette.fes.blue,
            '& > span': {
                color: theme.palette.white
            },
            '& > svg': {
                filter: 'brightness(0) invert(1)'
            }
        },
        '& > span': {
            ...theme.typography.h4,
            color: theme.palette.fes.navy,
            width: '70%'
        },
        '& > svg': {
            width: '30%'
        }
    },
    resultsSummary: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    exportButtons: {
        display: 'flex',
        '& > *': {
            marginLeft: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(3, 0),
            justifyContent: 'space-between',
            '& > *': {
                marginLeft: '0'
            }
        }
    },
    error: {
        margin: theme.spacing(5, 0, 0)
    },
    loading: {
        margin: theme.spacing(5, 0, 0)
    }
}));

export default styles;
