import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const QuestionContext = createContext();

export const useQuestionContext = () => useContext(QuestionContext);

export function QuestionContextProvider({ children }) {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [lastCompetencyId, setLastCompetencyId] = useState(0);

    const values = useMemo(
        () => ({
            questionIndex,
            setQuestionIndex,
            lastCompetencyId,
            setLastCompetencyId,
            questions,
            setQuestions
        }),
        [lastCompetencyId, questionIndex, questions]
    );

    return <QuestionContext.Provider value={values}>{children}</QuestionContext.Provider>;
}

QuestionContextProvider.propTypes = {
    children: PropTypes.element.isRequired
};
