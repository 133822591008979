import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    grid: {
        display: 'grid',
        gap: theme.spacing(2),
        margin: theme.spacing(2, 0),
        gridTemplateColumns: 'repeat(2, 1fr)',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(6, 1fr)'
        },
        '& svg': {
            marginTop: theme.spacing(2)
        }
    }
}));

export default styles;
