import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    contentArea: {
        margin: 'auto',
        width: 'calc(204mm - 2px)',
        '@media print': {
            margin: '0'
        }
    }
}));

export default styles;
