import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    dialog: {
        borderRadius: '1rem',
        padding: '80px 80px 80px 80px',
        MuiBackdropRoot: {
            backgroundColor: 'lightgreen'
        }
    },
    diaTitle: {
        padding: theme.spacing(4, 4, 0, 4)
    },
    title: {
        // fontSize: '1.25rem',
        lineHeight: 1.6,
        fontWeight: 400
    },
    dialogContent: {
        overflowY: 'scroll',
        padding: theme.spacing(3, 4)
    },
    dialogActions: {
        padding: theme.spacing(1, 10.9, 2)
    },
    cancelButton: {
        textDecoration: 'none'
    }
}));

export default styles;
