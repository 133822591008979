import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

import styles from './PageBody.styles';

const PageBody = ({ children, containerMaxWidth }) => {
    const classes = styles();

    return (
        <Container maxWidth={containerMaxWidth} className={classes.root}>
            {children}
        </Container>
    );
};

PageBody.defaultProps = {
    containerMaxWidth: 'lg'
};

PageBody.propTypes = {
    containerMaxWidth: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.node]).isRequired
};

export default PageBody;
