import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormControl, FormHelperText, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContentManagementContext } from '../../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../../components/JiscButton/JiscButton';
import Loader from '../../../../components/Loader/Loader';
import HtmlEditor from '../../../../components/HtmlEditor/HtmlEditor';

import * as api from '../../../../lib/api';
import urls from '../../../../config/urls';
import styles from './NewResource.styles';

const NewResource = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const history = useHistory();

    // Data
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState();

    const [excerpt, setExcerpt] = useState('');
    const [excerptError, setExcerptError] = useState();

    const [body, setBody] = useState('');
    const [bodyError, setBodyError] = useState();

    // Error
    const [loading, setLoading] = useState();
    const [error, setError] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !excerpt || !body) {
            if (!title) {
                setTitleError('You must supply a title.');
                return;
            }
            if (!excerpt) {
                setExcerptError('You must supply a short description.');
                setTitleError(undefined);
                return;
            }
            if (!body) {
                setBodyError('You must supply body content.');
                setExcerptError(undefined);
                setTitleError(undefined);
                return;
            }
        }

        setLoading(true);
        setTitleError(undefined);
        setExcerptError(undefined);
        setBodyError(undefined);

        try {
            const { status } = await api.post(`/resources`, {
                title,
                excerpt,
                body
            });

            if (status !== 201) throw Error('There was a problem creating this resource.');

            setErrorMessage(undefined);
            setError(undefined);
            setLoading(false);
            history.push(urls.contentManagement.resources.url);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        setActivePage(urls.contentManagement.resources.add);
    }, []);
    return (
        <ContentManagementLayout>
            {error && errorMessage && (
                <Alert severity='error' className={classes.alert}>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            {!loading && (
                <>
                    <header className={classes.titleHeader}>
                        <Typography variant='h5' component='h1'>
                            {urls.contentManagement.resources.add.text}
                        </Typography>
                        <div>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='check' role='button' />}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Save
                            </JiscButton>
                            <JiscButton
                                isLink
                                linkType='internal'
                                href={urls.contentManagement.resources.url}
                                variant='ghost'
                                startIcon={<FontAwesomeIcon icon='arrow-alt-circle-left' role='button' />}
                            >
                                Back to all
                            </JiscButton>
                        </div>
                    </header>

                    <form id='new-resource' className={classes.form}>
                        <FormControl fullWidth>
                            <TextField
                                label='Title'
                                placeholder='Resource title...'
                                variant='outlined'
                                error={titleError}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            {titleError && <FormHelperText>{titleError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                label='Excerpt'
                                placeholder='Short description...'
                                variant='outlined'
                                error={excerptError}
                                value={excerpt}
                                onChange={(e) => setExcerpt(e.target.value)}
                            />
                            {excerptError && <FormHelperText>{excerptError}</FormHelperText>}
                        </FormControl>

                        <HtmlEditor
                            title='Body'
                            placeholder='Start typing here...'
                            error={bodyError}
                            content={body}
                            setContent={setBody}
                        />
                    </form>
                </>
            )}
            {loading && <Loader />}
        </ContentManagementLayout>
    );
};

export default NewResource;
