import React from 'react';

import styles from './PrintLayout.styles';

const PrintLayout = ({ children }) => {
    const classes = styles();

    return (
        <main>
            <section className={classes.contentArea}>{children}</section>
        </main>
    );
};

export default PrintLayout;
