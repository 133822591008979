import React from 'react';

function Transform() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'>
            <g data-name='Group 2436' transform='translate(6556 -13973)'>
                <g data-name='Group 2433'>
                    <rect
                        data-name='Rectangle 2393'
                        width='80'
                        height='80'
                        transform='translate(-6556 13973)'
                        fill='none'
                    />
                    <g data-name='elevate icon 1' transform='translate(-6538.233 13983)'>
                        <g data-name='Layer 1' transform='translate(0 0)'>
                            <path
                                data-name='Path 4132'
                                d='M42.036.446A1.468,1.468,0,0,0,40.962,0H39.075C29.287.328,23.7,4.573,21.215,9.343,18.739,4.573,13.1.328,3.368,0H1.455A1.468,1.468,0,0,0,.407.446,1.428,1.428,0,0,0,0,1.546L.132,3.42c.734,10,5.634,16.89,13.1,18.436a8.386,8.386,0,0,0,1.717.17,7.443,7.443,0,0,0,4.809-1.782V32.758H10.208A4.062,4.062,0,0,0,6.146,36.82v5.372a.59.59,0,0,0,0,.144.734.734,0,0,0,0,.17l3.8,16.733a4.075,4.075,0,0,0,3.931,3.171H28.54a4.075,4.075,0,0,0,3.931-3.171l3.761-16.746a1.31,1.31,0,0,0,0-.314h0V36.807a4.062,4.062,0,0,0-4.062-4.062H22.6v-12.5a7.456,7.456,0,0,0,4.914,1.782,9.408,9.408,0,0,0,1.717-.17c7.482-1.546,12.383-8.439,13.1-18.436l.118-1.874a1.31,1.31,0,0,0-.419-1.1ZM3.277,2.935c10.548.288,15.4,5.661,16.405,10.587a4.429,4.429,0,0,1-.131,2.188A16.694,16.694,0,0,0,11.9,9.631a1.428,1.428,0,0,0-1.782.852,1.4,1.4,0,0,0,0,1.1,1.559,1.559,0,0,0,.826.76,14.414,14.414,0,0,1,6.774,5.8A4.6,4.6,0,0,1,13.785,19C7.679,17.768,3.644,11.793,3.015,3.21V2.922ZM31.449,12.37a1.444,1.444,0,0,0-.957-2.725,16.576,16.576,0,0,0-7.6,6.08,4.311,4.311,0,0,1-.144-2.162c1.009-4.94,5.857-10.312,16.405-10.6h.288V3.25c-.629,8.635-4.665,14.545-10.823,15.816a4.652,4.652,0,0,1-3.931-.865,14.414,14.414,0,0,1,6.761-5.831ZM29.667,58.611a1.166,1.166,0,0,1-1.14.917H13.9a1.179,1.179,0,0,1-1.14-.917L9.4,43.621H32.982ZM33.4,40.738H9.029V36.807a1.179,1.179,0,0,1,1.179-1.179H32.222A1.179,1.179,0,0,1,33.4,36.807Z'
                                transform='translate(0.003 0)'
                                fill='#008e83'
                            />
                        </g>
                        <g data-name='Layer 3' transform='translate(2.519 3.447)'>
                            <g data-name='Group 2273' transform='translate(19.264 0.039)' opacity='0.5'>
                                <path
                                    data-name='Path 4133'
                                    d='M17.681,16.93l.5.524a4.625,4.625,0,0,0,4.35,1.31c6.159-1.31,10.207-7.181,10.836-15.816V2.66H33.1c-10.483.288-15.4,5.661-16.405,10.6a4.376,4.376,0,0,0,.983,3.669Z'
                                    transform='translate(-16.622 -2.66)'
                                    fill='#008e83'
                                />
                            </g>
                            <g data-name='Group 2276' opacity='0.5'>
                                <path
                                    data-name='Path 4134'
                                    d='M1.92,2.63v.288c.642,8.635,4.691,14.61,10.836,15.816a4.665,4.665,0,0,0,4.377-1.31l.328-.341a4.416,4.416,0,0,0,1.153-3.931C17.6,8.356,12.756,2.931,2.208,2.63Z'
                                    transform='translate(-1.92 -2.63)'
                                    fill='#008e83'
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Transform;
