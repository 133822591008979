import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { CircularProgress, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import JiscButton from '../JiscButton/JiscButton';
import styles from './NoteModalContent.styles';

export function NoteHistory({ notes, notesLoading }) {
    const classes = styles();

    return notesLoading ? (
        <div className={classes.spinner}>
            <CircularProgress />
        </div>
    ) : (
        <div className={classes.container}>
            {notes.length ? (
                notes.map((note) => {
                    return (
                        <div key={note.id} className={classes.note}>
                            <div className={classes.info}>
                                <p className={classes.createdBy}>Note by: {note.created_by}</p>
                                <p className={classes.date}>
                                    {DateTime.fromISO(note.created_at).toFormat('dd/MM/yyyy h:mma')}
                                </p>
                            </div>
                            <div className={classes.content}>{note.content}</div>
                        </div>
                    );
                })
            ) : (
                <p className={classes.noNotesInfo}>No notes have been added yet.</p>
            )}
        </div>
    );
}

NoteHistory.propTypes = {
    notes: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
            created_by: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired
        })
    ).isRequired,
    notesLoading: PropTypes.bool.isRequired
};

export function NewNote({ handleSubmitNote }) {
    const [showAddNote, setShowAddNote] = useState(false);
    const [noteContent, setNoteContent] = useState(null);

    const classes = styles({ showAddNote });

    return (
        <>
            {showAddNote && (
                <TextField
                    className={classes.textField}
                    autoFocus
                    id='outlined-required'
                    label='Your new note'
                    variant='outlined'
                    style={{ width: '100%' }}
                    minRows={5}
                    multiline
                    onChange={(e) => setNoteContent(e.target.value)}
                    inputProps={{ maxLength: 1000 }}
                />
            )}
            <div className={classes.noteDialogActions}>
                {!showAddNote ? (
                    <JiscButton className={classes.addNoteButton} onClick={() => setShowAddNote(true)}>
                        <FontAwesomeIcon icon={'plus'} style={{ marginRight: '4px', height: '14px', width: '14px' }} />
                        Add a note
                    </JiscButton>
                ) : (
                    <div className={classes.buttons}>
                        <JiscButton
                            className={classes.closeButton}
                            onClick={() => {
                                setShowAddNote(false);
                            }}
                            variant='ghost'
                        >
                            Cancel
                        </JiscButton>
                        <JiscButton
                            className={classes.addNoteButton}
                            onClick={() => {
                                handleSubmitNote(noteContent);
                                setNoteContent(null);
                                setShowAddNote(false);
                            }}
                        >
                            Submit note
                        </JiscButton>
                    </div>
                )}
            </div>
        </>
    );
}

NewNote.propTypes = {
    handleSubmitNote: PropTypes.func.isRequired
};
