import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const TableLoader = ({ rows, columns }) => {
    return new Array(rows).fill(0).map((a, aIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={aIndex}>
            {new Array(columns).fill(0).map((b, bIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={bIndex}>
                    <Skeleton data-testid='skeleton' variant='rect' height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));
};

TableLoader.propTypes = {
    rows: PropTypes.number.isRequired,
    columns: PropTypes.number.isRequired
};

export default TableLoader;
