import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getThemeIcon } from '../../../lib/enums';

import styles from './ThemePickerCard.styles';

function ThemePickerCard({ theme, handleClick }) {
    const { theme_id: id, theme_title: title, theme_color: color, theme_icon: icon } = theme;

    const classes = styles({ color });

    const [selected, setSelected] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [progress, setProgress] = useState(0);

    const click = (value) => {
        setSelected(value);
        handleClick({ id, title, color, icon });
    };

    useEffect(() => {
        let total = 0;
        let answered = 0;

        theme.levels.forEach((competencyQuestionResults) => {
            total += competencyQuestionResults.total;
            answered += competencyQuestionResults.totalResponses;
        });

        setProgress(Math.round((answered * 100) / total));
    }, []);

    return (
        <button
            data-testid='theme-picker-card'
            className={`${classes.root} ${selected ? classes.rootSelected : ''}`}
            tabIndex='0'
            aria-pressed={selected}
            onClick={() => click(!selected)}
            onFocus={() => setHovered(true)}
            onBlur={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            type='button'
        >
            {selected && (
                <span className={classes.selector}>
                    <FontAwesomeIcon icon='check' />
                </span>
            )}
            {hovered && <div data-testid='theme-picker-card-selector' className={classes.hovered} aria-hidden='true' />}
            {getThemeIcon(icon)}
            <Typography variant='h5' className={classes.themePickerCardTitle}>
                {title}
            </Typography>
            {progress > 0 && (
                <div className={classes.completedIndicator}>
                    <div className={classes.completedIndicatorBar}>
                        <span style={{ width: `${progress}%` }} className={classes.completedIndicatorBarGrowth} />
                    </div>
                    <Typography variant='body1' className={classes.completedIndicatorLabel}>
                        <span>{progress}%</span> questions answered
                    </Typography>
                </div>
            )}
        </button>
    );
}

ThemePickerCard.propTypes = {
    theme: PropTypes.shape({
        theme_id: PropTypes.number.isRequired,
        theme_title: PropTypes.string.isRequired,
        theme_color: PropTypes.string.isRequired,
        theme_icon: PropTypes.number.isRequired,
        levels: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    handleClick: PropTypes.func.isRequired
};

export default ThemePickerCard;
