import React from 'react';

function BlueTick() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='17.685' height='12.674' viewBox='0 0 17.685 12.674'>
            <path
                data-name='Path 1599'
                d='M19.59,26.559l4.4,4.7L34.448,21'
                transform='translate(-18.177 -19.586)'
                fill='none'
                stroke='#00619e'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                strokeWidth='2'
            />
        </svg>
    );
}

export default BlueTick;
