import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(3)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.white
    },
    alert: {
        marginBottom: theme.spacing(3)
    },
    card: {
        backgroundColor: theme.palette.jisc.white,
        border: '0.09rem solid #CCCCCC',
        borderRadius: '0.5rem',
        padding: theme.spacing(3)
    },
    error: {
        color: theme.palette.jisc.error,
        marginTop: theme.spacing(5)
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    formDescription: {
        marginTop: theme.spacing(2)
    },
    formControl: {
        marginTop: theme.spacing(3)
    }
}));

export default styles;
