import React, { useEffect, useState, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import urls from '../../config/urls';
import { useAuthContext } from '../../lib/hooks/useAuthContext';

import ThemeResultCard from '../ThemeResultCard/ThemeResultCard';
import UnseenThemes from '../UnseenThemes/UnseenThemes';

import styles from './ResultsSummary.styles';
import JiscButton from '../JiscButton/JiscButton';
import CustomModal from '../CustomModal/CustomModal';
import OrganisationReportSelector from '../OrganisationReportSelector/organisationReportSelector';
import { get, postJson } from '../../lib/api';
import { ResultsContext } from '../../pages/Results/ResultsContext';
import * as enums from '../../lib/enums';

function ResultsSummary() {
    const classes = styles();
    const history = useHistory();
    const { auth } = useAuthContext();
    const { user } = auth;
    const resultsContext = useContext(ResultsContext);
    const [startedThemes, setStartedThemes] = useState([]);
    const [notStartedThemes, setNotStartedThemes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [showExportModal, setShowExportModal] = useState(false);
    const [resourceError, setResourceError] = useState();

    const exportOnclick = (e) => {
        setShowExportModal(true);
    };
    const onExport = async (e) => {
        const result = await postJson(
            '/report',
            JSON.stringify({
                institutions: [user.institution_id],
                reportTypes: Object.keys(resultsContext.selectedReports).filter(
                    (k) => resultsContext.selectedReports[k] === true
                ),
                reportFormats: Object.keys(resultsContext.selectedReportFormats).filter(
                    (k) => resultsContext.selectedReportFormats[k] === true
                )
            }),
            true
        );

        const { key } = result.data;
        if (key) {
            const { data } = await get(`/report/${key}`);
            const link = document.createElement('a');
            link.href = data;
            link.setAttribute('download', `${key}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        resultsContext.setSelectedReports({
            yourCurrentAnswers: true,
            versionHistoryAllAnswers: false,
            simpleOverview: false
        });
        resultsContext.setSelectedReportFormats({
            xls: true,
            csv: false
        });
        setShowExportModal(false);
    };
    const onModalClose = (e) => {
        setShowExportModal(false);
    };
    const arrangeThemes = (themes) => {
        if (themes.length) {
            themes.forEach((theme) => {
                let answered = 0;

                theme.levels.forEach((level) => {
                    answered += level.totalResponses;
                });

                if (answered > 0) {
                    setStartedThemes((prev) => [...prev, theme]);
                } else {
                    setNotStartedThemes((prev) => [...prev, theme]);
                }
            });
        } else {
            setError('There was an error collecting themes.');
        }
    };

    const getThemes = async () => {
        try {
            const { data } = await get(`/surveys/1/institution/${auth.user.institution_id}/progress`);
            arrangeThemes(data);
        } catch (err) {
            setError('Error retrieving themes.');
        }
        setLoading(false);
    };

    useEffect(() => {
        getThemes();
    }, []);

    return (
        <div className={classes.root} data-testid='goals'>
            <Typography variant='h2' className={classes.goalsTitle}>
                See your goals
            </Typography>
            <div className={classes.resultsSummary}>
                <div>
                    <Typography variant='body1'>
                        Explore the themes in more detail to see how you can make progress with your goals.
                    </Typography>
                    <Typography variant='body1'>
                        See how many goals are remaining for you to reach the next level of digital elevation.
                    </Typography>
                </div>
                {(user.role === enums.user.role.ADMIN || user.role === enums.user.role.JISC_ADMIN) && (
                    <div className={classes.exportButtons}>
                        <JiscButton isLink linkType='external' href={urls.exportNotes.url}>
                            Export notes
                        </JiscButton>
                        <JiscButton onClick={exportOnclick}>Export results</JiscButton>
                        <CustomModal
                            open={showExportModal}
                            title='Export your results'
                            onClose={onModalClose}
                            buttonText='Export results'
                            successCallback={onExport}
                            error={resourceError}
                            errorMessage={resourceError}
                        >
                            <OrganisationReportSelector />
                        </CustomModal>
                    </div>
                )}
            </div>
            {!!error && (
                <Alert data-testid='theme-picker-error-message' className={classes.error} severity='error'>
                    <AlertTitle>There was an error fetching themes for this survey.</AlertTitle>
                    <Typography varient='body2'>
                        Please try again later or if this issues persist, please contact us at{' '}
                        <a
                            href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                            target='_blank'
                            rel='noreferrer'
                        >
                            help@jisc.ac.uk
                        </a>
                    </Typography>
                </Alert>
            )}

            {loading && <Skeleton data-testid='skeleton' variant='rect' height={350} className={classes.loading} />}

            {!loading && startedThemes.length === 0 && (
                <div>
                    <br />
                    <Typography variant='body1'>Please answer at least one question to view goals.</Typography>
                    <br />
                    <JiscButton
                        variant='ghost'
                        onClick={() => history.push(urls.selfAssessment.url)}
                        endIcon={<FontAwesomeIcon icon='angle-double-right' />}
                        className={classes.heroCTA}
                    >
                        Complete your self-assessment
                    </JiscButton>
                </div>
            )}

            <div data-testid='theme-results-card-parent' className={classes.grid}>
                {startedThemes.map((theme, index) => (
                    <ThemeResultCard key={theme.theme_id} index={index} theme={theme} />
                ))}
                <UnseenThemes notStartedThemes={notStartedThemes} />
            </div>
        </div>
    );
}

export default ResultsSummary;
