import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';
import { Container, Typography, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GrowthTree from '../../lib/assets/GrowthTree';
import HelpBanner from '../../components/HelpBanner/HelperBanner';

import urls from '../../config/urls';
import styles from './Help.styles';

const Help = () => {
    const classes = styles();

    const instructions = [
        {
            id: 1,
            title: 'I haven’t received my log-in email.',
            content:
                'In the first instance please check your junk mail. If you are still not receiving emails please contact <a href="mailto:help@jisc.ac.uk?subject=Digital Elevation Tool" rel="noreferrer noopener" target="_blank">help@jisc.ac.uk</a>.'
        },
        {
            id: 2,
            title: 'I have received a log in email, but when I click on the link I get redirected to the log in page.',
            content:
                'The log in tokens for the site are only valid for 5 minutes. After this point, you will be redirected to the log in page to regenerate a valid token. Please add your email and resubmit to send a new email. If the link is not working within the 5 minute window please contact <a href="mailto:help@jisc.ac.uk?subject=Digital Elevation Tool" rel="noreferrer noopener" target="_blank">help@jisc.ac.uk</a>.'
        },
        {
            id: 3,
            title: 'I have logged in, but my account does not have a related institution.',
            content:
                'If you have logged in but do not have an associated organisation, please contact <a href="mailto:help@jisc.ac.uk?subject=Digital Elevation Tool" rel="noreferrer noopener" target="_blank">help@jisc.ac.uk</a>.'
        },
        {
            id: 4,
            title: 'I have logged in, but I can’t see the survey.',
            content:
                'This may be because your account does not have an associated organisation, please contact <a href="mailto:help@jisc.ac.uk?subject=Digital Elevation Tool" rel="noreferrer noopener" target="_blank">help@jisc.ac.uk</a> and provide a screenshot.'
        },
        {
            id: 5,
            title: 'What does foundation, transform, elevate mean?',
            content:
                'The Digital Elevation toolkit provides statements that allow anyone working within a FES Provider to review the status of their Digital Elevation in terms of the Foundations they have put in place, the elements to Transform and where possible the Elevate components that ensure a provider is at the forefront of digital best practice. <br /><br /><strong>Foundations:</strong> The `must-haves`. The policies, processes and digital, data or technology elements that each provider must have in place as the foundations for further progress.<br /><br /><strong>Transform:</strong> The `should-haves`. Building on the `Foundations`, the very same elements that we expect, and hope providers have or are planning to put in place to transform the end user experience.<br /><br /><strong>Elevate:</strong> The `can-haves`. Building on `Transformation`, some of these aspects might currently be in operation across a small number of UK FES providers. This is where early adopters will lead future innovation across the regions and truly elevate the experience of all FES stakeholders.'
        },
        {
            id: 6,
            title: 'What are the themes in the tool?',
            content:
                'Our Digital Elevation Model brings to life how digital, data and technology will help FES providers elevate their digital ambitions. The key themes include leadership, culture and governance, learner experience, staff experience, curriculum development and underpinning technologies.'
        },
        {
            id: 7,
            title: 'What is a validation statement?',
            content:
                'The DEM toolkit uses validation statements to assess if an organisation is meeting the requirements for each theme and level of the toolkit. Organisations can confirm they meet the requirements of the statement, they are working towards the requirement, they have not started working on this requirement or they do not see this requirement as a priority.'
        },
        {
            id: 8,
            title: 'How do I view my results?',
            content:
                'Once you, or another member of your organisation have completed at least one validation statement within a theme, the themes “goal” page will be available from your dashboard. This will allow you to review your organisations current status and also update any changes for statements within that theme.'
        },
        {
            id: 9,
            title: 'How do I change my answers?',
            content: 'Answers to statements can be made either via the survey or goals page. '
        },
        {
            id: 10,
            title: 'How many people can answer the survey from my organisation?',
            content:
                'The organisation administrator or Jisc, can add multiple people to your organisation. This allows people from the correct knowledge areas to answer the survey. '
        },
        {
            id: 11,
            title: 'A validation statement has already been answered, what shall I do?',
            content:
                'If a validation statement has been answered already, this means that either you or another user from your organisation has already answered this statement in a previous session. If this is the case, you can either continue, by selecting next, or update the answer if required.'
        },
        {
            id: 12,
            title: 'What happens if two people answer the same validation statement?',
            content:
                'If two people answer the same validation statement, the latest answer will be displayed in both the survey and the goals page.'
        },
        {
            id: 13,
            title: 'What should I do if I receive an error message?',
            content:
                'If you receive an error message, please contact <a href="mailto:help@jisc.ac.uk?subject=DEM pilot Support" rel="noreferrer noopener" target="_blank">help@jisc.ac.uk</a> and provide a screenshot.'
        },
        {
            id: 14,
            title: 'How do I provide feedback on the tool?',
            content:
                'Pilot users will be asked to provide feedback, as part of the pilot phase. You will be contacted about this directly. If however you spot any errors, including broken links, please contact <a href="mailto:help@jisc.ac.uk?subject=Digital Elevation Tool" rel="noreferrer noopener" target="_blank">help@jisc.ac.uk</a> and provide a screenshot or URL.'
        },
        {
            id: 15,
            title: 'Can I add more users to the site?',
            content:
                'Yes, if you click on the menu in the top right-hand corner, then “user management” and select “invite user”. Once you have entered the new users email select “send invite”. The user will then receive an email inviting them to the site.'
        },
        {
            id: 16,
            title: 'What is Hotjar?',
            content:
                'Hotjar is a tool for gathering user feedback and heatmaps, this will help the Jisc project team assess the usability and usefulness of the site. The tool is used across Jisc and more information can be found <a target="_blank" href="https://www.hotjar.com/">here</a>'
        }
    ];

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.help.title}</title>
                <link rel='canonical' href={urls.base.url + urls.help.url} />
            </Helmet>

            <main>
                <Container>
                    <section id='fes-help-hero' className={classes.hero}>
                        <Typography variant='h1' className={classes.heroTitle}>
                            {urls.help.text}
                        </Typography>
                        <Typography variant='h3' className={classes.heroSubTitle}>
                            How it works
                        </Typography>
                        <Typography variant='body1' className={classes.heroContent}>
                            Our tool helps you to progress on your digital elevation journey moving from one level to
                            the next in order to improve performance and outcomes.
                        </Typography>
                        <GrowthTree />
                    </section>
                </Container>
                <Container>
                    <section id='fes-help-content' className={classes.content}>
                        <div className={classes.accordions}>
                            {instructions.map((instruction) => {
                                return (
                                    <Accordion
                                        key={instruction.id}
                                        expanded={expanded === instruction.id}
                                        onChange={handleChange(instruction.id)}
                                        className={classes.accordion}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1bh-content'
                                            id='panel1bh-header'
                                        >
                                            <Typography className={classes.heading}>{instruction.title} </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography varient='body1'>{parse(instruction.content)}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </div>
                    </section>
                </Container>

                <HelpBanner />
            </main>
        </>
    );
};

export default Help;
