import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import { getThemeIcon, getLevelIcon } from '../../lib/enums';
import { findPercentage } from '../../lib/helpers';

import JiscLink from '../JiscLink/JiscLink';

import styles from './ThemeResultCard.styles';

const ThemeResultCard = ({ theme }) => {
    const { theme_id: id, theme_title: title, theme_color: color, theme_icon: icon } = theme;
    const classes = styles({ color });

    const { auth } = useAuthContext();

    const [level, setLevel] = useState();
    const [hasReachedALevel, setHasReachedALevel] = useState(false);
    const [goalsLinkLabel, setGoalsLinkLabel] = useState('View goals');

    useEffect(() => {
        const sortedLevels = theme.levels
            .sort(({ id: levelId }, prev) => levelId > prev.id)
            .reverse()
            .sort((a, b) => a.level.id - b.level.id) // this is done due a dumb safari bug where it refuses to keep order
            .reverse();
        // eslint-disable-next-line no-restricted-syntax
        for (const progression of sortedLevels) {
            if (findPercentage(progression) >= 70) {
                setHasReachedALevel(true);
                setLevel(progression.level);

                if (findPercentage(progression) === 100) {
                    setGoalsLinkLabel('You have completed all the goals');
                }
                break;
            } else {
                setLevel(sortedLevels[sortedLevels.length - 1].level);
            }
        }
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.icon}>{getThemeIcon(icon)}</div>

            <Typography variant='h3' className={classes.title}>
                {title}
            </Typography>

            {level && (
                <>
                    <div data-testid='theme-result-card-level-parent' className={classes.level}>
                        <Typography data-testid='theme-result-card-title' variant='h4' className={classes.levelText}>
                            {hasReachedALevel
                                ? `You are currently at ${level.name} level`
                                : `You are currently working towards ${level.name} level`}
                        </Typography>
                        {getLevelIcon(level.id)}
                    </div>
                    <JiscLink
                        type='internal'
                        to={`/surveys/1/institution/${auth.user.institution_id}/themes/${id}/goals`}
                        endIcon='&#8594;'
                        className={classes.link}
                    >
                        <Typography variant='body1'>{goalsLinkLabel}</Typography>
                    </JiscLink>
                </>
            )}
        </div>
    );
};

ThemeResultCard.propTypes = {
    theme: PropTypes.shape({
        theme_id: PropTypes.number.isRequired,
        theme_title: PropTypes.string.isRequired,
        theme_color: PropTypes.string.isRequired,
        theme_icon: PropTypes.number.isRequired,
        levels: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.shape({})
            })
        )
    }).isRequired
};

export default ThemeResultCard;
