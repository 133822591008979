import React from 'react';

function MessageWhiteSvg() {
    return (
        <svg
            data-name='Layer 2'
            xmlns='http://www.w3.org/2000/svg'
            width='1088.77'
            height='481.39'
            viewBox='0 0 1088.77 481.39'
        >
            <g data-name='Layer 1'>
                <g data-name='Group 2184'>
                    <g data-name='Group 2183'>
                        <g data-name='Group 2173'>
                            <g data-name='Group 2171'>
                                <path
                                    data-name='Path 3738'
                                    d='M727.36,435.4H695.22a7.17,7.17,0,0,1-7-5.62l-8.3-36.87a1.43,1.43,0,0,1,1.08-1.72,1.12,1.12,0,0,1,.32,0h60a1.44,1.44,0,0,1,1.44,1.44,1.17,1.17,0,0,1,0,.32l-8.3,36.87a7.15,7.15,0,0,1-7.1,5.58ZM683.1,394l7.9,35.11a4.29,4.29,0,0,0,4.22,3.38h32.14a4.31,4.31,0,0,0,4.22-3.38L739.49,394Z'
                                    fill='#fff'
                                />
                            </g>
                            <g data-name='Group 2172'>
                                <path
                                    data-name='Path 3739'
                                    d='M741.29,394h-60a1.44,1.44,0,0,1-1.44-1.44h0V380.77a7.22,7.22,0,0,1,7.21-7.21h48.46a7.21,7.21,0,0,1,7.21,7.21v11.82a1.44,1.44,0,0,1-1.44,1.44Zm-58.55-2.88h57.11V380.77a4.34,4.34,0,0,0-4.33-4.33H687.06a4.32,4.32,0,0,0-4.32,4.33Z'
                                    fill='#fff'
                                />
                            </g>
                        </g>
                        <g data-name='Group 2182'>
                            <g data-name='Group 2174'>
                                <path
                                    data-name='Path 3740'
                                    d='M711.29,376.44a1.43,1.43,0,0,1-1.44-1.44h0V331.41a1.44,1.44,0,0,1,2.88,0V375A1.44,1.44,0,0,1,711.29,376.44Z'
                                    fill='#fff'
                                />
                            </g>
                            <g data-name='Group 2181'>
                                <g data-name='Group 2177'>
                                    <g data-name='Group 2175'>
                                        <path
                                            data-name='Path 3741'
                                            d='M725.21,346.51a14.57,14.57,0,0,1-10.61-4.44l-.74-.77h0a14.23,14.23,0,0,1-3.72-12.69c2.59-12.58,14.6-26.29,40.51-27l4.14-.11a1.46,1.46,0,0,1,1.08.44,1.44,1.44,0,0,1,.4,1.1l-.3,4.13c-1.55,21.19-11.83,35.77-27.49,39A16.28,16.28,0,0,1,725.21,346.51Zm-9.26-7.2.73.77a12,12,0,0,0,11.22,3.27c14.32-3,23.74-16.56,25.19-36.39l.18-2.54-2.55.07c-24.22.67-35.39,13.2-37.76,24.7A11.37,11.37,0,0,0,716,339.31Z'
                                            fill='#fff'
                                        />
                                    </g>
                                    <g data-name='Group 2176'>
                                        <path
                                            data-name='Path 3742'
                                            d='M714.9,341.75a1.45,1.45,0,0,1-1.44-1.45,1.489,1.489,0,0,1,.16-.65c.27-.53,6.77-12.9,18.71-17a1.449,1.449,0,0,1,1,2.72h0c-10.86,3.75-17,15.5-17.08,15.62a1.46,1.46,0,0,1-1.35.76Z'
                                            fill='#fff'
                                        />
                                    </g>
                                </g>
                                <g data-name='Group 2180'>
                                    <g data-name='Group 2178'>
                                        <path
                                            data-name='Path 3743'
                                            d='M697.37,346.51a16.177,16.177,0,0,1-3.26-.34c-15.67-3.23-25.94-17.81-27.49-39l-.3-4.13a1.44,1.44,0,0,1,.4-1.1,1.5,1.5,0,0,1,1.08-.45l4.14.12c25.91.71,37.91,14.42,40.51,27a14.26,14.26,0,0,1-3.73,12.69h0l-.73.77a14.59,14.59,0,0,1-10.62,4.44Zm-28.06-42.09.18,2.54c1.45,19.83,10.87,33.43,25.2,36.39a12,12,0,0,0,11.21-3.27l.73-.77a11.37,11.37,0,0,0,3-10.12c-2.37-11.5-13.54-24-37.77-24.7Z'
                                            fill='#fff'
                                        />
                                    </g>
                                    <g data-name='Group 2179'>
                                        <path
                                            data-name='Path 3744'
                                            d='M707.68,341.75a1.44,1.44,0,0,1-1.28-.78c-.06-.12-6.25-11.88-17.09-15.62a1.444,1.444,0,0,1,.91-2.74h0c12,4.12,18.44,16.5,18.71,17a1.43,1.43,0,0,1-1.28,2.1Z'
                                            fill='#fff'
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <path
                    data-name='Path 4025'
                    d='M1040.32,177.18A98.93,98.93,0,0,0,982.2,87l-1.2-.53-.24-1.35c-3.81-21.5-15.53-42.81-32.15-58.45C935.64,14.5,913.77,0,883,0,820.74,0,779,64.32,779,124.39c0,2.15.09,4.26.19,6l.09,1.63-1.42.79a67.6,67.6,0,0,0,33.05,126.6c12.69,0,53.95-3.87,74.57-16.35l4-2.38V442a2.18,2.18,0,1,0,4.35,0V242.3l4.68,6.23c11.88,15.82,20.08,22.36,42.47,27.55a66.308,66.308,0,0,0,14.79,1.7c15.72,0,31.22-5.72,44.83-16.54C1024.69,242,1040.32,209,1040.32,177.18Zm-42.53,80.65c-9,7.13-23.5,15.62-41.86,15.62a62.65,62.65,0,0,1-14.06-1.63c-25.07-5.8-31.41-13.41-47.66-36.61l-.48-.67V200.08l1.43-.73a2,2,0,0,0,.56-.4l42.2-42.2a2.13,2.13,0,0,0,.64-1.54,2.18,2.18,0,0,0-3.72-1.54l-36.66,36.66-4.44,4.44V79.33a2.185,2.185,0,1,0-4.37,0v45.82L885,121,848.66,86.45a2.21,2.21,0,0,0-1.5-.6,2.16,2.16,0,0,0-1.44.54v.06l-.17.15a2.19,2.19,0,0,0,.14,3l42.92,40.79.81.77v103.3l-.75.76c-7.27,7.4-23.75,12.18-36.3,14.88-14,3-30.26,5-41.47,5h0a63.23,63.23,0,0,1-28.36-119.8,2.17,2.17,0,0,0,1.19-2.09c-.2-2.91-.38-5.78-.38-8.8,0-58,40-120,99.65-120,51,0,87.75,42.68,93.87,84a2.22,2.22,0,0,0,1.3,1.7A94.21,94.21,0,0,1,1036,177.19c0,30.55-15,62.21-38.21,80.64Z'
                    fill='#fff'
                />
                <path
                    data-name='Path 4026'
                    d='M982.2,87l-1.2-.53-.24-1.35c-3.81-21.5-15.53-42.81-32.15-58.46C935.64,14.5,913.77,0,883,0,820.78,0,779,64.31,779,124.38c0,2.16.1,4.27.2,6l.09,1.63-1.42.79a67.6,67.6,0,0,0,33.05,126.6c12.69,0,53.94-3.87,74.57-16.35l4-2.38V442a2.18,2.18,0,1,0,4.35,0V242.3l4.68,6.23c11.88,15.82,20.08,22.36,42.47,27.55a65.718,65.718,0,0,0,14.79,1.69c15.72,0,31.22-5.71,44.83-16.53,24.19-19.23,39.82-52.22,39.82-84.06a98.926,98.926,0,0,0-58.245-90.187Zm15.59,170.8c-9,7.13-23.5,15.62-41.86,15.62a62.652,62.652,0,0,1-14.06-1.63c-25.07-5.8-31.41-13.41-47.67-36.61l-.47-.67V200.08l1.43-.73a2,2,0,0,0,.56-.4l42.2-42.2a2.18,2.18,0,1,0-3.08-3.08l-36.66,36.66-4.44,4.43V79.33a2.185,2.185,0,1,0-4.37,0v45.82L885,121,848.66,86.45a2.21,2.21,0,0,0-1.5-.6,2.16,2.16,0,0,0-1.44.54v.06l-.17.15a2.17,2.17,0,0,0,.14,3l42.92,40.79.81.77v103.3l-.75.76c-7.27,7.4-23.75,12.18-36.3,14.88-7,1.51-14.56,2.75-21.78,3.61a173.055,173.055,0,0,1-19.69,1.35,63.23,63.23,0,0,1-28.36-119.8,2.17,2.17,0,0,0,1.19-2.09c-.2-2.91-.38-5.78-.38-8.8,0-58,40-120,99.65-120,51,0,87.75,42.68,93.86,84a2.25,2.25,0,0,0,1.31,1.7A94.21,94.21,0,0,1,1036,177.18c0,30.56-15,62.22-38.21,80.65Z'
                    fill='#fff'
                />
                <path
                    data-name='Path 4027'
                    d='M587.86,382.1s-12.16,10-13.73,18.2,1.68,13,6.83,14.25,10.75-3,11.85-10.28S587.86,382.1,587.86,382.1Z'
                    fill='none'
                    stroke='#fff'
                    strokeWidth='2.8'
                />
                <path
                    data-name='Path 4028'
                    d='M583.74,395.44s-5,20.48,3.65,38.46'
                    fill='none'
                    stroke='#fff'
                    strokeLinecap='round'
                    strokeWidth='2.8'
                />
                <path
                    data-name='Path 4029'
                    d='M594.94,433.11s-8.67-4.31-16.26.52'
                    fill='#fff'
                    stroke='#fff'
                    strokeLinecap='round'
                    strokeWidth='2.88'
                />
            </g>
            <g data-name='Layer 4'>
                <path
                    data-name='Path 3763'
                    d='M962.57,278.83a66.6,66.6,0,0,1-15.006-1.711C922.35,271.27,914.78,263.67,899.44,242c-18.16,14.22-62.9,19-77.84,19a68.32,68.32,0,0,1-33.4-127.95c-.11-2-.19-4-.19-6,0-59.67,41.7-123.57,103.75-123.57,52.2,0,90.14,42.72,97.63,84.92a98.67,98.67,0,0,1,58.05,90c0,31.73-15.59,64.61-39.7,83.79C994,273.15,978.35,278.83,962.57,278.83Z'
                    fill='#fff'
                    // style='isolation: isolate'
                />
                <path
                    data-name='Path 3759'
                    d='M585.73,385.36s-10.81,8.26-12,15.39S575,411.88,579.4,413s8.74-2.63,10.27-8.64S585.73,385.36,585.73,385.36Z'
                    fill='#fff'
                    // style='isolation: isolate'
                />
                <path
                    data-name='Path 4027'
                    d='M711.5,331.19a11.4,11.4,0,0,0,3,10.12l.73.76a12,12,0,0,0,11.22,3.28c14.32-3,23.74-16.57,25.19-36.39l.19-2.54-2.55.07C725,307.16,713.87,319.69,711.5,331.19Z'
                    fill='#fff'
                />
                <path
                    data-name='Path 4028'
                    d='M670.4,306.49l-2.55-.07L668,309c1.45,19.82,10.86,33.43,25.19,36.38a12,12,0,0,0,11.21-3.27l.74-.76a11.39,11.39,0,0,0,3-10.12C705.79,319.69,694.62,307.16,670.4,306.49Z'
                    fill='#fff'
                />
                <path
                    data-name='Path 4029'
                    d='M735.52,373.56H687.06a7.22,7.22,0,0,0-7.21,7.21v11.81a1.242,1.242,0,0,0,0,.33l8.3,36.87a7.17,7.17,0,0,0,7,5.62h32.14a7.15,7.15,0,0,0,7-5.62l8.3-36.87h0a1.12,1.12,0,0,0,0-.32V380.77a7.21,7.21,0,0,0-7.071-7.21Z'
                    fill='#fff'
                />
            </g>
            <g data-name='Layer 2'>
                <path
                    data-name='Path 4030'
                    d='M622,431.9c-178.38,0-368.7,37.1-622,49.49H1088.77S992,431.9,622,431.9Z'
                    fill='#fff'
                    // style='isolation: isolate'
                />
            </g>
        </svg>
    );
}

export default MessageWhiteSvg;
