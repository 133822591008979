import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const ReportContext = createContext();

const ReportContextProvider = ({ children }) => {
    const [selectedInstitutions, setSelectedInstitutions] = useState([]);
    const context = {
        selectedInstitutions,
        setSelectedInstitutions
    };

    return <ReportContext.Provider value={context}>{children}</ReportContext.Provider>;
};

ReportContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export { ReportContext, ReportContextProvider };
