import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    chartGrid: {
        width: '140px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        gap: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    }
}));

export default styles;
