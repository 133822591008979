import React from 'react';

function Elevate() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'>
            <g data-name='Group 2437' transform='translate(6459 -13973)'>
                <g data-name='Group 2432'>
                    <rect
                        data-name='Rectangle 2394'
                        width='80'
                        height='80'
                        transform='translate(-6459 13973)'
                        fill='none'
                    />
                    <g data-name='elevate icon 2' transform='translate(-6441.533 13979.999)'>
                        <g data-name='Layer 1' transform='translate(0 0)'>
                            <path
                                data-name='Path 4126'
                                d='M36.506,12.867C35.209,6.552,29.536,0,21.333,0c-9.67,0-16.1,9.88-16.1,19.118v.5a10.889,10.889,0,0,0,5.648,20.166A31.985,31.985,0,0,0,21.163,37.8V63.721H13.641a1.415,1.415,0,0,0,0,2.83H31.567a1.415,1.415,0,0,0,0-2.83H23.993V39.31a9.657,9.657,0,0,0,5.477,2.843,10.483,10.483,0,0,0,2.4.275h0a11.479,11.479,0,0,0,7.181-2.621A17.113,17.113,0,0,0,45.22,26.783,15.409,15.409,0,0,0,36.506,12.867ZM23.993,30.662l6.132-6.093a1.4,1.4,0,0,0,0-1.965,1.428,1.428,0,0,0-2,0l-4.075,4.088v-14.1a1.415,1.415,0,1,0-2.83,0v4.075l-4.1-3.839a1.441,1.441,0,0,0-1.009-.393,1.415,1.415,0,0,0-.93,2.437l5.988,5.7V34.593C19.459,35.9,14,36.951,10.89,36.951h0A8.072,8.072,0,0,1,7.273,21.673a1.415,1.415,0,0,0,.773-1.31V19.17c0-7.862,5.359-16.287,13.339-16.287,6.774,0,11.688,5.7,12.514,11.216a1.415,1.415,0,0,0,.852,1.1,12.566,12.566,0,0,1,7.7,11.61,14.322,14.322,0,0,1-5.136,10.85A8.347,8.347,0,0,1,30.125,39.4c-3.158-.734-3.931-1.494-6.119-4.678Z'
                                transform='translate(-0.04)'
                                fill='#aa1d5d'
                            />
                        </g>
                        <g data-name='Layer 3' transform='translate(1.799 3.407)'>
                            <g data-name='Group 2262' opacity='0.5'>
                                <path
                                    data-name='Path 4127'
                                    d='M21.261,33.472h0a1.31,1.31,0,0,1,1.14.6c2.463,3.512,3.092,4.272,6.381,5.032a8.334,8.334,0,0,0,7.154-1.8,14.322,14.322,0,0,0,5.136-10.85,12.592,12.592,0,0,0-7.692-11.544,1.415,1.415,0,0,1-.852-1.1C31.7,8.3,26.79,2.6,20.016,2.6c-7.98-.066-13.339,8.386-13.339,16.248v1.192a1.415,1.415,0,0,1-.773,1.31,8.072,8.072,0,0,0,3.656,15.3h0c3.328,0,9.369-1.14,10.574-2.621a1.415,1.415,0,0,1,1.127-.563Z'
                                    transform='translate(-1.413 -2.6)'
                                    fill='#aa1d5d'
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Elevate;
