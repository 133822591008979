import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';

import { getThemeIcon } from '../../lib/enums';
import { useSurveyContext } from '../../lib/hooks/useSurveyContext';

import JiscButton from '../JiscButton/JiscButton';

import styles from './SubThemePicker.styles';

function SubThemePicker({ setIsAnsweringQuestions, theme }) {
    const { selectedSubThemes, setSelectedSubThemes } = useSurveyContext();
    const classes = styles({ color: theme.theme_color });

    const handleCheckboxToggle = (e, subTheme) => {
        const checkedStatus = e.target.checked;
        const selectedSubThemesSet = new Set(selectedSubThemes);
        if (checkedStatus) {
            selectedSubThemesSet.add(subTheme);
        } else {
            selectedSubThemesSet.delete(subTheme);
        }
        const arrayOfSubThemes = Array.from(selectedSubThemesSet);

        arrayOfSubThemes.sort((a, b) => {
            return a.sub_theme_id - b.sub_theme_id;
        });
        setSelectedSubThemes(arrayOfSubThemes);
    };

    return (
        <article className={classes.root}>
            <div className={classes.heading}>
                {getThemeIcon(theme.theme_icon)}
                <Typography variant='h1'>{`${theme.theme_title}`}</Typography>
            </div>

            <div className={classes.checkboxGrid}>
                <div className={classes.checkboxGridHeading}>
                    <Typography variant='h2'>Select the areas you want to focus on</Typography>
                </div>
                <div className={classes.checkboxContainer}>
                    {theme &&
                        Object.values(theme.sub_themes).map((subTheme) => {
                            return (
                                <FormControlLabel
                                    data-testid={subTheme.sub_theme_title}
                                    key={subTheme.sub_theme_id}
                                    className={classes.checkboxItem}
                                    control={
                                        <Checkbox
                                            name={`checkbox-${subTheme.sub_theme_id}`}
                                            className={classes.checkbox}
                                            color='default'
                                            inputProps={{ 'aria-label': 'decorative checkbox' }}
                                            onChange={(e) => handleCheckboxToggle(e, subTheme)}
                                        />
                                    }
                                    label={subTheme.sub_theme_title}
                                />
                            );
                        })}
                </div>
                <JiscButton
                    dataTestId='lets do this'
                    disabled={!selectedSubThemes.length > 0}
                    onClick={() => setIsAnsweringQuestions(true)}
                    className={classes.button}
                >
                    Continue your assessment
                </JiscButton>
            </div>
        </article>
    );
}

SubThemePicker.propTypes = {
    setIsAnsweringQuestions: PropTypes.func.isRequired,
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired
};

export default SubThemePicker;
