import React, { useContext, useState } from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './reportSelector.styles';
import { ReportContext } from '../../pages/Reports/ReportContext';
import { postJson, get } from '../../lib/api';
import JiscButton from '../JiscButton/JiscButton';

function ReportSelector({ processing, handleSetProcessing }) {
    const reportFetcher = async (reportType, reportFormat, selectedInstitutions) => {
        const result = await postJson(
            '/report',
            JSON.stringify({
                institutions: selectedInstitutions,
                reportTypes: Object.keys(reportType).filter((k) => reportType[k] === true),
                reportFormats: Object.keys(reportFormat).filter((k) => reportFormat[k] === true)
            }),
            true
        );

        return result;
    };

    const reportContext = useContext(ReportContext);
    const classes = styles();
    const [reportType, setReportType] = useState({
        yourCurrentAnswers: true,
        versionHistoryAllAnswers: false,
        simpleOverview: false
    });
    const [reportFormat, setReportFormat] = useState({
        xls: true,
        csv: false
    });

    const reportTypeChanged = (e) => {
        const newReportTypestate = { ...reportType };
        switch (e.target.id) {
            case 'version_history_answers':
                newReportTypestate.versionHistoryAllAnswers = e.target.checked;
                break;
            case 'current_answers':
                newReportTypestate.yourCurrentAnswers = e.target.checked;
                break;
            case 'simple_overview':
                newReportTypestate.simpleOverview = e.target.checked;
                break;
            default:
                break;
        }
        setReportType(newReportTypestate);
    };
    const reportFormatChanged = (e) => {
        const newReportFormatState = { ...reportFormat };
        switch (e.target.id) {
            case 'xls':
                newReportFormatState.xls = e.target.checked;
                break;
            case 'csv':
                newReportFormatState.csv = e.target.checked;
                break;
            default:
                break;
        }
        setReportFormat(newReportFormatState);
    };
    const exportOnclick = async () => {
        const selectedInstitutions = reportContext.selectedInstitutions
            .filter((institution) => institution.value !== 'All')
            .map((i) => i.id);
        if (
            reportContext.selectedInstitutions.filter((institution) => institution.value.toUpperCase() === 'ALL')
                .length > 0
        ) {
            selectedInstitutions.push('all');
        }

        const allReportTypesSelected = Object.values(reportType).every((v) => v === true);

        // Only set processing if all reports are selected and all institutions are selected
        if (allReportTypesSelected && selectedInstitutions.includes('all')) {
            handleSetProcessing(true);
            await reportFetcher(reportType, reportFormat, selectedInstitutions);
            return;
        }

        const result = await reportFetcher(reportType, reportFormat, selectedInstitutions);

        const { key } = result.data;
        if (key) {
            const { data } = await get(`/report/${key}`);
            const link = document.createElement('a');
            link.href = data;
            link.setAttribute('download', `${key}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        reportContext.setSelectedInstitutions([]);
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Paper variant='outlined' className={classes.greyPanel}>
                    <Typography variant='h6'>Report Type</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <label htmlFor='current_answers'>
                                <input
                                    type='checkbox'
                                    id='current_answers'
                                    className={classes.checkField}
                                    defaultChecked={reportType.yourCurrentAnswers}
                                    onChange={reportTypeChanged}
                                />
                                Your current answers
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <label htmlFor='version_history_answers'>
                                <input
                                    type='checkbox'
                                    id='version_history_answers'
                                    className={classes.checkField}
                                    defaultChecked={reportType.versionHistoryAllAnswers}
                                    onChange={reportTypeChanged}
                                />
                                Version history of all answers
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <label htmlFor='simple_overview'>
                                <input
                                    type='checkbox'
                                    id='simple_overview'
                                    className={classes.checkField}
                                    defaultChecked={reportType.simpleOverview}
                                    onChange={reportTypeChanged}
                                />
                                Simple overview
                            </label>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper variant='outlined' className={classes.greyPanel}>
                    <Typography variant='h6'>Format</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <label htmlFor='xls'>
                                <input
                                    type='checkbox'
                                    id='xls'
                                    className={classes.checkField}
                                    defaultChecked={reportFormat.xls}
                                    onChange={reportFormatChanged}
                                />
                                XLSX
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <label htmlFor='csv'>
                                <input
                                    type='checkbox'
                                    id='csv'
                                    className={classes.checkField}
                                    defaultChecked={reportFormat.csv}
                                    onChange={reportFormatChanged}
                                />
                                CSV
                            </label>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={1}>
                <JiscButton variant='primary'>Cancel</JiscButton>
            </Grid>
            <Grid item xs={2}>
                <JiscButton
                    variant='primary'
                    onClick={exportOnclick}
                    disabled={
                        processing ||
                        (reportContext &&
                            reportContext.selectedInstitutions &&
                            Array.isArray(reportContext.selectedInstitutions) &&
                            reportContext.selectedInstitutions.length === 0) ||
                        (!reportType.yourCurrentAnswers &&
                            !reportType.versionHistoryAllAnswers &&
                            !reportType.simpleOverview) ||
                        (!reportFormat.xls && !reportFormat.csv)
                    }
                >
                    Export results
                </JiscButton>
            </Grid>
        </Grid>
    );
}

ReportSelector.propTypes = {
    processing: PropTypes.bool,
    handleSetProcessing: PropTypes.func
};

ReportSelector.defaultProps = {
    processing: false,
    handleSetProcessing: () => {}
};

export default ReportSelector;
