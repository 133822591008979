import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash';

import styles from './SubThemeSidebarCard.styles';
import { useSurveyContext } from '../../lib/hooks/useSurveyContext';
import { useQuestionContext } from '../../lib/hooks/useQuestionContext';

function SubThemeSidebarCard({ theme, subTheme }) {
    const { currentQuestion, totalAnswered } = useSurveyContext();
    const { questions, setQuestionIndex, setLastCompetencyId } = useQuestionContext();
    const classes = styles({ color: theme.theme_color });

    const handleSkip = (competencyOptions) => {
        // If a user clicks on the assessment areas before loading questions, don't do anything
        if (questions === undefined || questions.length === 0) return;

        const compQId = Object.keys(competencyOptions)[0];

        const subThemeFirstQuestionIndex = questions.findIndex((item) => item.competency_option_id === Number(compQId));

        setQuestionIndex(subThemeFirstQuestionIndex);
        setLastCompetencyId(compQId);
    };

    return (
        <div
            data-testid={subTheme.sub_theme_title}
            id={`subThemeCard-${subTheme.sub_theme_id}`}
            className={`${classes.root}`}
        >
            <Typography
                data-testid={subTheme.sub_theme_title}
                className={classes.title}
                variant='body2'
                color='initial'
            >
                {subTheme.sub_theme_title}
            </Typography>
            <div>
                {Object.values(subTheme.competencies).length ? (
                    Object.values(subTheme.competencies).map((comp) => {
                        let totalQuestionsInComp = 0;
                        let totalQuestionsWithResponses = 0;
                        let currentCompetency = false;

                        Object.values(comp.competency_statements).forEach((statement) => {
                            totalQuestionsInComp += Object.values(statement.competency_options).length;

                            Object.values(statement.competency_options).forEach((option) => {
                                if (typeof currentQuestion !== 'undefined') {
                                    if (option.competency_option_id === currentQuestion.id) {
                                        currentCompetency = true;
                                    }
                                }
                                if (!isEmpty(option.responses)) {
                                    totalQuestionsWithResponses += 1;
                                }
                            });
                        });

                        return (
                            <div
                                className={`${classes.compItem} ${currentCompetency ? classes.active : ''}`}
                                key={comp.competency_id}
                            >
                                <button
                                    type='button'
                                    className={`${classes.subThemeButton}  ${currentCompetency ? classes.active : ''}`}
                                    onClick={() =>
                                        handleSkip(Object.values(comp.competency_statements)[0].competency_options)
                                    }
                                >
                                    {comp.competency_title}
                                </button>
                                <span>
                                    {totalAnswered[comp.competency_id] || totalQuestionsWithResponses}/
                                    {totalQuestionsInComp}
                                </span>
                            </div>
                        );
                    })
                ) : (
                    <Skeleton variant='rect' height={30} />
                )}
            </div>
        </div>
    );
}

SubThemeSidebarCard.propTypes = {
    subTheme: PropTypes.shape({
        sub_theme_id: PropTypes.number,
        sub_theme_title: PropTypes.string,
        competencies: PropTypes.shape({})
    }).isRequired,
    theme: PropTypes.shape({
        theme_id: PropTypes.number.isRequired,
        theme_title: PropTypes.string.isRequired,
        theme_color: PropTypes.string.isRequired,
        theme_icon: PropTypes.number.isRequired,
        levels: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};

export default SubThemeSidebarCard;
