import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(4, 0)
    },
    divider: {
        margin: theme.spacing(1, 0)
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',

        zIndex: 1,
        '&:focus': {
            outline: [['.25rem', 'solid', theme.palette.jisc.yellowLight]]
        }
    },
    siteTitleContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        color: theme.palette.fes.smallText,
        textDecoration: 'none',
        marginLeft: theme.spacing(2)
    },
    menuButtonWrap: {
        position: 'relative'
    },
    menuButton: {
        padding: theme.spacing(1, 1.5)
    },
    menuIcon: {
        marginRight: theme.spacing(1.5)
    },
    dropDownMenu: {
        top: '100% !important',
        left: 'auto !important',
        right: '0 !important',
        transform: 'none !important',
        willChange: 'unset !important',
        zIndex: 3
    }
}));

export default styles;
