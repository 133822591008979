import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import styles from './JiscButton.styles';

const JiscButton = ({
    children,
    variant,
    startIcon,
    endIcon,
    disabled,
    onClick,
    id,
    fullWidth,
    uppercase,
    type,
    className,
    isLink,
    href,
    linkType,
    inline,
    dataTestId
}) => {
    const classes = styles({ disabled, variant });

    const classNames = clsx(
        {
            [classes.root]: variant !== 'link' && disabled === false,
            [classes.link]: variant === 'link',
            [classes.primary]: variant === 'primary',
            [classes.secondary]: variant === 'secondary',
            [classes.ghost]: variant === 'ghost',
            [classes.ghostDarkBg]: variant === 'ghost-dark',
            [classes.destructive]: variant === 'destructive',
            [classes.disabled]: disabled === true,
            [classes.fullWidth]: fullWidth === true,
            [classes.uppercase]: uppercase === true,
            [classes.inline]: inline === true
        },
        className
    );

    return isLink ? (
        <>
            {linkType === 'internal' ? (
                <Link data-testid={dataTestId} className={classNames} disabled={disabled} to={href} type={type}>
                    <span className={classes.startIcon}>{startIcon}</span>
                    {children}
                    <span className={classes.endIcon}>{endIcon}</span>
                </Link>
            ) : (
                <a
                    data-testid={dataTestId}
                    target='_blank'
                    rel='noreferrer'
                    className={classNames}
                    disabled={disabled}
                    href={href}
                    type={type}
                >
                    <span className={classes.startIcon}>{startIcon}</span>
                    {children}
                    <span className={classes.endIcon}>{endIcon}</span>
                </a>
            )}
        </>
    ) : (
        <button
            data-testid={dataTestId}
            className={classNames}
            disabled={disabled}
            id={id}
            onClick={onClick}
            // See discussion here https://github.com/yannickcr/eslint-plugin-react/issues/1555
            // eslint-disable-next-line react/button-has-type
            type={type}
        >
            {startIcon && <span className={classes.startIcon}>{startIcon}</span>}
            {children}
            {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
        </button>
    );
};

JiscButton.defaultProps = {
    className: null,
    disabled: false,
    endIcon: null,
    fullWidth: false,
    href: null,
    id: null,
    isLink: false,
    linkType: 'internal',
    onClick: null,
    startIcon: null,
    type: 'button',
    uppercase: false,
    variant: 'primary',
    inline: false,
    dataTestId: undefined
};

JiscButton.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    endIcon: PropTypes.node,
    fullWidth: PropTypes.bool,
    href: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLink: PropTypes.bool,
    linkType: PropTypes.string,
    onClick: PropTypes.func,
    startIcon: PropTypes.node,
    type: PropTypes.string,
    uppercase: PropTypes.bool,
    variant: PropTypes.string,
    inline: PropTypes.bool,
    dataTestId: PropTypes.string
};

export default JiscButton;
