import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormControl, FormHelperText, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContentManagementContext } from '../../../../lib/hooks/useContentManagementContext';

import ContentManagementLayout from '../../../../layout/ContentManagementLayout/ContentManagementLayout';

import JiscButton from '../../../../components/JiscButton/JiscButton';
import Loader from '../../../../components/Loader/Loader';
import HtmlEditor from '../../../../components/HtmlEditor/HtmlEditor';
import CustomModal from '../../../../components/CustomModal/CustomModal';

import * as api from '../../../../lib/api';
import urls from '../../../../config/urls';
import styles from './EditResource.styles';

let mounted;

const EditResource = () => {
    const classes = styles();
    const { setActivePage } = useContentManagementContext();
    const history = useHistory();
    const { id } = useParams();

    // Data
    const [resource, setResource] = useState();

    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState();

    const [excerpt, setExcerpt] = useState('');
    const [excerptError, setExcerptError] = useState();

    const [body, setBody] = useState('');
    const [bodyError, setBodyError] = useState();

    // Error
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState();

    // Delete
    const [deleteError, setDeleteError] = useState();
    const [comfirmModal, setComfirmModal] = useState(false);

    const deleteResource = async () => {
        try {
            const { status } = await api.del(`/resources/${id}`);

            if (status !== 200) throw Error('There was a problem deleting this resource.');
            setComfirmModal(false);

            history.push(urls.contentManagement.resources.url);
        } catch (err) {
            setDeleteError(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !excerpt || !body) {
            if (!title) {
                setTitleError('You must supply a title.');
                return;
            }
            if (!excerpt) {
                setExcerptError('You must supply a short description.');
                setTitleError(undefined);
                return;
            }
            if (!body) {
                setBodyError('You must supply body conent.');
                setExcerptError(undefined);
                setTitleError(undefined);
                return;
            }
        }

        setLoading(true);
        setTitleError(undefined);
        setExcerptError(undefined);
        setBodyError(undefined);

        try {
            const { status } = await api.put(`/resources/${id}`, {
                title,
                excerpt,
                body
            });

            if (status !== 200) throw Error('There was a problem updating this resource.');

            setErrorMessage(undefined);
            setError(undefined);
            setLoading(false);
            history.push(urls.contentManagement.resources.url);
        } catch (err) {
            setErrorMessage(err.message);
            setError(true);
            setLoading(false);
        }
    };

    const getData = async () => {
        try {
            if (mounted) {
                const { data } = await api.get(`/resources/${id}`);
                setResource(data);
                setTitle(data.title);
                setExcerpt(data.excerpt);
                setBody(data.body);

                setLoading(false);
            }
        } catch (err) {
            setErrorMessage(`There was a problem getting resource with id ${id}.`);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        mounted = true;
        setActivePage(urls.contentManagement.resources.edit);
        setLoading(true);
        getData();

        return () => {
            mounted = false;
        };
    }, []);
    return (
        <ContentManagementLayout>
            {!loading && error && errorMessage && (
                <Alert severity='error' className={classes.alert}>
                    <Typography varient='body2'>{errorMessage}</Typography>
                </Alert>
            )}

            {!loading && resource && (
                <>
                    <header className={classes.titleHeader}>
                        <Typography variant='h5' component='h1'>
                            {urls.contentManagement.resources.edit.text}
                        </Typography>
                        <div>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='check' role='button' />}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Update
                            </JiscButton>
                            <JiscButton
                                type='submit'
                                startIcon={<FontAwesomeIcon icon='trash-alt' role='button' />}
                                onClick={() => setComfirmModal(true)}
                                className={classes.trash}
                            >
                                Delete
                            </JiscButton>
                            <JiscButton
                                isLink
                                linkType='internal'
                                href={urls.contentManagement.resources.url}
                                variant='ghost'
                                startIcon={<FontAwesomeIcon icon='arrow-alt-circle-left' role='button' />}
                            >
                                Back to all
                            </JiscButton>
                        </div>
                    </header>

                    <form id='edit-resource' className={classes.form}>
                        <FormControl fullWidth>
                            <TextField
                                label='Title'
                                placeholder='Resource title...'
                                variant='outlined'
                                error={titleError}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            {titleError && <FormHelperText>{titleError}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                label='Excerpt'
                                placeholder='Short description...'
                                variant='outlined'
                                error={excerptError}
                                value={excerpt}
                                onChange={(e) => setExcerpt(e.target.value)}
                            />
                            {excerptError && <FormHelperText>{excerptError}</FormHelperText>}
                        </FormControl>

                        <HtmlEditor
                            title='Body'
                            placeholder='Start typing here...'
                            error={bodyError}
                            content={body}
                            setContent={setBody}
                        />
                    </form>

                    <CustomModal
                        dialogTitle='delete-resource'
                        title='Are you sure you want to delete this resource?'
                        open={comfirmModal}
                        onClose={() => {
                            setComfirmModal(false);
                        }}
                        buttonText='Confirm delete'
                        successCallback={deleteResource}
                        error={deleteError}
                        errorMessage={setDeleteError}
                    />
                </>
            )}
            {loading && <Loader />}
        </ContentManagementLayout>
    );
};

export default EditResource;
