import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createTheme({
    palette: {
        background: {
            default: '#fff'
        },
        primary: {
            main: '#006699'
        },
        secondary: {
            main: '#b02887'
        },
        text: {
            primary: '#333',
            secondary: '#666'
        },
        // Jisc colour palette
        black: {
            5: '#f4f4f4',
            20: '#d1dfcf',
            35: '#b1afaf',
            50: '#8f8d8d',
            65: '#6E6C6B',
            80: '#4b4947',
            100: '#000'
        },
        white: '#fff',
        blue: '#006699',
        navy: '#004777',
        yellow: '#ffdd66',
        twitter: '#00aced',
        facebook: '#3b5998',
        youtube: '#ee1111',
        jisc: {
            additionalGrey: '#FAFAFA',
            black: '#000000',
            blue: '#006699',
            blueDark: '#004777',
            darkGrey: '#9BA7B0',
            veryDarkGrey: '#00000099',
            darkOrange: '#9F3515',
            error: '#F44336',
            green: '#458525',
            grey: '#F2F2F2',
            greyLight: '#F7F7F7',
            greySilver: '#D9D9D9',
            greyDisabled: '#8f8f8f',
            indigo: '#00557F',
            info: '#2196F3',
            lime: '#B2BB1C',
            maroon: '#820036',
            midGrey: '#B9C9D5',
            olive: '#8A7300',
            orange: '#E85E13',
            pink: '#E61554',
            primary: '#006699',
            purple: '#660099',
            silver: '#B2B2B2',
            delete: '#E50E45',
            greyHeader: '#7F7F7F',
            selectedGrey: '#E5E5E5',
            blackText: '#333333',
            success: '#4CAF50',
            supportText: '#525E67',
            textGrey: '#2C3841',
            violet: '#B71A8B',
            warning: '#FF9800',
            white: '#FFFFFF',
            yellow: '#F9B000',
            yellowLight: '#FFDD66',
            blueLink: '#007DBA',
            inputGreen: '#00857D',
            tab: '#CCCCCC',
            lockedTab: '#E8F4FD'
        },
        fes: {
            headline: '#666666',
            smallText: '#4C4C4C',
            tundoraDark: '#404040',
            jade: '#008D82',
            blue: '#0083BF',
            navy: '#14416D',
            purple: '#6D2077',
            grape: '#AA1D5D',
            aquaSqueeze: '#e9f5f4'
        }
    },

    overrides: {
        // Used by Material-UI components.
        MuiTypography: {
            h1: {
                fontSize: '2.5rem',
                fontWeight: 400,
                lineHeight: '3.5rem',
                letterSpacing: 'unset',
                [breakpoints.up('sm')]: {
                    fontSize: '2.75rem',
                    lineHeight: '3.5rem'
                },
                [breakpoints.up('sm')]: {
                    fontSize: '3rem',
                    lineHeight: '4rem'
                }
            },
            h3: {
                fontSize: '1.5rem',
                fontWeight: 500,
                lineHeight: '2rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                [breakpoints.up('sm')]: {
                    fontSize: '1.75rem',
                    lineHeight: '2.5rem'
                },
                [breakpoints.up('md')]: {
                    fontSize: '2rem',
                    lineHeight: '3rem'
                },
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            subtitle2: {
                fontSize: '1.25rem',
                fontWeight: 400,
                lineHeight: '2rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            body1: {
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: '1.5rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            body2: {
                fontSize: '.875rem',
                fontWeight: 400,
                lineHeight: '1.43rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            }
        },
        typography: {
            // Used by our component styles.
            h2: {
                fontSize: '2rem',
                fontWeight: 400,
                lineHeight: '3rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                [breakpoints.up('sm')]: {
                    fontSize: '2.25rem',
                    lineHeight: '3rem'
                },
                [breakpoints.up('md')]: {
                    fontSize: '2.5rem',
                    lineHeight: '3.5rem'
                },
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            h3: {
                fontSize: '1.5rem',
                fontWeight: 500,
                lineHeight: '2rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                [breakpoints.up('sm')]: {
                    fontSize: '1.75rem',
                    lineHeight: '2.5rem'
                },
                [breakpoints.up('md')]: {
                    fontSize: '2rem',
                    lineHeight: '3rem'
                },
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            h4: {
                fontSize: '1.25rem',
                fontWeight: 400,
                lineHeight: '2rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                [breakpoints.up('md')]: {
                    fontSize: '1.5rem',
                    lineHeight: '2rem'
                },
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            h5: {
                fontSize: '1rem',
                fontWeight: 500,
                lineHeight: '1.5rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                [breakpoints.up('md')]: {
                    fontSize: '1.25rem',
                    lineHeight: '2rem'
                },
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            h6: {
                fontSize: '.875rem',
                fontWeight: 700,
                lineHeight: '1.5rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            subtitle2: {
                fontSize: '1.25rem',
                fontWeight: 400,
                lineHeight: '2rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            body1: {
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: '1.5rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            },
            body2: {
                fontSize: '.875rem',
                fontWeight: 400,
                lineHeight: '1.43rem',
                letterSpacing: 'unset',
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                '& b': {
                    fontWeight: 'bolder'
                },
                '& strong': {
                    fontWeight: 'bolder'
                }
            }
        },
        MuiRadio: {
            root: {
                color: '#0083BF'
            },
            colorSecondary: {
                '&$checked': {
                    color: '#0083BF'
                }
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 30
            }
        },
        MuiAccordion: {
            root: {
                border: '1px solid rgba(0, 0, 0, .125)',
                boxShadow: 'none',
                '&:not(:last-child)': {
                    // borderBottom: 0
                },
                '&:before': {
                    display: 'none'
                },
                '&$expanded': {
                    marginBottom: '1rem'
                }
            },
            expanded: {}
        },
        MuiAccordionSummary: {
            root: {
                backgroundColor: 'transparent',
                marginBottom: -1,
                minHeight: 56,
                '&$expanded': {
                    minHeight: 56
                }
            },
            content: {
                '&$expanded': {
                    margin: '12px 0'
                }
            },
            expanded: {}
        },
        MuiAccordionDetails: {
            root: {
                padding: '2rem 3rem'
            }
        }
    },

    spacing: (factor) => `${0.5 * factor}rem`
});

export default theme;
