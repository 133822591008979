import React, { useContext, useRef, useState } from 'react';
import { TextField, Chip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from '../../lib/api';
import styles from './autocomplete.styles';
import { ReportContext } from '../../pages/Reports/ReportContext';

function Autocomplete() {
    const searchRef = useRef();
    const [displayAutocomplete, setDisplayAutocomplete] = useState(false);
    const reportContext = useContext(ReportContext);
    const [results, setResults] = useState([]);
    const classes = styles();

    const onClick = (e) => {
        reportContext.setSelectedInstitutions([
            ...reportContext.selectedInstitutions,
            {
                id: Number(e.target.getAttribute('data-id')),
                value: e.target.innerText
            }
        ]);
        setDisplayAutocomplete(false);
        searchRef.current.querySelector('input').value = '';
    };

    const onChange = async (e) => {
        if (e.target.value.length === 0) {
            setDisplayAutocomplete(false);
        } else {
            const { data: searchResults } = await get(`/institutions?search=${e.target.value}`);
            if (e.target.value.startsWith('a') || e.target.value.startsWith('A')) {
                searchResults.institutions.unshift({
                    id: 'all',
                    name: 'All'
                });
            }
            searchResults.institutions =
                reportContext.selectedInstitutions &&
                Array.isArray(reportContext.selectedInstitutions) &&
                searchResults.institutions.filter(
                    (i) => !reportContext.selectedInstitutions.some((s) => s.value === i.name)
                );
            setResults(searchResults);
            setDisplayAutocomplete(true);
        }
    };

    const moveHighlightUp = () => {
        if (!results.institutions.length > 0) return;
        if (!results.institutions.filter((i) => i.highlighted)) return;
        const resultsCopy = { ...results };

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < resultsCopy.institutions.length; i++) {
            const institution = resultsCopy.institutions[i];
            if (institution.highlighted === true && i > 0) {
                institution.highlighted = false;
                Object.defineProperty(resultsCopy.institutions[i - 1], 'highlighted', {
                    value: true,
                    writable: true
                });
                break;
            }
        }
        setResults(resultsCopy);
    };

    const moveHighlightDown = () => {
        if (!results.institutions.length > 0) return;
        if (results.institutions.filter((i) => i.highlighted).length === 0) {
            const highlightedResults = { ...results };
            highlightedResults.institutions[0].highlighted = true;
            setResults(highlightedResults);
            return;
        }
        const resultsCopy = { ...results };

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < resultsCopy.institutions.length; i++) {
            const institution = resultsCopy.institutions[i];
            if (institution.highlighted === true && i < resultsCopy.institutions.length - 1) {
                institution.highlighted = false;
                Object.defineProperty(resultsCopy.institutions[i + 1], 'highlighted', {
                    value: true,
                    writable: true
                });

                break;
            }
        }
        setResults(resultsCopy);
    };

    const makeSelection = () => {
        if (!results.institutions.length > 0) return;
        if (!results.institutions.filter((i) => i.highlighted)) return;
        const selectedItem = results.institutions.filter((i) => i.highlighted)[0];
        reportContext.setSelectedInstitutions([
            ...reportContext.selectedInstitutions,
            {
                id: selectedItem.id,
                value: selectedItem.name
            }
        ]);
        setDisplayAutocomplete(false);
        searchRef.current.querySelector('input').value = '';
    };

    const onKeyDown = async (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                e.stopPropagation();
                moveHighlightDown();
                break;
            case 'ArrowUp':
                e.preventDefault();
                e.stopPropagation();
                moveHighlightUp();
                break;
            case 'Enter':
                e.preventDefault();
                e.stopPropagation();
                makeSelection();
                break;
            default:
                break;
        }
    };

    const onDelete = (e) => {
        const id = Number(e.target.closest('div').getAttribute('data-id'));
        reportContext.setSelectedInstitutions(reportContext.selectedInstitutions.filter((item) => item.id !== id));
    };
    return (
        <div className={classes.wrapper}>
            <div
                className={classes.autoComplete}
                style={{
                    display: displayAutocomplete ? 'block' : 'none',
                    top: '55px'
                }}
            >
                <ul role='presentation' onClick={onClick}>
                    {results.institutions &&
                        results.institutions.map((result) => (
                            <li className={result.highlighted ? 'highlighted' : ''} key={result.id} data-id={result.id}>
                                {result.name}
                            </li>
                        ))}
                </ul>
            </div>
            <TextField
                type='text'
                fullWidth
                variant='outlined'
                InputProps={{
                    endAdornment: <FontAwesomeIcon icon='search' />
                }}
                onChange={onChange}
                onKeyDown={onKeyDown}
                ref={searchRef}
            />
            <div className={classes.selectedItemsArea}>
                {reportContext.selectedInstitutions &&
                    Array.isArray(reportContext.selectedInstitutions) &&
                    reportContext.selectedInstitutions.map((selectedItem) => (
                        <Chip
                            data-id={selectedItem.id}
                            key={selectedItem.id}
                            clickable
                            onDelete={onDelete}
                            color='default'
                            label={selectedItem.value}
                            size='medium'
                        />
                    ))}
            </div>
        </div>
    );
}

export default Autocomplete;
