import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    ClickAwayListener,
    Container,
    Divider,
    Grow,
    MenuItem,
    MenuList,
    ListItemIcon,
    Paper,
    Popper,
    Typography,
    ListItemText
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuthContext } from '../../lib/hooks/useAuthContext';

import * as enums from '../../lib/enums';
import JiscLogo from '../../lib/assets/JiscLogo';
import urls from '../../config/urls';
import styles from './SiteHeader.styles';

function SiteHeader() {
    const classes = styles();

    const { auth, logout } = useAuthContext();

    const [menuOpen, setMenuOpen] = useState(false);
    const menuAnchorRef = useRef(null);

    const handleMenuToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    const handleMenuClose = (event) => {
        if (menuAnchorRef.current && menuAnchorRef.current.contains(event.target)) return;
        setMenuOpen(false);
    };

    const handleMenuListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    };

    return (
        <Container maxWidth='lg'>
            <header className={classes.root}>
                <div className={classes.siteTitleContainer}>
                    <Link to={urls.home.url} className={classes.logo}>
                        <JiscLogo />
                        <Typography variant='body1' className={classes.title}>
                            {urls.home.title}
                        </Typography>
                    </Link>
                </div>
                <div className={classes.menuButtonWrap}>
                    <Button
                        data-testid='header-menu-button'
                        ref={menuAnchorRef}
                        aria-controls={menuOpen ? 'main-menu' : undefined}
                        aria-haspopup='true'
                        title='header-menu-button'
                        onClick={handleMenuToggle}
                        variant='outlined'
                        className={classes.menuButton}
                    >
                        <FontAwesomeIcon icon='bars' className={classes.menuIcon} />
                        <FontAwesomeIcon icon='user' />
                    </Button>
                    <Popper
                        open={menuOpen}
                        anchorEl={menuAnchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.dropDownMenu}
                    >
                        {({ TransitionProps }) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Grow {...TransitionProps}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleMenuClose}>
                                        <MenuList
                                            data-testid='header-menu'
                                            autoFocusItem={menuOpen}
                                            id='main-menu'
                                            onKeyDown={handleMenuListKeyDown}
                                        >
                                            {auth.token && (
                                                <div>
                                                    <MenuItem
                                                        key='header-menu-profile'
                                                        data-testid='header-menu-profile'
                                                        component={Link}
                                                        to={urls.profile.url}
                                                        onClick={handleMenuClose}
                                                    >
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='user' />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            data-testid='header-menu-profile-info'
                                                            primary={urls.profile.text}
                                                            secondary={
                                                                <span>
                                                                    <span data-testid='header-menu-profile-info-email'>
                                                                        {auth.user.email}
                                                                    </span>
                                                                    <br />
                                                                    <span data-testid='header-menu-profile-info-institution-name'>
                                                                        {auth.user.institution_name}
                                                                    </span>
                                                                </span>
                                                            }
                                                        />
                                                    </MenuItem>
                                                    <Divider key='header-menu-divider' className={classes.divider} />
                                                    <MenuItem
                                                        key='header-menu-dashboard'
                                                        data-testid='header-menu-dashboard'
                                                        component={Link}
                                                        to={urls.home.url}
                                                        onClick={handleMenuClose}
                                                    >
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='home' />
                                                        </ListItemIcon>
                                                        Home
                                                    </MenuItem>
                                                    <MenuItem
                                                        key='header-glossary'
                                                        data-testid='header-glossary'
                                                        component={Link}
                                                        to={urls.glossary.url}
                                                        onClick={handleMenuClose}
                                                    >
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='book' />
                                                        </ListItemIcon>
                                                        Resources
                                                    </MenuItem>
                                                    {[
                                                        enums.user.role.JISC_REPORTER,
                                                        enums.user.role.JISC_ADMIN,
                                                        enums.user.role.JISC_CONTENT
                                                    ].includes(auth.user.role) && (
                                                        <MenuItem
                                                            key='header-reports'
                                                            component={Link}
                                                            to={urls.reports.frontend.url}
                                                            onClick={handleMenuClose}
                                                        >
                                                            <ListItemIcon>
                                                                <FontAwesomeIcon icon='file-csv' />
                                                            </ListItemIcon>
                                                            Reports
                                                        </MenuItem>
                                                    )}
                                                    {auth.user.role === enums.user.role.ADMIN && (
                                                        <MenuItem
                                                            key='header-menu-organisation-settings'
                                                            data-testid='header-menu-organisation-settings'
                                                            component={Link}
                                                            to={`${urls.organisationSettings.url}/${auth.user.institution_id}`}
                                                            onClick={handleMenuClose}
                                                        >
                                                            <ListItemIcon>
                                                                <FontAwesomeIcon icon='cogs' />
                                                            </ListItemIcon>
                                                            {urls.organisationSettings.text}
                                                        </MenuItem>
                                                    )}
                                                    {auth.user.role === enums.user.role.JISC_CONTENT && (
                                                        <MenuItem
                                                            key='header-menu-content-management'
                                                            component={Link}
                                                            to={urls.contentManagement.resources.url}
                                                            onClick={handleMenuClose}
                                                        >
                                                            <ListItemIcon>
                                                                <FontAwesomeIcon icon='user-cog' />
                                                            </ListItemIcon>
                                                            Content Management
                                                        </MenuItem>
                                                    )}
                                                    {auth.user.role === enums.user.role.JISC_ADMIN && (
                                                        <>
                                                            <MenuItem
                                                                key='header-menu-organisation-overview'
                                                                data-testid='header-menu-organisation-overview'
                                                                component={Link}
                                                                to={urls.organisationsOverview.url}
                                                                onClick={handleMenuClose}
                                                            >
                                                                <ListItemIcon>
                                                                    <FontAwesomeIcon icon='list-alt' />
                                                                </ListItemIcon>
                                                                {urls.organisationsOverview.text}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key='header-menu-content-management'
                                                                component={Link}
                                                                to={urls.contentManagement.resources.url}
                                                                onClick={handleMenuClose}
                                                            >
                                                                <ListItemIcon>
                                                                    <FontAwesomeIcon icon='user-cog' />
                                                                </ListItemIcon>
                                                                Content Management
                                                            </MenuItem>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            {!auth.token && (
                                                <div>
                                                    <MenuItem
                                                        key='header-menu-register'
                                                        data-testid='header-menu-register'
                                                        component={Link}
                                                        to={urls.register.url}
                                                        onClick={handleMenuClose}
                                                    >
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='user-plus' />
                                                        </ListItemIcon>
                                                        {urls.register.text}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key='header-menu-login'
                                                        data-testid='header-menu-login'
                                                        component={Link}
                                                        to={urls.login.url}
                                                        onClick={handleMenuClose}
                                                    >
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='sign-in-alt' />
                                                        </ListItemIcon>
                                                        {urls.login.text}
                                                    </MenuItem>
                                                </div>
                                            )}
                                            <MenuItem
                                                key='header-menu-help'
                                                data-testid='header-menu-help'
                                                component={Link}
                                                to={urls.help.url}
                                                onClick={handleMenuClose}
                                            >
                                                <ListItemIcon>
                                                    <FontAwesomeIcon icon='question-circle' />
                                                </ListItemIcon>
                                                {urls.help.text}
                                            </MenuItem>
                                            {auth.token && (
                                                <div>
                                                    <Divider key='header-menu-divider' className={classes.divider} />
                                                    <MenuItem
                                                        key='header-menu-logout'
                                                        data-testid='header-menu-logout'
                                                        onClick={logout}
                                                    >
                                                        <ListItemIcon>
                                                            <FontAwesomeIcon icon='sign-out-alt' />
                                                        </ListItemIcon>
                                                        Logout
                                                    </MenuItem>
                                                </div>
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </header>
        </Container>
    );
}

export default SiteHeader;
