import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import JiscButton from '../JiscButton/JiscButton';
import { stripHtml } from '../../lib/helpers';
import styles from './NoteExport.styles';
import { get } from '../../lib/api';

/**
 * @param {string} date
 * @returns {string}
 */
function formatDateTime(date) {
    return DateTime.fromISO(date).toFormat('HH:mm dd/MM/yyyy');
}

/**
 * @param {string} date
 * @returns {string}
 */
function formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
}

function NoteExport() {
    const classes = styles();
    const { auth } = useAuthContext();

    const [notes, setNotes] = useState(null);
    const [error, setError] = useState(null);

    const [errorXlsx, setErrorXlsx] = useState(null);
    const [loadingXlsx, setLoadingXlsx] = useState(false);

    const getNotes = useCallback(async () => {
        try {
            setError(null);
            const { data } = await get(`/institutions/${auth.user.institution_id}/notes`, auth.token);
            setNotes(data);
        } catch (e) {
            setNotes([]);
            setError('There was a problem retriving the notes. Please try again later.');
        }
    }, [auth.token, auth.user.institution_id]);

    const downloadXLSX = useCallback(async () => {
        try {
            setLoadingXlsx(true);
            const {
                data: { url }
            } = await get(`/institutions/${auth.user.institution_id}/notes/export`, auth.token);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `notes-export-${formatDate(new Date().toISOString())}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            setErrorXlsx('There was a problem exporting xlsx. Please try again later.');
        } finally {
            setLoadingXlsx(false);
        }
    }, [auth.token, auth.user.institution_id]);

    useEffect(() => getNotes(), [getNotes]);

    return !notes ? (
        <p>Loading...</p>
    ) : (
        <div style={{ padding: '1rem' }}>
            <div className={classes.header}>
                <h1 className={classes.title}>Notes Export</h1>
                <p className={classes.description}>
                    This is a list of all notes that have been created in the Digital Elevation Tool
                </p>
                <div className={classes.actions}>
                    <div>
                        <JiscButton onClick={() => window.print()}>Download PDF</JiscButton>
                    </div>
                    <div>
                        <JiscButton onClick={() => downloadXLSX()} disabled={loadingXlsx}>
                            {loadingXlsx ? 'Downloading...' : 'Download XLSX'}
                        </JiscButton>
                        {errorXlsx && <p className={classes.error}>{errorXlsx}</p>}
                    </div>
                </div>
            </div>
            {error ? (
                <p className={classes.error}>{error}</p>
            ) : (
                notes.map((note, index) => {
                    const isFirstNote = index === 0;
                    const isSameCompetencyOption =
                        !isFirstNote && note.competency_option_id === notes[index - 1].competency_option_id;

                    return (
                        <div key={note.created_at} className={classes.outerContainer}>
                            {!isSameCompetencyOption && (
                                <div className={classes.question}>
                                    <div className={classes.questionText}>{stripHtml(note.text)}</div>
                                    <div className={classes.additionalInfo}>
                                        <p>
                                            <strong>Theme:</strong> {note.theme_title}
                                        </p>
                                        <p>
                                            <strong>Sub Theme:</strong> {note.sub_theme_title}
                                        </p>
                                        <p>
                                            <strong>Competency Statement:</strong> {stripHtml(note.statement)}
                                        </p>
                                        <p>
                                            <strong>Assessment area:</strong> {note.competency_title}
                                        </p>
                                        <p>
                                            <strong>Status</strong> {note.status}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className={classes.noteContainer}>
                                <p className={classes.noteContent}>{note.content}</p>
                                <div className={classes.noteInfo}>
                                    <p className={classes.createdBy}>{note.created_by}</p>
                                    <p className={classes.createdAt}>{formatDateTime(note.created_at)}</p>
                                </div>
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
}

export default NoteExport;
