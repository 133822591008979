import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Typography } from '@material-ui/core';

import { useAuthContext } from '../../lib/hooks/useAuthContext';
import useQueryParams from '../../lib/hooks/useQueryParams';

import urls from '../../config/urls';
import styles from './Verify.styles';

const Verify = () => {
    const query = useQueryParams();
    const history = useHistory();
    const classes = styles();
    const queryEmail = query.get('email');
    const queryToken = query.get('token');

    const { login } = useAuthContext();

    useEffect(() => {
        if (queryEmail && queryToken) {
            try {
                login(queryEmail, queryToken);
                history.push(urls.home.url); // logged in
            } catch (err) {
                history.push(urls.login.url); // invalid token
            }
        } else {
            history.push(urls.login.url); // no token
        }
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{urls.verify.title}</title>
                <link rel='canonical' href={urls.base.url + urls.verify.url} />
            </Helmet>
            <section className={classes.root}>
                <Container>
                    <Typography variant='body1'>Verifying...</Typography>
                </Container>
            </section>
        </>
    );
};

export default Verify;
