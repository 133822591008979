import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

import JiscLink from '../JiscLink/JiscLink';

import styles from './HtmlEditor.styles';

const HtmlEditor = ({ title, rows, placeholder, content, error, setContent }) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <FormHelperText>{title}</FormHelperText>
            <div className={classes.contentParent}>
                <div>
                    <FormHelperText>
                        <span>
                            <JiscLink href='https://wordtohtml.net/' target='_blank' type='external' rel='noreferrer'>
                                Click here
                            </JiscLink>{' '}
                            to use an external editor & paste the resulting html below
                        </span>
                        Editor
                    </FormHelperText>

                    <textarea
                        placeholder={placeholder}
                        className={classes.textarea}
                        rows={rows}
                        onChange={(e) => setContent(DOMPurify.sanitize(e.target.value))}
                    >
                        {DOMPurify.sanitize(content)}
                    </textarea>
                </div>
                <div>
                    <FormHelperText>Preview</FormHelperText>
                    <div className={classes.preview}>
                        {content ? (
                            parse(content)
                        ) : (
                            <div className={classes.previewMessage}>
                                <Typography variant='body1'>Content preview will be shown here.</Typography>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {error && <FormHelperText>{error}</FormHelperText>}
        </div>
    );
};

HtmlEditor.defaultProps = {
    rows: 20,
    error: undefined
};

HtmlEditor.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.number,
    placeholder: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    error: PropTypes.string,
    setContent: PropTypes.func.isRequired
};

export default HtmlEditor;
