import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, FormControl, TextField, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import PageBody from '../PageBody/PageBody';
import JiscLink from '../JiscLink/JiscLink';
import JiscButton from '../JiscButton/JiscButton';

import { login } from '../../lib/api';
import styles from './AuthForm.styles';

const AuthForm = ({ title, description, submitLabel }) => {
    const classes = styles();

    const [email, setEmail] = useState('');
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const [requestMade, setRequestMade] = useState(false);

    const handleLoginRequest = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await login('/login', {
                email
            });
            if (res.status === 200) {
                setRequestMade(true);
            }
        } catch (err) {
            setError(err);
        }
        setEmail('');
        setLoading(false);
    };

    useEffect(() => {
        if (email.length) setFormIsValid(true);
    }, [email]);

    return (
        <>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color='primary' />
            </Backdrop>
            <PageBody containerMaxWidth='sm'>
                {error && (
                    <Alert className={classes.alert} onClose={() => setError(null)} severity='error'>
                        <AlertTitle>There was an issue registering your account.</AlertTitle>
                        If this problem persists please contact{' '}
                        <JiscLink
                            href='mailto:help@jisc.ac.uk?subject=Digital Elevation Tool'
                            type='external'
                            background='light'
                        >
                            help@jisc.ac.uk
                        </JiscLink>
                    </Alert>
                )}

                {!requestMade ? (
                    <div className={classes.card}>
                        <form autoComplete='off' onSubmit={handleLoginRequest} className={classes.form}>
                            <Typography data-testid='auth-form-title' variant='h4'>
                                {title}
                            </Typography>
                            <Typography variant='body1' className={classes.formDescription}>
                                {description}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    data-testid='auth-form-input'
                                    id='email'
                                    type='email'
                                    label='Email'
                                    className={classes.input}
                                    fullWidth
                                    value={email}
                                    variant='outlined'
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>

                            <JiscButton
                                variant='primary'
                                type='submit'
                                className={classes.button}
                                disabled={!formIsValid}
                                dataTestId='authFormSubmit'
                            >
                                {submitLabel}
                            </JiscButton>
                        </form>
                    </div>
                ) : (
                    <div className={classes.card}>
                        <Typography variant='h4'>Please check your inbox</Typography>
                        <Typography variant='body1' className={classes.formDescription}>
                            An email has been sent to you with a log in link.
                        </Typography>
                        <Typography variant='body1' className={classes.formDescription}>
                            You can now close this window.
                        </Typography>
                    </div>
                )}
            </PageBody>
        </>
    );
};

AuthForm.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    submitLabel: PropTypes.string
};

AuthForm.defaultProps = {
    title: 'Title',
    description: 'Description',
    submitLabel: 'Submit'
};

export default AuthForm;
