import React from 'react';

function LeadershipGovernanceCulture() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='148' height='141' viewBox='0 0 148 141'>
            <defs>
                <clipPath>
                    <rect data-name='Rectangle 2268' width='138.47' height='138.47' fill='none' />
                </clipPath>
            </defs>
            <g transform='translate(-315 -2111)'>
                <g data-name='Layer 2' transform='translate(316.294 2111.529)'>
                    <g data-name='Layer 1'>
                        <g data-name='Group 2067'>
                            <g data-name='Group 2257' clipPath='url(#clip-path)'>
                                <g data-name='Group 2066'>
                                    <g data-name='Group 2057'>
                                        <path
                                            data-name='Path 1783'
                                            d='M118.09,40.77a20.39,20.39,0,1,1,20.38-20.387A20.39,20.39,0,0,1,118.09,40.77m.137-14.63a9.67,9.67,0,0,0-9.58,7.75l-.08.37.31.2a16.77,16.77,0,0,0,18.65-.15l.32-.21-.09-.37a9.89,9.89,0,0,0-9.53-7.59m-.14-22.59a16.85,16.85,0,0,0-16.84,16.83A16.63,16.63,0,0,0,105,30.92l.58.72.35-.85a12.94,12.94,0,0,1,5.84-6.54l.63-.35-.49-.51a8.76,8.76,0,1,1,12.64,0l-.49.52.62.34a13.49,13.49,0,0,1,5.75,6.31l.38.84.56-.73a16.66,16.66,0,0,0,3.57-10.32,16.85,16.85,0,0,0-16.85-16.8m.14,8.63a5.2,5.2,0,1,0,5.2,5.2,5.2,5.2,0,0,0-5.2-5.2'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2058'>
                                        <path
                                            data-name='Path 1784'
                                            d='M118.09,138.47a20.39,20.39,0,1,1,20.38-20.387,20.39,20.39,0,0,1-20.38,20.387m.137-14.63a9.9,9.9,0,0,0-7,2.88,9.72,9.72,0,0,0-2.59,4.87l-.08.37.31.2a16.81,16.81,0,0,0,18.65-.15l.32-.21-.09-.37a9.89,9.89,0,0,0-9.53-7.59m-.14-22.59a16.85,16.85,0,0,0-16.84,16.83A16.63,16.63,0,0,0,105,128.62l.58.72.35-.85a13.61,13.61,0,0,1,2.82-4.28,14.22,14.22,0,0,1,3-2.27l.63-.34-.49-.52a8.75,8.75,0,1,1,12.64,0l-.49.52.62.34a13.49,13.49,0,0,1,5.75,6.31l.38.84.56-.73a16.66,16.66,0,0,0,3.57-10.32,16.85,16.85,0,0,0-16.83-16.83m.14,8.63a5.2,5.2,0,1,0,5.2,5.2,5.2,5.2,0,0,0-5.2-5.2'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2059'>
                                        <path
                                            data-name='Path 1785'
                                            d='M20.39,40.77A20.39,20.39,0,1,1,40.77,20.383,20.39,20.39,0,0,1,20.39,40.77m.137-14.63A9.67,9.67,0,0,0,11,33.89l-.08.37.31.2a16.77,16.77,0,0,0,18.65-.15l.32-.21-.09-.37a9.89,9.89,0,0,0-9.53-7.59M20.39,3.55A16.85,16.85,0,0,0,3.55,20.38,16.63,16.63,0,0,0,7.28,30.92l.58.72.36-.85A13.42,13.42,0,0,1,11,26.51a13.85,13.85,0,0,1,3-2.26l.62-.35-.48-.51a8.76,8.76,0,1,1,12.64,0l-.49.52.62.34a13.49,13.49,0,0,1,5.75,6.31l.38.84.56-.73a16.66,16.66,0,0,0,3.57-10.32A16.85,16.85,0,0,0,20.39,3.55m.14,8.63a5.2,5.2,0,1,0,5.2,5.2,5.2,5.2,0,0,0-5.2-5.2'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2060'>
                                        <path
                                            data-name='Path 1786'
                                            d='M20.39,138.47a20.39,20.39,0,1,1,20.38-20.387A20.39,20.39,0,0,1,20.39,138.47m.137-14.63A9.67,9.67,0,0,0,11,131.59l-.08.37.31.2a16.81,16.81,0,0,0,18.6-.16l.32-.21-.09-.37a9.89,9.89,0,0,0-9.53-7.59m-.14-22.59A16.85,16.85,0,0,0,3.55,118.08a16.63,16.63,0,0,0,3.73,10.54l.58.72.36-.85A13.42,13.42,0,0,1,11,124.21a14.22,14.22,0,0,1,3-2.27l.62-.34-.48-.52a8.75,8.75,0,1,1,12.64,0l-.49.52.71.4a13.49,13.49,0,0,1,5.75,6.31l.38.84.56-.73a16.66,16.66,0,0,0,3.57-10.32,16.85,16.85,0,0,0-16.83-16.83m.14,8.63a5.2,5.2,0,1,0,5.2,5.2,5.2,5.2,0,0,0-5.2-5.2'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2061'>
                                        <path
                                            data-name='Path 1787'
                                            d='M91.28,17.33a56.6,56.6,0,0,0-44.09,0l-1.43-3.26a60.13,60.13,0,0,1,46.94,0Z'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2062'>
                                        <path
                                            data-name='Path 1788'
                                            d='M13.89,92.25a60,60,0,0,1,.18-46.48l3.26,1.42a56.54,56.54,0,0,0-.17,43.66Z'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2063'>
                                        <path
                                            data-name='Path 1789'
                                            d='M69.24,129.17a59.63,59.63,0,0,1-23-4.59l1.4-3.26a56.62,56.62,0,0,0,43.24,0l1.4,3.26a59.67,59.67,0,0,1-23,4.59'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2064'>
                                        <path
                                            data-name='Path 1790'
                                            d='M121.32,90.86a56.62,56.62,0,0,0-.18-43.67l3.26-1.43a60.07,60.07,0,0,1,.18,46.49Z'
                                            fill='#008e82'
                                        />
                                    </g>
                                    <g data-name='Group 2065'>
                                        <path
                                            data-name='Path 1791'
                                            d='M55.28,94.27A1.78,1.78,0,0,1,53.5,92.5v-.56H41.32a1.77,1.77,0,0,1-1.77-1.77V71H36.66a1.77,1.77,0,0,1-1.58-2.57l4.65-9.3a1.78,1.78,0,0,1,1.59-1H53.5V43.64a1.79,1.79,0,0,1,1.78-1.77h5.2V34.34a1.77,1.77,0,0,1,1.78-1.77h5.2V27.91H71v4.66h5.2A1.77,1.77,0,0,1,78,34.34v7.53h5.2A1.77,1.77,0,0,1,85,43.64v14.5H97.15a1.81,1.81,0,0,1,1.59,1l4.65,9.31a1.78,1.78,0,0,1-.79,2.38,1.85,1.85,0,0,1-.8.19H98.92V90.17a1.77,1.77,0,0,1-1.77,1.77H85v.56a1.77,1.77,0,0,1-1.77,1.77Zm14-17.51a6.43,6.43,0,0,1,6.43,6.43v7.53h5.76V45.42H57.06v45.3h5.75V83.19a6.43,6.43,0,0,1,6.42-6.43m0,3.56a2.88,2.88,0,0,0-2.87,2.87v7.53h5.75V83.19a2.87,2.87,0,0,0-2.87-2.87M85,88.4H95.37V71H85Zm-41.87,0h10.4V71H43.09ZM85,67.46H99l-2.88-5.75H85Zm-45.42,0h14V61.7H42.42ZM64,41.87H74.4V36.11H64Z'
                                            fill='#008e82'
                                        />
                                    </g>
                                </g>
                            </g>
                            <path data-name='Path 1793' d='M62.81,54.73h3.55V51.17H62.81Z' fill='#008e82' />
                            <path data-name='Path 1794' d='M62.81,64h3.55V60.48H62.81Z' fill='#008e82' />
                            <path data-name='Path 1795' d='M62.81,73.34h3.55V69.79H62.81Z' fill='#008e82' />
                            <path data-name='Path 1796' d='M72.11,54.73h3.55V51.17H72.11Z' fill='#008e82' />
                            <path data-name='Path 1797' d='M72.11,64h3.55V60.48H72.11Z' fill='#008e82' />
                            <path data-name='Path 1798' d='M72.11,73.34h3.55V69.79H72.11Z' fill='#008e82' />
                            <path data-name='Path 1799' d='M88.39,80.32h3.55V76.76H88.39Z' fill='#008e82' />
                            <path data-name='Path 1800' d='M46.52,80.32h3.56V76.76H46.52Z' fill='#008e82' />
                        </g>
                    </g>
                </g>
                <rect data-name='Rectangle 2290' width='148' height='141' transform='translate(315 2111)' fill='none' />
            </g>
        </svg>
    );
}

export default LeadershipGovernanceCulture;
