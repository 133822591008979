import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

import * as api from '../../../lib/api';

import { useSurveyContext } from '../../../lib/hooks/useSurveyContext';
import { useQuestionContext } from '../../../lib/hooks/useQuestionContext';

import SurveyNavigationButton from '../../SurveyNavigationButton/SurveyNavigationButton';
import Question from './Question/Question';
import JiscButton from '../../JiscButton/JiscButton';
import Modal from '../../Modal/Modal';

import urls from '../../../config/urls';
import keys from '../../../config/keys';
import styles from './QuestionStep.styles';
import { NoteHistory, NewNote } from '../../NoteModalContent/NoteModalContent';

function QuestionStep({
    question,
    handlePrevious,
    shouldDisablePrevious,
    handleForward,
    handleSendingResponse,
    shouldDisableNext,
    notes,
    notesLoading,
    handleSubmitNote,
    institutionId,
    theme
}) {
    const history = useHistory();
    const classes = styles();
    const [inProp, setInProp] = useState(false);
    const [userResponsesModal, setUserResponsesModal] = useState(false);
    const [noteHistoryModal, setNoteHistoryModal] = useState(false);

    // Reminder state
    const [reminderModal, setReminderModal] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [reminderDate, setReminderDate] = useState(new Date());
    const [reminderTimePeriod, setReminderTimePeriod] = useState('AM');
    const [reminderError, setReminderError] = useState(false);

    const { currentQuestion, responseOptions } = useSurveyContext();
    const { lastCompetencyId } = useQuestionContext();

    const duration = 350;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
        transform: 'translateY(25%)',
        opacity: 0
    };

    const transitionStyles = {
        entering: { opacity: 0, transform: `translateY(25%)` },
        entered: { opacity: 1, transform: `translateY(0)` },
        exiting: { opacity: 0 },
        exited: { opacity: 0, transform: `translateY(-50%)` }
    };

    const saveAndExit = () => {
        handleSendingResponse();
        localStorage.setItem(keys.storage.lastCompetencyId, lastCompetencyId);
        history.push(urls.home.url);
    };

    const next = () => {
        setInProp(false);
        handleSendingResponse();
        setTimeout(() => {
            handleForward();
            setInProp(true);
        }, 300);
    };

    const prev = () => {
        setInProp(false);
        handleSendingResponse();
        setTimeout(() => {
            handlePrevious();
            setInProp(true);
        }, 300);
    };

    const resetReminder = () => {
        setReminderModal(false);
        setReminder(false);
        setReminderDate(new Date());
        setReminderTimePeriod('AM');
        setReminderError(false);
    };

    const saveReminder = async () => {
        setReminderError(false);
        const questionNumber = currentQuestion.id;

        try {
            await api.post(`/institutions/${institutionId}/competency-options/${questionNumber}/reminders`, {
                reminderDateTime: reminderDate.toISOString(),
                period: reminderTimePeriod
            });
        } catch (err) {
            setReminderError(true);
            return;
        }

        resetReminder();
        saveAndExit();
    };

    useEffect(() => {
        setInProp(true);
    }, []);

    return (
        <Transition in={inProp} timeout={duration}>
            {(state) => (
                <>
                    <JiscButton
                        dataTestId='Save and Exit'
                        variant='ghost'
                        className={classes.saveExitButton}
                        linkType='internal'
                        onClick={() => setReminderModal(true)}
                    >
                        Save and Exit
                    </JiscButton>

                    <div className={classes.questionWrap}>
                        <div
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state]
                            }}
                        >
                            <Question question={question} theme={theme} />
                        </div>
                    </div>

                    <div className={classes.navigationButtonParent}>
                        <div>
                            <JiscButton
                                variant='ghost'
                                className={classes.saveExitButton}
                                onClick={() => {
                                    setNoteHistoryModal(true);
                                }}
                                aria-label='Notes'
                            >
                                {notesLoading ? (
                                    <CircularProgress size='16px' aria-label='Loading notes' />
                                ) : (
                                    <> Notes ({notes.length}) </>
                                )}
                            </JiscButton>
                        </div>
                        <div>
                            <JiscButton
                                dataTestId='View Question Responses'
                                variant='ghost'
                                className={classes.saveExitButton}
                                href={urls.home.url}
                                onClick={() => setUserResponsesModal(true)}
                            >
                                View Question Responses
                            </JiscButton>
                        </div>
                        <div>
                            <SurveyNavigationButton
                                disable={shouldDisablePrevious}
                                label='Previous'
                                icon={<FontAwesomeIcon icon='arrow-left' />}
                                iconPlacement='left'
                                clickAction={prev}
                            />
                        </div>
                        <div>
                            <SurveyNavigationButton
                                disable={shouldDisableNext}
                                label='Next'
                                icon={<FontAwesomeIcon icon='arrow-right' />}
                                iconPlacement='right'
                                clickAction={next}
                            />
                        </div>
                    </div>

                    <Modal
                        title='Notes'
                        open={noteHistoryModal}
                        maxWidth='md'
                        setModalOpen={() => setNoteHistoryModal(false)}
                        useDefaultDialog={false}
                    >
                        <NoteHistory notes={notes} notesLoading={notesLoading} />
                        <NewNote handleSubmitNote={handleSubmitNote} setNoteHistoryModal={setNoteHistoryModal} />
                    </Modal>

                    <Modal
                        open={userResponsesModal}
                        title='User Responses'
                        maxWidth='sm'
                        setModalOpen={setUserResponsesModal}
                    >
                        <TableContainer>
                            <Table data-testid='user-responses-table'>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell>Response Option</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Updated At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentQuestion?.latestResponses.map((response) => {
                                        return (
                                            <TableRow
                                                key={response}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align='left'>
                                                    {responseOptions[response.response_option_id - 1].secondary_label}
                                                </TableCell>
                                                <TableCell align='left'>{response.answered_by}</TableCell>
                                                <TableCell align='left'>
                                                    {DateTime.fromISO(response.updated_at).toFormat('DD')}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Modal>

                    <Modal open={reminderModal} title='Set a reminder' maxWidth='sm' setModalOpen={resetReminder}>
                        {reminder ? (
                            <div>
                                <Typography>When would you like your reminder to be sent?</Typography>
                                <div id='new-theme' className={classes.reminderModalForm} onSubmit={saveReminder}>
                                    <TextField
                                        id='date'
                                        defaultValue={reminderDate.toISOString().split('T')[0]}
                                        label='Reminder date'
                                        type='date'
                                        onChange={(e) => setReminderDate(new Date(e.target.value))}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <InputLabel id='time-period'>Time period (required)</InputLabel>
                                    <Select
                                        className={classes.select}
                                        labelId='time-period'
                                        label='Time period'
                                        defaultValue={reminderTimePeriod}
                                        onChange={(e) => {
                                            setReminderTimePeriod(e.target.value);
                                        }}
                                        inputProps={{
                                            ariaLabel: 'Time period'
                                        }}
                                    >
                                        <MenuItem value='AM'>AM</MenuItem>
                                        <MenuItem value='PM'>PM</MenuItem>
                                    </Select>
                                    {reminderError && (
                                        <Alert className={classes.addNoteError} severity='error'>
                                            There was an error setting your reminder. Please try again.
                                        </Alert>
                                    )}
                                    <JiscButton
                                        variant='primary'
                                        className={classes.modalButton}
                                        onClick={saveReminder}
                                    >
                                        Set reminder
                                    </JiscButton>
                                </div>
                            </div>
                        ) : (
                            <>
                                <Typography>
                                    Would you like to set a reminder to come back and complete your question set?
                                </Typography>
                                <div className={classes.reminderModalContent}>
                                    <JiscButton
                                        variant='ghost'
                                        onClick={() => setReminder(true)}
                                        className={classes.modalButton}
                                    >
                                        Yes
                                    </JiscButton>
                                    <JiscButton variant='ghost' onClick={saveAndExit} className={classes.modalButton}>
                                        No
                                    </JiscButton>
                                </div>
                            </>
                        )}
                    </Modal>
                </>
            )}
        </Transition>
    );
}

QuestionStep.propTypes = {
    question: PropTypes.shape({
        competency_option_id: PropTypes.number.isRequired,
        competency_option_text: PropTypes.string.isRequired,
        latestResponses: PropTypes.shape({})
    }).isRequired,
    handleForward: PropTypes.func.isRequired,
    shouldDisablePrevious: PropTypes.bool.isRequired,
    handlePrevious: PropTypes.func.isRequired,
    handleSendingResponse: PropTypes.func.isRequired,
    shouldDisableNext: PropTypes.bool.isRequired,
    notes: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
            created_by: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired
        })
    ).isRequired,
    notesLoading: PropTypes.bool.isRequired,
    handleSubmitNote: PropTypes.func.isRequired,
    institutionId: PropTypes.number.isRequired,
    theme: PropTypes.shape({
        theme_id: PropTypes.number,
        theme_title: PropTypes.string,
        theme_icon: PropTypes.number,
        theme_color: PropTypes.string,
        sub_themes: PropTypes.shape({}).isRequired
    }).isRequired
};

export default QuestionStep;
