import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        color: theme.palette.jisc.black,
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            top: 7,
            left: -30,
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            backgroundColor: theme.palette.fes.navy,
            transition: 'color 150ms ease'
        }
    },
    title: {
        fontWeight: '500',
        position: 'relative',
        marginBottom: '0.75rem',
        '&:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: '100%',
            height: '0.125rem',
            width: '100%',
            opacity: 0.3,
            backgroundColor: ({ color }) => color
        }
    },
    compItem: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '0.75rem',
        color: theme.palette.fes.navy,
        '&:last-child': {
            paddingBottom: 0
        }
    },
    subThemeButton: {
        border: 0,
        textAlign: 'left',
        backgroundColor: 'transparent',
        color: theme.palette.fes.navy,

        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    active: {
        fontWeight: 'bold',
        color: ({ color }) => color
    }
}));

export default styles;
