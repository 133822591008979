import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const ResultsContext = createContext();

function ResultsContextProvider({ children }) {
    const [selectedReports, setSelectedReports] = useState({
        yourCurrentAnswers: true,
        versionHistoryAllAnswers: false,
        simpleOverview: false
    });
    const [selectedReportFormats, setSelectedReportFormats] = useState({
        xls: true,
        csv: false
    });
    const context = {
        selectedReports,
        setSelectedReports,
        selectedReportFormats,
        setSelectedReportFormats
    };

    return <ResultsContext.Provider value={context}>{children}</ResultsContext.Provider>;
}

ResultsContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export { ResultsContext, ResultsContextProvider };
