import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(6, 0, 20, 0),
        '& section': {
            [theme.breakpoints.up('lg')]: {
                maxWidth: '70%',
                margin: `0 auto ${theme.spacing(10)}`
            }
        }
    },
    asSection: {
        [theme.breakpoints.up('lg')]: {
            maxWidth: '70%',
            margin: `0 auto ${theme.spacing(3)}`
        }
    },
    actionPlan: {
        margin: theme.spacing(2, 0),
        verticalAlign: 'bottom'
    },
    hero: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gap: theme.spacing(3),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '9.375rem auto',
            columnGap: theme.spacing(5),
            rowGap: theme.spacing(3),
            '& svg': {
                gridRow: '1/3'
            }
        }
    },
    heroTitle: {
        fontSize: '2.5rem',
        fontWeight: 300,
        wordBreak: 'break-word',
        lineHeight: '114%',
        color: theme.palette.fes.headline,
        [theme.breakpoints.up('lg')]: {
            fontSize: '3.5rem'
        }
    },
    subTitle: {
        color: theme.palette.fes.headline
    },
    goalAccordionSection: {
        padding: theme.spacing(2, 0)
    },
    accordians: {
        position: 'relative'
    },
    goalsStickyMenu: {
        display: 'none',
        position: 'absolute',
        height: '100%',
        right: 0,
        width: theme.spacing(49.2),
        top: theme.spacing(9),
        zIndex: 1,
        '& > div': {
            position: 'sticky',
            overflowY: 'auto',
            maxHeight: '90vh',
            top: theme.spacing(5),
            padding: theme.spacing(2, 3),
            backgroundColor: theme.palette.jisc.white,
            boxShadow: `0px 1px 3px 0px ${theme.palette.jisc.silver}`,
            borderRadius: '0.313rem'
        },
        '& a': {
            display: 'block',
            textDecoration: 'none !important',
            '& p': {
                fontWeight: 500,
                color: ({ color }) => color
            }
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            right: `calc(-${theme.spacing(49.2)} + -${theme.spacing(3)})`
        }
    },
    goalsStickyMenuAccordion: {
        border: 'none',
        margin: '0 !important',
        padding: 0
    },
    goalsStickyMenuAccordionSummary: {
        padding: 0
    },
    goalsStickyMenuAccordionDetails: {
        padding: theme.spacing(0, 0, 0, 2),
        '& a': {
            marginBottom: theme.spacing(1)
        }
    },
    parentDiv: {
        display: 'flex',
        width: 'fit-content',
        margin: '0 0 0 auto',
        alignItems: 'flex-end',
        '& p': { lineHeight: 1.6 }
    },
    parentDivLeft: {
        display: 'flex',
        width: 'fit-content',
        margin: '0 0 auto 0',
        alignItems: 'flex-end',
        '& p': { lineHeight: 1.6 }
    },
    parentDivBoth: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        '& p': { lineHeight: 1.6 },
        '& svg': { marginBottom: '-0.5rem' }
    },
    arrowLeft: {
        fontSize: '1.5rem',
        margin: theme.spacing(0, 2),
        color: 'grey'
    },
    left: {
        textAlign: 'right'
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none !important',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.up('lg')]: {
            maxWidth: '70%',
            margin: `auto auto ${theme.spacing(6)}`
        },
        '& svg': {
            display: 'block',
            width: '1.25rem',
            height: '1.25rem',
            color: theme.palette.jisc.blue,
            marginRight: theme.spacing(2)
        }
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        '& select': {
            background: 'transparent',
            padding: 0,
            margin: theme.spacing(0, 1, 0, 0.5),
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        }
    }
}));

export default styles;
