import { makeStyles } from '@material-ui/core';
import { hexToRGB } from '../../lib/helpers';

const styles = makeStyles((theme) => ({
    root: {
        border: ({ color }) => `1px solid ${color} !important`,
        borderRadius: '0.6rem !important',
        overflow: 'hidden',
        margin: `${theme.spacing(3, 0)} !important`
    },
    summary: {
        alignItems: 'flex-start',
        padding: 0,
        border: 'none',
        '& header': {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: ({ color }) => color,
            padding: theme.spacing(2, 0, 2, 4)
        },
        '& > div': {
            display: 'block',
            margin: '0 !important',

            '& > div': {
                padding: theme.spacing(2, 0, 2, 4)
            },

            '&:last-child': {
                backgroundColor: ({ color }) => color,
                padding: theme.spacing(2),
                borderRadius: 0,
                transform: 'none !important',
                transition: 'none !important'
            }
        }
    },
    secondaryHeading: {
        fontWeight: 500
    },
    details: {
        display: 'block',
        padding: theme.spacing(0, 4),
        border: 'none'
    },
    accordionBody: {
        margin: theme.spacing(4.625, 0),
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2)
        },
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    goal: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    resources: {
        backgroundColor: ({ color }) => hexToRGB(color, 0.1),
        '& >:first-child': {
            marginTop: theme.spacing(2)
        },
        '& >:last-child': {
            marginBottom: theme.spacing(2)
        },
        '& li': {
            marginBottom: theme.spacing(2),
            '&:last-child': {
                marginBottom: 0
            }
        }
    },
    resourceLink: {
        fontSize: '1rem',
        color: ({ color }) => color,
        textDecoration: 'underline'
    },
    resourceLinkContact: {
        display: 'grid',
        gap: theme.spacing(2),
        gridTemplateColumns: '4rem auto',
        margin: theme.spacing(2, 0, 0, 0),
        '& span': {
            display: 'block',
            marginBottom: theme.spacing(0.5)
        }
    },
    resourceHtml: {
        ...theme.typography.body1,
        '& a': {
            color: theme.palette.primary.main,
            '&:visited': {
                color: theme.palette.jisc.purple
            },
            '&:focus': {
                color: theme.palette.jisc.black,
                textDecoration: 'none',
                backgroundColor: theme.palette.yellow,
                outline: '.25rem solid transparent',
                boxShadow: `0 .25rem 0 0 ${theme.palette.yellow}, 0 -.25rem 0 0 ${theme.palette.yellow}`
            },
            '&:hover': {
                color: theme.palette.jisc.blueDark,
                textDecoration: 'underline',
                transition: 'color .2s ease'
            },
            '&:focus:hover': {
                color: theme.palette.jisc.black,
                textDecoration: 'none',
                backgroundColor: theme.palette.yellow,
                outline: '.25rem solid transparent',
                boxShadow: `0 .25rem 0 0 ${theme.palette.yellow}, 0 -.25rem 0 0 ${theme.palette.yellow}`
            },
            '&:active': {
                color: theme.palette.jisc.black
            },
            '&:focus:active': {
                textDecoration: 'underline',
                backgroundColor: 'transparent',
                outline: 'none',
                boxShadow: 'none'
            }
        }
    },
    statementTitle: {
        ...theme.typography.h6,
        display: 'block',
        color: '#757575',
        fontWeight: 400,
        '& p': {
            margin: 0,
            display: 'inline'
        }
    },
    competencyOption: {
        padding: theme.spacing(2, 2),
        '& p': {
            margin: 0
        }
    },
    competencyOptionTableHeader: {
        '& p': {
            color: theme.palette.text.primary,
            fontWeight: 500
        }
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: ({ color }) => color,
        padding: theme.spacing(2, 4, 2, 4),
        '& h5': {
            color: theme.palette.white
        }
    },
    chevron: {
        color: theme.palette.white,
        fontSize: '1rem',
        padding: theme.spacing(0.5)
    },
    expandIcon: {
        padding: 0
    },
    accordionSummary: {
        backgroundColor: ({ color }) => color,
        color: theme.palette.white,
        padding: theme.spacing(2, 4, 2, 4),
        '& *': {
            margin: 0
        }
    },
    heading: {
        display: 'block',
        color: theme.palette.white
    },
    circleRoot: {
        float: 'left',
        position: 'relative',
        display: 'flex',
        gap: theme.spacing(1.5)
    },
    bottom: {
        color: theme.palette.white,
        marginTop: theme.spacing(0.25),
        marginLeft: theme.spacing(0.5)
    },
    top: {
        color: ({ color }) => hexToRGB(color, 0.6),
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        marginTop: theme.spacing(0.25),
        marginLeft: theme.spacing(0.5)
    },
    circle: {
        float: 'left',
        strokeLinecap: 'round'
    },
    completed: {
        float: 'right'
    },
    check: {
        color: theme.palette.white,
        marginTop: theme.spacing(0.625),
        marginLeft: theme.spacing(0.5)
    },
    accordionSummaryParent: {
        border: 'none'
    },
    accordionSummaryTitle: {
        fontSize: '1.5rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
        color: '#fff'
    },
    accordionSummaryFooter: {
        fontSize: '1.5rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
        color: '#fff'
    }
}));

export default styles;
